import React, { useContext, useState } from 'react';
import Reviewcard from "../utils/Reviewcard.json"
import { Link, NavLink } from "react-router-dom";
import { BsCalendarPlus, BsWhatsapp } from "react-icons/bs";
import { FiCheck } from "react-icons/fi";
import RefContext from '../Context/RefContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import ButtonLoader from './ButtonLoader';


function Body() {

  const a = useContext(RefContext);
  const date = new Date().toDateString();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const API = process.env.REACT_APP_API_URL;
  const SHEET_API = process.env.REACT_APP_SHEET_API_URL;
  const ref = useContext(RefContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let data = {
      "date": date,
      "event_name": "Contact",
      "name": name,
      "email": email,
      "phone_number": phone,
      "ref": "Contact us"
    }

    try {
      if (phone.length === 10) {
        const result = await axios.post(API, data,
          {
            headers: {
              'Content-Type': "text/plain;charset=utf-8"
            },
            'mode':"no-cors"
          }).then((resp) => {
            if (resp.status===200) {

              setLoading(false);
              setName('');
              setEmail('');
              setPhone('');
              Swal.fire(
                'Thank You For Inquiry , we will contact You soon on Whatsapp',
                'Your form is succesfully submitted!',
                'success'
              )
            }
          

          }).catch(error => {
           
              setLoading(false);
              Swal.fire(
                "Something went wrong",
                "Try after few minutes",
                "error"
              );
            
          });
        // const googleSheet = await axios.post(SHEET_API, formData);

        // if (result.status === 200 && googleSheet.status === 200) {
          console.log("data",result)
        
      } else {
        Swal.fire(
          "Something went wrong",
          "Mobile Number Not Valid",
          "error"
        );
      }
      

    } catch (error) {
      setLoading(false);
      Swal.fire(error.message, "Try after few minutes", "error");
    }

  }

  return (
    <>
      <div className='main-containerr'>
        {/* top-section */}
        <div className='bg-white '>
          <div className='w-[90%] mx-auto xs:pb-10 sm:pb-10 md:pb-10 lg:py-20 xl:py-12 2xl:py-12 xs:px-6 sm:px-6 md:px-8 lg:px-2 xl:px-5 2xl:px-5 xs:w-full sm:w-full md:w-full xl:w-[80%] 2xl:w-[80%]'>

            <div className='grid grid-cols-2 gap-5 pt-10 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xs:pt-0 sm:pt-0 md:pt-0'>
              {/* content-side */}
              <div className=' xs:pt-4 sm:pt-4 md:pt-6 lg:pt-4 xl:pt-14 pt-[128px]'>
                <h1 className='text-[#1E1E24] text-6xl font-bold xs:text-[35px] sm:text-[38px] md:text-[40px] xl:text-7xl 2xl:text-7xl'>Save <span className='text-[#1D70ED]'>Tax</span> <br /> Save <span className='text-[#1D70ED]'>Money</span></h1>
                <p className='py-4 text-[#73739a] text-xl xs:text-base sm:text-base md:text-lg'>Tax2clear helps you file your income tax return easily, so you can get the maximum possible refund.</p>
                <Link to="/assistedtax">
                  <div className='flex items-center'>
                    <p className='mt-3 py-2.5 px-[100px] xs:px-[20px] sm:px-[20px] md:px-[30px] bg-[#0F52BA] rounded-[4px] text-white hover:bg-[#0B3B84] '>
                      <span>Free consultation</span>
                    </p>
                    <div className='bg-white mt-3 py-3.5 px-3.5 rounded-r-lg '>
                      <p className='text-[#000]'><BsCalendarPlus /></p>
                    </div>
                  </div>
                </Link>
              </div>
              {/* video-side */}
              <div className=' xs:order-1 sm:order-1 md:order-1 cursor-pointer'>
                <video autoPlay muted loop className='main-header-animetion'>
                  <source src='hro-main-animation-tax.mp4'></source>
                </video>
              </div>
            </div>
          </div>
        </div>

        {/* IncomeTax-Refund and form */}

        <div className='container mx-auto sm:max-w-full xs:max-w-full'>
          <div className='grid grid-cols-2 py-14 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1'>

            <div className='incomeTaxRefund px-10 xs:px-5 sm:px-24 md:px-20 lg:ps-14 lg:pe-0 xl:px-20 2xl:px-20 '>
              <div className='incomeTaxt-Image'>
                <img src="Income-Tax.svg" alt='image' className='h-9/12 w-9/12 xs:m-auto' />
              </div>
              <div className='incomeTax-data'>
                <h2 className='text-2xl font-bold mt-10 xs:text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl'>Income-Tax Return</h2>
                <p className='text-lg mt-4 text-[#73739a]'>We Can Prepare a tax Return and get a refund of incometax,tax collected at source or tax deducted at source</p>
              </div>
            </div>

            {/* form */}
            <div className='xl:h-full 2xl:h-full flex items-center'>
              <div className='px-6 py-8 sx:px-5 sm:px-20 sm:pt-16 md:px-20 md:pt-16 lg:px-0 lg:w-full'>
                <form onSubmit={handleSubmit} className='mx-auto bg-gray-100 rounded py-6 px-8 xs:w-full sm:w-full md:w-full lg:w-4/5 xl:w-3/4 2xl:w-3/5'>
                  <h1 className='py-2 text-xl font-bold text-center'>Contact us</h1>
                  <div className='py-4'>
                    <input onChange={(e) => setName(e.target.value)} type='text' value={name} placeholder='Full Name' required className='w-full py-1.5 px-2.5 border rounded border-[#ccc] text-[#555] focus:outline-0 focus:border-[#66afe9]' />

                    <input onChange={(e) => setEmail(e.target.value)} type='email' value={email} placeholder='Email Address' required className='mt-6 w-full py-1.5 px-2.5 border rounded border-[#ccc] text-[#555] focus:outline-0 focus:border-[#66afe9]' />
                    <input onChange={(e) => setPhone(e.target.value)} type='tel' value={phone} placeholder='Mobile Number' maxLength='10' required className='mt-6 w-full py-1.5 px-2.5 border rounded border-[#ccc] text-[#555] focus:outline-0 focus:border-[#66afe9]' />
                  </div>
                  <button type="submit" className='text-white bg-[#0F52BA] w-full rounded-[4px] mt-5 py-2 text-center'>{loading === true ? <ButtonLoader /> : "SEND DETAILS"}</button>
                </form>
              </div>
            </div>

          </div>
        </div>


        <div className='bg-[#F7F8F8] py-12 xs:py-8 sm:py-8 md:py-10'>
          {/* what we do */}
          <div className=' py-6 w-[90%] mx-auto xs:w-full sm:w-full md:w-full xl:w-[70%] 2xl:w-[70%] xs:px-4 sm:px-4 md:px-6'>
            <div className='text-center w-4/5 mx-auto xs:w-full sm:w-full md:w-full'>
              <p className='text-[#1D70ED] capitalize tracking-wider font-bold'>Welcome to Tax2clear Universe</p>
              <h2 className='py-2 text-2xl font-bold xs:text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl'>What We do</h2>
              <p className='text-[#73739a]'>We have changed the way tax filing is done in india. Our tax return filing service doesn't just get your tax return filed in minutes - we get you maximum refunds in the shortest time possible. </p>
            </div>

            <div className='mt-6 grid grid-cols-2 gap-8 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1'>
              {/* /file your retuen */}
              <div className='bg-white rounded-2xl pb-6 pt-4'>
                <div className='h-[100px] pl-8 '><img className='w-20' src='icon-1.svg.svg' /></div>
                <div className='px-8 xs:px-6 sm:px-6'>
                  <h1 className='font-bold text-2xl text-[#001F00]'>File Your Return</h1>
                  <p className='text-[#73739a] py-2 h-[80px]'>File all type of Income Tax return with Tax2clear on due date and Save Time, interest and late fees and penalty.</p>
                  <div className='py-5 flex justify-center items-center gap-6'>
                    <Link to='/fileyourreturn'>
                      <button onClick={() => a.setRef("File Your Return")} className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#0F52BA] text-white hover:bg-[#0B3B84] flex items-center gap-2 xs:text-sm sm:text-sm md:text-[15px]'>
                        <span>Book a Free call</span>
                      </button>
                    </Link>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#F4F6F7] text-[#51636F] flex items-center gap-2 xs:text-sm sm:text-sm md:text-[15px]'>
                      <p><BsWhatsapp /></p>
                      <span>WhatsApp us</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* upload-form 16 */}
              <div className='bg-white rounded-2xl pb-6 pt-4'>
                <div className='h-[100px] pl-8'><img className='w-20' src='upload-form.svg' /></div>
                <div className='px-8 xs:px-6 sm:px-6'>
                  <h1 className='font-bold text-2xl text-[#001F00]'>Upload Form-16</h1>
                  <p className='text-[#73739a] py-2 h-[80px]'>Income Tax Return Filing is easy with Tax2clear and the smartest way to online file your taxes return is to just upload your form 16 and get your ITR prepared.</p>
                  <div className='py-4 xs:py-10 flex justify-center items-center gap-6'>
                    <NavLink to='/form16'>
                      <button className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#0F52BA] text-white hover:bg-[#0B3B84] flex items-center gap-2'>
                        <span>Upload Form</span>
                      </button>
                    </NavLink>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Upload+Form-16&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#F4F6F7] text-[#51636F] flex items-center gap-2'>
                      <p><BsWhatsapp /></p>
                      <span>WhatsApp us</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* tax filling */}
              <div className='bg-white rounded-2xl pb-6 pt-4'>
                <div className='h-[100px] pl-8'><img className='w-20' src='assistant.svg' /></div>
                <div className='px-8 xs:px-6 sm:px-6'>
                  <h1 className='font-bold text-2xl text-[#001F00]'>Get a Personal eCA</h1>
                  <p className='text-[#73739a] py-2.5 h-[100px]'>Get your personal eCA to do tax filing for you. And it is personal, convenient & fast with maximum refund calculated for you.</p>
                  <div className='pt-4 flex justify-center items-center gap-6'>
                    <NavLink to='/eca'>
                      <button onClick={() => { a.setRef("Get a Personal eCA") }} className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#0F52BA] text-white hover:bg-[#0B3B84] flex items-center gap-2'>
                        <span>Book a Free call</span>
                      </button>
                    </NavLink>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Get+a+Personal+eCA&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#F4F6F7] text-[#51636F] flex items-center gap-2'>
                      <p><BsWhatsapp /></p>
                      <span>WhatsApp us</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* refund status */}
              <div className='bg-white rounded-2xl pb-6 pt-4'>
                <div className='h-[100px] pl-8'><img className='w-20' src='refund.svg' /></div>
                <div className='px-8 xs:px-6 sm:px-6'>
                  <h1 className='font-bold text-2xl text-[#001F00]'>Taxes on Winnings from Online gaming websites and apps</h1>
                  <p className='text-[#73739a] py-2 h-[110px]'>Do you have great gaming skills and earn daily on online gaming platforms but find it hard to file taxes? Govt has made it mandatory to file taxes for winnings from online platforms.</p>
                  <div className='py-4 xs:py-10 flex justify-center items-center gap-6'>
                    <NavLink to='/taxonwinnings'>
                      <button onClick={() => a.setRef("Taxes on Winnings from Online gaming websites and apps")} className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#0F52BA] text-white hover:bg-[#0B3B84] flex items-center gap-2'>
                        <span>Book a Free call</span>
                      </button>
                    </NavLink>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Taxes+on+Winnings+from+Online+gaming+websites+and+apps&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#F4F6F7] text-[#51636F] flex items-center gap-2'>
                      <p><BsWhatsapp /></p>
                      <span>WhatsApp us</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* plan tax */}
              <div className='bg-white rounded-2xl pb-6 pt-4'>
                <div className='pt-3 pl-8'><img className='w-16' src='file-ntr.png' /></div>
                <div className='px-8 pt-6 xs:px-6 sm:px-6'>
                  <h1 className='font-bold text-2xl text-[#001F00]'>Calculate Capital Gain on Share or Property </h1>
                  <p className='text-[#73739a] py-2.5'>Calculating capital gains on shares or property involves many steps. For advice on how to save the most money on your capital gains, feel free to contact us.</p>
                  <div className='py-4 flex justify-center items-center gap-6'>
                    <NavLink to='/capitalgainproduct'>
                      <button onClick={() => a.setRef("Calculate Capital Gain on Share or Property ")} className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#0F52BA] text-white hover:bg-[#0B3B84] flex items-center gap-2'>
                        <span>Book a Free call</span>
                      </button>
                    </NavLink>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Calculate+Capital+Gain+on+Share+or+Property&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#F4F6F7] text-[#51636F] flex items-center gap-2'>
                      <p><BsWhatsapp /></p>
                      <span>WhatsApp us</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* ITR Filling  */}
              <div className='bg-white rounded-2xl pb-6 pt-4'>
                <div className='pl-8 pt-3'><img className='w-16' src='plan-tax.png' /></div>
                <div className='px-8 pt-6 xs:px-6 sm:px-6'>
                  <h1 className='font-bold text-2xl text-[#001F00]'>NRI ITR filling in India</h1>
                  <p className='text-[#73739a] py-2.5 h-[110px]'>Do you live Outside India & have an income from India through sale of property or by other means? File your taxes with our inhouse expert e-CAs</p>
                  <div className='pt-8 flex justify-center items-center gap-6'>
                    <NavLink to='/ntrtax'>
                      <button onClick={() => a.setRef("NRI ITR filling in India")} className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#0F52BA] text-white hover:bg-[#0B3B84] flex items-center gap-2'>
                        <span>Book a Free call</span>
                      </button>
                    </NavLink>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=NRI+ITR+filling+in+India&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#F4F6F7] text-[#51636F] flex items-center gap-2'>
                      <p><BsWhatsapp /></p>
                      <span>WhatsApp us</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Who we serve */}
          <div className=' py-10 w-[90%] mx-auto xs:w-full sm:w-full md:w-full xl:w-[70%] 2xl:w-[70%] xs:px-4 sm:px-4 md:px-6 xs:py-6 sm:py-6 md:py-8'>
            <h1 className='text-center text-[42px] font-bold xs:text-2xl sm:text-2xl md:text-3xl'>Who <span className='text-[#1D70ED]'>We Serve</span></h1>
            <div className='py-8 flex items-center justify-between xs:py-6 sm:py-6 md:py-6 xs:grid xs:grid-cols-3 xs:gap-5 sm:grid sm:grid-cols-3 sm:gap-5 md:grid md:grid-cols-3 md:gap-5'>
              <div>
                <img className='w-20 xs:w-16 sm:w-16 md:w-16 mx-auto' src='salary_person.svg' />
                <p className='font-semibold text-lg xs:text-sm sm:text-sm md:text-base'>Salary Person</p>
              </div>
              <div>
                <img className='w-20 xs:w-16 sm:w-16 md:w-16 mx-auto' src='startup.svg' />
                <p className='font-semibold text-center text-lg xs:text-sm sm:text-sm md:text-base xs:px-2'>Start up</p>
              </div>
              <div>
                <img className='w-20 xs:w-16 sm:w-16 md:w-16 mx-auto' src='MSMEs.svg' />
                <p className='font-semibold text-center text-lg xs:text-sm sm:text-sm md:text-base'>MSMEs</p>
              </div>
              <div>
                <img className='w-20 xs:w-16 sm:w-16 md:w-16 mx-auto mt-1' src='businessmen.svg' />
                <p className='font-semibold text-lg xs:text-sm sm:text-sm md:text-sm'>Businessmen</p>
              </div>
              <div>
                <img className='w-20 xs:w-16 sm:w-16 md:w-16 mx-auto' src='contractor.svg' />
                <p className='font-semibold text-lg xs:text-sm sm:text-sm md:text-base'>Contractors</p>
              </div>
              <div>
                <img className='w-20 xs:w-16 sm:w-16 md:w-16 mx-auto' src='account_holder.svg' />
                <p className='font-semibold text-center text-lg xs:text-sm sm:text-sm md:text-base'>Demat Account Holder</p>
              </div>
            </div>
          </div>

        </div>

        {/* we use technologies */}
        <div className='w-[90%] mx-auto py-8 xs:py-5 sm:py-5 md:py-6 xs:px-4 sm:px-4 md:px-6 xs:w-full sm:w-full md:w-full '>
          <h1 className='text-center text-[42px] font-bold xs:text-2xl sm:text-2xl md:text-3xl text-[#0F52BA]'>Technologies  We Use</h1>
          <div className='pt-6 grid grid-cols-5  gap-8 xs:grid-cols-6 sm:grid-cols-6 xs:w-full sm:w-full xs:justify-items-start sm:justify-items-start xs:gap-x-28 sm:gap-x-28 md:gap-x-28 scrollbar-hide xs:overflow-x-auto sm:overflow-x-auto md:overflow-x-auto'>
            <div className='technologies'>
              <img className='grayscale  max-w-none w-40 mt-2 xs:w-[90px] sm:w-[100px] md:w-[100px] m-auto mt-[-1px]' src='tally.png' />
            </div>
            <div className='technologies'>
              <img className='grayscale  max-w-none w-36 -mt-12  mx-auto xs:w-[100px] sm:w-[100px] md:w-[100px] xs:-mt-9 sm:-mt-9 md:-mt-9' src='genius.png' />
            </div>
            <div className='technologies'>
              <img className='grayscale  max-w-none w-36 mx-auto mt-1 xs:mx-4 sm:mx-4 md:mx-4 xs:w-[75px] sm:w-[80px] md:w-[80px] xs:mt-2 sm:mt-1 md:mt-1' src='suvit.png' />
            </div>
            <div className='technologies'>
              <img className='grayscale  max-w-none w-48 mx-auto xs:w-[110px] sm:w-[110px] md:w-[110px] xs:mt-1 sm:mt-1 md:mt-1' src='quickbook.png' />
            </div>
            <div className='technologies'>
              <img className='grayscale  max-w-none w-48 -mt-14 xs:w-[90px] m-auto sm:w-[90px] md:w-[0px] xs:-mt-5' src='zoho.png' />
            </div>
          </div>
        </div>

        {/* talk to salespeople */}
        <div className='bg-[#E5F2F5] pt-12 '>
          <div className='grid grid-cols-[40%_60%] xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1'>
            {/* video-side */}
            <div>
              <div className='pl-20 xs:pl-0 sm:pl-0 md:pl-0'>
                <div className='w-[70%] xs:mx-auto sm:mx-auto md:mx-auto'>
                  <p className='text-sm'>GOODBYE PUSHY SALESPEOPLE</p>
                  <h1 className='py-3 text-[40px] font-black leading-none text-[#1D70ED]'>Talk to a real advisor now!</h1>
                </div>
              </div>
              <div className='pt-3 xs:order-1 sm:order-1 md:order-1 cursor-pointer'>
                <video loop muted controls autoPlay className='video'>
                  <source className='max-w-full h-full pl-4' src='ditto_hotline.mp4' />
                </video>
              </div>
            </div>
            {/* content-side */}
            <div className='bg-white w-full h-[100%] rounded-2xl pt-14 pl-14 pr-8 pb-8 z-10 xs:py-4 sm:py-4 md:py-4 xs:px-6 sm:px-6 md:px-6'>
              <h1 className='text-4xl text-[#1D70ED] font-bold xs:text-2xl sm:text-2xl md:text-3xl xl:text-[40px] 2xl:text-[40px]'>Received a notice from Income Tax Department?</h1>
              <h3 className='py-2 xl:py-4 2xl:py-4 font-bold text-lg xl:text-2xl 2xl:text-2xl'>Expert assistance for any notices from Income Tax Department</h3>
              <p className='w-[90%] xs:w-full sm:w-full md:w-full'>Receiving a tax notice need not be a cause for worry, simply upload your notice on Tax2clear.co and our tax expert will assist you with optimal advice and help you in handling or replying to your income tax notice.</p>
              <div className='pt-12 pb-8 grid grid-cols-2 gap-y-10  gap-x-3 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1'>
                <div>
                  <div className='flex gap-4'>
                    <p className='font-bold mt-2'><FiCheck fontSize={20} /></p>
                    <div>
                      <span className='text-xl font-semibold'>Get Tax Expert Help</span>
                      <p className='text-[#73739a] text-sm pt-1'>Let us help you responde to the IT Department</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='flex gap-4'>
                    <p className='font-bold mt-2'><FiCheck fontSize={20} /></p>
                    <div>
                      <span className='text-xl font-semibold'>Notices covered</span>
                      <p className='text-[#73739a] text-sm pt-1'>Refund re-issue request, Notice under section 143(1) or 143(2) and 143(3), 154, 254, 156</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='flex gap-4'>
                    <p className='font-bold mt-2'><FiCheck fontSize={20} /></p>
                    <div>
                      <span className='text-xl font-semibold'>30 min consultation</span>
                      <p className='text-[#73739a] text-sm pt-1'>Good advice takes time. And you will have our undivided attention for at least 30 mins.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='flex gap-4'>
                    <p className='font-bold mt-2'><FiCheck fontSize={20} /></p>
                    <div>
                      <span className='text-xl font-semibold'>It’s free</span>
                      <p className='text-[#73739a] text-sm pt-1'>Our consultations are free and we won’t harass you.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='py-4 flex justify-start items-center gap-6'>
                {/* <button className='mt-3 py-2.5 px-3 rounded-lg bg-[#0F52BA] text-white hover:bg-[#48af3d] flex items-center gap-2'>
                <span>Book a Free call</span>
              </button> */}
                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Book+a+free+call&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#0F52BA] text-white hover:bg-[#0B3B84] flex items-center gap-2'>
                  {/* <button className='mt-3 py-2.5 px-3 rounded-lg bg-[#F4F6F7] text-[#51636F] flex items-center gap-2'> */}
                  <p><BsWhatsapp /></p>
                  <span>WhatsApp us</span>
                  {/* </button> */}
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* health insurance policy */}
        <div className='bg-[#F7F8F8] py-6 px-5 relative -top-[170px] xs:top-0'>
          <div className='w-[90%] mx-auto px-10 pt-44 xs:w-full sm:w-full md:w-full xs:px-4 sm:px-4 md:px-4 xs:pt-4 sm:pt-4 md:pt-4'>
            {/* <div className='text-center py-8'>
            <span className='capitalize tracking-wider font-bold text-[#1D70ED] text-lg'>If you already have a plan</span>
            <h1 className='text-4xl font-bold py-2 xs:text-2xl sm:text-2xl md:text-3xl'>Understand your Health Insurance Policy</h1>
            <p className='text-[#73739a] w-3/5 mx-auto text-base py-2 xs:w-full sm:w-full md:w-full xs:py-0 sm:py-0 md:py-0'>Understand what your policy has to offer. Read terms and conditions in plain English. And discover the good, bad and ugly in your health plan.</p>
          </div> */}
          </div>
          <div className='w-[95%] mx-auto flex items-center xs:flex-col sm:flex-col md:flex-col xs:w-full sm:w-full md:w-full lg:w-4/5 xl:w-4/5 2xl:w-4/5 xs:px-6 sm:px-6 md:px-6'>
            <div className='w-5/12 pr-14 xs:w-full sm:w-full md:w-full xs:pr-0 sm:pr-0 md:pr-0'>
              <h1 className='text-[#314259] text-5xl font-bold xs:text-3xl sm:text-3xl md:4xl lg:text-6xl xl:text-[52px] 2xl:text-[52px] xs:text-center sm:text-center md:ext-center'>Numbers <br /> don't lie!</h1>
              <p className='text-[#73739a] py-4 xs:py-2 sm:py-2 md:py-2 xl:text-lg 2xl:text-lg'>Products that perform seamlessly during any kind of surge, so you don’t have to worry about uptime and reliability. Ingest and process any amount of data any time of the month, smoothly.</p>
            </div>
            <div className='w-7/12 px-4 flex items-center gap-5 xs:flex-col sm:flex-col md:flex-col xs:w-full sm:w-full md:w-full xl:px-8 2xl:px-8 xs:gap-0 sm:gap-0 md:gap-0 xs:mt-5 sm:mt-5 md:mt-7'>
              <div className='w-1/2 xs:w-full sm:w-full md:w-full'>
                <div className='px-5 py-4 h-[185px] bg-white rounded-xl'>
                  <div className='mb-3'>
                    <h1 className='font-bold text-2xl xl:text-3xl 2xl:text-3xl text-[#314259]'>500
                      <span className='text-[#0F52BA] font-black'>+</span>
                    </h1>
                    <p className='text-[#74749a] capitalize xl:text-xl 2xl:text-xl'>companies</p>
                  </div>
                  <div className='pt-7 flex justify-end '>
                    <img src='upload-form.svg' />
                  </div>
                </div>
                <div className='px-5 py-4 h-[185px] mt-5 bg-white rounded-xl'>
                  <div className='mb-3'>
                    <h1 className='font-bold text-2xl xl:text-3xl 2xl:text-3xl text-[#314259]'>10
                      <span className='text-[#0F52BA] font-black'>+</span>
                    </h1>
                    <p className='text-[#74749a] capitalize xl:text-xl 2xl:text-xl'>Years experience</p>
                  </div>
                  <div className='pt-7 flex justify-end '>
                    <img src='6m.svg' />
                  </div>
                </div>
                <div className='px-5 py-4 h-[185px] mt-5 bg-white rounded-xl'>
                  <div className='mb-3'>
                    <h1 className='font-bold text-2xl xl:text-3xl 2xl:text-3xl text-[#314259]'>200
                      <span className='text-[#0F52BA] font-black'>+</span>
                    </h1>
                    <p className='text-[#74749a] capitalize xl:text-xl 2xl:text-xl'>Notice Resolved</p>
                  </div>
                  <div className='pt-7 flex justify-end '>
                    <img src='300b.svg' />
                  </div>
                </div>
              </div>
              <div className='w-1/2 xs:w-full sm:w-full md:w-full'>
                <div className='px-5 py-4 h-[185px] mt-5 bg-white rounded-xl'>
                  <div className='mb-3'>
                    <h1 className='font-bold text-2xl xl:text-3xl 2xl:text-3xl text-[#314259]'>10,000
                      <span className='text-[#0F52BA] font-black'>+</span>
                    </h1>
                    <p className='text-[#74749a] capitalize xl:text-xl 2xl:text-xl'>happy client</p>
                  </div>
                  <div className='pt-7 flex justify-end '>
                    <img src='35.svg' />
                  </div>
                </div>
                <div className='px-5 py-4 h-[185px] mt-5 bg-white rounded-xl'>
                  <div className='mb-3'>
                    <h1 className='font-bold text-2xl xl:text-3xl 2xl:text-3xl text-[#314259]'>10CR
                      <span className='text-[#0F52BA] font-black'>+</span>
                    </h1>
                    <p className='text-[#74749a] capitalize xl:text-xl 2xl:text-xl'>it refund process</p>
                  </div>
                  <div className='pt-7 flex justify-end '>
                    <img src='assistant.svg' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Client's review */}
        <div className='bg-white'>
          <div className='pt-0 pb-10 w-4/5 mx-auto xs:w-full sm:w-full md:w-full xl:w-[70%] 2xl:w-[70%] xs:py-6 sm:py-6 md:py-8'>
            <div className='w-[70%] mx-auto text-center xs:w-full sm:w-full md:w-full lg:w-3/5'>
              <h1 className='text-4xl mb-5 font-bold xs:text-2xl sm:text-2xl md:text-3xl'>What our clients say</h1>

            </div>
            <div className='py-4 grid justify-items-center	 grid-cols-3 gap-5 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xs:px-4 sm:px-4 md:px-4'>
              {
                Reviewcard.map((object) => {
                  return (
                    <div data-aos="zoom-in-up" className='bg-[#f7f8f8] rounded-xl w-[300px] h-[300px] py-6 px-6 flex justify-between flex-col xs:px-3 sm:px-3 md:px-3 xs:w-full sm:w-full md:w-full xl:w-[310px] 2xl:w-[310px]'>
                      <div className='w-[40px] h-[55px] -mt-10 text-[100px] text-[#0F52BA] code'>"</div>
                      <div className='pt-8 text-xl font-semibold'>{object.title}</div>
                      <div className='py-2 text-[#73739A] text-[14px] capitalize'>{object.subtxt}</div>
                      <div className='py-3 flex items-center gap-3'>
                        <img className='w-9 rounded-full' src={object.image} />
                        <div>
                          <h3 className='text-[#121F3E] font-semibold'>{object.name}</h3>
                          <p className='text-[12px] text-[#73739A]'>{object.type}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        {/* our clients */}
        <div className='bg-[#F7F8F8]'>
          <div className='w-[80%] mx-auto py-8 xs:w-full sm:w-full md:w-full xs:px-2 sm:px-2 md:px-2 xs:py-4 sm:py-4 md:py-6'>
            <h1 className='text-center text-4xl font-bold xs:text-2xl sm:text-2xl md:text-3xl'>Trusted by employees from over 500+ companies</h1>
            <p className='text-center  py-2 text-[#73739A] xs:px-2.5 sm:px-2.5 md:px-2.5'>We are India's best income tax e-filing service provider</p>
            <div className='pt-4 grid grid-cols-6 gap-5 xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-3 justify-items-center items-center'>
              <div className='w-32 -mt-10 mx-auto xs:-mt-6 sm:-mt-6 md:-mt-6 xs:w-24 sm:w-24 md:w-28 xs:h-14 sm:h-14 md:h-16'>
                <img className='grayscale  mt-4 xs:mt-6 sm:mt-2 md:mt-4' src='reliance.png' />
              </div>
              <div className='w-32 mx-auto xs:w-28 sm:w-32 md:w-32 xs:h-14 sm:h-14 md:h-16'>
                <img className='grayscale  -mt-5 xs:-mt-1 sm:-mt-1 md:-mt-2' src='byjus-logo.png' />
              </div>
              <div className='w-32 mx-auto mt-4 xs:w-24 sm:w-24 md:w-24 xs:h-14 sm:h-14 md:h-16'>
                <img className='grayscale  ' src='hmel.png' />
              </div>
              <div className='w-32 mx-auto mx-6 -mt-2 xs:w-20 sm:w-20 md:w-24'>
                <img className='grayscale  ' src='nayara.png' />
              </div>
              <div className='w-16 mx-auto mt-9 xs:w-12 sm:w-12 md:w-14 xs:mt-4 sm:mt-4 md:mt-5'>
                <img className='grayscale  -ml-5 xs:ml-0 sm:ml-0 md:-ml-2' src='tcs.png' />
              </div>
              {/* <div className='w-32 mx-auto mx-4 mt-7 xs:w-20 sm:w-20 md:w-24'>
              <img src='cropped-logo.png' />
            </div> */}
              <div className='w-28 h-28 mx-auto mx-6 -mt-2 xs:w-20 sm:w-20 md:w-24 xs:h-14 sm:h-14 md:h-16'>
                <img className='grayscale  ' src='jubilant.png' />
              </div>
              {/* <div className='w-20 h-20 mx-auto mx-6 -mt-2 xs:w-14 sm:w-14 md:w-16'>
              <img className='mt-4 xs:ml-2 sm:ml-2 md:ml-3' src='bhel.png' />
            </div> */}
              <div className='w-32 mx-auto mx-6 mt-4 xs:w-20 sm:w-20 md:w-24'>
                <img className='grayscale  ml-4 xs:ml-0 sm:ml-0 md:ml-0' src='ambuja-cement.png' />
              </div>
              <div className='w-24 mx-auto mx-6 mt-3 xs:w-16 sm:w-16 md:w-20'>
                <img className='grayscale  ' src='airtel.png' />
              </div>
              <div className='w-28 mx-auto mx-6 mt-6 xs:w-20 sm:w-20 md:w-24'>
                <img className='grayscale  ' src='infosys.png' />
              </div>
              <div className='w-16 mx-auto mx-8 mt-2 xs:w-12 sm:w-12 md:w-14'>
                <img className='grayscale  ' src='ongc.png' />
              </div>
              <div className='w-32 mx-auto mx-8 mt-6 xs:w-24 sm:w-24 md:w-28 xs:mx-2 sm:mx-2 md:mx-4'>
                <img className='grayscale  ' src='tata-steel.png' />
              </div>
              <div className='w-20 mx-auto mx-8  mt-2 xs:w-14 sm:w-14 md:w-16 xs:mx-2 sm:mx-2 md:mx-4'>
                <img className='grayscale  xs:ml-4 sm:ml-4 md:ml-6' src='wipro.png' />
              </div>
              <div className='w-4/5 mx-auto mx-2 mt-6 xs:w-24 sm:w-24 md:w-28 xs:mx-2 sm:mx-2 md:mx-4'>
                <img className='grayscale  ' src='hdfc.png' />
              </div>
              {/* <div className='w-[70%] mx-auto mx-8 mt-5 xs:w-24 sm:w-24 md:w-28 xs:mx-2 sm:mx-2 md:mx-4'>
              <img src='cbi-logo.jpg' />
            </div> */}
              <div className='w-4/5 mx-auto mx-8 mt-2 xs:w-24 sm:w-24 md:w-28 xs:mx-2 sm:mx-2 md:mx-4'>
                <img className='grayscale  ' src='icici.png' />
              </div>
              <div className='w-full mx-auto mx-8 mt-6 xs:w-28 sm:w-28 md:w-28 xs:-mx-2 sm:-mx-2 md:-mx-4'>
                <img className='grayscale  xs:-mt-1 sm:-mt-1 md:-mt-1.5' src='uco-bank.png' />
              </div>
              <div className='w-28 mx-auto mx-8 mt-8 xs:w-20 sm:w-20 md:w-24 xs:mx-2 sm:mx-2 md:mx-4'>
                <img className='grayscale  xs:-mt-3 sm:-mt-3 md:-mt-3.5' src='amazon.png' />
              </div>
              <div className='w-4/5 mx-auto mx-8 mt-5 xs:w-24 sm:w-24 md:w-28 xs:mx-2 sm:mx-2 md:mx-4'>
                <img className='grayscale  ' src='myntra.png' />
              </div>
              <div className='w-full mx-auto mx-8 -mt-6 xs:w-24 sm:w-24 md:w-28 xl:w-34 2xl:w-34 xs:mx-2 sm:mx-2 md:mx-4 xs:mt-2.5 sm:mt-2.5 md:mt-3'>
                <img className='grayscale  xs:-mt-3 sm:-mt-3 md:-mt-3.5' src='flipkart-logo.png' />
              </div>
            </div>
          </div>

        </div>
        <div className='fix-watsapp-icon'>
          <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
        </div>
      </div>
    </>
  )
}

export default Body