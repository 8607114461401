import React from "react";
import { BsCheck2Circle } from "react-icons/bs";
import refund from "../utils/refund.json";
import { BsWhatsapp } from "react-icons/bs"

const ITRefund = () => {
  const refundStatus = [
    {
      id: 1,
      status: "No Result found",
      meanins:
        "We were unable to check filing status and e-verification status for PAN and Acknowledgement number entered by you. Kindly verify if the details entered by you is correct.",
    },
    {
      id: 2,
      status:
        "Refund is already credited to your bank, please contact your bank.",
      meanins:
        "Refund has been processed and credited to your Bank Account If your refund has not been credited, then contact: CMP, State Bank of India Address: Survey No.21 Opposite : Hyderabad Central University, Main Gate, Gachibowli, Hyderabad -500019 Email: itro@sbi.co.in Toll free Number: 1800 425 9760",
    },
    {
      id: 3,
      status: "Refund cheque has already been encashed.",
      meanins:
        "Your income tax refund process is complete & it has been credited to your Bank Account and the same has been encashed.If your refund has not been credited, then contact: CMP, State Bank of India Address: Survey No.21 Opposite : Hyderabad Central University, Main Gate, Gachibowli, Hyderabad -500019 Email: itro@sbi.co.in Toll free Number: 1800 425 9760",
    },
    {
      id: 4,
      status: "Refund Not Determined",
      meanins:
        "As per the Income Tax department, no refund is determined after processing of your ITR",
    },
    {
      id: 5,
      status:
        "Unable to credit refund as the account number provided by you is incorrect.",
      meanins:
        "This means that the account number provided by you was incorrect. You are requested to apply for refund reissue by providing the correct account number to claim your refund.",
    },
    {
      id: 6,
      status:
        "Defective return u/s 139(9) (or) ITR filed is defective or incomplete",
      meanins:
        "It may be that your return has been treated as a defective Return. For the exact reason, you can check this on your income tax website user account.",
    },
    {
      id: 7,
      status: "ITR Processed but need to submit rectification request",
      meanins:
        "This may occur due to a mismatch in the calculation of the department & ITR filed.",
    },
    {
      id: 8,
      status:
        "Return submitted. Processing rights transferred to Jurisdictional Assessing Officer. (or) ITR Transferred to Jurisdiction AO",
      meanins:
        "This implies that your return will be processed by your Jurisdictional Assessing Officer (AO). Please contact AO for further details.",
    },
    {
      id: 9,
      status: "Pending for e-verification",
      meanins:
        "This implies that your return has been submitted successfully but it has not been e-verified either through post or online mode. Further, until and unless you verify your return, it would not be processed by the income tax department. So, please verify within 30 days from the date of filing.",
    },
    {
      id: 10,
      status: "Processed with no demand/refund",
      meanins:
        "It implies that your return has been processed successfully without any additional tax payable or refund due. Further, you can check the details from the intimation received u/s 143(1) on your mail id.",
    },
    {
      id: 11,
      status: "Under Processing",
      meanins:
        "This implies that your Income-tax Return is still not processed.It is under process. Further, no need to worry if you have e-verified or sent your signed ITR V by post as it will definitely be processed by the department sooner or later and then you will receive a refund. It would be credited along with interest",
    },
    {
      id: 12,
      status: "Refund issued (or) Refund Re-issued",
      meanins:
        "This implies that your return as well as refund has been processed successfully and the refund has been credited in your bank account. Now, no further action is needed from your side for this year.",
    },
    {
      id: 13,
      status:
        "Return Submitted and Verified (or) Successfully e-verified (or) E-Verified (or) ITRV Received",
      meanins:
        "This implies that your return has been submitted and successfully verified. It will now be processed by the Income Tax Department. So, you have to wait for some more days",
    },
    {
      id: 14,
      status: "Invalid original return, File Revised Return",
      meanins:
        "This implies that your return has been treated as an invalid return by the Income Tax Department. You need to file your return again in this case.",
    },
    {
      id: 15,
      status: "ITR Accepted",
      meanins:
        "Your ITR has been successfully received at CPC Bangalore & pending for processing.",
    },
    {
      id: 16,
      status: "ITR-V rejected",
      meanins:
        "It may be that your return has been treated as the defective Return.",
    },
    {
      id: 17,
      status: "Rectification processed with refund due",
      meanins:
        "Your rectification request has been processed by the department & refund is generated.",
    },
    {
      id: 18,
      status: "Rectification processed with no demand/refund",
      meanins:
        "Your rectification request has been processed by the department & no demand/refund is due.",
    },
    {
      id: 19,
      status: "Another Return filed",
      meanins:
        "You have filed another return,Please check status using the latest ack number.",
    },
    {
      id: 20,
      status: "Refund Re-issue failure",
      meanins:
        "It implies that your refund re-issue request has been failed due to some incorrect details. Please raise a new refund Re-issue request to claim your refund.",
    },
    {
      id: 21,
      status: "Rectification filed",
      meanins:
        "Your rectification request has been filed & it is under process.",
    },
  ];

  return (
    <div className="main-containerr">
      <div className="itrRefund">
        <div className="itRefundform mb-10 h-100 flex items-center justify-center xs:justify-normal  bg-[#f8f8f8]">
          <div className="formWrapper p-10 xs:px-0 flex xs:block sm:block items-center w-3/4 xs:w-4/4 lg:w-[100%] md:w-[100%] sm:w-[100%] justify-between">
            <div className="textForm border-l-4 border-400 border-[#0F52BA] xs:border-none w-[50%] sm:w-[100%] xs:w-[100%] xs:p-3 p-10">
              <p className="text-3xl mb-2">
                Check <b> Income Tax (ITR) Refund Status </b>Online
              </p>
              {/* <br /> */}
              <p className="font-bold mb-2">Income Tax Department says :</p>
              {/* <br /> */}
              <p className="mb-2">
                "Checking the online refund status is always good. It's free and
                it tells you what's going on?"
              </p>
              {/* <br /> */}
              <p className="font-bold mb-2">Please Note:</p>
              <p className="mb-2">
                Refund Status of income tax return that have been filed this year,
                must be updated in the Income Tax Department systems. You can
                check the latest status of tax refund by using this utility. Use
                the tool & check refund status now!
              </p>
              <hr />
              <br />
              <p>
                If you think your friends/network would find this useful, please
                share it with them – We'd really appreciate it.
              </p>
            </div>
            <div className="formItSelf bg-white  shadow-lg h-auto border-b-2 p-3 border-400 border-[#0f52ba] w-[350px] sm:w-[80%] sm:m-auto sm:mt-3 xs:w-full">
              <form className="p-2">
                <label className="text-[12px]" htmlFor="">
                  Permanent Account Number (PAN)
                </label>
                <input
                  type="text"
                  className="border mt-2 rounded focus:outline-none focus:border-400 focus:border-[#0F52BA] w-full p-2 placeholder:text-[14px]"
                  placeholder="NIMP1234P"
                />
                <label className="text-[14px]" htmlFor="">
                  Email Address
                </label>
                <input
                  type="email"
                  className="border mt-2 rounded focus:outline-none focus:border-400 focus:border-[#0F52BA] w-full p-2 placeholder:text-[14px]"
                  placeholder="email@gmail.com"
                />
                <label className="text-[14px]" htmlFor="">
                  Acknowledgement Number(optional)
                </label>
                <input
                  type="text"
                  className="border mt-2 rounded focus:outline-none focus:border-400 focus:border-[#0F52BA] w-full p-2 placeholder:text-[14px]"
                  placeholder="Acknowledgement Number"
                />
                <label className="text-[14px]" htmlFor="">
                  Assessment Year to check refund status for
                </label>
                <select
                  className="text-[14px] border mt-2 rounded focus:outline-none focus:border-400 focus:border-[#0F52BA] w-full p-2 placeholder:text-[14px]"
                  placeholder="NIMP1234P"
                >
                  <option>Select assessment year</option>
                  <option>2022-2023</option>
                  <option>2021-2022</option>
                  <option>2020-2021</option>
                  <option>2019-2020</option>
                  <option>2018-2019</option>
                  <option>2017-2018</option>
                  <option>2018-2019</option>
                  <option>2017-2018</option>
                  <option>2016-2017</option>
                  <option>2015-2016</option>
                  <option>2014-2015</option>
                </select>

                <button className="mt-2 text-white font-bold border-none bg-600 rounded-[4px] px-10 py-3 w-full bg-[#0F52BA]">
                  Chech Refund Status
                </button>

                <p className="mt-2 text-center text-[12px]">
                  *Status has been updated just now!
                </p>
              </form>
            </div>
          </div>
        </div>
        <div className="textITRefund w-[70%] xs:w-[95%] sm:w-[95%] sm:m-auto border-t-2 xs:block md:w-[80%] lg:w-[80%] lg:m-auto md:m-auto xs:justify-content-center m-auto border-600 border-[#0F52BA]  xs:m-[auto]  shadow-lg xs:p-4 p-10">
          <p className="text-2xl">What is Income Tax Refund?</p>
          <br />
          <p>
            Income tax refund means a refund amount that is initiated by the
            income tax department if the amount paid in taxes exceeds the actual
            amount due (either by way of TDS or TCS or Advance Tax or
            Self-Assessment Tax) and is known as an income tax refund. The tax is
            calculated after taking into consideration all the deductions and
            exemptions at the time of filing of Income Tax Return.
          </p>
          <br />
          <img src="332.jpg" alt="" />

          <br />
          <hr />
          <br />

          <p className="text-2xl">How to claim an income tax refund?</p>
          <br />
          <p>
            For claiming your income tax refund or TDS refund all you need to do
            is give written proof to the Department about your Income and
            deduction details, by way of filing your Income Tax Return. It is
            essential to remember that online filing of the return is a must to
            claim your Income Tax Refund.
          </p>

          <br />
          <hr />
          <br />

          <p className="text-2xl">
            I forgot to file my Income Tax Return within the due date, can I claim
            my refund now?
          </p>
          <br />
          <p>
            Don’t worry, if you missed the bus, you can still file a Belated/Late
            Return under section 139(4) and you’re good to go! To know more about
            Belated Return, refer to our blog
          </p>
          <p>
            The filing for Financial Year (FY) 2021-22 Assessment Year (AY)
            2022-23 has begun. The due date for which was 31st July 2022 but the
            belated return can be filed upto 31st March 2023. After filing &
            e-verifying your return, use our tool to check your ITR refund status.
          </p>
          <br />
          <hr />
          <br />
          <p className="text-2xl">
            I am eligible for a refund as per my ITR but haven’t received it yet?
          </p>
          <br />
          <p>
            Refund processing by the tax department starts only after the return
            is e-verified by the tax filer. Usually, it takes 25-60 days for the
            refund to be credited to your account. However, If you haven't
            received your refund during this time duration, you must check for
            discrepancies in your ITR; check your email for any notification from
            the IT department regarding the refund.
          </p>
          <br />
          <hr />
          <br />
          <p className="text-2xl">
            If it’s already been verified, then the reason for not receiving your
            refund can be:
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>Your ITR status is still under
                processing.</p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>You have received a notice/
                communication from the IT department regarding your filed ITR. For
                e.g., Defective notice u/s 139(9) or notice for proposed adjustments
                u/s 143(1)(a).</p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p> Your ITR has been processed but the
                Income Tax Department determined: No Refund Due.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p> The Return has been processed after
                determining the refund, but the refund could not be credited due to
                reasons like incorrect bank account number, incorrect postal address
                etc.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p> Your ITR has been processed after
                adjusting it with outstanding demand (if any).
              </p>
            </li>
          </ul>
          <br />
          <hr />
          <br />
          <p className="text-2xl">
            How much time does it take for a refund to get credited to my Bank
            Account?
          </p>
          <br />
          <p>
            Generally, as mentioned earlier, it takes 20-60 days from the date of
            e-verification of your Income Tax Return to get your refund credited.
            However, for the returns verified through sending of ITR-V to CPC
            Bengaluru, it may take some more time.
          </p>
          <br />
          <hr />
          <br />
          <p className="text-2xl">
            For how many previous years can I claim an income tax refund?
          </p>
          <br />
          <p>
            Earlier, the taxpayers had the freedom to file IT returns for two
            financial years. However, as per the new rules, IT refund can only be
            filed for one financial year.
          </p>
          <br />
          <p>
            In case, you have a refund due for the years preceding the aforesaid
            year and you forgot to file an income tax return for those years, then
            you can take respite of CBDT Circular No: 9/2015 to get a refund for
            previous six assessment years. So, as per this provision, you can
            claim a refund for the AY 2013-14; AY 2014-15; AY 2015-16; AY 2016-17;
            AY 2017-18 and AY2018-19.
          </p>
          <br />
          <p>
            This circular can be used only where your refunds are the result of
            the excessive deduction/collection of tax and/or excess deposit of
            advance /self-assessment tax.
          </p>
          <p>
            For taking benefit of the above circular, you need to make an
            application to the relevant authority depending upon the amount of
            claim.
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr>
                <th className="border py-3 px-7 xs:px-2">
                  Amount of claim (refund / loss) for any one assessment year{" "}
                </th>
                <th className="border py-3 px-7 xs:px-2">Application To:</th>
              </tr>
              <tr>
                <td className="border py-3 px-7 xs:px-2">10 Lakhs or less </td>
                <td className="border py-3 px-7 xs:px-2">10 Lakhs or less </td>
              </tr>
              <tr>
                <td className="border py-3 px-7 xs:px-2">
                  More than Rs.10 Lakhs but less than Rs. 50 Lakhs{" "}
                </td>
                <td className="border py-3 px-7 xs:px-2">
                  Principal Chief Commissioners of Income-tax/Chief Commissioners of
                  Income-tax
                </td>
              </tr>
              <tr>
                <td className="border py-3 xs:px-2 px-7">More than 50 Lakhs </td>
                <td className="border py-3 xs:px-2 px-7">
                  Central Board of Direct Taxes (CBDT)
                </td>
              </tr>
            </table>
          </div>
          <br />
          <p>
            <b>Note:</b> These authorities have all the powers to make necessary
            inquiries or scrutinize to ascertain the correctness of the claim and
            accept/ reject your application. Also, in such cases the Income Tax
            department shall not pay any interest on the delayed refunds.
          </p>

          <br />
          <hr />
          <br />
          <p className="text-2xl">
            What is the meaning of the different “Refund Status” ?
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr>
                <th className="border py-3 px-7 xs:px-0">Status</th>
                <th className="border py-3 px-7 xs:px-0">Meaning</th>
              </tr>
              {refundStatus.map((item) => {
                return (
                  <tr key={item.id}>
                    <td className="border py-3 px-7 xs:px-2">{item.status}</td>
                    <td className="border py-3 px-7 xs:px-2">{item.meanins}</td>
                  </tr>
                );
              })}
            </table>
          </div>
          <br />
          <hr />
          <br />
          <p className="text-2xl">
            What are the different ways in which refund is issued by the
            Department?
          </p>
          <br />
          <p>The Refunds are sent by the Income Tax Department in two ways-</p>
          <p>
            <b>RTGS/NECS: </b> This is the fastest facility provided by the
            department to get refunds. Under this facility your refund is directly
            credited in your bank account.
          </p>
          <p>
            <b>Paper Cheque: </b> Under the new policy, income tax refund is not
            allowed through cheques.
          </p>
          <br />
          <p>
            The Income Tax Department has altered the process of claiming the ITR
            refund a bit. It has been made compulsory by the IT Department to
            pre-validate your bank account in which you wish to receive the income
            tax refund. Pre Validating a bank account alone is not enough, the
            individual also needs to link the PAN with the bank account.This has
            come into force from March 1, 2019.
          </p>
          <br />
          <hr />
          <br />
          <p className="text-2xl">
            Will I also get any interest for the delayed refund?
          </p>
          <br />
          <p>
            Yes, the Income Tax department takes the responsibility of granting a
            simple interest @ 0.5 percent per month to compensate for the delayed
            refunds .In the case of refund arising out of TDS / TCS / Advance Tax
            – Interest is calculated @0.5% for every month or part of a month for
            a period starting on 1st April of the relevant AY till the date of
            grant of refund, in case the return is filed on time i.e., on or
            before the due date. However, if the return is not filed within the
            due date the period of interest shall be from the date of filing the
            return to the date of grant of refund.In this case, if the refund is
            less than 10% of the tax determined under assessment or in intimation
            u/s 143(1), then you are not entitled to any interest.
          </p>
          <br />
          <p>
            <b>
              In the case of refund arising out of self-assessment tax u/s 140A{" "}
            </b>
            Interest is calculated @0.5% for every month or part of a month for a
            period starting from the date of filing of return or payment of tax,
            whichever is later to the date of grant of refund. In this case also,
            if the refund is less than 10% of the tax determined under assessment
            or in intimation u/s 143(1), then you are not entitled to any interest
          </p>
          <br />
          <p>
            <b>In case of variation in the amount of refund</b>
            Where, as a result of an order u/s 143(3) / 144 / 147 / 154 / 155 /
            250 / 254 / 260 / 262 / 263/ 264 or an order of the Settlement
            Commission u/s 245D (4), the amount of refund payable has been
            increased or reduced, then the amount of interest shall be increased
            or reduced accordingly. The assessing office may issue a demand notice
            for recovery of excess interest paid in a case where the interest is
            reduced.
          </p>
          <br />
          <p>
            <b>In any other case</b>
            Interest is calculated @0.5% for every month or part of a month for a
            period from the date of payment of tax to the date of grant of refund.
            Suppose, Ms. Gupta has paid advance taxes of Rs. 50,000 over the year
            when she was actually liable to pay a tax of Rs. 30,000 only, then, in
            that case, she is entitled to a refund of Rs. 20,000/- along with the
            interest @0.5%. Let’s say, that Ms Gupta filed her return on 5.09.2019
            i.e., after the due date and the refund of Rs. 20,000 is received by
            her on 30.11.2019, then, the interest payable shall amount to Rs.
            300/-. (Rs. 20,000 x 0.5% x 3 months)
          </p>
          <br />
          <p>
            <b>
              Notes: Interest on Refund in case of Appeals/Revision of IT order
            </b>{" "}
            In case, any refund arises out of Income Tax Appeal or revision of
            passed orders, then you are entitled to additional interest over and
            above the one specified earlier, for delay beyond the time prescribed
            by the department (3 months from the end of the month in which the
            appellate order is received by the PCCIT/ CCIT / PCIT/ CIT or in case
            of revision, 3 months from the end of the month in which the order is
            passed by the PCCIT/ CIT) @ 3 percent per annum for the period
            beginning from the date following the date of expiry of prescribed
            period till the income tax refund date.
          </p>
          <br />
          <img src="5578.jpg" alt="" />
          <br />
          <hr />
          <br />
          <p className="text-2xl">When & How to apply for Refund Reissue?</p>
          <p>
            First verify your Income Tax Return, then track the status of your
            income tax refund with the department. In case, you have still not
            received your refund then one of the reasons for the ITR refund delay
            could be due to a problem in your bank account or address details.
          </p>
          <br />
          <p>
            In such a situation, you can make a “refund reissue” request to the
            Income Tax Department but only after receiving an "Intimation"
          </p>
          <br />
          <b className="mb-2">Simple steps to apply for Refund Reissue:</b>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA " />
              <p><b>Step 1:</b>Go to http://incometaxindiaefiling.gov.in</p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " />
              <p><b>Step 2:</b>Log in with your PAN card details and password
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " />
              <p><b>Step 3:</b>After logging in, you will see a dashboard
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " />
              <p><b>Step 4:</b>Click on the pending action tab and refund reissue
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " />
              <p><b>Step 5:</b>When you click on refund reissue, you will see a
                window with a tab refund reissue.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " />
              <p><b>Step 6:</b>Once you select the “Refund Reissue” tab visible on
                your screen, you will be redirected to the refund reissue page where
                you need to create a refund reissue request.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " />
              <p><b>Step 7:</b> Select the bank account in which you want your refund
                and click proceed to move to the verification stage.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " />
              <p><b>Step 8:</b> For successful submission of Refund Reissue request,
                users must have EVC. (Users must authenticate the refund reissue
                request through Aadhaar OTP & EVC code.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " />
              <p><b>Step 9:</b>Click on the submit Button
              </p>
            </li>
          </ul>
          <p className="">
            Once all these steps are done successfully, you can then checck the
            refund status on the dashbord.
          </p>
          <br />
          <hr />
          <br />
          <p className="text-2xl">
            How to update Address/Email id/Mobile number for all communications
            with the Income Tax Department?
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>
                <b>Step 1:</b>Go to http://incometaxindiaefiling.gov.in
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>
                <b>Step 2:</b> Log in with your PAN and password.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>
                <b>Step 3:</b>After logging in, click on Dashboard and you will see
                the options to edit the details.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>
                <b>Step 4:</b>To edit your contact details or bank account details,
                go to the respective tab and click update.
              </p>
            </li>
          </ul>
          <br />
          <hr />
          <br />
          <p className="text-2xl">How to check income tax refund status?</p>
          <br />
          <p>You can check income tax refund status in the following manner:</p>
          <br />
          <p>
            <b>With Income Tax login</b>
          </p>
          <br />
          <p>
            To check the status of the refund, there is no separate tab for it in
            the new portal, just click on the service tab and then click on the “
            refund reissue” tab and you will be redirected to the reissue status
            page.
          </p>
          <br />
          <p>
            <b>Without Income Tax Login</b>
          </p>

          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>
                Through TIN NSDL website
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>
                Enter your PAN number
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>
                Select the assessment year for which you want to check the refund
                status
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p>
                Enter Captcha Code and proceed
              </p>
            </li>
          </ul>
          <br />
          <hr />
          <br />
          <p className="text-2xl">
            What are the possible reasons for Income Tax Refund delay?
          </p>
          <br />
          <p className="mb-2">
            There are various possible reasons for income tax refund delay
          </p>
          <ul>
            <b className="flex items-center gap-3">
              {" "}
              <BsCheck2Circle color="#0F52BA " />
              <p>Not verified</p>
            </b>
            <li>
              After logging in, click on Dashboard and you will see the options to
              edit the details.
            </li>
            <br />
            <b className="flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA " />
              <p>Wrong Bank Account Number</p>
            </b>
            <li>
              Your refund might get delayed in case the bank account number
              entered by you is found to be wrong. In such a case you can place a
              refund reissue request.
            </li>
            <br />
            <b className="flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA " />
              <p>Delayed filing</p>
            </b>
            <li>
              If you have filled your return after the due date then your refund
              also will get delayed accordingly.
            </li>
            <br />
            <b className="flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA " />
              <p>Wrong computations</p>
            </b>
            <li>
              If you have entered wrong information while filling your return then
              the IT department will ask you to provide clarifications for the
              same by issuing notices. The process is quite long and hence will
              delay your refund.
            </li>
            <br />
            <b className="flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA " />
              <p>Defective Return</p>
            </b>
            <li>
              If your return is marked as defective under section 139(9), then
              your refund will be delayed if you don't reply to the same timely.
            </li>
            <br />
            <b className="flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA " />
              <p>Return Revised</p>
            </b>
            <li>
              If you have committed a clerical mistake and have therefore revised
              your return then your refund will naturally get delayed.
            </li>
            <br />
            <b className="flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA " />
              <p>Offline verification</p>
            </b>

            <li>
              If you have verified the returns offline by posting it to Bangalore
              then it becomes quite obvious that your refund will get delayed. So,
              one should always opt for online mode rather than offline.
            </li>
          </ul>
          <br />
          <hr />
          <br />
          <p className="text-2xl">Can any other person get my refund?</p>
          <br />
          <p>
            As per the law, only the person whose excessive tax is deducted is
            eligible to get a refund. However, there are a couple of exceptions to
            this: When your income is included in any other person’s income (as
            per provisions of the law), then the latter alone can get your refund.
            In case of death, incapacity, insolvency, liquidation or any other
            reason when you are unable to claim/receive a refund, then your legal
            representative or trustee or guardian or receiver shall be entitled to
            your refund.
          </p>
          <br />
          <hr />
          <br />
          <p className="text-2xl">
            How to contact an assessing officer for an income tax refund?
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA " /><p> You can read our guide which will
                guide you on how to know your AO details and then you can contact
                him/her. You may also choose to file a grievance on the income tax
                official portal.</p>
            </li>
          </ul>
          <br />
          <div id="faqITR" className="p-10 xs:p-4 mb-10 bg-100 bg-[#C6DBFA]">
            <h1 className="text-4xl font-semibold text-900 text-[#1E1E24]">
              Frequently Asked Questions
            </h1>
            {refund.map((item) => {
              return (
                <>
                  <div key={item.id}>
                    <p className="text-900 text-[#1E1E24] font-bold text-xl pt-10 pb-5 flex items-center xs:items-start gap-2.5 xs:text-lg xs:leading-6">
                      <span className="text-base xs:mt-2 text-center h-6 w-6 flex items-center justify-center bg-900 bg-[#1E1E24] question p-2 xs:leading-[100%]  xs:text-sm xs:rounded-[100%] rounded-full  font-normal text-white">
                        Q
                      </span>
                      {item.question}
                    </p>
                    <p className="text-base">Ans: {item.answer}</p>
                    {item.list ? (
                      <ul className="pt-5 listQuestion">
                        {item.list.map((li) => {
                          return <li>&#x2713; {li}</li>;
                        })}
                      </ul>
                    ) : null}
                    {/* <div className='border-solid border border-sky-900 my-4 w-1/4'></div> */}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className='fix-watsapp-icon'>
      <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default ITRefund;
