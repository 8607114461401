import Form16 from './Components/Form16';
import Assistedtax from './Components/Assistedtax';
import Faq from './Components/Faq';
import Navbar from './Components/Navbar';
import Body from './Components/Body';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './Components/Footer';
import './App.css';
import RefState from './Context/RefState';
import Aadhar from './Components/Aadhar';
import FillingITR from './Components/fillingITR';
import CapitalGainsTax from './Components/CapitalGainsTax';
import ITRefund from './Components/ITRefund';
import SalaryIncome from './Components/SalaryIncome';
import Section80 from './Components/Section80';
import TDS from './Components/TDS';
import GeteCA from './Components/Products/GeteCA';
import FileYourReturn from './Components/Products/FileYourReturn';
import CapitalGainTax from './Components/Products/CapitalGainTax';
import TaxOnWinnings from './Components/Products/TaxOnWinnings';
import NRITax from './Components/Products/NRITax';
import ScrollToTop from './Components/ScrollToTop';
import PanCard from './Components/PanCard';
import HomeLoader from './Components/HomeLoader';
// import Pricing from './Components/Pricing';
import Pricing from './Components/price-demo';
import HUFfilling from './Components/HUFfilling';
import { useState, useEffect } from 'react';
import AadharLinkPan from './Components/AadharLinkPan';

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3500)

  }, [])

  return (
    <>
      <BrowserRouter>
        <RefState>
          {/* {loading === true ? <HomeLoader /> : */}
          <><ScrollToTop />
            <Navbar />
            <Routes>
              <Route path="/" element={<Body />} />
              <Route path="/form16" element={<Form16 />} />
              <Route path="/Pricing" element={<Pricing />} />
              <Route path="/huffiling" element={<HUFfilling />} />
              <Route path="/assistedtax" element={<Assistedtax />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/aadhar" element={<Aadhar />} />
              <Route path="/fillingITR" element={<FillingITR />} />
              <Route path="/capitalgain" element={<CapitalGainsTax />} />
              <Route path="/itrefund" element={<ITRefund />} />
              <Route path="/salaryincome" element={<SalaryIncome />} />
              <Route path="/section80" element={<Section80 />} />
              <Route path="/tds" element={<TDS />} />
              <Route path="/eca" element={<GeteCA />} />
              <Route path="/fileyourreturn" element={<FileYourReturn />} />
              <Route path="/capitalgainproduct" element={<CapitalGainTax />} />
              <Route path="/taxonwinnings" element={<TaxOnWinnings />} />
              <Route path="/ntrtax" element={<NRITax />} />
              <Route path="/pancard" element={<PanCard />} />
              <Route path="/aadhar-link-pan" element={<AadharLinkPan />} />
            </Routes>
            <Footer /> </>
          {/* } */}
        </RefState>
      </BrowserRouter>
    </>
  );
}

export default App;
