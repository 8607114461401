import React, { useContext } from 'react';
import { BsTelephoneFill } from "react-icons/bs"
import { IoLocationSharp } from "react-icons/io5"
import { HiMail } from "react-icons/hi"
import { FaFacebookF, FaGithub, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import RefContext from '../Context/RefContext';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";



function Footer() {

    const a = useContext(RefContext);

    return (
        <div className='main-containerr'>
            <div className='bg-[#1E1E24] text-[#cfcfcf] '>
                <div className='w-[80%] xs:w-[100%] mx-auto py-8 grid grid-cols-4 gap-8 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-4 2xl:grid-cols-4 sm:w-3/5 md:w-[90%] lg:w-[98%] md:w-[98%] xs:px-4 sm:px-4 md:px-4'>
                    <div>
                        <img className='w-40' src='final-footer-logo(2).svg' />
                        {/* <p className='py-2 text-lg font-semibold'>About us</p>
                    <p className='pb-2 text-lg font-semibold'>Careers</p>
                    <p className='pb-2 text-lg font-semibold'>Contact Us</p>  
                    <p className='pb-2 text-lg font-bold'>Mobile: <span className='font-medium'>+91- 8866876515</span></p>  
                    <p className='pb-2 text-lg font-bold'>Email: <span className='font-medium'>savemytax7@gmail.com</span></p>   */}
                        <p className='py-6 font-semibold xs:pt-4 sm:pt-4 md:pt-5 xs:pb-0 sm:pb-0 md:pb-0'> Tax2clear © {new Date().getFullYear()} </p>
                        <div className='flex gap-2 xs:mt-3'>
                            <a href='https://www.facebook.com/profile.php?id=61559150955015' target='_blank'><FaFacebook className='text-2xl' /></a>
                            <a href='https://www.instagram.com/tax_2_clear' target='_blank'><FaInstagram className='text-2xl' /></a>
                            <a href=' https://x.com/Tax_2_Clear' target='_blank'><FaTwitter className='text-2xl' /></a>
                        </div>
                    </div>

                    <div>
                        <div className='py-2 text-xl font-bold uppercase xs:text-lg sm:text-lg md:text-xl xl:text-2xl 2xl:text-2xl'>Services</div>
                        {/* <hr /> */}
                        <Link to='/fileyourreturn'>
                            <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base'>File IT Return</p>
                        </Link>
                        <Link to='/form16'>
                            <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base'>Send Form 16</p>
                        </Link>
                        <Link to='/eca'>
                            <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base'>Book eCA.</p>
                        </Link>
                        <Link to='/taxonwinnings'>
                            <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base'>Taxes on Winnings from Online gaming websites and apps</p>
                        </Link>
                        <Link to='/capitalgainproduct'>
                            <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base'>Calculate Capital Gain on Share or Property
                            </p>
                        </Link>

                        <Link to='/ntrtax'>
                            <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base'>NRI ITR filling in India
                            </p>
                        </Link>

                    </div>

                    <div>
                        <div className='py-2 text-xl font-bold uppercase xs:text-lg sm:text-lg md:text-xl xl:text-2xl 2xl:text-2xl'>Guides</div>
                        {/* <hr /> */}
                        <Link to="/aadhar">
                            <p className="pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base">Aadhar</p>
                        </Link>
                        <Link to="/pancard">
                            <p className="pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base">Pancard</p>
                        </Link>
                        <Link to="/huffiling">
                            <p className="pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base">HUF Filing</p>
                        </Link>

                        <Link to='/fillingITR'>
                            <p className="pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base">E-filling ITR</p>
                        </Link>

                        <Link to="/itrefund">
                            <p className="pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base ">Income tax refund</p>
                        </Link>

                        <Link to='/tds'>
                            <p className="pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base">TDS</p>
                        </Link>

                        <Link to='/salaryincome'>
                            <p className="pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base">Salary income</p>
                        </Link>

                        <Link to='/section80'>
                            <p className="pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base">Section 80 deductions</p>
                        </Link>

                        <Link to='/capitalgain'>
                            <p className="pb-2 text-lg font-normal xs:text-sm sm:text-sm md:base">Capital gains income</p>
                        </Link>

                    </div>

                    <div>
                        <div className='py-2 text-xl font-bold uppercase xs:text-lg sm:text-lg md:text-xl xl:text-2xl 2xl:text-2xl'>Tax2clear</div>
                        {/* <hr /> */}

                        <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:text-base xs:text-sm sm:text-sm md:text-base'>About us</p>
                        <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:text-base'>Carrers</p>
                        <Link to="/Pricing">
                            <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:text-base'>Pricing</p>
                        </Link>
                        <Link to="/assistedtax">
                            <p className='pb-2 text-lg font-normal xs:text-sm sm:text-sm md:text-base'>Contact US</p>
                        </Link>
                        <a className='pb-2 text-lg font-bold' href='tel:93283 43844'>Mobile: <span className='font-medium xs:text-sm sm:text-sm md:text-base'>+91- 93283 43844</span></a>
                        <br />
                        {/* <p className='pb-2 text-lg font-bold'>Email: <span className='font-medium xs:text-sm sm:text-sm md:text-base'>help@Tax2clear.com</span></p> */}
                        <a className='pb-2 text-lg font-bold' href='mailto:help@Tax2clear.com'>Email: <span className='font-medium xs:text-sm sm:text-sm md:text-base'>help@Tax2clear.com</span></a>

                    </div>


                </div>
                {/* about us */}
                <div className='py-10 w-4/5 mx-auto text-center xs:w-full sm:w-full md:w-full xl:w-[70%] 2xl:w-[70%] xs:px-4 sm:px-4 md:px-4'>
                    <h1 className='text-4xl font-bold xs:text-xl sm:text:xl md:text-2xl'>About Us</h1>
                    <p className='py-4 text-lg xs:text-base sm:text-base'>Tax2clear has been a one-stop solution for any business or individual looking for a chartered accountant or tax consultant for income tax-related compliance in India. Over the past years, the depth of their offerings, connection with reliable professionals, affordable prices, and customer satisfaction has made them one of the largest online facilitators of income tax-related legal services in India. </p>
                    <p className=' w-4/5 mx-auto text-lg xs:text-base sm:text-base xs:w-full sm:w-full'>Please note that we are a facilitating platform enabling access to reliable professionals. We are not a law firm and do not provide legal services ourselves. The information on this website is for the purpose of knowledge only and should not be relied upon as legal advice or opinion.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer