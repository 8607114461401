import React from "react";
import { HashLink } from "react-router-hash-link";
import { BsCheck2Circle, BsWhatsapp } from "react-icons/bs";
import panFAQ from "../utils/panFAQ.json";
const PanCard = () => {
  const content = [
    {
      id: 1,
      content: "What is a PAN Card?",
      path: "#whatispan",
    },
    {
      id: 2,
      content: "What is significance of 4th letter in the PAN card number ?",
      path: "#whatissignificance",
    },
    {
      id: 3,
      content: "How does the 5th digit depict the last name of the holder?",
      path: "#5thdigit",
    },
    {
      id: 4,
      content: "Why should I need a PAN Card?",
      path: "#whypan",
    },
    {
      id: 5,
      content:
        "But what if I don't file my Income Tax Return and I've got nothing to do with the Income Tax Department, do I still need it?",
      path: "#doistillneedit",
    },
    {
      id: 6,
      content:
        "Is it mandatory to apply for a PAN, even if I don't fall in any of the above categories?",
      path: "#isitmandatory",
    },
    {
      id: 7,
      content:
        "What if my minor child enters in the abovementioned transactions?",
      path: "#minorchild",
    },
    {
      id: 8,
      content:
        "What happens when I enter into such above mentioned transactions without having PAN card?",
      path: "#entermentionedtransectionwithoutpancard",
    },
    {
      id: 9,
      content: "Is it different from other ID proofs? How?",
      path: "#diffrentfromidhow",
    },
    {
      id: 10,
      content: "How do I apply for a PAN?",
      path: "#howdoapply",
    },
    {
      id: 11,
      content: "What are the types of PAN application form?",
      path: "#typesofpanapplication",
    },
    {
      id: 12,
      content: "How much time will it take to receive my PAN card?",
      path: "#howmuchtimetake",
    },
    {
      id: 13,
      content:
        "I am not a citizen of India, is the procedure for applying PAN different for me?",
      path: "#notcitizenofindia",
    },
    {
      id: 14,
      content:
        "I applied for a PAN some time ago and haven't received it yet, what to do?",
      path: "#haven'treceived",
    },
    {
      id: 15,
      content: "Can I have two PAN cards?",
      path: "#twopancard",
    },
    {
      id: 16,
      content: "Can I apply a PAN on behalf of someone else?",
      path: "#behalfofsomeone",
    },
    {
      id: 17,
      content: "I have lost my PAN card, now?",
      path: "#lostpancard",
    },
    {
      id: 18,
      content: "How to correct any mistake or change any data in my PAN card?",
      path: "#howtocorrectmistake",
    },
    {
      id: 19,
      content: "My address has changed, do I need to apply for a new PAN card?",
      path: "#addresschangeneednewpan",
    },
    {
      id: 20,
      content: "I forgot my PAN number? What to do?",
      path: "#forgotpannumber",
    },
    {
      id: 21,
      content: "What happens in case of any contravention relating to PAN?",
      path: "#whatispan",
    },
    {
      id: 22,
      content: "Frequently Asked Questions",
      path: "#faqs",
    },
  ];

  return (
    <div className="main-containerr">
      <div className="panContainer">
        <br />
        <h1 className="text-4xl font-bold">Pan Card</h1>
        <br />
        <br />
        <div className="contentBox">
          <h3 className="text-2xl font-semibold">Contents</h3>
          <ul>
            {content.map((item) => {
              return (
                <HashLink to={content.path} smooth key={content.id}>
                  <li className="font-bold"># {item.content}</li>
                </HashLink>
              );
            })}
          </ul>
        </div>
        <br />

        <div id="whatispan">
          <p className="text-2xl font-semibold">What is a PAN Card?</p>
          <br />
          <p>
            As we all know PAN (Permanent Account Number) Card is an
            identification proof issued by the Income Tax Department. It contains
            the following 5 basic information along with your photograph:
          </p>
          <br />
          <ol>
            <li>1. Your Full Name</li>
            <li>2. Father's Name (Mother's name in case of a single parent)</li>
            <li>3. Date of Birth</li>
            <li>4. PAN Number which is a ten-digit alphanumeric unique code.</li>
            <li>5. Your Signature</li>
          </ol>
          <br />
          <p>
            Through your PAN number, the Income Tax Department keeps an eye on all
            your transactions that requires your PAN Card like depositing money in
            your bank account over Rs 50,000 at one time, buying goods and
            services more than Rs 2 Lakh etc.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="whatissignificance">
          <p className="text-2xl font-semibold">
            What is significance of 4th letter in the PAN card number ?
          </p>
          <br />
          <p className="font-semibold">
            The 4th letter stands for any of the following :-
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                "P" for Individual
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                "C" for Company
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                "H" for Hindu Undivided Family (HUF)
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                "A" for Association of Persons (AOP)
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                "B" for Body of Individuals (BOI)
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                "G" for Government Agency
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                "J" for Artificial Juridical Person
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                "L" for Local Authority
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                "E" for Limited Liability Partnership
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                "F" for Firm
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                "T" for Trust
              </p>
            </li>
          </ul>
        </div>

        <br />
        <hr />
        <br />

        <div id="5thdigit">
          <p className="text-2xl font-semibold">
            How does the 5th digit depict the last name of the holder?
          </p>
          <br />
          <p>
            In case of an individual, the 5th digit is the first letter of the
            last name / surname. For example, Ms. Tania Gupta applied for a PAN,
            then the 5th digit of her PAN will be "G".
          </p>
          <br />
          <p>
            But, in case of a non-individual, the 5th digit shall denote the first
            character of the PAN holder's name. Let's say, Tania Limited applied
            for a PAN, then the 5th digit of the unique number allotted to it will
            be "T".
          </p>
        </div>
        <br />
        <hr />
        <br />

        <div id="whypan">
          <p className="text-2xl font-semibold">Why should I need a PAN Card?</p>
          <br />
          <p>
            PAN number basically creates your identity in front of Income Tax
            Department. The department doesn't identify you by your name or
            father's name but by your unique 10-digit number mentioned on your PAN
            Card. From filing returns to paying taxes to getting refunds and for
            all the communications with the Income Tax Department, PAN is a
            pre-requisite.The Income Tax Department mandates you to have PAN Card
            in the following circumstances-
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                When you are liable to pay income tax i.e. if your income exceeds
                Rs. 2,50,000/- in a financial year;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                When you are liable to pay excise duty / service tax / sales tax /
                VAT;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                When TDS is deducted from your income;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                Where you are a professional or businessman and your gross receipts
                exceed Rs. 5 lakhs in a year;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                Where you are an importer or exporter, who is required to obtain
                Import Export Code;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />
              <p>
                Where you are a charitable trust who is required to furnish return
                under Section 139(4A); and
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                When you intend to enter into any specified financial transactions
                in which quoting of PAN is mandatory
              </p>
            </li>
          </ul>
        </div>

        <br />
        <hr />
        <br />

        <div id="doistillneedit">
          <p className="text-2xl font-semibold">
            But what if I don't file my Income Tax Return and I've got nothing to
            do with the Income Tax Department, do I still need it?
          </p>
          <br />
          <p>
            Well, not only the Income Tax Department but other institutions also
            require quoting of pan mandatorily in the following specified
            transactions:
          </p>
          <br />
          <p className="font-semibold">1. Opening:</p>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" />A New Bank Account;
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" />A new DEMAT Account
            </li>
          </ul>

          <br />

          <p className="font-semibold">2. Applying for:</p>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" />A credit card or debit card;
            </li>
          </ul>

          <br />

          <p className="font-semibold">3. Payments to:</p>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Life insurance premium when the total amount paid during the year is
                exceeds Rs.50000</p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Mutual fund / company / RBI for acquiring units / debentures / bonds
                issued by it when the total amount paid during the year exceeds
                Rs.50000.</p>
            </li>
          </ul>

          <br />

          <p className="font-semibold">4. Sale or purchase of :</p>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Immovable property for an amount exceeding Rs. 10,00,000
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Securities or shares for an amount exceeding Rs. 1 lakh per
                transaction;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Motor vehicle or vehicle other than two- wheeled vehicles (inclusive
                of any detachable side car having an extra wheel);
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Goods and services of any nature other than those mentioned above
                for an amount exceeding Rs. 2 lakhs per transaction.
              </p>
            </li>
          </ul>
          <br />
          <p className="font-semibold">
            5. Time deposits with a banking company, post office, Nidhi Company or
            any NBFC of amount exceeding Rs. 50,000 in a day or Rs. 5 lakhs in a
            year
          </p>
          <br />

          <p className="font-semibold">6. Payments in cash :</p>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                To hotels and restaurants for bills exceeding Rs. 50,000;</p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                For purchase of bank drafts / pay orders / banker's cheques from a
                banking company or a co-operative bank an amount exceeding Rs.
                50,000 during a day</p>
            </li>
          </ul>

          <br />

          <p className="font-semibold">
            7. Cash deposit of Rs. 50,000 or more with any bank during one day;
          </p>
          <br />
          <p className="font-semibold">
            8. Payment for travel to any foreign country or for purchase of
            foreign currency for an amount exceeding Rs. 50,000;
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div>
          <p className="text-2xl font-semibold">
            In case, you are a non - resident, then you enjoy the following
            exemptions in relation to quoting of PAN:
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Applying for a credit card or debit card;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Payments in cash to hotels and restaurants for bills exceeding Rs.
                50,000;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Payment in connection with travel to any foreign country or for
                purchase of foreign currency for an amount exceeding Rs. 50,000;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Payment in cash for an amount exceeding Rs. 50,000 during any one
                day for purchase of bank drafts / pay orders / banker's cheques from
                a banking company or a co-operative bank;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Payment of an amount exceeding Rs. 50,000/- to RBI for acquiring the
                bonds issued by it;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Sale or purchase of goods and services of any nature other than
                those mentioned above exceeding an amount of Rs. 2 lakhs per
                transaction;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Payment in cash or by way of a bank draft / pay order / banker's
                cheque of an amount exceeding Rs. 50,000 in a financial year for one
                or more pre-paid
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                payment instruments issued by RBI to a banking company or a
                co-operative bank or to any other company or institution.
              </p>
            </li>
          </ul>
        </div>
        <br />
        <hr />
        <br />

        <div id="isitmandatory">
          <p className="text-2xl font-semibold">
            Is it mandatory to apply for a PAN, even if I don't fall in any of the
            above categories?
          </p>
          <br />
          <p>
            Well, its highly unlikely that you do not enter into any of the above
            mentioned categories, but still if you do not then you are not
            required to obtain a PAN as per the law. Although, you can still apply
            for a PAN voluntarily, obtaining a PAN is optional and not mandatoryin
            such a case.
          </p>
        </div>

        <div id="minorchild">
          <p className="text-2xl font-semibold">
            What if my minor child enters in the above mentioned transactions?
          </p>
          <br />
          <p>
            If any minor enters in these specified transactions, then he can quote
            PAN of his parents or guardian.But when the income of the minor child
            exceeds Rs 2,50,000 which is chargeable to Income Tax then he cannot
            quote PAN of his parents or guardian and will be required to have his
            own PAN card.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="entermentionedtransectionwithoutpancard">
          <p className="text-2xl font-semibold">
            What happens when I enter into such above mentioned transactions
            without having PAN card?
          </p>
          <br />
          <p>
            In case you don't have a PAN number and you enter into any of the
            above mentioned transactions, then you are required to file a
            declaration in Form No. 60. A Form 60 is just a form containing basic
            details such as your name, the transaction you are entering into, any
            return if filed and the reason for not having a PAN. Also, a valid
            proof of address is to be attached with Form 60.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="diffrentfromidhow">
          <p className="text-2xl font-semibold">
            Is it different from other ID proofs? How?
          </p>
          <br />
          <p>
            Yes, totally. Other ID proofs such as Aadhaar Number Cards, Driving
            License, Passport etc. are issued to only Indian citizens, while a PAN
            card can be issued to non-citizens / foreigners too who have dealing
            in specified transactions stated above as it is not a proof of Indian
            Citizenship.
          </p>
        </div>
        <br />
        <hr />
        <br />

        <div id="howdoapply">
          <p className="text-2xl font-semibold">How do I apply for a PAN?</p>
          <br />
          <p>
            You are required to fill the prescribed application form along with
            the required attachments either offline or online through NSDL or UTI
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="typesofpanapplication">
          <p className="text-2xl font-semibold">
            What are the types of PAN application form?
          </p>
          <br />
          <p className="font-semibold text-[12px]">
            Following are the different types of application forms:
          </p>
          <br />
          <ul>
            <li>
              {/* <BsCheck2Circle color="#0F52BA" /> */}
              Form 49A – Application for allotment of PAN to be filled by the
              Indian citizens / Application of request for new PAN Card or / and
              changes or corrections in PAN
            </li>
            <li>
              {/* <BsCheck2Circle color="#0F52BA" /> */}
              Form 49AA - Application for allotment of PAN to be filled by foreign
              citizens.
            </li>
            <li>
              {/* <BsCheck2Circle color="green" /> */}
              In case of a company which has not been registered under the
              Companies Act, 2013, the application for allotment of Permanent
              Account Number (PAN) may be made in FormNo.INC-7 specified u/s 7(1)
              of the said Act for incorporation of the company.
            </li>
          </ul>
        </div>

        <br />
        <hr />
        <br />

        <div id="howmuchtimetake">
          <p className="text-2xl font-semibold">
            How much time will it take to receive my PAN card?
          </p>
          <br />
          <p>
            Generally, it takes about 15 - 20 business days for your PAN card to
            reach at the address provided by you in the details. (yes, that fast)
          </p>
        </div>
        <br />
        <hr />
        <br />

        <div id="notcitizenofindia">
          <p className="text-2xl font-semibold">
            I am not a citizen of India, is the procedure for applying PAN
            different for me?
          </p>
          <br />
          <p>
            No, the procedure for applying a PAN is same for every individual,
            whether an Indian citizen or a Foreign citizen. The only difference
            being Form 49AA is to be filled and submitted through an authorized
            representative at any authorized PAN centre instead of Form 49A.
            However, you may find the online mode of application easier and more
            convenient which provides for online payment of fees using various
            options.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="haven'treceived">
          <p className="text-2xl font-semibold">
            I applied for a PAN some time ago and haven't received it yet, what to
            do?
          </p>
          <br />
          <p>
            In any such case, where you have applied for a PAN card and wondering
            where it is, simply just track the status of your PAN card with the
            help of acknowledgement number issued at the time of applying for a
            PAN or consult our experts.
          </p>
        </div>
        <br />
        <hr />
        <br />

        <div id="twopancard">
          <p className="text-2xl font-semibold">Can I have two PAN cards?</p>
          <br />
          <p>
            No person can hold two PAN's and thus, if you have more than one PAN,
            surrender it immediately to the income tax department. In fact, a
            penalty of Rs. 10,000 can be imposed on you in such a case.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="behalfofsomeone">
          <p className="text-2xl font-semibold">
            Can I apply a PAN on behalf of someone else?
          </p>
          <br />
          <p className="font-semibold text-[12px]">
            Yes, you can apply for a PAN on behalf of the following persons:
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>A minor
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>A lunatic or mentally unstable
                person;
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>A deceased person; or
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Such other persons who are required to be represented by an
                Authorized Representative.
              </p>
            </li>
          </ul>
        </div>

        <br />
        <hr />
        <br />

        <div id="lostpancard">
          <p className="text-2xl font-semibold">I have lost my PAN card, now?</p>
          <br />
          <p>
            In case, you lost your PAN card, you need to apply for re-issuing or
            re-printing of your PAN card by submitting a valid ID, address and DOB
            proof along with an attested photocopy of old PAN card. Remember, do
            not apply for a new PAN card altogether.
          </p>
        </div>
        <br />
        <hr />
        <br />

        <div id="howtocorrectmistake">
          <p className="text-2xl font-semibold">
            How to correct any mistake or change any data in my PAN card?
          </p>
          <br />
          <p>
            A request for re-issue of PAN Card is to be filled in by selecting the
            appropriate box on the left margin of the application form naming
            “Request for New PAN Card or/ and Changes or Correction in PAN Data”
            along with the correct documents as a proof.
          </p>
        </div>
        <br />
        <hr />
        <br />

        <div id="addresschangeneednewpan">
          <p className="text-2xl font-semibold">
            My address has changed, do I need to apply for a new PAN card?
          </p>
          <br />
          <p>
            No. A change in address is just a change in your jurisdiction and
            assessing officer, and no application for a new PAN is required in
            such a case. Only, the change in address is required to be intimated
            to the income tax department for successful updation of their database
            for future correspondence.
          </p>
        </div>
        <br />
        <hr />
        <br />

        <div id="forgotpannumber">
          <p className="text-2xl font-semibold">
            What happens in case of any contravention relating to PAN?
          </p>
          <br />
          <p>
            In case, if you contravene any provision relating to Section 139A i.e.
            PAN, then you will be liable for a penalty amounting to Rs. 10,000/-
            per default u/s 272B of the Income Tax Act, 1961. The contraventions
            can be any of the following:
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Not obtaining a PAN when you are liable to obtain one;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Knowingly quoting an incorrect PAN in any documents;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Intimating an incorrect PAN to the person who is liable to deduct
                tax at source (TDS);
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Intimating an incorrect PAN to the person who is liable to collect
                tax at source (TCS);
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Obtaining two PAN's.
              </p>
            </li>
          </ul>
        </div>
        <br />
        <hr />
        <br />

        <div className="p-10 xs:p-4 mb-10 bg-100 bg-[#C6DBFA]" id="faqs">
          <h1 className="text-4xl text-900 text-[#1E1E24] ">Frequently Asked Questions</h1>
          {panFAQ.map((item) => {
            return (
              <>
                <div key={item.id}>
                  <p className="text-900 text-[#1E1E24] font-bold text-xl pt-10 pb-5 flex items-center gap-2.5 xs:text-lg xs:leading-6">
                    {/* <span className="text-base text-center flex items-center justify-center bg-900 bg-[#1E1E24] question w-6 h-6 rounded-full font-normal text-white">
                    Q
                  </span> */}
                    <span className="text-base xs:mt-2 h-6 w-6 text-center flex items-center justify-center bg-900 bg-[#1E1E24] question p-2 xs:leading-[100%]  xs:text-sm xs:rounded-[100%] rounded-full  font-normal text-white">
                      Q
                    </span>
                    {item.question}
                  </p>
                  <p className="text-base xs:text-justify xs:text-sm">Ans: {item.answer}</p>
                  {/* {item.list ? (
                  <ul className="pt-5 listQuestion">
                    {item.list.map((li, index) => {
                      return <li>&#x2713; {li.list}</li>;
                    })}
                  </ul>
                ) : null} */}
                  {item.list ? (
                    <ul className="pt-5 listQuestion">
                      {item.list.map((li) => {
                        return <li>&#x2713; {li}</li>;
                      })}
                    </ul>
                  ) : null}
                  {/* <div className='border-solid border border-sky-900 my-4 w-1/4'></div> */}
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className='fix-watsapp-icon'>
      <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default PanCard;
