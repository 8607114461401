import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { BsWhatsapp } from "react-icons/bs";
import ButtonLoader from "./ButtonLoader";
function Form16() {
  const [selectedFile, setSelectedFile] = useState();
  const [name, setName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const API = process.env.REACT_APP_API_URL;
  const SHEET_API = process.env.REACT_APP_FORM16_SHEET_API_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log(selectedFile.size);

 

  return (
    <>
      <div className="main-containerr">
        <div className="py-12 w-4/5 mx-auto xs:py-6 sm:py-6 md:py-8 xs:px-4 sm:px-4 md:px-6 xs:w-full sm:w-full md:w-full">
          <div className="bg-[#F5F6F7] rounded-xl px-6 py-8 h-auto xs:h-[1000px] sm:h-[1000px] md:h-[1000px]">
            <h1 className="py-2.5 font-bold text-3xl xs:text-xl sm:text-xl md:text-2xl">
              Send Form 16
            </h1>
            <p>
              Save time and energy by using Tax2clear's automated process to
              generate your ITR after you upload your Form 16, instead of manually
              filing your income tax return.
            </p>
            <div className="flex  flex-col justify-center items-center py-4">
              <img className="w-36" src="PDF-upload.png" />
              {/* <input
              type="file"
              value=""
              onChange={handleFileChange}
              id="uploadFile"
              style={{ display: "none" }}
            />
            <label
              style={{ cursor: "pointer" }}
              htmlFor="uploadFile"
              // onClick={getFile}
              className="capitalize text-lg bg-[#0F52BA] text-white my-5 py-1.5 px-4 rounded-md"
            >
              {selectedFile === undefined
                ? "Select Form 16"
                : selectedFile.name}
            </label> */}
              <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Send+Form+16&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-[4px] bg-[#0F52BA] text-[#FFFFFF] flex items-center gap-2 xs:text-sm sm:text-sm md:text-[15px]'>
                <p><BsWhatsapp /></p>
                <span>WhatsApp us</span>
              </a>
            </div>
            <h1 className="text-[#73739a]">
              <span className="font-bold text-black">Disclaimer:</span> Data from
              Form 16 is fetched for your convenience. Please verify all your data
              before filing the ITR.
            </h1>
            
          </div>
          <br />
          <p className="text-2xl font-semibold">
            We will import your Form 16 data automatically and help you file your
            Income-tax return in minutes. If you have more than one Form 16, we
            will ask you to upload the other Form 16 once the importing of first
            one is over.
          </p>
          <br />
          <hr />
          <br />
          <p className="font-semibold text-2xl">
            What documents are required to file ITR 1 Sahaj Form?
          </p>
          <br />
          <ul className="list-disc list-inside">
            <li className="font-semibold text-[16px] p-3">Form 16</li>
            <li className="font-semibold text-[16px] p-3">Salary slips</li>
            <li className="font-semibold text-[16px] p-3">
              Interest Certificates from the Post offices and Banks
            </li>
            <li className="font-semibold text-[16px] p-3">Form 16A/16B/16C</li>
            <li className="font-semibold text-[16px] p-3">Form 26AS</li>
            <li className="font-semibold text-[16px] p-3">
              Tax saving investment proof
            </li>
            <li className="font-semibold text-[16px] p-3">
              Deduction under the Section 80 D to 80 U
            </li>
            <li className="font-semibold text-[16px] p-3">
              Home Loan statement from the NBFC or the Bank
            </li>
            <li className="font-semibold text-[16px] p-3">Capital Gains.</li>
          </ul>
          <br />
          <p className="text-2xl font-semibold">
            What are the conditions to opt for a new tax regime?
          </p>
          <br />
          <p>
            The taxpayer opting for concessional rates in the new tax regimes has
            to forgo the exemptions and the deduction that is available under the
            old tax regime. In total 70 deductions are allowed out of which the
            most commonly used are listed below:
          </p>
          <br />
          <p>
            The list of common exemptions and deductions that are not allowed in
            the new Income tax regime are:
          </p>
          <br />
          <ul className="list-disc list-inside">
            <li className="text-[16px] p-3 font-semibold">
              Leave travel Allowance
            </li>
            <li className="text-[16px] p-3 font-semibold">
              House Rent Allowance
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Conveyance Allowance
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Daily expenses in the course of employment
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Relocation Allowance
            </li>
            <li className="text-[16px] p-3 font-semibold">Helper Allowance</li>
            <li className="text-[16px] p-3 font-semibold">
              Children Education Allowance
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Other Special Allowances [Section 10(4)]
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Standard deduction on salary
            </li>
            <li className="text-[16px] p-3 font-semibold">Professional tax</li>
            <li className="text-[16px] p-3 font-semibold">
              Interest on housing loan (Section 24)
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Deductions under Chapter VI A deduction (80C, 80D, 80E and so on)
              (Except Section 80CCD (2))
            </li>
          </ul>
          <br />
          <p className="text-2xl font-semibold">
            List of common deductions allowed the New Tax Regime
          </p>
          <br />
          <ul className="list-disc list-inside">
            <li className="text-[16px] p-3 font-semibold">
              Transport allowance for specially-abled people
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Conveyance allowance for expenditure incurred for traveling to work.
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Investment in Notified Pension Scheme under Section 80 CCD(2)
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Deduction for the employment of new employees under Section 80JJAA
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Depreciation u/s 32 of the Income Tax Act except for additional
              depreciation.
            </li>
            <li className="text-[16px] p-3 font-semibold">
              Any allowance for traveling for employment or on transfer.
            </li>
          </ul>
        </div>
        <div className='fix-watsapp-icon'>
        <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
        </div>
      </div>
    </>
  );
}

export default Form16;
