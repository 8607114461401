import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ButtonLoader from "../ButtonLoader";
import { BsWhatsapp } from "react-icons/bs";
const GeteCA = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const date = new Date().toDateString();
    const API = process.env.REACT_APP_API_URL;

    let data = {
      "date": date,
      "event_name": "Contact",
      "name": name,
      "email": email,
      "phone_number": phone,
      "ref": "Get a CA"
    }

    try {
      if (phone.length === 10) {
        const result = await axios.post(API, data,
          {
            headers: {
              'Content-Type': "text/plain;charset=utf-8"
            },
            'mode': "no-cors"
          }).then((resp) => {
            if (resp.status === 200) {

              setLoading(false);
              setName('');
              setEmail('');
              setPhone('');
              Swal.fire(
                'Thank You For Inquiry , we will contact You soon on Whatsapp',
                'Your form is succesfully submitted!',
                'success'
              )
            }


          }).catch(error => {

            setLoading(false);
            Swal.fire(
              "Something went wrong",
              "Try after few minutes",
              "error"
            );

          });
        // const googleSheet = await axios.post(SHEET_API, formData);

        // if (result.status === 200 && googleSheet.status === 200) {
        console.log("data", result)

      } else {
        Swal.fire(
          "Something went wrong",
          "Mobile Number Not Valid",
          "error"
        );
      }


    } catch (error) {
      setLoading(false);

      Swal.fire(error.message, "Something went wrong", "error");
    }
  };
  return (
    <div className="main-containerr">
      <div className="geteCA items-center  flex flex-col">
        <div className="bg-[#F5F6F7] xl:h-full 2xl:h-full w-[100%]">
          <div className="px-6 py-8  w-4/5 xs:w-5/5 mx-auto xs:w-full sm:w-full md:w-full xl:w-9/12 2xl:w-9/12 xl:h-[600px] 2xl:h-[600px]">
            <div className=" py-12 grid grid-cols-2 gap-9 xs:py-0 xs:px-0 md:py-8  md:px-6 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
              <div>
                <h1 className="text-[#0c2343] text-3xl font-normal xs:text-xl sm:text-xl md:text-xl">
                  Hire Expert CA's for <br />
                  <p className="my-2 text-[40px] font-extrabold xs:text-2xl sm:text-2xl md:text-2xl xs:my-1 sm:my-1 md:my-1">
                    Income <span className="text-[#1D70ED]"> Tax </span> Return
                    Filing
                  </p>
                  and Get Maximum Refunds
                </h1>
                <div className="mt-8">
                  <div className="flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      Access to expert CAs for Income Tax Return filing
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[17px]">
                      Assurance of accurate and timely filing of returns
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      Help in identifying and claiming maximum deductions to
                      reduce tax liability
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      Assistance in getting the highest possible tax refunds from
                      the government
                    </h3>
                  </div>
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                className="w-4/5 mx-auto bg-[#ffffff80] box-shadow rounded-[30px] py-6 px-8 border-b border-[#0F52BA] xs:w-full sm:w-full md:w-full"
              >
                <h1 className="py-2 text-xl font-bold text-center">Get eCA</h1>
                <div className="py-4">
                  <input
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    required
                    className="w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />

                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    required
                    className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    type="number"
                    placeholder="Mobile Number"
                    value={phone}
                    maxLength="10"
                    required
                    className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />

                </div>
                <p>
                  By submitting the form, you agree to our{" "}
                  <a href="https://sites.google.com/view/Tax2clear/terms-condition" target="_blank" className="text-[#0F52BA] hover:underline">
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a href="https://sites.google.com/view/Tax2clear/privacy-policy" target="_blank" className="text-[#0F52BA] hover:underline">
                    Privacy Policy
                  </a>{" "}
                </p>
                <button
                  type="submit"
                  className="text-white bg-[#0F52BA] w-full rounded-[4px] mt-5 py-2 text-center"
                >
                  {loading === true ? <ButtonLoader /> : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>

        <br />
        <div className="flex flex-col w-3/4 lg:w-[80%]">
          <br />
          <p className="text-center text-4xl font-semibold">
            CA Assisted ITR Filing
          </p>
          <br />
          <p className="font-semibold text-[22px]">
            Filing your Income Tax Return online has never been easier with
            Tax2clear. Enjoy the convenience of filing from the comfort of your
            own home with confidence. We provide you access to some of the best
            tax experts in India at a nominal cost. Our team of eCAs is dedicated
            to ensuring accurate returns, maximizing deductions, and securing the
            highest tax refunds for you."
          </p>
          <br />
          {/* <img src="" alt="" /> */}
          <div className="mt-5 flex flex-col items-center">
            <p className="text-2xl font-semibold">How it Works</p>
            <br />
            <p className="text-[18px]">
              Process of online income tax return filing with Tax2clear eCA
            </p>
            <br />

            <div className="grid grid-cols-4 lg:grid-cols-2 md:grid-cols-2 xs:block sm:block items-center gap-5">
              <div className="flex flex-col gap-3 h-auto min-h-[350px] xs:mt-3 justify-between items-start  sm:w-[80%] sm:m-auto  xs:w-[250px] sm:mt-3  p-5 border rounded-md">
                <img src="hire-ca.webp" className="w-[120px]" alt="" />
                <p className="font-bold text-[18px]">
                  Hire your personal eCA & Upload the Documents
                </p>
                <p className="text-[13px] text-[#727682]">
                  Everything you’ve loved about a CA, you’ve had all in one place.
                  For this, all you need to do is just enter your name, email &
                  phone no. Make a payment of our professional fees and get your
                  personal eCA.
                </p>
              </div>

              <div className="flex flex-col gap-3 h-auto min-h-[350px] xs:mt-3 justify-between  items-start  sm:mt-3 sm:w-[80%] sm:m-auto  xs:w-[250px]  p-5 border rounded-md">
                <img src="ca-reviews.webp" className="w-[120px]" alt="" />
                <p className="font-bold text-[18px]">
                  eCA Reviews Your Documents & Calls You
                </p>
                <p className="text-[13px] text-[#727682]">
                  Every Document is thoroughly studied for it’s accuracy and
                  completeness. Further, the eCA will then call you to discuss
                  your income, investments & deductions to help you get the
                  maximum refund.
                </p>
              </div>

              <div className="flex flex-col gap-3 h-auto min-h-[350px] xs:mt-3 justify-between items-start  sm:w-[80%] sm:m-auto sm:mt-3 p-5  xs:w-[250px] border rounded-md">
                <img src="relax.webp" className="w-[120px]" alt="" />
                <p className="font-bold text-[18px]">
                  Relax & Leave All Hardwork to us
                </p>
                <p className="text-[13px] text-[#727682]">
                  Leave all the hard work on us, to maximise deductions and save
                  taxes for you. We know everything about the tax laws applicable
                  on you. We also check Form 26AS with the Department to get you
                  the max. benefit of your TDS.
                </p>
              </div>

              <div className="flex flex-col gap-3 h-auto min-h-[350px] xs:mt-3 justify-between items-start  sm:w-[80%] sm:m-auto sm:mt-3 p-5  xs:w-[250px] border rounded-md">
                <img src="fiys-step3.webp" className="w-[120px]" alt="" />
                <p className="font-bold text-[18px]">
                  Approve and Get Started ITR & ITR file Done
                </p>
                <p className="text-[13px] text-[#727682]">
                  A detailed summary of your Income Tax Return is sent to you for
                  your approval. Once you approve it, the return can be filed from
                  the dashboard.
                </p>
              </div>
            </div>
            <br />
            <div className="flex xs:block sm:block my-10 gap-16 p-5 xs:p-0">
              <div className="flex-2 gap-3 flex flex-col">
                <p className="font-bold text-2xl">
                  Benefits of efiling <br />
                  <span className="text-400 text-[#0F52BA] ">Income Tax Returns</span>
                </p>
                <img src="why-us-element.svg" className="w-[350px]" alt="" />
              </div>
              <div className="flex-2 grid gap-3  grid-rows-2 grid-cols-3 xs:grid-cols-1">
                <div className=" rounded-md flex flex-col items-center p-3 justify-center gap-3 bg-white shadow-lg">
                  <img src="claim-refund.webp" alt="" />
                  <p className="text-[18px]">Claim tax refunds</p>
                </div>
                <div className=" rounded-md flex flex-col items-center p-3 justify-center gap-3 bg-white shadow-lg">
                  <img src="avoid-notices.webp" alt="" />
                  <p className="text-[18px]">Avoid tax notices</p>
                </div>
                <div className=" rounded-md flex flex-col items-center p-3 justify-center gap-3 bg-white shadow-lg">
                  <img src="build-document.webp" alt="" />
                  <p className="text-[18px]">Build financial documentation</p>
                </div>
                <div className=" rounded-md flex flex-col items-center p-3 justify-center gap-3 bg-white shadow-lg">
                  <img src="carry-forward.webp" alt="" />
                  <p className="text-[18px]">Carry forward your losses</p>
                </div>
                <div className=" rounded-md flex flex-col items-center p-3 justify-center gap-3 bg-white shadow-lg">
                  <img src="avoid-latefees.webp" alt="" />
                  <p className="text-[18px]">Avoid late fee penalties</p>
                </div>
                <div className=" rounded-md flex flex-col items-center p-3 justify-center gap-3 bg-white shadow-lg">
                  <img src="quick-visa.webp" alt="" />
                  <p className="text-[18px]">Quick visa processing</p>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className='fix-watsapp-icon'>
        <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default GeteCA;
