import React from 'react'
import { BsWhatsapp } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import HUFfaq from '../../src/utils/HUFfaq.json'

const HUFfilling = () => {
  return (
    <div className='main-containerr'>
      <div className='huf-file-main'>
        <div className='huf-file-childs'>
          <div className='understand-huf flex justify-between w-[60%] mx-auto xs:block md:block sm:block md:w-[100%] sm:w-[100%] xs:w-[100%]'>
            <div className='about-huf w-[50%] xs:w-[90%] xs:mx-auto md:w-[90%] md:mx-auto sm:w-[90%] sm:mx-auto mt-[10%]'>
              <h1 className='text-6xl xs:text-4xl font-medium'>Understanding HUF</h1>
              <p className='text-xl mt-[40px]'><b>Welcome to Tax2clear</b> where we simplify the complexities of taxation, including the unique entity known as the Hindu Undivided Family (HUF).</p>
              {/* <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-lg w-[10%] text-center bg-[#F4F6F7] text-[#51636F]  items-center gap-2 xs:text-sm sm:text-sm md:text-[15px]'>
                <span>WhatsApp us</span>
              </a> */}
              <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0'><button type="button" class="mt-[30px] text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[4px] text-sm px-7 py-2.5 me-2 mb-2 dark:bg-blue-600 ">Start Your HUF Registration</button></a>
            </div>
            <div className="w-[30%] xs:w-[40%] xs:mx-auto md:w-[40%] md:mx-auto sm:w-[40%] sm:mx-auto">
              <img src="final-hero-huf-filing.png" className='w-[100%] h-[100%]'></img>
            </div>
          </div>
          <div className='huf-info-main flex xs:block md:block sm:block'>
            <div className='w-[50%] xs:w-[90%] xs:mx-auto xs:p-[50px] lg:p-[130px] xl:p-[130px] md:w-[90%] md:mx-auto md:p-[50px] sm:w-[90%] sm:mx-auto sm:p-[50px] p-[200px] bg-[#DBEFFF]'>
              <h1 className='text-4xl font-medium'>What is a HUF Account?</h1>
              <p className='mt-[30px]'>An HUF Account is a distinct and recognized form of business entity under Indian tax laws. It is formed through Hindu law, typically upon the marriage of an individual. The HUF consists of members who share a common ancestry, residence, and family property. In the realm of HUF registration in India, understanding key roles is paramount:</p>
              {/* <span><a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='mt-3 py-2.5 px-3 rounded-lg bg-[#F4F6F7] text-[#51636F] flex items-center gap-2 xs:text-sm sm:text-sm md:text-[15px]'>
                      <p><BsWhatsapp /></p>
                      <span>WhatsApp us</span>
                    </a></span> */}
              <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=HUF+Register+Now&type=phone_number&app_absent=0'><button type="button" class="mt-[30px] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[4px] text-sm px-7 py-2.5 me-2 mb-2 dark:bg-blue-600 ">Register Now</button></a>
            </div>
            <div className='w-[50%] xs:w-[90%] xs:mx-auto px-[150px] lg:px-[70px] xl:px-[70px] xs:px-[50px] xs:py-[50px] md:w-[90%] md:mx-auto md:p-[50px] sm:w-[90%] sm:mx-auto sm:p-[50px] py-[100px] bg-[#cc]'>
              <p className='text-3xl'>Karta:</p>
              <p className='mt-[20px]'>The Karta, often the patriarch of the family, holds the authority to make decisions on behalf of family members. This pivotal role involves managing family affairs and representing the HUF in various capacities.</p>
              <p className='text-3xl mt-[30px] '>Co-Parceners:</p>
              <p className='mt-[20px]'>Co-parceners possess the right to claim a share of the family property when opting to separate from the HUF account. The hierarchy of co-parceners extends through four degrees within the family structure:</p>
              <ul className='mt-[20px] list-disc list-inside '>
                <li>1st Degree: The individual holding ancestral property for the first time.</li>
                <li>2nd Degree: Sons and daughters.</li>
                <li>3rd Degree: Grandsons.</li>
                <li>4th Degree: Great-grandsons.</li>
              </ul>
              <p className='mt-[20px]'>Understanding these roles is crucial for efficient HUF account registration, a process that unlocks valuable tax benefits for the family unit. The Karta's leadership and the co-parcener's entitlements create a structured framework for managing ancestral assets and optimizing tax liabilities.</p>
            </div>
          </div>
          <div className='advantages-huf-account  bg-[#e9e9e95f] py-[30px]'>
            <h1 className='text-4xl text-center font-medium '>Key Advantages Of An HUF Account:</h1>
            <div className='w-[60%] xs:w-[90%] md:w-[80%] lg:w-[80%] xl:w-[80%] grid grid-cols-3 xs:grid-cols-1 md:grid-cols-2 mx-auto mt-[20px] gap-[20px]'>
              <div className='bg-[#fff] p-[30px] advantages-part'>
                <p className='text-xl '>Common Ancestry:</p>
                <p className='text-[#8c8c8c] mt-[5px]'>HUF account members are connected through a common lineage, often with the eldest male member recognized as the Karta, or head of the family.</p>
              </div>
              <div className='bg-[#fff] p-[30px] advantages-part'>
                <p className='text-xl '>Joint Property:</p>
                <p className='text-[#8c8c8c] mt-[5px]'>An HUF account typically holds ancestral property or assets acquired with HUF funds. The income generated from these properties is termed as HUF income.</p>
              </div>
              <div className='bg-[#fff] p-[30px] advantages-part'>
                <p className='text-xl '>Business Activities:</p>
                <p className='text-[#8c8c8c] mt-[5px]'>An HUF can engage in various business activities, and the income generated is attributed to the HUF entity.</p>
              </div>
            </div>
            <div className='let-get-start'>
              <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Get+Started&type=phone_number&app_absent=0'><button type="button" class="mt-[30px] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[4px] text-sm px-7 py-2.5 me-2 mb-2 dark:bg-blue-600 ">Let get Started </button></a>
            </div>
          </div>
          <div className='flex xs:block sm:block md:block'>
            <div className='w-[30%] xs:w-[50%] xs:mx-auto sm:w-[50%] sm:mx-auto md:w-[50%] md:mx-auto'>
              <img src='final-huf-account-registration.svg' className='h-[100%]'></img>
            </div>
            <div className='w-[70%] xs:w-[100%] sm:w-[100%] md:w-[100%] xs:mx-auto sm:w-[50%] sm:mx-auto md:w-[90%] md:mx-auto xs:px-[20px] sm:px-[20px] md:px-[20px] lg:px-[50px] xl:px-[50px] px-[300px] py-[50px] bg-[#e9eefc]'>
              <p className='text-4xl'>Requirements For HUF Account Registration For Tax Benefits</p>
              <p className='mt-[10px]'>To establish a Hindu Undivided Family (HUF) and avail associated tax benefits, certain criteria must be met:</p>
              <div>
                <p className='mt-[20px]'><span className='bg-[#5172e1] text-[#fff] rounded-full px-[7px]'>1</span><b>    Family Requirement:</b></p>
                <p>HUF account formation requires a family; an individual cannot initiate it. The family includes the descendants of a common ancestor, unmarried daughters, and their wives.</p>
                <p className='mt-[20px]'><span className='bg-[#5172e1] text-[#fff] rounded-full px-[7px]'>2</span><b>    Marriage Implication:</b></p>
                <p>Marriage automatically leads to the creation of a HUF.</p>
                <p className='mt-[20px]'><span className='bg-[#5172e1] text-[#fff] rounded-full px-[7px]'>3</span><b>    Eligible Communities:</b></p>
                <p>HUF accounts can be formed by individuals belonging to Hindu, Sikh, Jain, and Buddhist communities.</p>
                <p className='mt-[20px]'><span className='bg-[#5172e1] text-[#fff] rounded-full px-[7px]'>4</span><b>    Formal Registration:</b></p>
                <p>Following its creation, HUF must undergo formal registration. This involves a legal deed specifying details of HUF account members and its business activities. Additionally, acquiring a PAN number and opening a dedicated bank account are essential components of the registration process.</p>
                <p className='mt-[20px]'><span className='bg-[#5172e1] text-[#fff] rounded-full px-[7px]'>5</span><b>    Common Corpus:</b></p>
                <p>After formation, each member has the liberty to deposit their income into the common HUF corpus.</p>
                <p className='mt-[20px]'><span className='bg-[#5172e1] text-[#fff] rounded-full px-[7px]'>6</span><b>    Tax Benefits:</b></p>
                <p>HUF account members can leverage various tax benefits under applicable sections.</p>
                <p className='mt-[30px]'>By adhering to these conditions and formalities, individuals can effectively establish and manage a HUF, optimizing their tax positions.</p>
              </div>
              <div>
                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Get+Started&type=phone_number&app_absent=0'><button type="button" class="mt-[30px] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[4px] text-sm px-7 py-2.5 me-2 mb-2 dark:bg-blue-600 ">Let get Started </button></a>
              </div>
            </div>
          </div>
          <div className='py-[40px]'>
            <p className=' text-4xl text-center'>HUF Filing & Registration Services By Tax2clear</p>
            <p className='text-[15px] mt-[10px] text-center'>Applicable for Hindu, Sikh, Jain and Buddhist families</p>
            <div className='grid grid-cols-3 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xs:w-[90%] sm:w-[90%] md:w-[80%] w-[60%] lg:w-[90%] xl:w-[90%] mx-auto gap-[20px] mt-[30px]'>
              <div className='huf-priceing-plan text-center'>
                <p className='text-2xl border-b-2 border-dashed pb-[20px]'>HUF Registration</p>
                <p className='text-4xl text-[#0B3B84] mt-[20px]'>₹2,499</p>
                <p>*Exclusive of Taxes</p>
                <p className='mt-4'>Expert assistance in HUF creation</p>
                <p className='mt-4'>Comprehensive documentation support</p>
                <p className='mt-4'>Formal registration with legal expertise</p>
                <div className='watsapp-us-part'>
                  <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=HUF+Registration&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                      <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                    </a>
                  </button>
                </div>
              </div>
              <div className='huf-priceing-plan text-center'>
                <p className='text-2xl border-b-2 border-dashed pb-[20px]'>HUF PAN Application</p>
                <p className='text-4xl text-[#0B3B84] mt-[20px]'>₹899</p>
                <p>*Exclusive of Taxes</p>
                <p className='mt-4'>Seamless PAN application process for your HUF</p>
                <p className='mt-4'>Timely updates on application status</p>
                <p className='mt-4'>Expert guidance on PAN-related queries</p>
                <div className='watsapp-us-part'>
                  <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=HUF+PAN+Application&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                      <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                    </a>
                  </button>
                </div>
              </div>
              <div className='huf-priceing-plan text-center'>
                <p className='text-2xl border-b-2 border-dashed pb-[20px]'>HUF Filings</p>
                <p className='text-4xl text-[#0B3B84] mt-[20px]'>₹4,199</p>
                <p>*Exclusive of Taxes</p>
                <p className='mt-4'>Annual filing support for your HUF</p>
                <p className='mt-4'>Compliance check and documentation assistance</p>
                <p className='mt-4'>Dedicated expert consultation for tax optimization</p>
                <div className='watsapp-us-part'>
                  <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=HUF+Filings&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                      <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='bg-[#e9e9e95f] py-[20px]'>
            <p className='text-4xl text-center'>Unlock Tax Advantages With HUF's  Financial Benefits</p>
            <p className='text-[14px] mt-[5px] text-center'>Forming a Hindu Undivided Family (HUF) not only provides a distinct PAN number but also unlocks several tax advantages, enhancing overall financial planning. Let's delve into the specific benefits:</p>
            <div className='w-[60%] mx-auto xs:w-[90%] sm:w-[90%] '>
              <div className='py-[30px] px-[20px] bg-[#fff] mt-4 rounded-[12px] border-r-2 border-[#0F52BA]'>
                <p className='text-[17px] font-[500]'>Income Tax Privileges:</p>
                <p className='text-[#8c8c8c] leading-[30px]'>As a separate legal entity with its own PAN, the HUF can generate income, conduct business, and invest in various assets, all while enjoying the basic exemption limit of 2.5 lakhs.</p>
              </div>
              <div className='py-[30px] px-[20px] bg-[#fff] mt-4 rounded-[12px] border-r-2 border-[#0F52BA]'>
                <p className='text-[17px] font-[500]'>Residential Property Ownership:</p>
                <p className='text-[#8c8c8c] leading-[30px]'>The Indian Income Tax Act stipulates that if an individual possesses multiple residential properties, only one is considered self-occupied, and taxes are levied on the rest. By establishing a HUF, you can own multiple residential properties without incurring additional taxes.</p>
              </div>
              <div className='py-[30px] px-[20px] bg-[#fff] mt-4 rounded-[12px] border-r-2 border-[#0F52BA]'>
                <p className='text-[17px] font-[500]'>Life Insurance Deductions:</p>
                <p className='text-[#8c8c8c] leading-[30px]'>Similar to individual taxpayers benefiting from a deduction of Rs.1,50,000 on specified investments and life insurance premiums under section 80C, HUFs can also claim this benefit, reducing their taxable income.</p>
              </div>
              <div className='py-[30px] px-[20px] bg-[#fff] mt-4 rounded-[12px] border-r-2 border-[#0F52BA]'>
                <p className='text-[17px] font-[500]'>Investment Opportunities:</p>
                <p className='text-[#8c8c8c] leading-[30px]'>HUFs can explore tax-saving investment avenues such as Equity-Linked Savings Schemes (ELSS) and avail tax benefits of up to Rs.1,50,000 under section 80C.</p>
              </div>
              <div className='py-[30px] px-[20px] bg-[#fff] mt-4 rounded-[12px] border-r-2 border-[#0F52BA]'>
                <p className='text-[17px] font-[500]'>Health Insurance Advantage:</p>
                <p className='text-[#8c8c8c] leading-[30px]'>While individuals receive a deduction of Rs.25,000 annually on health insurance premiums for their family under section 80D, this might fall short as premiums rise. A HUF, however, can claim an additional deduction of Rs.25,000, resulting in a total health insurance premium deduction of Rs.50,000.</p>
              </div>
              <p className='mt-[30px] text-[18px] text-center'><b>By leveraging these tax benefits, a HUF becomes a strategic financial entity, optimizing tax liabilities and promoting long-term financial well-being.</b></p>
            </div>
          </div>
          <div className='grid grid-cols-2 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 md:w-[90%] sm:w-[90%] xs:w-[90%] w-[60%] lg:w-[90%] xl:w-[90%] mx-auto gap-[30px] py-[20px]'>
            <div className=''>
              <p className='text-4xl'>Essential Documentation for HUF Account Registration in India</p>
              <p className='text-[#414141] mt-[20px]'>The registration journey for your Hindu Undivided Family (HUF) in India involves gathering crucial documents. Ensure you have the following at your fingertips:</p>
              <p className='text-[#414141] mt-[20px]'>1.<b>PAN Card Copy of Karta:</b> Provide a clear copy of the Permanent Account Number (PAN) card belonging to the Karta, the individual entrusted with leading the HUF.</p>
              <p className='text-[#414141] mt-[20px]'>2.<b>Aadhar Card Copy of Karta:</b>Include a copy of the Aadhar card of the Karta, establishing identity and residence details.</p>
              <p className='text-[#414141] mt-[20px]'>3.<b>Passport Size Photograph of Karta:</b>Submit a recent passport-sized photograph of the Karta, adding a personal touch to the registration process.</p>
              <p className='text-[#414141] mt-[20px]'>4.<b>Specimen Signature of Karta & Family Members:</b>Furnish the specimen signature of the Karta along with those of family members, clearly indicating their names and relationship with the Karta. This step ensures the authenticity of the documentation.</p>
              <p className='text-[#414141] mt-[20px]'>5.<b>HUF Deed:</b>HUF deed is a legal document on stamp paper which states the  name of Karta, Co- Parceners and other members of HUF (Hindu Undivided Family).</p>
            </div>
            <div className=''>
              <img src='final-huf-doc-account.svg' className='w-[100%] mt-[40px]'></img>
            </div>
          </div>
          <div className='bg-[#e9e9e95f] py-[20px]'>
            <p className='text-4xl text-center'>Why Choose Tax2clear for HUF Account <br /> Registration Services?</p>
            <div className='why-choose-tax2clear-huf mt-[30px] w-[50%] xs:w-[90%] mx-auto'>
              <div className='flex mt-[20px] '>
                <p className='content-start mt-[10px]'><FaCheck color={"#0F52BA"} className='mr-2 align-center' /></p>
                <p><b className='text-xl'>Expert Guidance:</b><br />Benefit from our team of experienced professionals specialized in HUF registrations and filings.</p>
              </div>
              <div className='flex mt-[20px]'>
                <p className='content-start mt-[10px]'><FaCheck color={"#0F52BA"} className='mr-2 align-center' /></p>
                <p><b className='text-xl'>Efficiency:</b><br />Streamlined processes to ensure a hassle-free experience, saving you time and effort.</p>
              </div>
              <div className='flex mt-[20px]'>
                <p className='content-start mt-[10px]'><FaCheck color={"#0F52BA"} className='mr-2 align-center' /></p>
                <p><b className='text-xl'>Compliance Assurance:</b><br />Stay confident with our comprehensive support, ensuring adherence to all legal and tax requirements.</p>
              </div>
            </div>
          </div>
          <div className='p-10 xs:p-4 mb-10 bg-100 bg-[#C6DBFA] w-[80%] xs:w-[100%] mx-auto' id='aadharFaq'>
            <h1 className='text-4xl text-900 text-[#1E1E24]' >Frequently Asked Questions</h1>
            {
              HUFfaq.map((item) => {
                return (
                  <>
                    <div key={item.id}>
                      <p className='text-900 text-[#1E1E24] font-bold text-xl pt-10 pb-5 flex items-center xs:items-start  gap-2.5 xs:text-lg xs:leading-6'><span className='text-base xs:mt-2 text-center flex items-center justify-center bg-900 bg-[#1E1E24] question p-2 xs:leading-[100%] h-6 w-6 xs:text-sm xs:rounded-[100%] rounded-full  font-normal text-white'>Q</span>{item.question}</p>
                      <p className='text-base xs:text-justify xs:text-sm'>Ans: {item.answer}</p>
                      {
                        item.list ? <ul className='pt-5 listQuestion'>
                          {item.list.map((li) => {
                            return (
                              <li>&#x2713; {li.list}</li>
                            )
                          })}
                        </ul> : null
                      }

                      {/* <div className='border-solid border border-sky-900 my-4 w-1/4'></div> */}
                    </div>
                  </>
                );
              })
            }

          </div>
        </div>
      </div>
      <div className='fix-watsapp-icon'>
      <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  )
}

export default HUFfilling
