import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import RefContext from "../Context/RefContext";

function Navbar() {
    const a = useContext(RefContext);

    const [menu, setMenu] = useState(false);
    const [productBTN, setproductBTN] = useState(false);
    const [guidesBTN, setguidesBTN] = useState(false);

    const [dropProduct, setdropProduct] = useState(false);
    const [dropGuide, setdropGuide] = useState(false);

    const handleMenu = () => {
        setMenu(!menu);
    };

    const handelProduct = () => {
        setproductBTN(!productBTN);
    };

    const handelGuides = () => {
        setguidesBTN(!guidesBTN);
    };

    function toTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    return (
        <>
            {/* for mobile-screen */}
            <div className="px-6 py-2.5 lg:hidden xl:hidden 2xl:hidden">
                <div className="flex items-center justify-between">
                    <NavLink to="/">
                        <div className="w-4/4 ">
                            <img
                                className="w-100 xs:w-[60%] sm:w-[60%] md:w-[30%]"
                                src="final-header-logo.svg"
                            />
                        </div>
                    </NavLink>
                    <div>
                        <button>
                            {!menu ? (
                                <FiMenu fontSize={20} onClick={handleMenu} />
                            ) : (
                                <AiOutlineClose fontSize={20} onClick={handleMenu} />
                            )}
                        </button>
                    </div>
                </div>
            </div>
            {menu && (
                <div className="py-4 px-8 xs:px-0 sm:px-0 md:px-0">
                    <div >
                        <Link to={'/'} className="font-semibold text-lg text-black px-5">Home</Link>
                    </div>
                    <div className="px-5">
                        <div className="xs:py-0 sm:py-2 md:py-0 product flex items-center gap-1.5 text-[#333] cursor-pointer">
                            <p
                                className="font-semibold text-lg text-black pt-0"
                                onClick={handelProduct}
                            >
                                Products
                            </p>
                            <p>
                                <FaChevronDown fontSize={12} />
                            </p>
                        </div>
                        {!productBTN && (
                            <div className="px-3">
                                <Link
                                    onClick={() => a.setRef("File Your Return")}
                                    to="/fileyourreturn"
                                >
                                    <p className="text-sm pb-3">File Your Return</p>
                                </Link>
                                <NavLink to="/form16">
                                    <p className="text-sm pb-3">Send Form-16</p>
                                </NavLink>
                                <NavLink
                                    to="/eca"
                                    onClick={() => a.setRef("Get a Personal eCA")}
                                >
                                    {" "}
                                    <p className="text-sm pb-3">CA Assisted Tax Filing</p>
                                </NavLink>
                                <Link
                                    to="/capitalgainproduct"
                                    onClick={() =>
                                        a.setRef("Calculate Capital Gain on Share or Property")
                                    }
                                >
                                    <p className="text-sm pb-3">
                                        Calculate Capital Gain on Share or Property
                                    </p>
                                </Link>
                                <Link
                                    to="/taxonwinnings"
                                    onClick={() =>
                                        a.setRef(
                                            "Taxes on Winnings from Online gaming websites and apps"
                                        )
                                    }
                                >
                                    <p className="text-sm pb-3">
                                        Taxes on Winnings from Online gaming websites and apps
                                    </p>
                                </Link>
                                <Link
                                    to="/ntrtax"
                                    onClick={() => a.setRef("NRI Taxes & ITR Filing")}
                                >
                                    <p className="text-sm pb-3">NRI Taxes & ITR Filing</p>
                                </Link>
                            </div>
                        )}
                        <div className="guide flex items-center gap-1.5 cursor-pointer relative">
                            <p className="font-medium text-lg" onClick={handelGuides}>
                                Guides
                            </p>
                            <p>
                                <FaChevronDown fontSize={12} />
                            </p>

                        </div>
                        {!guidesBTN && (
                            <div className="px-3">
                                <div className="grid grid-cols-0">
                                    <div className="income-tex_guide border-r-0 xs:w-full sm:w-full md:w-full px-0">
                                        {/* <h2 className="text-lg font-bold">Income Tax Guides</h2> */}

                                        <NavLink to='/aadhar' onClick={handleMenu} className="pb-3">
                                            <span className="text-sm">Aadhar card</span>
                                        </NavLink><br />
                                        <NavLink to='/pancard' onClick={handleMenu} className="pb-3">
                                            <span className="text-sm ">Pan card</span>
                                        </NavLink><br />
                                        <NavLink to="/huffiling">
                                            <span className="text-sm">HUF Filing</span>
                                        </NavLink><br />
                                        <NavLink to='/fillingITR' onClick={handleMenu} className="pb-3">
                                            <span className="text-sm ">E-filling ITR</span>
                                        </NavLink><br />

                                        <NavLink to="/itrefund" onClick={handleMenu} className="pb-3">
                                            <span className="text-sm ">Incometax refund</span>
                                        </NavLink><br />

                                        <NavLink to='/tds' onClick={handleMenu} className="pb-3">
                                            <span className="text-sm ">TDS</span>
                                        </NavLink><br />

                                        <NavLink to='/salaryincome' onClick={handleMenu} className="pb-3">
                                            <span className="text-sm ">Salary income</span>
                                        </NavLink><br />

                                        <NavLink to='/section80' onClick={handleMenu} className="pb-3">
                                            <span className="text-sm ">Section 80 deductions</span>
                                        </NavLink><br />

                                        <NavLink to='/capitalgain' onClick={handleMenu} className="pb-3">
                                            <span className="text-sm ">Capital gains income</span>
                                        </NavLink><br />

                                        <NavLink to='/aadhar-link-pan' onClick={handleMenu} className="pb-3">
                                            <span className="text-sm ">Aadhar link PAN</span>
                                        </NavLink>

                                    </div>
                                    {/* <div className="gst_guide xs:pt-8 sm:pt-8 md:pt-8 px-6 w-full">
                                            <h2 className="text-lg font-bold">GST Guides</h2>

                                            <NavLink to="/assistedtax" onClick={() => { a.setRef("File Your Return"); handleMenu() }}>
                                                <span className="text-sm">File Your Return</span>
                                            </NavLink>

                                            <NavLink to="/assistedtax" onClick={() => { a.setRef("Get a Personal eCA"); handleMenu() }}>
                                                <span className="text-sm px-2">CA Assisted Tax Filing</span>
                                            </NavLink>

                                            <NavLink to="/assistedtax" onClick={() => { a.setRef("Taxes on Winnings from Online gaming websites and apps"); handleMenu() }}>
                                                <span className="text-sm">Taxes on Winnings from Online gaming websites and apps</span>
                                            </NavLink>

                                            <NavLink to="/assistedtax" onClick={() => { a.setRef("Calculate Capital Gain on Share or Property"); handleMenu() }}>
                                                <span className="text-sm">Calculate Capital Gain on Share or Property</span>
                                            </NavLink>

                                            <NavLink to="/form16" onClick={handleMenu}>
                                                <span className="text-sm px-2.5">Upload Form-16</span>
                                            </NavLink>

                                            <NavLink to="/assistedtax" onClick={() => { a.setRef("NRI Taxes & ITR Filing"); handleMenu() }}>
                                                <span className="text-sm">NRI Taxes & ITR Filing</span>
                                            </NavLink>

                                        </div> */}
                                </div>
                            </div>
                        )}

                        <NavLink to='/Pricing'><p className="font-semibold text-lg cursor-pointer mt-3" onClick={toTop}>Pricing</p></NavLink>
                        <div className="xs:py-2 sm:py-2 md:py-2 guide flex items-center gap-1.5 text-[#333]">
                            <NavLink to="/faq">
                                <a className="text-black hover:text-[#0F52BA] font-semibold text-lg">
                                    {" "}
                                    FAQ{" "}
                                </a>
                            </NavLink>
                        </div>
                        <div className="xs:hidden sm:hidden md:hidden">
                            <Link to="/assistedtax">
                                <button
                                    onClick={() => a.setRef("contact")}
                                    className="bg-[#0F52BA] text-white py-2 px-4 rounded-lg"
                                >
                                    Contact
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            {/* for desktop-screen */}
            <div className="main-containerr">
                <div className="xs:px-6 sm:px-6 md:px-6 xs:py-5 sm:py-5 md:py-5 sticky  top-0  navbar-glass py-2 px-7 xs:w-full sm:w-full md:w-full xs:px-4 sm:px-4 md:px-4 xs:py-3 sm:py-3 md:py-3 xs:top-0">
                    <div className="w-[80%] mx-auto">
                        <div className="flex items-center justify-between">
                            {!menu && (
                                <NavLink to="/">
                                    <div className=" xs:w-2/5">
                                        <img
                                            className="ml-[0px] lg:ml-[0px] md:ml-[0px] w-[12rem] xs:w-full sm:w-full md:w-full"
                                            src='final-header-logo.svg'
                                        />
                                    </div>
                                </NavLink>
                            )}

                            <div className="flex items-center gap-8 xs:hidden sm:hidden">
                                <NavLink to='/'><p className="font-medium text-lg cursor-pointer" onClick={toTop}>Home</p></NavLink>

                                <div className="product flex items-center gap-1.5 cursor-pointer relative">
                                    <div
                                        className="navLink flex items-center gap-1"
                                        onClick={() =>
                                            dropProduct === true
                                                ? setdropProduct(false)
                                                : setdropProduct(true)
                                        }
                                    >
                                        <p className="font-medium text-lg">Products</p>
                                        <p className="pt-1">
                                            <FaChevronDown fontSize={12} />
                                        </p>
                                    </div>
                                    {/* {dropProduct === true ? */}
                                    <div className="dropdown-content w-[200px]  mt-1">
                                        <span className="text-sm">
                                            <NavLink
                                                onClick={() => a.setRef("File Your Return")}
                                                to="/fileyourreturn"
                                            >
                                                File Your Return
                                            </NavLink>
                                        </span>
                                        <span className="text-sm px-2.5">
                                            <NavLink to="/form16">Send Form-16</NavLink>
                                        </span>
                                        <span className="text-sm px-2">
                                            <NavLink
                                                onClick={() => a.setRef("Get a Personal eCA")}
                                                to="/eca"
                                            >
                                                Get a Personal eCA
                                            </NavLink>
                                        </span>
                                        <span className="text-sm">
                                            <NavLink
                                                onClick={() =>
                                                    a.setRef("Calculate Capital Gain on Share or Property ")
                                                }
                                                to="/capitalgainproduct"
                                            >
                                                Calculate Capital Gain on Share or Property{" "}
                                            </NavLink>
                                        </span>
                                        <span className="text-sm">
                                            <NavLink
                                                onClick={() =>
                                                    a.setRef(
                                                        "Taxes on Winnings from Online gaming websites and apps"
                                                    )
                                                }
                                                to="/taxonwinnings"
                                            >
                                                Taxes on Winnings from Online gaming websites and apps
                                            </NavLink>
                                        </span>
                                        <span className="text-sm">
                                            <NavLink
                                                onClick={() => a.setRef("NRI Taxes & ITR Filing")}
                                                to="/ntrtax"
                                            >
                                                NRI Taxes & ITR Filing
                                            </NavLink>
                                        </span>
                                    </div>
                                    {/* : <></>} */}
                                </div>


                                <div className="guide flex items-center gap-1.5 cursor-pointer relative">
                                    <div
                                        className="navLink flex items-center gap-1"
                                        onClick={() => setdropGuide(!dropGuide)}
                                    >
                                        <p className="font-medium text-lg">Guides</p>
                                        <p className="pt-1">
                                            <FaChevronDown fontSize={12} />
                                        </p>
                                    </div>
                                    {/* {dropGuide ?  */}
                                    <div className="dropdown-content guide">

                                        <div className="income-tex_guide">
                                            {/* <h2 className="text-lg font-bold pl-1.5 hover:text-[#0F52BA]">
                                            Income Tax Guides
                                        </h2> */}

                                            <NavLink to="/aadhar">
                                                <span className="text-sm">Aadhar</span>
                                            </NavLink>
                                            <NavLink to="/pancard">
                                                <span className="text-sm">Pan card</span>
                                            </NavLink>
                                            <NavLink to="/huffiling">
                                                <span className="text-sm">HUF Filing</span>
                                            </NavLink>

                                            <NavLink to='/fillingITR'>
                                                <span className="text-sm">E-filling ITR</span>
                                            </NavLink>

                                            <NavLink to="/itrefund">
                                                <span className="text-sm px-2.5">Income tax refund</span>
                                            </NavLink>

                                            <NavLink to='/tds'>
                                                <span className="text-sm px-2">TDS</span>
                                            </NavLink>

                                            <NavLink to='/salaryincome'>
                                                <span className="text-sm">Salary income</span>
                                            </NavLink>

                                            <NavLink to='/section80'>
                                                <span className="text-sm">Section 80 deductions</span>
                                            </NavLink>

                                            <NavLink to='/capitalgain'>
                                                <span className="text-sm">Capital gains income</span>
                                            </NavLink>
                                            <NavLink to='/aadhar-link-pan'>
                                                <span className="text-sm">Aadhar link PAN</span>
                                            </NavLink>

                                        </div>

                                        {/* <div className="gst_guide">
                                            <h2 className="text-lg font-bold pl-1.5 hover:text-[#0F52BA]">
                                                Gst Guides
                                            </h2>

                                            <NavLink onClick={() => a.setRef("File Your Return")}
                                                to="/assistedtax">
                                                <span className="text-sm">File Your Return</span>
                                            </NavLink>

                                            <NavLink to="/form16">
                                                <span className="text-sm px-2.5">Upload Form-16</span>
                                            </NavLink>

                                            <NavLink onClick={() => a.setRef("Get a Personal eCA")}
                                                to="/assistedtax">
                                                <span className="text-sm px-2">CA Assisted Tax Filing</span>
                                            </NavLink>

                                            <NavLink onClick={() => a.setRef("Taxes on Winnings from Online gaming websites and apps")}
                                                to="/assistedtax">
                                                <span className="text-sm">Taxes on Winnings from Online gaming websites and apps</span>
                                            </NavLink>

                                            <NavLink onClick={() => a.setRef("Calculate Capital Gain on Share or Property")}
                                                to="/assistedtax">
                                                <span className="text-sm">Calculate Capital Gain on Share or Property</span>
                                            </NavLink>

                                            <NavLink onClick={() => a.setRef("NRI Taxes & ITR Filing")}
                                                to="/assistedtax">
                                                <span className="text-sm">NRI Taxes & ITR Filing</span>
                                            </NavLink>
                                        
                                        </div> */}

                                    </div>
                                    {/* // : <></>} */}
                                </div>
                                <NavLink to='/Pricing'><p className="font-medium text-lg cursor-pointer" onClick={toTop}>Pricing</p></NavLink>
                                <div className="cursor-pointer">
                                    <NavLink to="/faq">
                                        <a className="text-[17px] hover:text-[#0F52BA] font-medium">
                                            {" "}
                                            FAQ{" "}
                                        </a>
                                    </NavLink>
                                </div>
                                <div className="xs:hidden sm:hidden md:hidden">
                                    <Link to="/assistedtax">
                                        <button
                                            onClick={() => a.setRef("contact")}
                                            className="bg-[#0F52BA] text-white py-2 px-5 rounded-[4px]"
                                        >
                                            Contact
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;
