import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { BsCheck2Circle,BsWhatsapp } from "react-icons/bs"
import aadharFAQ from "../utils/aadharFAQ.json"

const Aadhar = () => {
    return (
        <div className='main-containerr'>
            <div className='aadharContainer'>
                <br />
                <h1 className='text-4xl font-bold'>Aadhar Card</h1>
                <br />
                <p>For establishing unique identification of every single person, UIDAI (Unique Identification Authority of India) on behalf of government of India created a centralised database for collecting biometric and demographic data of residents, and issued a unique 12- digit number known as AADHAR Number. Aadhar is a proof of identity and address, valid for lifetime. It is issued in the form of a letter which is delivered via post to the address mentioned in the application.
                    Read Below to know more about Aadhaar Card.</p>
                <br />
                <div className='contentBox'>
                    <h3 className='text-2xl font-semibold'>Contents</h3>
                    <ul className='xs:mx-0'>
                        <HashLink to='#whatisaadhar' smooth ><li className='font-bold'>What is Aadhar Card?</li></HashLink>
                        <HashLink to='#baalAadhar' smooth ><li className='font-bold'>What is Baal Addhar?</li></HashLink>
                        <HashLink to='#whatisaadharnumber' smooth ><li className='font-bold'>What is Aadhaar Card Number?</li></HashLink>
                        <HashLink to='#featuresOfAadhar' smooth ><li className='font-bold'>Features of Aadhar Card</li></HashLink>
                        <HashLink to='#benefitsOfAadhar' smooth ><li className='font-bold'>Benefits-of-having-an-Aadhar-Card</li></HashLink>
                        <HashLink to='#howToEnroll' smooth ><li className='font-bold'>How to enrol for Aadhar Card?</li></HashLink>
                        <HashLink to='#whoCanEnroll' smooth ><li className='font-bold'>Who can enrol for Aadhar Card?</li></HashLink>
                        <HashLink to='#aadharStatus' smooth ><li className='font-bold'>Checking Aadhar Card status online</li></HashLink>
                        <HashLink to='#e-aadhar' smooth ><li className='font-bold'>e-Aadhar, m-Aadhar and virtual ID</li></HashLink>
                        <HashLink to='#updateAadhar' smooth ><li className='font-bold'>Updating Aadhar card details</li></HashLink>
                        <HashLink to='#aadharLinking' smooth ><li className='font-bold'>Aadhar card linking</li></HashLink>
                        <HashLink to='#forgotUID' smooth ><li className='font-bold'>Retrieve Lost or Forgotten EID/UID</li></HashLink>
                        <HashLink to='#reprint' smooth ><li className='font-bold'>Aadhar Reprint</li></HashLink>
                        <HashLink to='#virtualID' smooth ><li className='font-bold'>Virtual ID</li></HashLink>
                        <HashLink to='#e-kyc' smooth ><li className='font-bold'>e-KYC</li></HashLink>
                        <HashLink to='#aadharFaq' smooth ><li className='font-bold'>FAQ's</li></HashLink>
                    </ul>
                </div>
                <br />
                <div id='whatisaadhar'>
                    <h1 className='text-4xl'>What is Aadhar Card?</h1>
                    <br />
                    <p>India is the second largest populated countries in the world with a population of more than 1.2 billon individuals. In such a large country, a need was felt to identify each individual for various financial and welfare services. As such, the Government of India launched the concept of Aadhar Card. Aadhar, also called as the Unique Identification Number (UID), is a 12-digit unique identification number which is allotted to individuals who apply for it. The record of each individual’s biometric and demographic data is stored in the Aadhar Card which can be easily accessed using the unique Aadhar number</p>
                    <br />
                    <p>The Unique Identification Authority of India (UIDAI) is the main agency which operates under the Government of India and is tasked with the job of issuing Aadhar Cards to individuals. UIDAI collects the biometric and demographic data of the applicants and stores it in a centralised database. After the data is collected and verified, a unique Aadhar number is issued which is printed on the Aadhar Card. The card also contains the name, address, date of birth, gender and other details of the cardholder. Moreover, there is a QR code in the card which can be scanned to access the details of the cardholder easily.</p>
                </div>

                <br />
                <hr />
                <br />

                <div id='baalAadhar'>
                    <h1 className='text-4xl '>What is Baal Addhar?</h1>
                    <br />
                    <ul>
                        <li><BsCheck2Circle color={"#0F52BA"} className='w-5' /> <p>Baal Aadhaar is for children below 5 year.</p> </li>
                        <li><BsCheck2Circle color={"#0F52BA"} className='w-5' /><p>Baal Aadhaar is blue in colour.</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} className='w-5' /><p>Baal Aadhaar does not contain biometric information of the child.</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} className='w-5' /><p>When a child attains the age of five, it is mandatory to get Baal Aadhaar updated with biometric information</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} className='w-5' /><p>For the purpose of authentication, one of parent aadhaar is mandatory.</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} className='w-5' /><p>A picture of the child is also required for enrollment of Aadhaar.</p></li>
                    </ul>
                </div>


                <br />
                <hr />
                <br />

                <div id='whatisaadharnumber'>
                    <h1 className='text-4xl '>What is Aadhaar Card Number?</h1>
                    <br />
                    <p>Aadhaar card is a 12 digit unique number provided to every Indian resident who gets himself registered for it. Under its process, demographic as well as biometric information of the residents is taken such as name, date of birth, address, all ten fingers’ fingerprints, both eye iris scan, and a recent photograph. After completing the whole process, you get a 12 digit unique number that can be used as identity and address proof.</p>
                    <br />
                    <p>A public or private entity can accept the Aadhaar number as an identity proof of Aadhaar number holder. It is also used for verification of the identity of a person who is receiving subsidies or Government services.</p>
                </div>

                <br />
                <hr />
                <br />


                <div id='featuresOfAadhar'>
                    <h1 className='text-4xl '>Features of Aadhar Card</h1>
                    <br />
                    <p className='font-bold'>Here are some of the salient features of Aadhar Card –</p>
                    <br />
                    <ul>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>The card contains the biometric details of the cardholder like fingerprints, iris scan, photograph, etc. These details help in identifying the cardholder</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Applying or the Aadhar Card is voluntary. However, in some cases, having an Aadhar Card becomes compulsory</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Aadhar number is the 12-digit number contained in the Aadhar Card.</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>The Aadhar Card can be linked to all your financial accounts and investments like PAN Card, Ration card, bank account, mutual fund investments, life insurance policies, etc.</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>The card serves as a proof of identity, proof of address, proof of date of birth, etc.</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>The details contained in the card can be changed if there are any changes for the cardholder. For instance, in case of change of name or change of address</p></li>
                    </ul>
                </div>


                <br />
                <hr />
                <br />

                <div id='benefitsOfAadhar'>
                    <h1 className='text-4xl '>Benefits of having an Aadhar Card</h1>
                    <br />
                    <p className='font-bold'>Though having an Aadhar Card is voluntary till now, there are various benefits which you can avail if you have an Aadhar Card. These benefits include the following.</p>
                    <br />
                    <ul>
                        <li>If you want to claim a subsidy on your LPG, you would have to provide your Aadhar number to the LPG agency. The agency then links your Aadhar number with the LPG consumer number and the subsidy is directly transferred to your bank account. You can, therefore, save on your LPG connection</li>
                        <li>Accounts under Jan Dhan Yojana can be opened easily by submitting only the copy of your Aadhar Card. No other documents would be required. This account would give you banking facilities along with a RuPay debit card, life insurance, accident insurance, zero balance savings account, etc.</li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>To get a passport you would need to submit a copy of your Aadhar Card</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>You can avail the facility of DigiLocker by providing your Aadhar Card number at the time of registration. DigiLocker helps you store your important documents online and access them easily</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>By linking the Aadhar Card number to your voter ID, the possibility of bogus voters is eliminated and elections become fair and transparent</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>To avoid fraudulent pension payments, pensioners are required to link their Aadhar number with their pension account after which they would be able to receive pensions</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Opening a bank account has become easy through Aadhar Card as the card acts as all the required KYC documents needed at the time of account opening</p></li>
                        <li>If pensioners want to avail the facility of Jeevan Praman for Pensioners wherein they do not have to be physically present at the pension disbursing agency to renew their life certificates, they would have to provide their Aadhar details after which renewal would be done digitally</li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Aadhar acts as a proof of address and identity by the SEBI (Securities and Exchange Board of India)</p></li>
                        <li>New mobile connections require Aadhar Card details. Moreover, when the Aadhar card is linked with the mobile number, instant updates can be received by cardholders Driving licenses might also be linked to Aadhar card in future making the card a necessity</li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>PAN card is to be linked to Aadhar card so that money laundering activities can be curbed and the prevalence of black money reduces</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Linking Aadhar with your investments has also become necessary in recent times</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>IRCTC users can book up to 12 train tickets in a month if they verify their transactions using their Aadhar card numbers.</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Ration cards are also being linked with Aadhar card numbers so that the beneficiaries receiving the ration card benefits can be easily identified and duplicitous transactions can be curbed</p></li>
                    </ul>
                </div>

                <br />
                <hr />
                <br />

                <div id='howToEnroll'>
                    <h1 className='text-4xl '>How to enrol for Aadhar Card?</h1>
                    <br />
                    <p className='font-bold'>The process to apply for Aadhar Card is as follows –</p>
                    <br />
                    <ul>
                        <li>Locate the nearest Aadhar enrolment centre which is authorised to accept applications for Aadhar card. The list of centres can be found at UIDAI website (You can also find the enrolment centre of popular cities here – Chennai, Kolkata, Lucknow, Mumbai, Gurgaon, Bangalore, Ahmedabad, Hyderabad, Chandigarh, Jaipur and Delhi)</li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>After finding the centre, book an online appointment. To book an appointment online, you can use this link Aadhaar Card Apply Online</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>When visiting the centre, you should carry all the relevant documents which would be required for enrolling for Aadhar Card. These documents include the following –
                            <ol>
                                <li>Identity proof</li>
                                <li>Proof of address</li>
                                <li>Date of birth proof</li>
                            </ol>
                        </p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>An enrolment form is available at the enrolment centre which you should fill and submit. You can also download the form online at Download Enrolment Form, fill up the form and submit it at the centre.</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>When submitting the enrolment form, you would have to submit the relevant documents and your biometric data like your fingerprints, eye scan and photograph.</p></li>
                        <li>After submitting the enrolment form you would receive an acknowledgement receipt which would contain a 14-digit unique identification number. This number acts as a reference to your Aadhar Card application and can be used to track the status of your application.</li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Once your details are verified by the UIDAI, you would be allotted an Aadhar number and the Aadhar Card would be sent to the address which you mentioned when filling up the enrolment form.</p></li>
                    </ul>
                </div>

                <br />
                <hr />
                <br />

                <div id='whoCanEnroll'>
                    <h1 className='text-4xl '>Who can enrol for Aadhar Card?</h1>
                    <br />
                    <p>Aadhar Card can be availed by Indian citizens, non-resident Indians, foreigners who live in India as well as by minor children. However, if the applicant is a child below the age of 5 years, the Aadhar Card number of one of the parents of the child would have to be provided when applying for the Aadhar Card. The details of the parent’s Aadhar Card would be recorded in the enrolment form and the child would not have to submit any biometric data. Only a photograph of the child is taken to be attached to the Aadhar Card. The birth certificate of the child would be the only accepted age proof. Moreover, after the child turns 15 years old, the Aadhar Card would be updated with the child’s latest photograph and biometric details.</p>
                </div>

                <br />
                <hr />
                <br />

                <div id='aadharStatus'>
                    <h1 className='text-4xl '>Checking Aadhar Card status online</h1>
                    <br />
                    <p>After you apply for Aadhar Card, it takes some time for the UIDAI to allot you the Aadhar number. Moreover, additional time is taken for the actual Aadhar Card to reach your home. Meanwhile, you can check the status of your Aadhar Card application online. The process of checking the status is as follows –</p>
                    <br />
                    <ul>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Visit the UIDAI website at https://uidai.gov.in/</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>After finding the centre, book an online appointment. To book an appointment online, you can use this link Aadhaar Card Apply Online</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>On the home page, select the option ‘My Aadhar’</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Under this option select ‘Check Aadhar Status’</p></li>
                        <li>A new window would open wherein you would have to provide your enrolment ID (EID) which is the 14-digit acknowledgement number mentioned in the acknowledgement slip. Besides the EID, you would also have to enter the date and time of enrolment which is also mentioned in the acknowledgement slip. A security code would also be mentioned on the site which you should enter in the box.</li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>When all the details are entered, select ‘Check Status’ and your Aadhar application status would be displayed </p></li>
                    </ul>
                </div>


                <br />
                <hr />
                <br />

                <div id="e-aadhar">
                    <h1 className='text-4xl '>e-Aadhar, m-Aadhar and virtual ID</h1>
                    <br />
                    <ul>
                        <li><b>e-Aadhar–</b> e-Aadhar is a soft copy of your Aadhar card. The soft copy can be downloaded from UIDAI website by filling in a specified form. A pdf file would be downloaded which is password protected. The password for accessing your e- Aadhar would be the first four letters of your name in capital letters followed by your year of birth in YYYY format.</li>
                        <li><b>M- Aadhar–</b> m- Aadhar is a mobile application which is developed by UIDAI using which you can access Aadhar related services through your mobile phone.</li>
                        <li><b>Virtual</b>  virtual ID is a 16-digit unique code which can be availed by each Aadhar card holder for Aadhar authentication. The code is generated at UIDAI website and is valid for 24 hours. VID can be used to furnish Aadhar related authentications to different agencies in place of your actual Aadhar number. This protects your Aadhar number and also fulfils the requirement of Aadhar authentication. The VID can also be used to download e- Aadhar.</li>
                    </ul>
                </div>

                <br />
                <hr />
                <br />

                <div id='updateAadhar'>
                    <h1 className='text-4xl '>Updating Aadhar card details</h1>
                    <br />
                    <p>There might be times when Aadhar card details need to be changed. For instance, any change in the address recorded in the card, phone number, name, etc. might happen and when these changes happen, the Aadhar card should be updated with the changed detail. A change in the details in the Aadhar card can be done online or offline. Online changes can be done by visiting the UIDAI website. Here are the processes for each –</p>
                    <br />
                    <ul>
                        <li><b>Online update</b><br /> For online updating of details, a Self-Service portal is designed which is called the SSUP portal. On the portal you would have to select the fields which need updating. Fill in the updated details in the selected fields and submit the form. When the form is submitted, a URN is generated. You would also have to submit relevant documents online which support the changed details. You can use the URN number to view the status of your update.</li>
                        <li><b>Offline update</b><br /> Updating of certain details like your mobile number, biometric data, etc. can be done offline only through the permanent enrolment centres of Aadhar. You would have to locate and visit the enrolment centre, fill out the correct information in the correction form which is available at the centre, attach self-attested copies of documents which are proof of the change being recorded and submit the form. UIDAI would verify the form and the updated Aadhar card would be sent to replace the old one. Instead of visiting the enrolment centre, you can download the Aadhar correction form, fill it, attach the documents and post everything to UIDAI’s offices in Madhya Pradesh or Haryana.</li>
                    </ul>
                </div>

                <br />
                <hr />
                <br />

                <div id='aadharLinking'>
                    <h1 className='text-4xl '>Aadhar card linking</h1>
                    <br />
                    <p>Linking of Aadhar card has become necessary in many instances like in case of a bank account, PAN card, insurance policies, investments, etc. Aadhar card linking is an easy process which can be done online using your Aadhar card number and registered mobile number. You would just have to enter your Aadhar card number and an OTP will be sent to your registered mobile number. Once you enter the OTP, the linking process would be completed.</p>
                    <br />
                    <p>Here are the different processes of linking Aadhar to various avenues –</p>
                    <br />
                    <ul>
                        <li><b>Linking Aadhar with bank account</b>To link your Aadhar card with your bank account, there are various ways. You can link through net banking facility available with your bank account, by visiting the branches of the bank, through the bank’s ATM, by sending a SMS, through phone banking facility or by using the mobile application of your bank.</li>
                        <li><b>Linking Aadhar with PAN Card</b>Linking of Aadhar with PAN card can be done using any of the three ways. The first is through the website of the income tax department without logging into your account. The second way is through the website of the income tax department but here you link by logging into your account. The third way is to send a SMS to UIDAI to link your Aadhar and PAN.</li>
                        <li><b>Linking Aadhar with Ration Card</b>To link your Aadhar card with your ration card, you can visit a PDS centre or a ration office and link offline. You can also link online by visiting the Aadhar seeding application portal and furnishing your Aadhar number and ration card number. Lastly, linking can also be done by sending an SMS to UIDAI.</li>
                    </ul>
                </div>

                <br />
                <hr />
                <br />

                <div id="forgotUID">
                    <h1 className='text-4xl '>Retrieve Lost or Forgotten EID/UID</h1>
                    <br />
                    <p>It often happens that we forget or lost our EID or UID. But there is nothing to worry you can still retrieve lost EID or UID by following some simple steps:</p>
                    <br />
                    <ul>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Visit site https://resident.uidai.gov.in/</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Select “My Aadhar” , select “retrieve lost or forgotten” from the drop down</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>Enter require details</p></li>
                        <li><BsCheck2Circle color={"#0F52BA"} /><p>You will get all the details.</p></li>
                    </ul>
                </div>

                <br />
                <hr />
                <br />

                <div id='reprint'>
                    <h1 className='text-4xl '>Aadhar Reprint</h1>
                    <br />
                    <p>You can order reprinting of your Aadhar card in case you have lost aadhar. Just visit https://resident.uidai.gov.in/ then click on “My Aadhar” and choose”Order Aadhar Reprint” form the dropdown, then fill the required details and its done.</p>
                </div>

                <br />
                <hr />
                <br />

                <div id='virtualID'>
                    <h1 className='text-4xl '>Virtual ID</h1>
                    <br />
                    <p>Virtual ID is a temporary 16-digit no. which is aligned with your Aadhar Card.It can be used in lieu of aadhar whenever e-KYC is required. You can get your Virtual ID thorough surfing on https://resident.uidai.gov.in/ My Aadhar &gt; Aadhar services&gt;Generate VID&gt; its done.</p>
                </div>

                <br />
                <hr />
                <br />

                <div id='e-kyc'>
                    <h1 className='text-4xl '>e-KYC</h1>
                    <br />
                    <p>e-KYC is shareable document for verification and it can be used by any Aadhar card holder.To generate e-KYC, all you have to do is generate digitally signed UIDAI details of the user. This should contain mobile no., name , address, DOB etc.</p>
                </div>

                <br />
                <hr />
                <br />

                <div className='p-10 xs:p-4  mb-10 bg-100 bg-[#C6DBFA]' id='aadharFaq'>
                    <h1 className='text-4xl text-900 text-[#1E1E24]' >Frequently Asked Questions</h1>
                    {
                        aadharFAQ.map((item) => {
                            return (
                                <>
                                    <div key={item.id}>
                                        <p className='text-900 text-[#1E1E24] font-bold text-xl pt-10 pb-5 flex items-center xs:items-start  gap-2.5 xs:text-lg xs:leading-6'><span className='text-base xs:mt-2 text-center flex items-center justify-center bg-900 bg-[#1E1E24] question p-2 xs:leading-[100%] h-6 w-6 xs:text-sm xs:rounded-[100%] rounded-full  font-normal text-white'>Q</span>{item.question}</p>
                                        <p className='text-base xs:text-justify xs:text-sm'>Ans: {item.answer}</p>
                                        {
                                            item.list ? <ul className='pt-5 listQuestion'>
                                                {item.list.map((li) => {
                                                    return (
                                                        <li>&#x2713; {li.list}</li>
                                                    )
                                                })}
                                            </ul> : null
                                        }

                                        {/* <div className='border-solid border border-sky-900 my-4 w-1/4'></div> */}
                                    </div>
                                </>
                            );
                        })
                    }

                </div>

            </div>
            <div className='fix-watsapp-icon'>
            <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
            </div>
        </div>
    )
}

export default Aadhar