import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ButtonLoader from "../ButtonLoader";
import { BsWhatsapp } from "react-icons/bs";

const NRITax = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const date = new Date().toDateString();
    const API = process.env.REACT_APP_API_URL;

    let data = {
      "date": date,
      "event_name": "Contact",
      "name": name,
      "email": email,
      "phone_number": phone,
      "ref": "NRI Tax"
    }

    try {
      if (phone.length === 10) {
          const result = await axios.post(API, data,
              {
                  headers: {
                      'Content-Type': "text/plain;charset=utf-8"
                  },
                  'mode': "no-cors"
              }).then((resp) => {
                  if (resp.status === 200) {

                      setLoading(false);
                      setName('');
                      setEmail('');
                      setPhone('');
                      Swal.fire(
                          'Thank You For Inquiry , we will contact You soon on Whatsapp',
                          'Your form is succesfully submitted!',
                          'success'
                      )
                  }


              }).catch(error => {

                  setLoading(false);
                  Swal.fire(
                      "Something went wrong",
                      "Try after few minutes",
                      "error"
                  );

              });
          // const googleSheet = await axios.post(SHEET_API, formData);

          // if (result.status === 200 && googleSheet.status === 200) {
          console.log("data", result)

      } else {
          Swal.fire(
              "Something went wrong",
              "Mobile Number Not Valid",
              "error"
          );
      }


  } catch (error) {
      setLoading(false);
      Swal.fire(error.message, "Something went wrong", "error");
    }
  };

  return (
    <div className="main-containerr">
      <div className="w-[100%] mx-auto">
        <div className="bg-[#F5F6F7] xl:h-full 2xl:h-full">
          <div className="px-6 py-8 xs:py-0 xs:px-0 w-4/5 mx-auto xs:w-full sm:w-full md:w-full xl:w-9/12 2xl:w-9/12 xl:h-[600px] 2xl:h-[600px]">
            <div className=" py-12 grid grid-cols-2 gap-9 xs:py-6 sm:py-6 md:py-8 xs:px-4 sm:px-4 md:px-6 lg:grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
              <div>
                <h1 className="text-[#0c2343] text-3xl font-normal xs:text-xl sm:text-xl md:text-xl">
                  <br />
                  <p className="my-2 text-[40px] font-extrabold xs:text-2xl sm:text-2xl md:text-2xl xs:my-1 sm:my-1 md:my-1">
                    <span className="text-[#1D70ED]"> NRI </span> tax filing
                    services
                  </p>
                  include on-call and email consultations as per your convenience.
                </h1>
                <div className="mt-8">
                  <div className="flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      We offer income tax return filing services for one financial
                      year, including vigilant analysis of both Indian and foreign
                      income.
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[17px]">
                      Our team provides extensive analysis to determine if the
                      income falls under the capital gains head, and we offer
                      suggestions to maximize tax refunds.
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      We also offer tax payment assistance and e-verification
                      assistance to ensure a smooth and hassle-free tax filing
                      experience for our NRI clients.
                    </h3>
                  </div>
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                className="w-4/5 mx-auto bg-[#ffffff80] box-shadow rounded-[30px] py-6 px-8 border-b border-[#0F52BA] xs:w-full sm:w-full md:w-full"
              >
                <h1 className="py-2 text-xl font-bold text-center">
                  NRI Tax Filing
                </h1>
                <div className="py-4">
                  <input
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    required
                    className="w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />

                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    required
                    className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    type="number"
                    placeholder="Mobile Number"
                    value={phone}
                    maxLength="10"
                    required
                    className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />
                </div>
                <p>
                  By submitting the form, you agree to our
                  <a href="https://sites.google.com/view/Tax2clear/terms-condition" target="_blank" className="text-[#0F52BA] hover:underline"> Terms & Conditions
                  </a> and <a href="https://sites.google.com/view/Tax2clear/privacy-policy" target="_blank" className="text-[#0F52BA] hover:underline">
                    Privacy Policy
                  </a>
                </p>
                <button
                  type="submit"
                  className="text-white bg-[#0F52BA] w-full rounded-[4px] mt-5 py-2 text-center"
                >
                  {loading === true ? <ButtonLoader /> : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <br />
        <div className="w-3/4 xs:w-[95%] mx-auto mt-8">
          {/* <ul className="list-disc">
          <li className="font-semibold text-[16px] p-2">
            Our NRI tax filing services include on-call and email consultations
            as per your convenience.
          </li>
          <li className="font-semibold text-[16px] p-2">
            We offer income tax return filing services for one financial year,
            including vigilant analysis of both Indian and foreign income.
          </li>
          <li className="font-semibold text-[16px] p-2">
            Our team provides extensive analysis to determine if the income
            falls under the capital gains head, and we offer suggestions to
            maximize tax refunds.
          </li>
          <li className="font-semibold text-[16px] p-2">
            We also offer tax payment assistance and e-verification assistance
            to ensure a smooth and hassle-free tax filing experience for our NRI
            clients.
          </li>
        </ul>
        <br /> */}
          <p>
            <b>"Non-Resident"</b> is a person who is not Resident in India. The
            residential status of an individual in a given year determines whether
            the individual is Resident or Non-Resident for that given year.Thus,
            the residential status of an individual needs to be determined every
            year.
          </p>
          <br />
          <div className="w-[100%] overflow-x-scroll">
            <table className="border ">
              <thead>
                <tr>
                  <th className="border px-3 py-5">Residental status</th>
                  <th className="border px-3 py-5">Condition 1</th>
                  <th className="border px-3 py-5">Condition 2</th>
                  <th className="border px-3 py-5">Comments</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border">
                  <td className="px-3 py-5 border font-bold">Resident</td>
                  <td className="px-3 py-5 border">
                    If you stayed in india for 182 days or more during the previous
                    year.
                  </td>
                  <td className="px-3 py-5 border">
                    If you stayed in india for 60 days more during the previous year
                    and has been in india for 365 days or more during 4 previous
                    years immediately preceeding the relevent financial year
                  </td>
                  <td className="px-3 py-5 border">
                    If you are satisfy one of the two Conditions then you are
                    Resident
                  </td>
                </tr>

                <tr className="border">
                  <td className="px-3 py-5 border font-bold">
                    Not ordinarily resident (NOR)
                  </td>
                  <td className="px-3 py-5 border">
                    You should not be a resident for at least 8 years in the
                    previous 10 year period.
                  </td>
                  <td className="px-3 py-5 border">
                    You should not have stayed in india for 730 days or more in
                    previous preceeding the financial year.
                  </td>
                  <td className="px-3 py-5 border">
                    If you are satisfy both these conditions you can claim NDR
                    status.
                  </td>
                </tr>

                <tr className="border">
                  <td className="px-3 py-5 font-bold border">Non-resident</td>
                  <td className="px-3 py-5 border">
                    In you haven't stayed in india for 182 days or more during the
                    previous year.
                  </td>
                  <td className="px-3 py-5 border">
                    If you haven't stayed in india for 60 days or more during the
                    previous year and if you haven't stayed in india for 365 days or
                    more during 4 previous years immediately preceeding the relevent
                    financial year.
                  </td>
                  <td className="px-3 py-5 border">
                    If you satisfy any one of the two conditions then you are a
                    non-resident.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='fix-watsapp-icon'>
      <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default NRITax;
