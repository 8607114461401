import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Question from "../utils/question.json";
import { BsWhatsapp } from "react-icons/bs";

function Faq() {

    const [key, setKey] = useState(1);

    const changeName = (_key) => {
        setKey(_key);
    }

    return (
        <div className='main-containerr'>
            <div className='bg-[#EEEEEE]'>
                <div className='py-8 px-4 text-center xs:py-4 sm:py-4 md:py-6'>
                    <h1 className='text-3xl xs:text-xl sm:text-xl md:text-2xl'>Tax2clear FAQ'S</h1>
                    <div className='py-1.5 border-b-2 w-20 mx-auto border-[#0F52BA] xs:pt-0 sm:pt-0 md:pt-0' />
                </div>
                <div className='bg-[#f3f3f3]'>
                    <div className='w-[90%] mx-auto py-10 xs:w-full sm:w-full md:w-full'>
                        <div className='grid grid-cols-[30%_70%] xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1'>
                            <div className='border-r-2 border-[#ccc] faq-part-main-part'>
                                <div className='flex items-center gap-3 px-4 py-3 cursor-pointer hover:bg-white' onClick={() => { changeName(1) }}>
                                    <img className='w-[40px]' src='fina-capital-faq.webp' />
                                    <span className='text-lg'>Capital Gains</span>
                                </div>
                                <div className='mt-3 flex items-center gap-3 px-4 py-4 cursor-pointer hover:bg-white' onClick={() => { changeName(2) }}>
                                    <img className='w-[40px]' src='final-tds-faq.webp' />
                                    <span className='text-lg'>ITR</span>
                                </div>
                                <div className='mt-3 flex items-center gap-3 px-4 py-4 cursor-pointer hover:bg-white' onClick={() => { changeName(3) }}>
                                    <img className='w-[40px]' src='final-form-16-faq.webp' />
                                    <span className='text-lg'>Form 16</span>
                                </div>
                                <div className='mt-3 flex items-center gap-3 px-4 py-3 cursor-pointer hover:bg-white' onClick={() => { changeName(4) }}>
                                    <img className='w-[35px]' src='final-main-tds-faq.webp' />
                                    <span className='text-lg'>TDS</span>
                                </div>
                                <div className='mt-3 flex items-center gap-3 px-4 py-3 cursor-pointer hover:bg-white' onClick={() => { changeName(5) }}>
                                    <img className='w-[35px]' src='final-salary-income-faq.webp' />
                                    <span className='text-lg'>Salary Income</span>
                                </div>
                                <div className='mt-3 flex items-center gap-3 px-4 py-3 cursor-pointer hover:bg-white' onClick={() => { changeName(6) }}>
                                    <img className='w-[35px]' src='final-income-house-faq.webp' />
                                    <span className='text-lg'>Income From House Property</span>
                                </div>
                                <div className='mt-3 flex items-center gap-3 px-4 py-3 cursor-pointer hover:bg-white' onClick={() => { changeName(7) }}>
                                    <img className='w-[35px]' src='final-seniar-citizen-faq.webp' />
                                    <span className='text-lg'>Senior Citizens Under Income Tax Act</span>
                                </div>
                                <div className='mt-3 flex items-center gap-3 px-4 py-3 cursor-pointer hover:bg-white' onClick={() => { changeName(8) }}>
                                    <img className='w-[35px]' src='final-tax-audit-faq.webp' />
                                    <span className='text-lg'>Tax Audit</span>
                                </div>
                                <div className='mt-3 flex items-center gap-3 px-4 py-3 cursor-pointer hover:bg-white' onClick={() => { changeName(9) }}>
                                    <img className='w-[35px]' src='final-TAN-faq.webp' />
                                    <span className='text-lg'>TAN</span>
                                </div>
                                <div className='mt-3 flex items-center gap-3 px-4 py-3 cursor-pointer hover:bg-white' onClick={() => { changeName(10) }}>
                                    <img className='w-[35px]' src='final-non-recidental-faq.webp' />
                                    <span className='text-lg'>Provisions Useful for Non-Residents</span>
                                </div>
                            </div>
                            <div className='px-[30px] py-6'>
                                {Question.map((object) => {
                                    return (
                                        <>
                                            {object.id == key &&
                                                <div className='px-20px py-2.5'>
                                                    <div className='flex gap-3'><span className='text-[#0F52BA] font-bold text-2xl'>Q.</span>
                                                        <p className='text-xl font-semibold xs:text-base'>{object.que}</p>

                                                    </div>
                                                    <div className='pt-3 border-b-2 border-[#e1e1e1]' />
                                                    <div className='py-2.5 text-[#73739a] text-[17px] xs:text-[15px]'>{object.ans.split("^^").map(content => {
                                                        console.log(content)
                                                        return <p>{content}</p>
                                                    })}</div>
                                                    {
                                                        object.list ? <ul className='pt-1 listQuestion'>
                                                            {object.list.map((li) => {
                                                                return (
                                                                    <li className='py-1.5 text-[#73739a] text-[17px] xs:text-[15px]'>&#x2022; {li.list}</li>
                                                                )
                                                            })}
                                                        </ul> : null
                                                    }
                                                    <div className='py-2.5 text-[#73739a] text-[17px] xs:text-[15px]'>{object.ans2}</div>
                                                    {
                                                        object.list2 ? <ul className='pt-1 listQuestion'>
                                                            {object.list2.map((li) => {
                                                                return (
                                                                    <li className='py-1.5 text-[#73739a] text-[17px] xs:text-[15px]'>&#x2022; {li.list2}</li>
                                                                )
                                                            })}
                                                        </ul> : null
                                                    }
                                                    <div className='pt-3 border-b border-[#e1e1e1]' />
                                                </div>
                                            }
                                        </>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='fix-watsapp-icon'>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
                </div>
        </div>
    )
}

export default Faq