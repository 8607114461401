import React from "react";
import { HashLink } from "react-router-hash-link";
import { BsCheck2Circle } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs"
import itrFAQ from "../utils/itrFAQ.json";
import { Link } from "react-router-dom";

const FillingITR = () => {
  const today = new Date();
  const year = today.getFullYear();
  const table = [
    {
      id: 1,
      particulars: "Income from salary (after Standard deduction)",
      case1: "2,90,000",
      case2: "2,71,000",
      case3: "8,92,000",
      case4: "4,98,000",
      case5: "1,90,000",
    },
    {
      id: 2,
      particulars: "HRA, Medical, and other allowances- EXEMPT	",
      case1: "8,000",
      case2: "74,000",
      case3: "2,43,000",
      case4: "1,00,000",
      case5: "0",
    },
    {
      id: 3,
      particulars: "Net taxable salary",
      case1: "2,82,000",
      case2: "1,97,000",
      case3: "6,49,000",
      case4: "3,98,000",
      case5: "1,90,000",
    },
    {
      id: 4,
      particulars: "Other Income",
      case1: "31,000",
      case2: "-",
      case3: "-",
      case4: "-",
      case5: "-",
    },
    {
      id: 5,
      particulars: "Capital Gains",
      case1: "-",
      case2: "-",
      case3: "1,30,000",
      case4: "-",
      case5: "-",
    },
    {
      id: 6,
      particulars: "Gross Total Income",
      case1: "3,13,000",
      case2: "1,97,000",
      case3: "7,79,000",
      case4: "3,98,000",
      case5: "1,90,000",
    },
    {
      id: 7,
      particulars: "Deductions (80C,80D etc.)",
      case1: "45,000",
      case2: "-",
      case3: "1,90,000",
      case4: "1,50,000",
      case5: "-",
    },
    {
      id: 8,
      particulars: "Total Income Taxable",
      case1: "Total Income Taxable",
      case2: "1,97,000",
      case3: "5,89,000",
      case4: "2,48,000",
      case5: "1,90,000",
    },
    {
      id: 9,
      particulars: "ITR filing required",
      case1: "Yes",
      case2: "No",
      case3: "Yes",
      case4: "Yes",
      case5: "No",
    },
  ];

  const itrBenefitCards = [
    {
      id: 1,
      heading: "Accidental Claims",
      content:
        "When you met with an accident and want an accidental claim from the insurance companies, if you have your income tax returns with you then the court applies a simple formula to arrive at the claim amount",
      color: "sky",
    },
    {
      id: 2,
      heading: "Proof of Income/Proof of Net worth",
      content:
        "What’s the best document for your Income Proof? No brownies for guessing this one. Yes, we all know it’s your income tax return.",
      color: "amber",
    },
    {
      id: 3,
      heading: "Refund",
      content:
        "You can claim a refund of the amount that you have paid in excess or deducted excessively in the form of TDS. There is no bigger joy than getting back your money from the Income Tax Department.",
      color: "cyan",
    },
    {
      id: 4,
      heading: "Eligibility in Loan Application",
      content:
        "Income Tax Returns of the last three years is one of the basic documents required for loans. This helps banks in judging your payback capacity.",
      color: "emerald",
    },
    {
      id: 5,
      heading: "Carry forward capital losses",
      content:
        "If in a financial year you have incurred capital losses, then you can carry forward it and settle in future",
      color: "cyan",
    },
    {
      id: 6,
      heading: "Obtaining Visa",
      content:
        "For traveling abroad, foreign consulates of many countries ask you to furnish the last 3 years’ income tax returns or the current year’s income tax return. The absence of any return can reduce your chances of getting a visa, especially under the visitor, investor, and work permit categories.",
      color: "emerald",
    },
    {
      id: 7,
      heading: "Carry forward of losses",
      content:
        "Want to claim last year’s business loss? File your income tax return, and you’re all set for it.",
      color: "sky",
    },
    {
      id: 8,
      heading: "For Startup Funding",
      content:
        "Looking to raise funds from VCs, or angels? You need to have income tax returns filed to date ready. Many investors study your business scalability, profitability, and other cost parameters from your business income tax return.",
      color: "amber",
    },
    {
      id: 9,
      heading: "Protection against Black Money",
      content:
        "If you diligently file your income tax return every year, then your savings will never be at risk of being termed as black money by the Income Tax officials, as any income not reported to the Income Tax Department comes under the radar of black money.  ",
      color: "cyan",
    },
    {
      id: 10,
      heading: "Buying an insurance Policy with high life cover",
      content:
        "Some insurance companies are adamant about income tax returns while providing high life cover to verify your annual income.",
      color: "emerald",
    },
    {
      id: 11,
      heading: "Obtaining Government Tender",
      content:
        "Sometimes, furnishing your income tax return is a must to apply for government tenders especially when tenders of high value are being awarded.",
      color: "cyan",
    },
    {
      id: 12,
      heading: "Avoid penalty",
      content:
        "Non-filing of income tax returns can land you in trouble with the Income Tax Department. You carry the risk of not only paying a penalty but interest on it as well",
      color: "emerald",
    },
    {
      id: 13,
      heading: "Credit Card Application",
      content:
        "Want to flaunt a high limited Credit Card? Well, you better have your income tax return with you to get the hang of it.",
      color: "sky",
    },
    {
      id: 14,
      heading: "Growth of the nation",
      content:
        "Be a part of the nation-building process by filing an income tax return.",
      color: "sky",
    },
  ];

  const itrTable = [
    {
      id: 1,
      itrForm: "ITR 1",
      type: "Individuals",
      ResidentialStatus: "-Resident -Ordinary Resident",
      Whocanfile: [
        {
          id: 1,
          item: "Salary or Pension",
        },
        {
          id: 2,
          item: "One House property",
        },
        {
          id: 3,
          item: "Other Sources",
        },
      ],
      whocannotFile: [
        {
          id: 1,
          item: "More than 1 house property",
        },
        {
          id: 2,
          item: "Income from winnings, lottery, race horse etc.",
        },
        {
          id: 3,
          item: "Income from business, profession, capital gains from the sale of shares, land etc.",
        },
        {
          id: 4,
          item: "Agriculture income of > Rs. 5000",
        },
        {
          id: 5,
          item: "Loss under any head except one house property upto Rs. 2 Lakh.",
        },
        {
          id: 6,
          item: "Have assets located outside india or having any income from outside india (Income from foreign)",
        },
        {
          id: 7,
          item: "Total income exceeds Rs.50 Lacs in the financial year, Individual who is either Director in a company or has invested in Unlisted Equity Shares",
        },
      ],
    },
    {
      id: 2,
      itrForm: "ITR 2",
      type: "Individuals and HUFs	",
      ResidentialStatus:
        "-Resident -Ordinary Resident -Non-resident (NRI_ -Non-Ordinary Resident	",
      Whocanfile: [
        {
          id: 1,
          item: "Salary",
        },
        {
          id: 2,
          item: "More than One House",
        },
        {
          id: 3,
          item: "property",
        },
        {
          id: 4,
          item: "Other Sources",
        },
        {
          id: 5,
          item: "Capital Gain, Company’s director or an individual holding investment in unlisted equity shares of a company",
        },
      ],
      whocannotFile: [
        {
          id: 1,
          item: "Income from business or profession",
        },
      ],
    },
    {
      id: 3,
      itrForm: "ITR-3	",
      type: "Individuals and HUFs	",
      ResidentialStatus:
        "-Resident -Ordinary Resident -Non-resident (NRI_ -Non-Ordinary Resident",
      Whocanfile: [
        {
          id: 1,
          item: "Salary",
        },
        {
          id: 2,
          item: "More than One House",
        },
        {
          id: 3,
          item: "Other Sources",
        },
        {
          id: 4,
          item: "Capital Gain",
        },
        {
          id: 5,
          item: "Business or profession,",
        },
        {
          id: 6,
          item: "Director of the company",
        },
        {
          id: 7,
          item: "Partner of the firm",
        },
      ],
      whocannotFile: [
        {
          id: 1,
          item: "Any other body corporate",
        },
      ],
    },
    {
      id: 4,
      itrForm: "ITR-4",
      type: "Individuals, HUFs and Firms (other than LLP)	",
      ResidentialStatus: "-Resident -Ordinary Resident",
      Whocanfile: [
        {
          id: 1,
          item: "Income From -Salary",
        },
        {
          id: 2,
          item: "House property,",
        },
        {
          id: 3,
          item: "Other Sources",
        },
        {
          id: 4,
          item: "Presumptive Income",
        },
      ],
      whocannotFile: [
        {
          id: 1,
          item: "More than 1 house property",
        },
        {
          id: 2,
          item: "Income from winnings, lottery, racehorse etc.",
        },
        {
          id: 3,
          item: "Income from capital gains",
        },
        {
          id: 4,
          item: "Agriculture income of > Rs. 5000",
        },
        {
          id: 5,
          item: "Loss under any head except one house property up to Rs. 2 Lakh.",
        },
        {
          id: 6,
          item: "Have assets located outside India or having any income from outside India (Income from foreign)",
        },
        {
          id: 7,
          item: "Individual who is either Director in a company or has invested in Unlisted Equity Shares",
        },
      ],
    },
    {
      id: 5,
      itrForm: "ITR-5",
      type: "Firms (including LLPs) AOP, BOI, Local Authority, Artificial Jurisdiction Person",
      ResidentialStatus: "-",
      Whocanfile: [
        {
          id: 1,
          item: "Income From House property,",
        },
        {
          id: 2,
          item: "Other Sources,",
        },
        {
          id: 3,
          item: "Capital Gains,",
        },
        {
          id: 4,
          item: "Lottery Income,",
        },
        {
          id: 5,
          item: "Agriculture Income more than INR 5000,",
        },
        {
          id: 6,
          item: "Having Foreign Assets",
        },
        {
          id: 7,
          item: "Carry forward losses,",
        },
        {
          id: 8,
          item: "Business Income",
        },
      ],
      whocannotFile: [
        {
          id: 1,
          item: "Individuals",
        },
        {
          id: 2,
          item: "HUFs",
        },
        {
          id: 3,
          item: "Companies",
        },
        {
          id: 4,
          item: "Trusts",
        },
      ],
    },
    {
      id: 6,
      itrForm: "ITR-6",
      type: "Companies",
      ResidentialStatus: "",
      Whocanfile: [
        {
          id: 1,
          item: "Income From House property,",
        },
        {
          id: 2,
          item: "Other Sources,",
        },
        {
          id: 3,
          item: "Capital Gains,",
        },
        {
          id: 4,
          item: "Lottery Income,",
        },
        {
          id: 5,
          item: "Agriculture Income more than INR 5000,",
        },
        {
          id: 6,
          item: "Having Foreign Assets",
        },
        {
          id: 7,
          item: "Carry forward losses,",
        },
        {
          id: 8,
          item: "Business Income",
        },
      ],
      whocannotFile: [
        {
          id: 1,
          item: "Companies claiming exemption u/s 11",
        },
      ],
    },
    {
      id: 7,
      itrForm: "ITR-7",
      type: [
        {
          id: 1,
          item: "Charitable Trusts,",
        },
        {
          id: 2,
          item: "Political Parties,",
        },
        {
          id: 3,
          item: "Research Institution,",
        },
        {
          id: 4,
          item: "News Agency,",
        },
        {
          id: 5,
          item: "Institution u/s 10(23A) and 10(23B),",
        },
        {
          id: 6,
          item: "Every University and institution,",
        },
        {
          id: 7,
          item: "Business Trust,",
        },
        {
          id: 8,
          item: "Investment Fund.",
        },
      ],
      ResidentialStatus: "",
      Whocanfile: [
        {
          id: 1,
          item: "Income From House property,",
        },
        {
          id: 2,
          item: "Other Sources,",
        },
        {
          id: 3,
          item: "Capital Gains,",
        },
        {
          id: 4,
          item: "Lottery Income,",
        },
        {
          id: 5,
          item: "Agriculture Income more than INR 5000,",
        },
        {
          id: 6,
          item: "Having Foreign Assets",
        },
        {
          id: 7,
          item: "Carry forward losses,",
        },
        {
          id: 8,
          item: "Business Income",
        },
      ],
      whocannotFile: [
        {
          id: 1,
          item: "Individuals",
        },
        {
          id: 2,
          item: "HUFs",
        },
        {
          id: 3,
          item: "Companies",
        },
        {
          id: 4,
          item: "Firms(including LLPs)",
        },
        {
          id: 5,
          item: "AOP, BOI",
        },
      ],
    },
  ];

  const content = [
    {
      id: 1,
      content: "Latest Updates-Income Tax Return",
      path: "#latest-update-itr",
    },
    {
      id: 2,
      content: "What is an Income Tax Return (ITR)?",
      path: "#whatIsITR",
    },
    {
      id: 3,
      content: "What are the Benefits of Filing an Income Tax Return?",
      path: "#benefits-itr",
    },
    {
      id: 4,
      content: "What is the Due Date to File an Income Tax Return?",
      path: "#due-date-itr",
    },
    {
      id: 5,
      content:
        "Who Should File an Income Tax Return?",
      path: "#who-shoud-itr",
    },
    {
      id: 6,
      content:
        "What Documents are Required to File ITR?",
      path: "#document-require-itr",
    },
    {
      id: 7,
      content: "How to File ITR for FY 2023-24 (AY 2024-25)?",
      path: "#how-to-file-itr",
    },
    {
      id: 8,
      content:
        "How to File ITR with Tax2clear?",
      path: "#file-with-Tax2clear",
    },
    {
      id: 9,
      content:
        "What Will be the Next Steps After e-filing of Income Tax Returns?",
      path: "#next-step-after-e-fiilng",
    },
    {
      id: 10,
      content:
        "How to e-file ITR with Form 16?",
      path: "#efile-itr-form16",
    },
    {
      id: 11,
      content:
        "What will Happen when I have Taxable Income, but do not File my Income Tax Return?",
      path: "#What-will-happen",
    },
    {
      id: 12,
      content: "5 Important changes to keep in mind while filing ITR for FY 2022-23 (AY 2023-24)",
      path: "#important-things-keep-min",
    },
    {
      id: 19,
      content: "Frequently Asked Questions",
      path: "#sectionAct",
    },
  ];
  return (
    <div className="main-containerr">
      <div className="itrContainer mt-5">
        <h1 className="text-4xl font-bold">
          What is Income Tax Return (ITR)? : How to File ITR Online for FY {year - 1}-{year - 2000} (AY {year}-{year - 1999})
        </h1>
        <br />
        <p>
          Filing income tax is always considered a complex process. However, it is not as tedious as it sounds. The Income Tax Department is rigorously working to make the filing process smooth and easy.
        </p>
        <br />
        <p>
          The Income Tax Department has also embraced digital technology, and one of the major conveniences that have come through it is electronic filing (ITR e-filing).
        </p>
        <br />
        <p>
          From introducing the provision u/s 234F for non-filers of income tax returns to making most of the processes accessible online the department's objective is very clear that it wants your files to be clean and compliant with the law.
        </p>
        <br />
        <p>
          While filing an ITR, a lot of questions cross the mind, like How to file ITR? Which ITR form is the correct one to choose? What documents are required to file ITR? Can I file ITR for FY 2023-24 (AY 2024-25)? And many.
        </p>
        <br />
        <p>
          The Income Tax Department has also embraced digital technology, and one of the major conveniences that have come through it is electronic filing (ITR e-filing).
        </p>
        <br />
        <p>
          This article will share the details and latest updates you must know while filing your income tax return (ITR) for FY {year - 1}-{year - 2000} (AY {year}-{year - 1999}).
        </p>
        <br />
        <div className="contentBox">
          <h3 className="text-2xl font-semibold">Contents</h3>
          <ul>
            {content.map((item) => {
              return (
                <HashLink to={item.path} smooth key={item.id}>
                  <li className="font-bold"># {item.content}</li>
                </HashLink>
              );
            })}
          </ul>
        </div>

        <br />
        <div className="latest-update-income-tax border-b-2 pb-4" id="latest-update-itr">

          <h1 className="text-4xl">Latest Updates on Income Tax Return</h1>
          <p className="font-300 mt-3">Budget 2023 Updates</p>
          <p>The new tax regime will be the default tax regime as per Budget 2023</p>
          <p>The basic exemption limit has been raised to Rs 3 lakh from Rs 2.5 lakh to make the new tax regime more attractive. Also, the highest tax rate of 30% will be levied above Rs 15 lakh income.</p>
          <p>In the budget 2023-24 announcement, the rebate under section 87A has been hiked to Rs 7 lakh from Rs 5 lakh under the new tax regime. The rebate benefit will be up to Rs 25000, provided income doesn't exceed the limit of 7 lakhs.</p>
          <p>The proposal to introduce the standard deduction in the new tax regime has been shared. As per this salaried class and the pensioners will benefit from a standard deduction of Rs. 50,000/-</p>
          <p>Also, the exemption of a family pension of Rs. 15,000 has been introduced under the new tax regime.</p>
          <p>Reduction in the surcharge on annual income above 5 crores from 37% to 25% under the new regime. Currently, the highest tax rate is 42.74%, which would slash the maximum tax rate to 39% after this reduction.</p>
          <p>The limit of Rs. 3 lakh for tax exemption on leave encashment on non-government salaried employees has been raised to Rs. 25 lakh.</p>
          <p>Furthermore, the new tax regime has reduced the income tax slabs from 7 to 6. The revised tax structure as per the new tax regime is:-</p>
          <div className="mt-4">
            <img src="Tax2clear-income-tax-return.png"></img>
          </div>
          <p className="pt-4">You can calculate your estimated income tax after the amendments made in Union Budget 2023-24</p>
        </div>
        <div id="whatIsITR">
          <h1 className="text-4xl mt-5">What is Income Tax Return (ITR)?</h1>
          <br />
          <p className="mt-3">
            Income tax is a type of tax that is levied by governments on the income earned by individuals, corporations, and other entities. The tax is usually calculated as a percentage of the income earned, and the amount paid is used to fund various government programs and services.
          </p>
          <p className="mt-3">
            eFiling of Income Tax returns refers to the electronic filing of tax returns through online platforms provided by tax authorities. It is an alternative to the traditional method of filing paper-based tax returns. This process is easy; quickly, in the comfort of your home or office, you can file an income tax return.
          </p>
          <p className="mt-3">
            Individuals are required to pay income tax on their salary or wages, as well as on other forms of income such as interest, dividends, rental income, business profits, and capital gains. Corporations are also required to pay income tax on their profits.
          </p>
        </div>
        <br />
        <div id="benefits-itr">
          <h1 className="text-4xl">What are the Benefits of Filing an Income Tax Return?</h1>
          <p className="mt-3 mb-3">In India, there is a general practice that if you are a citizen with a gross income that exceeds the basic tax exemption limit, i.e., 2,50,000, subject to certain conditions, you must file an Income Tax Return (ITR). If your income doesn’t exceed Rs 2,50,000 during the year, you’re not legally bound to file your return. But it’s still recommended that you file your return because of the benefits that come with filing an income tax return:</p>
        </div>
        <div className="itrBenefitCards grid md:m-auto m-auto lg:m-auto justify-items-center w-3/4 md:x-auto lg-m-auto sm:m-auto  gap-5 grid-cols-3 xs:m-auto mt-5 xs:grid-cols-1 mb-10">
          {itrBenefitCards.map((item) => {
            return (
              <div
                key={item.id}
                className="itrBenefitCard max-w-[350px] h-[350]  xs:h-[auto] xs:w-[100%] w-auto shadow-md flex flex-col items-center justify-between pt-7 px-7 gap-2 rounded-lg"
              >
                <div className="cardCont">
                  <p className="text-center font-bold text-lg tex-400 text-[#0F52BA]">
                    {item.heading}
                  </p>
                  <p className="text-center text-sm pb-7">{item.content}</p>
                </div>
                <div className="border w-3/4 h-[5px] bg-amber-400 border-amber-400 border-solid"></div>
              </div>
            );
          })}
        </div>
        <div className="benefit-itr-img w-[50%] m-auto">
          <h1 className="text-[40px] text-center">Benefits Of ITR</h1>
          <img src="final-itr-efilling-Tax2clear.jpg" className="w-[100%]"></img>
        </div>
        <hr />

        <div className="mt-5 pb-5" id="due-date-itr">
          <h1 className="text-4xl">
            What is the Due Date to File an Income Tax Return?
          </h1>
          <br />
          <p>
            The ITR filing due date for FY {year - 1}-{year - 2000} (AY {year}-{year - 1999}) is 31st July {year}. ITR filing on the due date is essential to comply with tax laws, avoid penalties, claim timely refunds, maintain accurate financial records, and facilitate financial transactions. It is advisable to prioritize timely filing to stay in line with regulatory requirements and avoid unnecessary complications.
          </p>
          <p className="mt-3">Although for the people who fall within the limits of a tax audit, the due date is 31st October, and in the case where transfer pricing is applicable, the due date is 30th November after the end of the financial year.</p>
          <p className="mt-3">Note: Persons who fall within the limits of Tax Audit are:</p>
          <ul className="mt-3">
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Persons carrying on business and sales/turnover exceeding Rs.1 Cr.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                A person carrying on professional and Gross Receipts exceeding Rs 50 Lakhs.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p> You will have authenticated
                A person carrying on business or profession under the provisions of section 44AD and sales/turnover exceeds Rs 2 Cr. or declaring income lower than the deemed income under the applicable section.
              </p>
            </li>
          </ul>
          <p>As per the Finance Act 2020: The threshold limit of Rs 1 crore for a tax audit is proposed to be increased to Rs 5 crore with effect from AY 2023-24 (AY 2024-25) if the taxpayer’s cash receipts and cash payments are limited to 5% of the gross receipts or gross payments. The same limit has been increased from Rs. 5 crores to Rs. 10 crores from AY 2022-23 (FY 2021-22)</p>
          <p className="mt-3">With effect from 1st April 2021, the threshold limit of Rs 5 crores is increased to Rs 10 crores in case cash transactions do not exceed 5% of the total transactions.</p>
          <br />
        </div>
        <hr></hr>

        <div className="pt-5 pb-5" id="who-shoud-itr">
          <h1 className="text-4xl">
            Who Should File an Income Tax Return?
          </h1>
          <br />
          <p>
            Every Indian, including an NRI, is required to file an Income tax return where Total Income exceeds the basic exemption limit of Rs. 2,50,000 for an individual, Rs. 3,00,000 in case of a senior citizen and Rs. 5,00,000 in case of super senior citizens.
          </p>
          <p>You are required to file a return irrespective of the fact whether you have paid tax or not. So, even if the employer has deducted TDS in full you are still required to file an income tax return.</p>
          <p>However, there are certain conditions, other than income above the Basic Exemption Limit, due to which a person would be mandatorily required to file an ITR! Such conditions are :</p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                If Business Turnover exceeds Rs.60 lakh
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                If a person carries on a Profession and Professional Receipts exceed Rs.10 lakh
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p> You will have authenticated
                If the Total TDS and TCS amount exceeds Rs.25000
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                If Total deposits in one or more Savings accounts exceed Rs.50 lakh
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                If Total deposits in one or more Current Account exceeds Rs.1 crore
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                If Foreign Travel expenses in a year exceed Rs.2 Lakh
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                If Total Electricity expenses in a year exceed Rs.1 lakh
              </p>
            </li>
          </ul>
          <p className="mt-3">If an individual has Income from foreign assets or is a beneficiary of an asset located in a foreign country.</p>
        </div>
        <hr></hr>
        <div className="pt-5 pb-5" id="document-require-itr">
          <h1 className="text-4xl">
            What Documents are Required to File ITR?
          </h1>
          <br />
          <p><b>Although documents differ with the income source, still there are a few documents that are common while filing an ITR. Also, note</b> that you do not need to submit/upload any of the documents on the website; these are advised to be kept with you so that required information can be filled in correctly, your time is saved, and common errors can be avoided)</p>
          <br />
          <div className="document-list-for-itr-form pb-5" >
            <div className="flex shadow-lg p-8 mt-5 border-r-4 border-[#0F52BA] rounded-lg">
              <div className="pan-img pr-8">
                <img src="pan-icon.webp"></img>
              </div>
              <div>
                <b>PAN Card-</b>
                <p>PAN is a mandatory requirement while filing the Income Tax Return. Your name & DOB on ITR should be the same as per data from the Income Tax Department.</p>
              </div>
            </div>
            <div className="flex shadow-lg p-8 mt-5 border-r-4 border-[#0F52BA] rounded-lg">
              <div className="pan-img pr-8">
                <img src="aadhaar-card.webp"></img>
              </div>
              <div>
                <b>Aadhaar Card-</b>
                <p>The government has also made it compulsory for all taxpayers to use Aadhaar while filing ITR.Form 16 - For income under the head salaries</p><br></br>
                <p>Form 26AS<br></br>
                  AIS(Annual Information statement) /TIS (Tax Information Summary)<br></br>
                  Capital Gain/Loss Statement - If there is an income/loss under the head Capital Gains<br></br>
                  Home loan interest Certificate (if applicable)</p>
              </div>
            </div>
            <div className="flex shadow-lg p-8 mt-5 border-r-4 border-[#0F52BA] rounded-lg">
              <div className=" pr-8">
                <img src="bank-icon.webp"></img>
              </div>
              <div>
                <b>Bank Account Statements-</b>
                <p>Just to find Income, Interest on Saving bank account, etc. So that you do not miss reporting of any income in ITR.</p>
              </div>
            </div>
            <div className="flex shadow-lg p-8 mt-5 border-r-4 border-[#0F52BA] rounded-lg">
              <div className=" pr-8">
                <img src="bank-icon.webp"></img>
              </div>
              <div>
                <b>Bank Account Details-</b>
                <p>Your details of all the bank accounts i.e., Bank account number, code, and Bank Name is, needed to be mentioned while filing ITR.</p>
              </div>
            </div>
            <div className="flex shadow-lg p-8 mt-5 border-r-4 border-[#0F52BA] rounded-lg">
              <div className=" pr-8">
                <img src="challan-icon.webp"></img>
              </div>
              <div>
                <b>Challan Details-(If any tax paid)</b>
                <p>It includes details such as the challan no., BSR code, date, amount of payment of Advance Tax/ Self Assessment Tax etc.</p>
              </div>
            </div>
            <div className="flex shadow-lg p-8 mt-5 border-r-4 border-[#0F52BA] rounded-lg">
              <div className=" pr-8">
                <img src="notice-icon.webp"></img>
              </div>
              <div>
                <b>Original Return/Notice-</b>
                <p>If an individual is filing a revised return or a return is filed in response to a notice received, then he/she is required to fill in the details of the original return and details of the notice.</p>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div id="how-to-file-itr">
          <h1 className="text-4xl">
            How to File ITR for FY 2023-24 (AY 2024-25)?
          </h1>
          <br />
          <p>
            The income tax department provides the facility for e-filing an income tax return. But before filing the ITR, a few points need to be considered:-
          </p>
          <ul>
            <li className="pt-[10px]">1.  Gather necessary documents: Collect all the relevant documents required to file your ITR, such as Form 16 (TDS certificate), bank statements, investment proofs, and any other supporting documents related to your income, deductions, and exemptions.</li>
            <li>2.  Choose the appropriate ITR form: Determine the applicable ITR form based on your income sources, residential status, and other factors. In India, different ITR forms (ITR-1 to ITR-7) are available for different categories of taxpayers. Ensure you select the correct form that aligns with your specific situation.</li>
            <li>3.  Calculate your taxable income: Calculate your total income for the financial year by considering all sources of income, including salary, business income, rental income, capital gains, and other income. Deduct eligible deductions and exemptions to arrive at your taxable income.</li>
            <li>4.  Fill out the ITR form: Fill out the selected ITR form accurately, providing the required details such as personal information, income details, deductions, tax payments made, and other relevant information. Take care to fill in the correct details to avoid any discrepancies.</li>
          </ul>
          <div>
            <div>
              <p><b>Step 1:</b> Log in to the e-filing portal using your user ID and password.</p>
              <img src="flling-itr-step1.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 2:</b> On your Dashboard, click e-File -- Income Tax Returns -- File Income Tax Return.</p>
              <img src="flling-ite-step-2.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 3:</b> Select Assessment Year as 2023–24 and Mode of filling as online, then click continue.</p>
              <img src="income-tax-return-3.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 4:</b> In case you have already filled the Income Tax Return and it is pending for submission, click Resume Filing. In case you wish to discard the saved return and start preparing the return afresh, click Start New Filing.</p>
              <img src="income-tax-return-4.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 5:</b> Select Status as applicable to you and click Continue to proceed further.</p>
              <img src="income-tax-return-5.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 6:</b> You have two options to select the type of Income Tax Return:</p>
              <ul className="mt-3">
                <li>
                  <BsCheck2Circle color={"#0F52BA"} /><p>
                    If you know which ITR to file, Select the ITR form; else
                  </p>
                </li>
                <li>
                  <BsCheck2Circle color={"#0F52BA"} /><p>
                    If you are not sure which ITR to file, you may select Help me decide which ITR Form to file and click Proceed. Here, the system helps you determine the correct ITR, then you can proceed with filing your ITR.
                  </p>
                </li>
              </ul>
              <img src="income-tax-return-6-sub-1.jpg" className="mt-3"></img>
              <img src="income-tax-return-6-sub-2.jpg" className="mt-3"></img>
              <br></br>
              <b>Note:</b>
              <ul className="mt-3">
                <li>
                  <BsCheck2Circle color={"#0F52BA"} /><p>
                    In case you are not aware which ITR or schedules are applicable to you or income and deductions details, your answers in response to a set of questions will guide in determining the same and help you in correct / error-free filing of ITR.
                  </p>
                </li>
                <li>
                  <BsCheck2Circle color={"#0F52BA"} /><p>
                    In case you are aware of the ITR or schedules applicable to you or income and deduction details, you can skip these questions.
                  </p>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <p><b>Step 7:</b> Once you have selected the ITR applicable to you, note the list of documents needed and click Let’s Get Started.</p>
              <img src="income-tax-return-7.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 8:</b> Select the checkbox applicable to you regarding the reason for filing ITR and click Continue.</p>
              <img src="income-tax-return-8.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 9:</b>  In case you wish to opt for New Tax Regime, select Yes in the Personal Information Section. Please note the pop-up information that certain deductions and exemptions are not available in the new tax regime. Review your pre-filled data and edit it if necessary. Enter the remaining/additional data (if required). Click Confirm at the end of each section</p>
              <img src="income-tax-return-9.jpg" className="mt-3"></img>
              <img src="income-tax-return-9-sec.jpg" className="mt-3"></img>
              <img src="income-tax-return-9-third.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 10:</b>  Enter/ Edit your income and total deductions details in the different sections. After completing and confirming all the sections of the form, click Proceed.</p>
              <img src="income-tax-return-10.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 10a:</b>  In case there is a tax liability</p>
              <p className="mt-3">After clicking on total tax liability, you will be shown a summary of your tax computation based on the details provided by you. If there is tax liability payable based on the computation, you will get the Pay Now and Pay Later options at the bottom of the page.</p>
              <img src="income-tax-return-10a.jpg" className="mt-3"></img>
              <b>Note:</b>
              <ul className="mt-3">
                <li>
                  <BsCheck2Circle color={"#0F52BA"} /><p>
                    It is recommended to use the Pay Now option.
                  </p>
                </li>
                <li>
                  <BsCheck2Circle color={"#0F52BA"} /><p>
                    If you opt to Pay Later, you can make the payment after filing your Income Tax Return, but there is a risk of being considered as taxpayer in default, and liability to pay interest on tax payable may arise.
                  </p>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <p><b>Step 10b:</b>   In case there is no tax liability (No Demand / No Refund) or if you are eligible for a Refund</p>
              <p className="mt-3">Click Preview Return. If there is no tax liability payable, or if there is a refund based on tax computation, you will be taken to the Preview and Submit Your Return page.</p>
              <img src="income-tax-return-10b.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 11:</b>    If you click on “Pay Now” you will be redirected to e-pay Tax service. Click Continue</p>
              <img src="income-tax-return-11.jpg" className="mt-3"></img>
              <b className="mt-3">Note:</b>
              <p className="mt-3">You will be taken to e-Pay Tax page on the portal for making tax payment after you click Continue. Refer to e-Pay Tax user manuals to learn more.</p>
            </div>
            <div className="mt-3">
              <p><b>Step 12:</b>   After successful payment through e-Filing portal, a success message is displayed. Click Back to Return Filing to complete filing of ITR.</p>
              <p className="mt-3">Click Preview Return. If there is no tax liability payable, or if there is a refund based on tax computation, you will be taken to the Preview and Submit Your Return page.</p>
              <img src="income-tax-return-10b.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 13:</b>   Click Preview Return.</p>
              <img src="income-tax-return-13-2.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 14:</b>    On the Preview and Submit Your Return page, select the declaration checkbox and click Proceed to Preview.</p>
              <img src="income-tax-return-13.jpg" className="mt-3"></img>
              <p className="mt-3"><b>Note:</b> If you have not involved a tax return preparer or TRP in preparing your return, you can leave the textboxes related to TRP blank.</p>
            </div>
            <div className="mt-3">
              <p><b>Step 15:</b>    Preview your return and click Proceed to Validation.</p>
              <img src="income-tax-return-15.jpg" className="mt-3"></img>
            </div>
            <div className="mt-3">
              <p><b>Step 16:</b>    Once validated, on your Preview and Submit your Return page, click Proceed to Verification.</p>
              <img src="income-tax-return-16.jpg" className="mt-3"></img>
              <p className="mt-3"><b>Note:</b> If you are shown a list of errors in your return, you need to go back to the form to correct the errors. If there are no errors, you can proceed to e-Verify your return by clicking Proceed to Verification.</p>
            </div>
            <div className="mt-3">
              <p><b>Step 17:</b>     On the Complete your Verification page, select your preferred option and click Continue</p>
              <p className="mt-5">It is mandatory to verify your return, and e-Verification (recommended option – e-Verify Now) is the easiest way to verify your ITR – it is quick, paperless, and safer than sending a signed physical ITR-V to CPC by speed post.</p>
              <img src="income-tax-return-17.jpg" className="mt-3"></img>
              <p className="mt-3"><b>Note:</b> In case you select e-Verify Later, you can submit your return, however, you will be required to verify your return within 30 days of filing of your ITR.</p>
            </div>
            <div className="mt-3">
              <p><b>Step 18:</b>     On the e-Verify page, select the option through which you want to e-Verify the return and click Continue.</p>
              <p className="mt-5">Once you e-Verify your return, a success message is displayed along with the Transaction ID and Acknowledgment Number. You will also receive a confirmation message on your mobile number and email ID registered on the e-Filing portal.</p>
              <img src="income-tax-return-19.jpg" className="mt-3"></img>
            </div>
          </div>
          <br />
          {/* <p>
          <b>Few of the benefits of filing an Income Tax return are:</b>
        </p>
        <br />
        <ul>
          <li>
            <BsCheck2Circle color={"#0F52BA"} /><p>
              You are helping the nation as the tax you pay is used to build
              infrastructure, and improve facilities like medical, education,
              defense, etc.
            </p>
          </li>
          <li>
            <BsCheck2Circle color={"#0F52BA"} /><p>
              As income proof, while applying for a loan, planning to go abroad,
              easy VISA processing, loss compensation, etc.
            </p>
          </li>
          <li>
            <BsCheck2Circle color={"#0F52BA"} /><p> You will have authenticated
              evidence of your earnings.
            </p>
          </li>
          <li>
            <BsCheck2Circle color={"#0F52BA"} /><p>
              While you are planning to start a new business and seeking funding.
              Specifics of ITR can be asked at this time to evaluate the
              business's financial stability and profitability.
            </p>
          </li>
        </ul> */}
        </div>
        {/* <div id="whyITR">
        <h1 className="text-4xl">
          Why Filing a tax return in India is important?
        </h1>
        <br />
        <p>
          It is mandatory to file income tax returns and all the responsible
          citizens of the country should file it. For many, filing income tax
          returns is a burdensome process but the benefits is far ahead of it.
        </p>
        <br />
        <p>
          <b>Few of the benefits of filing an Income Tax return are:</b>
        </p>
        <br />
        <ul>
          <li>
            <BsCheck2Circle color={"#0F52BA"} /><p>
              You are helping the nation as the tax you pay is used to build
              infrastructure, and improve facilities like medical, education,
              defense, etc.
            </p>
          </li>
          <li>
            <BsCheck2Circle color={"#0F52BA"} /><p>
              As income proof, while applying for a loan, planning to go abroad,
              easy VISA processing, loss compensation, etc.
            </p>
          </li>
          <li>
            <BsCheck2Circle color={"#0F52BA"} /><p> You will have authenticated
              evidence of your earnings.
            </p>
          </li>
          <li>
            <BsCheck2Circle color={"#0F52BA"} /><p>
              While you are planning to start a new business and seeking funding.
              Specifics of ITR can be asked at this time to evaluate the
              business's financial stability and profitability.
            </p>
          </li>
        </ul>
      </div> */}

        <br />
        <hr />
        <br />

        <div id="file-with-Tax2clear">
          <h1 className="text-4xl">How to File ITR with Tax2clear?</h1>
          <br />
          <p>
            You can file the Income Tax return with Tax2clear easily and smoothly. Tax2clear simplifies the process of filing Income Tax Returns (ITR) and offers comprehensive tax-related services. With a user-friendly interface and step-by-step guidance, it enables individuals to easily file their ITR online
          </p>
          <br />
          <div className="grid grid-cols-5 md:grid-cols-3 lg:grid-cols-3  gap-3 xs:block sm:block ">
            <div className="card bg-yellow-100 min-w-[250px] max-w-[350px] xs:mt-3 sm:mt-3 w-auto sm:m-auto p-10 rounded flex justify-start flex-col gap-3">
              <h3 className="font-bold text-xl text-yellow-600">Step 1:</h3>
              <p>
                Visit the Tax2clear.co website. Here, click on the option “File
                ITR Now”.
              </p>
            </div>
            <div className="card bg-blue-100 min-w-[250px] max-w-[350px] xs:mt-3 sm:mt-3 sm:m-auto w-auto p-10 rounded flex justify-start flex-col gap-3">
              <h3 className="font-bold text-xl text-blue-600">Step 2:</h3>
              <p>Select your source of Income and click on continue.</p>
            </div>
            <div className="card bg-cyan-100 min-w-[250px] max-w-[350px] xs:mt-3 sm:mt-3 sm:m-auto w-auto p-10 rounded flex justify-start flex-col gap-3">
              <h3 className="font-bold text-xl text-cyan-600">Step 3:</h3>
              <p>
                If you're a salaried individual, Just Upload your Form 16. If you do not have any form 16, you can skip the option and move further.
              </p>
            </div>
            <div className="card bg-sky-100 min-w-[250px] max-w-[350px] xs:mt-3 sm:mt-3 sm:m-auto w-auto p-10 rounded flex justify-start flex-col gap-3">
              <h3 className="font-bold text-xl text-sky-600">Step 4:</h3>
              <p>
                Enter the Financial Year, PAN & Aadhaar details, and other basic details like your Employment Details, income Details, Deductions(If Any), and bank details. Input your prepaid taxes after giving the details of all the information.
              </p>
            </div>
            <div className="card bg-green-100 min-w-[250px] max-w-[350px] xs:mt-3 sm:mt-3 sm:m-auto w-auto p-10 rounded flex justify-start flex-col gap-3">
              <h3 className="font-bold text-xl text-green-600">Step 5:</h3>
              <p>
                Review your tax computation and click on the “File My ITR” button. And it is done!!
              </p>
            </div>
          </div>
          <br />
        </div>

        <br />
        <hr />
        <br />

        <div id="next-step-after-e-fiilng">
          <h1 className="text-4xl">
            What Will be the Next Steps After e-filing of Income Tax Returns?
          </h1>
          <br />
          <p>
            Once you are done with the e-filing of your income tax return, you must:-
          </p>
          <br />
          <br />
          <ul>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Check your inbox for the email id as provided in your income tax return form, You will receive an intimation for the successful filing of your Income Tax Return, along with ITR-V (Acknowledgement) attached with eMail.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Cross-Check all figures thoroughly in your filled ITR form or ITR-V for any error, mistake, etc.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Verify the Income-tax return by sending a physical copy to CPC Bangalore or you can e-Verify online. Once the return has been successfully Verified, the return filing procedure ends here. And now your job is done.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                In case you have a refund in your tax return, you shall receive the same in your bank account within the next few days. It is better to keep tracking your income tax refund status so that you do not miss out on any important updates from the department. You can keep checking the refund status from here
              </p>
            </li>
          </ul>
          <br />
          <p>
            <b>Note:-</b> Verification of an ITR is an important step without which your return filing process is not completed. If you fail to verify your ITR within the time limit (30 days), it shall be considered “Invalidate,” i.e., null and void. It means that no Income Tax Return has been filed at all.
          </p>
          <br />
          <hr />

          <div className="efile-itr-form16">
            <h1 className="text-4xl mt-5">
              How to e-file ITR with Form 16?
            </h1>
            <p className="mt-3 mb-3">File your Income Tax Return with absolute ease with us in just 3 steps:</p>
            <ul>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  Upload Form 16: Visit our e-filing portal, select your sources of income & upload form 16.
                </p>
              </li>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  Review: Our advanced software automatically picks up the required information & fills in your details by itself. Simply review the information.
                </p>
              </li>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  File ITR: As soon as you give your confirmation, our system files your income tax return online.
                </p>
              </li>
            </ul>
            <br />
            <hr />
          </div>
          <div id="What-will-happen">

            <h1 className="text-4xl mt-5">
              What will Happen when I have Taxable Income, but do not File my Income Tax Return?
            </h1>
            <p className="mt-5">
              Having taxable income and not filing income tax return can put you in trouble with the Income Tax Authorities. Be prepared to welcome notices from the Income Tax Department along with fines and penalties. The consequences of non-filing of return are:
            </p>
            <br />
            <ul>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  Late filing Fee u/s 234F shall be levied if the return is not filed on or before the due date. The quantum of fees shall vary according to the amount of your income and return filing date. The maximum fee levied is Rs. 5000/-.
                </p>
              </li>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  Notice u/s 142(1), which requires you to file your return if you have not filed it before the due date. if you fail to comply with this notice, then it may result in <br />
                  (a) Best Judgment Assessment u/s 144 and/or <br />
                  (b) Penalty of Rs. 10,000 u/s 271(1)(b) and/or <br />
                  (c) Imprisonment with or without fine u/s 276D.<br />
                </p>
              </li>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  Notice u/s 148 for furnishing return within the period as mentioned in the notice itself.            </p>
              </li>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  As per Section 270A, if income is under-reported due to <br />
                  - Wrong disclosure/misreporting then the penalty shall be leviable @200% of tax payable on such income and<br />
                  - In any other case, the penalty shall be 50% of the tax payable on such income<br />
                </p>
              </li>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  A penalty of minimum 100% to a maximum of 300% of the amount of tax for concealment of income u/s 271(1)(c).</p>
              </li>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  Interest u/s 234A keeps on mounting at the rate of 1% per month/ part of the month till the time you file your return. Also, you'd be required to pay interest u/s 234B and 234C, both at the rate of 1% per month/ part of the month if you've not complied with the advance tax provisions at the time of late filing of return.    </p>
              </li>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  If you have losses (Business, Capital loss) that you want to be carried forward, you can not carry forward your loss if you do not file an Income Tax Return or file after the due date.            </p>
              </li>
            </ul>
            <br />
            <hr />
          </div>
          <div className="important-things-keep-min">
            <h1 className="text-4xl mt-5">
              5 Important Changes to Keep in Mind While Filing ITR for FY {year - 1}-{year - 2000} (AY {year}-{year - 1999})?
            </h1>
            <br />
            <b>Reporting of virtual digital asset while filing income tax return</b><br />
            <p>As per the latest update in 2023, the income generated via transfer of VDAs like crypto assets is subject to taxation at a rate of 30 percent (including surcharge and cess). It's important to note that deductions for expenses, except for the cost of acquisition (if applicable), are not allowed when calculating such income. Income from VDAs cannot be reported on ITR-1 or ITR-4; instead, it should be reported on form ITR-2 or ITR-3 under the head of business income or capital gains.</p><br />
            <b>Rules on opting out of new tax regime</b><br />
            <p><b>Starting 2024, the new regime has become the default regime. Therefore, you have to explicitly select the old regime if you want to change it. While salaried persons can switch their regimes every year, business professionals only have the option to switch regimes once in their lifetime. Salaried people can switch regimes while filing their ITR and business professionals are required to submit Form 10 IEA to opt out of the new regime by the last date to file ITR, but before filing the ITR.</b></p><br />
            <b>80G deduction Claim</b><br />
            <p>To claim the deduction under Section 80 G, it is important to provide the donation receipt and the donation certificate in Form 10BE. In the ITR form, taxpayers need to disclose their donations in the applicable 'Schedule 80G'. A new column has been added to 'Table D' in the current year's ITR form, requiring the disclosure of the ARN (Donation Reference Number) for donations made to entities where a 50% deduction is allowed, subject to the qualifying limit. The ARN should be obtained from the donation certificate issued in Form 10BE by the donee institutions and should be mentioned in the ITR.</p><br />
            <b>Intraday trading reporting</b><br />
            <p>Profit or loss from intraday trading is categorized as business income for taxation purposes. The current year's ITR form includes Part A–Trading Account, where individuals need to provide separate information regarding their intraday trading activities. The form requires details such as turnover from intraday trading and income from intraday trading transferred to the Profit and Loss account.</p><br />
            <b>Section 89A relief claim</b><br />
            <p>If an individual has claimed relief under Section 89 ( income from retirement benefit accounts held in specified countries), they need to provide the relevant information in Schedule Salary of the ITR form.</p><br />
            <p>The ITR filing for the FY {year - 1}-{year - 2000} has already started. File early and say no to last-minute hassle. Get CA-assisted ITR filing services to ensure accurate and seamless ITR filing.<br /><Link to={"/eca"} style={{ color: "#0F52BA", textDecoration: "underline" }}>Book an Online CA Now!</Link></p>
            <br />
          </div>
        </div>

        <br />
        <hr />
        <br />



        <div id="faqITR" className="p-10 xs:p-4 mb-10 bg-100 bg-[#C6DBFA]">
          <h1 className="text-4xl text-900 text-[#1E1E24]">Frequently Asked Questions</h1>
          {itrFAQ.map((item) => {
            return (
              <>
                <div key={item.id}>
                  <p className="text-900 text-[#1E1E24] font-bold text-xl pt-10  pb-5 flex items-center xs:items-start gap-2.5 xs:text-lg xs:leading-6">
                    <span className="text-base xs:mt-2 text-center flex items-center justify-center bg-900 h-6 w-6 bg-[#1E1E24] question p-2 xs:leading-[100%]  xs:text-sm xs:rounded-[100%] rounded-full  font-normal text-white">
                      Q
                    </span>
                    {item.question}
                  </p>
                  <p className="text-base xs:text-justify xs:text-sm">Ans: {item.answer}</p>
                  {item.list ? (
                    <ul className="pt-5 listQuestion">
                      {item.list.map((li) => {
                        return <li>&#x2713; {li}</li>;
                      })}
                    </ul>
                  ) : null}
                  {/* <div className='border-solid border border-sky-900 my-4 w-1/4'></div> */}
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className='fix-watsapp-icon'>
      <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default FillingITR;
