import axios from 'axios';
import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2'
import RefContext from '../Context/RefContext';
import { BsWhatsapp } from "react-icons/bs";
import ButtonLoader from './ButtonLoader';
function Assistedtax() {

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [loading, setLoading] = useState(false);
    const API = process.env.REACT_APP_API_URL;
    const SHEET_API = process.env.REACT_APP_SHEET_API_URL;
    const ref = useContext(RefContext);
    // console.log(ref.ref)
    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        let data = {
            "event_name": "contact",
            "name": name,
            "email": email,
            "phone_number": phone,
            "date":new Date().toDateString(),
            "ref": "Contact us"
        }

        try {
            if (phone.length === 10) {
                const result = await axios.post(API, data,
                    {
                        headers: {
                            'Content-Type': "text/plain;charset=utf-8"
                        },
                        'mode': "no-cors"
                    }).then((resp) => {
                        if (resp.status === 200) {

                            setLoading(false);
                            setName('');
                            setEmail('');
                            setPhone('');
                            Swal.fire(
                                'Thank You For Inquiry , we will contact You soon on Whatsapp',
                                'Your form is succesfully submitted!',
                                'success'
                            )
                        }


                    }).catch(error => {

                        setLoading(false);
                        Swal.fire(
                            "Something went wrong",
                            "Try after few minutes",
                            "error"
                        );

                    });
                // const googleSheet = await axios.post(SHEET_API, formData);

                // if (result.status === 200 && googleSheet.status === 200) {
                console.log("data", result)

            } else {
                Swal.fire(
                    "Something went wrong",
                    "Mobile Number Not Valid",
                    "error"
                );
            }


        } catch (error) {
            setLoading(false);
            console.log(error)
            Swal.fire(error.message, "Try after few minutes", "error");
        }

    }

    return (
        <>
            <div className='main-containerr'>
                <div className='bg-[#F5F6F7] xl:h-full 2xl:h-full'>
                    <div className='px-6 py-8 xs:px-0 xs:py-0 w-4/5 mx-auto xs:w-full sm:w-full md:w-full xl:w-9/12 2xl:w-9/12 xl:h-[600px] 2xl:h-[600px]'>

                        {/* <div>
                            <h1 className='text-[#0c2343] text-3xl font-normal xs:text-xl sm:text-xl md:text-xl'>Hire Expert CA's for <br />
                            <p className='my-2 text-[40px] font-extrabold xs:text-2xl sm:text-2xl md:text-2xl xs:my-1 sm:my-1 md:my-1'>Income <span className='text-[#1D70ED]'> Tax </span> Return Filing</p>
                                and Get Maximum Refunds
                                </h1>
                                <div className='mt-8'>
                                <div className='flex items-center gap-3'>
                                    <img className='w-6' src='tick-icon.webp' />
                                    <h3 className='font-semibold text-[18px]'>Authorised by Income Tax Department</h3>
                                    </div>
                                    <div className='pt-3 flex items-center gap-3'>
                                    <img className='w-6' src='tick-icon.webp' />
                                    <h3 className='font-semibold text-[17px]'>India’s Most Trusted Tax Filing Partner (4.8 Service Rating)</h3>
                                    </div>
                                    <div className='pt-3 flex items-center gap-3'>
                                    <img className='w-6' src='tick-icon.webp' />
                                    <h3 className='font-semibold text-[18px]'>Lowest Tax Filing Fees in India</h3>
                                    </div>
                                    <div className='pt-3 flex items-center gap-3'>
                                    <img className='w-6' src='tick-icon.webp' />
                                    <h3 className='font-semibold text-[18px]'>Reliable and Secure Return Filing Platform</h3>
                                    </div>
                                    </div>
                                </div> */}

                        <form onSubmit={handleSubmit} className='w-2/4 mx-auto bg-[#ffffff80] box-shadow rounded-[30px] py-6 px-8 xs:w-full sm:w-full md:w-full'>
                            <h1 className='py-2 text-xl font-bold text-center'>Contact us</h1>
                            <div className='py-4'>
                                <input onChange={(e) => setName(e.target.value)} type='text' placeholder='Full Name' value={name} required className='w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]' />

                                <input onChange={(e) => setEmail(e.target.value)} type='email' placeholder='Email Address' value={email} required className='mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]' />
                                <input onChange={(e) => setPhone(e.target.value)} type='tel' placeholder='Mobile Number' maxLength='10' value={phone} required className='mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]' />
                                {/* <input onChange={(e) => setPhone(e.target.value)} type='tel' placeholder="Contact us" maxLength='10' required className='placeholder:text-[#555] mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]' disabled /> */}
                            </div>
                            <p>By submitting the form, you agree to our <a href='https://sites.google.com/view/Tax2clear/terms-condition' target='_blank' className='text-[#0F52BA] hover:underline'>Terms & Conditions</a> and <a href='https://sites.google.com/view/Tax2clear/privacy-policy' target='_blank' className='text-[#0F52BA] hover:underline'>Privacy Policy</a> </p>
                            <button type="submit" className='text-white bg-[#0F52BA] w-full rounded mt-5 py-2 text-center'>{loading === true ? <ButtonLoader /> : "Submit"}</button>
                        </form>
                        {/* <div className=' py-12 grid grid-cols-2 gap-9 xs:py-6 sm:py-6 md:py-8 xs:px-4 sm:px-4 md:px-6 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1'>
                    </div> */}
                    </div>
                </div>
                <div className='fix-watsapp-icon'>
                    <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
                </div>
            </div>
        </>
    )

}
export default Assistedtax