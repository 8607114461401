import React from "react";
import Lottie from "react-lottie";
import animationData from "../utils/95692-loader-green.json";

const ButtonLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    renderer: "svg",
  };

  return (
    <div
      style={{
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie options={defaultOptions} height={"30px"} width={"50px"} />
    </div>
  );
};

export default ButtonLoader;
