import React from "react";
import { HashLink } from "react-router-hash-link";
import { BsCheck2Circle } from "react-icons/bs";
import salaryIncome from "../utils/salaryIncome.json";
import { BsWhatsapp } from "react-icons/bs"

const SalaryIncome = () => {
  const content = [
    {
      id: 1,
      content: "Salary Income",
      path: "#salaryincome",
    },
    {
      id: 2,
      content: "Retirement Benefits",
      path: "#retirementbenefits",
    },
    {
      id: 3,
      content: "Income Tax Calculation",
      path: "#incometaxcalculation",
    },
    {
      id: 4,
      content: "FAQs",
      path: "#faqs",
    },
  ];
  return (
    <div className="main-containerr">
      <div className="salaryIncome mt-5">
        <p className="text-4xl font-bold">Save Income Tax on Salary Income</p>
        <br />
        <p>
          Understand Salary and its components - Basic, HRA, Travel Allowance, PF,
          Standard Deduction. Difference bewteen CTC & take home salary. Tax
          exemptions & benefits.
        </p>
        <br />
        <div className="contentBox">
          <h3 className="text-2xl font-semibold">Contents</h3>
          <ul>
            {content.map((item) => {
              return (
                <HashLink to={item.path} smooth key={item.id}>
                  <li className="font-bold"># {item.content}</li>
                </HashLink>
              );
            })}
          </ul>
        </div>
        <br />
        <div id="salaryincome">
          <p className="text-2xl">Salary Income</p>
          <br />
          <p>
            The moment the financial year comes to an end all the individuals
            start bothering about their tax returns. It is very important to know
            about the tax system, the income calculation and the slab rates so
            that the calculation of the tax amount becomes simpler. There are five
            heads of income –
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Income from salary
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Income from house property
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Income for capital gains
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Income for business or profession
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Income from other sources
              </p>
            </li>
          </ul>
          <br />
          <p>
            Income from salary is the first head of income which is further
            subdivided into other components. You would first need to understand
            the components of the salary income and then you can find out the
            correct tax liability. To understand the salary you need to first
            understand the different components of the pay slip. Then you need to
            find out the difference between the CTC and take home salary, the
            retirement benefits deducted from the salary and then you can
            calculate tax.
          </p>
          <br />
          <p>
            So, let’s start with the pay slip components. These components include
            the following -
          </p>
          <br />
          <ul>
            <span className="font-bold flex items-start gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Basic Salary :</p>
            </span>
            <li>
              This is considered to be the fixed amount of your pay slip which is
              the basic pay which your employer promises you apart from the other
              salary benefits. The basic salary is also used to calculate the
              contribution to the provident fund (EPF) as the contribution is
              expressed as a part of the basic salary.
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Dearness Allowance :</p>
            </span>
            <li>
              Dearness allowance (DA) is an allowance which is allowed to you for
              factoring in inflation which increases the cost of living.
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Housing rent allowance :</p>
            </span>
            <li>
              If you are employed and live in a rented apartment or house you can
              claim HRA to lower your tax amount. There is an option to partially
              or completely exempt the amount of rent paid from the taxable
              income. You need to follow the guidelines set by the income tax
              department in the computation of the HRA amount.
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Leave Travel Allowance :</p>
            </span>
            <li>
              Under LTA you can apply for exemption from taxation for the costs
              incurred while travelling within India. This exemption can be
              availed on the shortest distance during the trip. The allowance
              claim also includes cost for spouse, children and parents if
              travelling together. To avail the exemption you need to provide all
              the related documents associated with travel and so you need to take
              a trip before making the claim. LTA is allowed twice in a block of
              four years.
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Bonus :</p>
            </span>
            <li>
              The bonus declared by the company differs from one company to
              another. Generally, performance bonus is given once or twice a year.
              The bonus comes under the tax slab as it is an income. 100% of the
              bonus amount is taxed.
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" />
              <p>
                Employees’ Provident Fund contributions :
              </p>
            </span>
            <li>
              The Government of India has initiated the social security scheme for
              salaried individuals where both the employee and the employer
              contribute 12% of the basic salary and dearness allowance on a
              monthly basis towards the Employees’ Provident Fund (EPF). The
              amount attracts 8.55% of the interest on the accumulated amount. All
              the companies employing above 20 employees need to contribute
              towards the PF amount under the EPF ACT 1952. The EPF contributions
              create a retirement corpus for you when you retire.
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Standard deduction :</p>
            </span>
            <li>
              Medical allowances and the conveyance allowance have been replaced
              by standard deduction in the 2018 budget. Now you can claim INR
              50000 from your total salary income as standard deduction thereby
              reducing the amount of taxable income.
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Professional Tax :</p>
            </span>
            <li>
              This tax is levied by the State Government and is similar to the
              income tax which is levied by the Central Government. The maximum
              amount that can be levied is INR 2500. This is usually deducted by
              the employer only and deposited to the State Government.
            </li>
          </ul>
          <br />
          <p>
            Let us now understand the difference between the CTC (Cost to Company)
            and take home salary. The company may entitle you with other benefits
            like food coupons, pick and drop facility, rent free accommodation,
            gratuity, etc. These benefits add up and form the total amount of
            hiring you for the company which is known as cost to the company. So,
            the CTC would include the salary paid every month, the retirement
            benefits which are payable when you retire or leave the organization
            and non-monetary benefits like free meals, free transport, etc.
          </p>
          <br />
          <p>
            Compared to CTC, the basic take home pay would include the gross
            salary paid to you after deducting the tax-free allowances like HRA,
            LTA, etc., and the income tax payable by you.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="retirementbenefits">
          <p className="text-2xl">Retirement Benefits</p>
          <br />
          <p>
            In calculation of taxation on your salary income a lot of tax benefit
            is given on the amount of money spent on planning for retirement.
            These benefits are known as retirement benefits. Let’s discuss all
            retirement benefits in details:
          </p>
          <br />
          <ul>
            <span className="flex items-center gap-3 font-bold">
              <BsCheck2Circle color="#0F52BA" /><p>
                Leave Encashment Exemption :
              </p>
            </span>
            <li>
              As an employee you should always check with the employer regarding
              their leave encashment policy. The policy varies from company to
              company, some allow you to carry forward the leave days and some
              give you the option of encashment of leave days. The amount that you
              receive comes under the taxable slab.
            </li>
            <br />
            <span className="flex items-center gap-3 font-bold">
              <BsCheck2Circle color="#0F52BA" /><p>
                Advance Salary : :
              </p>
            </span>
            <li>
              Under Section 89(1) tax exemption is given in case if you have
              received a certain portion of your total salary in advance, or got a
              family pension in advance.
            </li>
            <br />
            <span className="flex items-center gap-3 font-bold">
              <BsCheck2Circle color="#0F52BA" /><p>
                VRS (Voluntary Retirement Scheme) :
              </p>
            </span>
            <li>
              Under section 10 (10C), exemption is given on the amount received at
              the time of voluntary retirement if certain criteria is followed.
              These criteria state that the compensation amount should be received
              on voluntary retirement, it should not exceed INR 500000, the
              receipt should follow the rule 2BA, and you should be an employee of
              an authority which is formed in compliance with certain rules. The
              employees who have already taken benefit under section 89 are not
              eligible for this exemption.
            </li>
            <br />
            <span className="flex items-center gap-3 font-bold">
              <BsCheck2Circle color="#0F52BA" /><p>
                Pension :
              </p>
            </span>
            <li>
              Any amount received under pension comes under the tax slab in the
              year that the pension is received. Pension is usually paid in the
              form of annuities. You can also commute 1/3rd of the accumulated
              pension and receive the amount in lump sum. This lump sum amount
              would be tax-free under Section 10(10A) but the annuity payments
              would be taxed in your hand at your tax slab when it is paid.
            </li>
            <br />
            <span className="flex items-center gap-3 font-bold">
              <BsCheck2Circle color="#0F52BA" /><p>
                Gratuity :
              </p>
            </span>
            <li>
              Gratuity is a benefit given by the employer to its employee at the
              time of retirement. When you complete 5 years in the company then
              you would be entitled to receive gratuity from the employer. But the
              amount is received only at the time of resignation or retirement.
              The tax calculation is different and it depends on whether you are
              covered under the Gratuity Act or not. The amount received by a
              family member in case of your death is exempted from the tax slab.
            </li>
          </ul>
        </div>

        <br />
        <hr />
        <br />

        <div id="incometaxcalculation">
          <p className="text-2xl">Income Tax calculation</p>
          <br />
          <p>
            After you calculate the taxable income from your salary, you can
            calculate your tax liability. However, the following should be kept in
            mind while calculating tax -
          </p>
          <ul>
            <li>
              Salary income is not the only taxable income that you have. There
              are various sources that also contributes towards income besides
              salary for example it can include income from property, stocks,
              interest etc. all these income are added together and then on the
              accumulated amount tax is charged depending on the slab that the
              income falls in.
            </li>
            <li>
              You also get deductions and exemptions from the gross taxable income
              calculated from all the heads of income. You have to deduct such
              tax-free deductions and exemptions to arrive at the net taxable
              income.
            </li>
            <li>
              The net taxable income would then be taxed as per the given slab
              rates -
            </li>
            <div className="w-[100%] overfloww-x-scroll">
              <table className="border">
                <tr>
                  <th className="border py-3 px-5">Income</th>
                  <th className="border py-3 px-5">Tax Rate</th>
                </tr>
                <tr>
                  <td className="border py-3 px-5">Up to INR 2,50,000 </td>
                  <td className="border py-3 px-5">No Tax</td>
                </tr>
                <tr>
                  <td className="border py-3 px-5">INR 2,50,000 – INR 5,00,000 </td>
                  <td className="border py-3 px-5">5%</td>
                </tr>
                <tr>
                  <td className="border py-3 px-5">
                    INR 5,00,000 – INR 10,00,000{" "}
                  </td>
                  <td className="border py-3 px-5">20%</td>
                </tr>
                <tr>
                  <td className="border py-3 px-5">INR 10,00,000 and above </td>
                  <td className="border py-3 px-5">30%</td>
                </tr>
              </table>
            </div>
            <p>
              The above table is applicable for taxpayers up to 60 years of age.
              In case of taxpayers between the age group of 60 years to 80 years,
              the tax exemption limit is INR300000. For taxpayers above the age of
              80 years tax exemption limit is INR 500000. 4% of the total amount
              of tax calculated is charged as the health and education cess.
            </p>
            <br />
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Tax deduction at source on the salary :
              </p>
            </span>
            <li>
              Every employer deducts a certain amount of money from your basic
              salary and pays it to the tax department on your behalf. The
              employer calculates the tax amount on your basic salary and
              investments made, deducts TDS from your salary. The employer will
              provide you with the TDS certificate also known as form 16
              containing all the details about the TDS deductions. These
              deductions should be factored in to know the tax liability
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                FORM 16 :
              </p>
            </span>
            <li>
              Form 16, typically known as TDS certificate, contains all the
              information regarding the tax deductions made by the employer from
              your salary on a monthly basis during the given financial year. The
              form is typically divided in two parts. Part 1 contains details
              about the employer like the name, address, Pan Details etc. Part 2
              contains details about the salary paid, deduction, other income etc.
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Form 26AS :
              </p>
            </span>
            <li>
              This form is provided by the income tax department containing
              details about the tax deducted on your behalf and also the amount of
              tax paid. The form is available on the website of the IT department.
            </li>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Deductions :
              </p>
            </span>
            <li>
              Section 80 and section 10(10D) includes all the deductions that can
              be availed by you from your gross taxable income. By utilizing these
              deductions you can reduce your tax liability thus reducing the
              amount of the tax to be paid to the government.
            </li>
          </ul>
        </div>

        <br />
        <hr />
        <br />

        <div id="faqs" className="p-10 xs:p-4 mb-10 bg-100 bg-[#C6DBFA]">
          <h1 className="font-bold text-4xl text-900 text-[#1E1E24]">
            Frequently Asked Questions
          </h1>
          {salaryIncome.map((item) => {
            return (
              <>
                <div key={item.id}>
                  <p className="text-900 text-[#1E1E24] font-bold text-xl pt-10 pb-5 flex items-center gap-2.5 xs:text-lg xs:leading-6">
                    <span className="text-base xs:mt-2 text-center flex items-center justify-center bg-900 bg-[#1E1E24] question p-2 xs:leading-[100%]  xs:text-sm xs:rounded-[100%] rounded-full h-6 w-6 font-normal text-white">
                      Q
                    </span>
                    {item.question}
                  </p>
                  <p className="text-base text-justify text-sm">Ans: {item.answer}</p>
                  {item.list ? (
                    <ul className="pt-5 listQuestion">
                      {item.list.map((li) => {
                        return <li>&#x2713; {li}</li>;
                      })}
                    </ul>
                  ) : null}
                  {/* <div className='border-solid border border-sky-900 my-4 w-1/4'></div> */}
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className='fix-watsapp-icon'>
      <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default SalaryIncome;
