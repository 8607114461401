import React from "react";
import { BsPlusCircle } from "react-icons/bs";
import PanAccrodatipn from "./PanAccrodatipn";
import { BsWhatsapp } from "react-icons/bs"
import { HashLink } from "react-router-hash-link";
const AadharLinkPan = () => {
  const item = [
    {
      id: 1,
      question:
        "What will happen if I'll not link my PAN with Aadhaar by 31st March 2023?",
      ans: "As per the latest updates if you do not link your PAN with Aadhaar by 31st March 2023, then will become inoperative.PAN holder will not be able to enter any financial transaction requiring PAN details, once it becomes inoperative.",
    },
    {
      id: 2,
      question: "What is Aadhaar Card and how to obtain it?",
      ans: "Aadhaar is one of the unique identity launched by union government. It is embedded with your demographic (Name, Address etc.) and biometric information (fingerprints, iris pattern and photograph). It can be obtained by UIDAI ( Unique Identification Authority of India).",
    },
    {
      id: 3,
      question: "What is the purpose of Linking PAN and Aadhaar ?",
      ans: "The purpose of this step taken by government is three fold: 1.It will restrict Multiple PAN card users 2.It will prevent tax evasion. 3.It will simplify the e-filing process of Income tax return.",
    },
    {
      id: 4,
      question:
        "Is it mandatory for everyone to link their Aadhaar card with their PAN Card?",
      ans: "Yes, as per the judgement of Supreme court (SC) passed on 26th September 2018 , linking must be done by everyone i.e. Individuals, HUFs, Companies, Firms etc. Linking has to be done even if the income is below taxable limit. Initially, Linking was needed to be done before December 31, 2017. However, to facilitate the process of linking, the last date for linking of Aadhaar with PAN was extended to 30/06/2018 and then to 31/03/2019. But now, as per the recent CBDT circular, it has been further extended till 30th September 2019.",
    },
    {
      id: 5,
      question:
        "I am an NRI/Foreign Citizen living in India. Is it mandatory for me to link my Aadhaar Card with PAN Card?",
      ans: "Generally, a Non Resident Indians (NRI) does not require an Aadhaar card. However, if you have an Aadhaar card or have applied for it, then it is mandatory to link it with your PAN Card.",
    },
    {
      id: 6,
      question:
        "In case of PAN numbers for HUF/AOP/BOI/Partnership Firms, etc., whose Aadhaar Card number be used?",
      ans: "In case of HUF, Aadhaar Card of Karta will be linked with the PAN card of HUF. In case of other non-corporate organisations , Aadhaar card of the Principal Contact of the organisation will be linked with PAN card.",
    },
    {
      id: 7,
      question: "Why should I link my Aadhaar Card with PAN Card?",
      ans: "The government has made the linking mandatory and supreme court has affirmed it too. If you don't link your PAN card with Aadhaar card, then your PAN card will become invalid.",
    },
    {
      id: 8,
      question: "What are the Prerequisites for Linking ?",
      ans: "In order to link, there are 3 details that should match in both PAN card and Aadhaar card- Your Name, Date of Birth and gender. However, in case of minor mismatch in name, linking can be done through OTP Process.",
    },
    {
      id: 9,
      question:
        "Is there any document required to be submitted for linking purpose?",
      ans: "No, you are not required to submit any document while linking. All you need to do is to enter your Aadhaar Number , PAN & Name as per Aadhaar and click on “Link Aadhaar” button.",
    },
    {
      id: 8,
      question:
        "What will happen if I'll not link my PAN with Aadhaar by 31st March 2023?",
      ans: "As per the latest updates if you do not link your PAN with Aadhaar by 31st March 2023, then will become inoperative.PAN holder will not be able to enter any financial transaction requiring PAN details, once it becomes inoperative.",
    },
    {
      id: 9,
      question: "What is the procedure to link PAN card with Aadhaar?",
      ans: "Following are the 3 methods to link PAN card with Aadhaar Card: (a) Income Tax Website (Without log-in) - You can directly link the cards by using this website (b) Income Tax Website (With log-in) - To use this option, you will have to create a account at website (c) You can also use SMS facility. Simply, type UIDPAN <12 Digit Aadhaar No.> <10 Digit PAN> and send it to 567678 or 56161 (d) Link Aadhaar and PAN manually by visiting the centers of PAN, UTIITSL, NSDL. Along with the copies of Aadhaar and PAN fill the Annexure-1.",
    },
    {
      id: 10,
      question:
        "Due to different name on Aadhaar Card and PAN card, I am unable to link them. How can I resolve this?",
      ans: "If there is a minor mismatch in your name, you will receive an OTP on the mobile number linked to your Aadhaar card and after verification, you will be able to continue the linking process. For major mismatch, you can either update on the UIDAI website (for Aadhaar) or NSDL website (for PAN).",
    },
    {
      id: 11,
      question: "How can I update my details?",
      ans: "Updating any details on PAN or Aadhaar takes time (usually 20-25 days) as there is a particular verification and validation process of your documents.",
    },
    {
      id: 12,
      question:
        "In case of mismatch of my name in Aadhaar and PAN Card, OTP will be sent on which mobile number?",
      ans: "OTP will be sent to the registered mobile number, which was submitted by you while applying for Aadhaar Card.",
    },
    {
      id: 13,
      question:
        "What if I don't remember the mobile number which I registered at the time of application of Aadhaar Card?",
      ans: "Don't Worry! Just fill in your details at https://eaadhaar.uidai.gov.in and you will be able to view the last four digits of your registered mobile number.",
    },
    {
      id: 14,
      question:
        "Under which situations will I have to quote my Aadhaar Card Number from 1st July, 2017 onwards?",
      ans: "A person who already has an Aadhaar card or has applied for it, will have to quote the number in the following situations: (a) while applying for allotment of new Permanent Account Number (PAN). (b) while filing their Income Tax Return.",
    },
    {
      id: 15,
      question:
        "What will be the effect on my ITR if I do not link my Aadhaar card with PAN card?",
      ans: "Earlier it was mentioned that if your Aadhaar and PAN are not linked by 31st December, 2017 then your ITR filed for F.Y 16-17 would not be processed by Income Tax Department. However, to facilitate the process of linking, the last date for linking of Aadhaar with PAN was extended to 31/03/2018 and then to 30/06/2018. . But now,as per the recent CBDT circular, it has been further extended till 31st March 2023. Notification by the government states that all the taxpayers who do have a valid PAN on 1st July 2017 are required to link their PAN with Aadhaar before 31st March 2023. Otherwise their PAN will be invalidated.",
    },
    {
      id: 16,
      question:
        "After linking PAN with Aadhaar, will it become mandatory for me to file an Income Tax Return?",
      ans: "Linking Aadhaar with PAN doesn't impose a compulsion on filing ITR. You are required to file an ITR, if your Gross Taxable Income exceeds ? 2,50,000 or you fall under the various category as specified by Income Tax Department.",
    },
    {
      id: 17,
      question:
        "Is linking of Aadhaar & PAN Card mandatory for processing my Income Tax Return?",
      ans: "Yes, as per press release issued by the Income Tax Department on 31/08/2017, linking Aadhaar Card with PAN Card is mandatory for processing an ITR. However, to facilitate the process of linking, the last date for linking of Aadhaar with PAN was extended to 31/03/2018 and then to 30/06/2018 . But now, as per the recent CBDT circular, it has been further extended till 31st March 2019. Now the last date for linking PAN with Aadhaar is 30th September 2019.You will not be able to file your ITR if PAN is not linked with Aadhaar.",
    },
    {
      id: 18,
      question:
        "Can I file my Income Tax Return, even if I don't have an Aadhaar Number?",
      ans: "No, from 1st July, 2017 onwards, it is mandatory to quote your Aadhaar number in Income Tax Return. In case, you don't have Aadhaar no., then you are required to quote your Aadhaar Enrolment Number.",
    },
    {
      id: 19,
      question:
        "I have applied for Aadhaar Card, but have not received it yet. Can I still file my ITR?",
      ans: "Yes. However, while filing your return, you have to mention the enrollment ID generated at the time of applying for Aadhaar Card. For ex : your enrolment ID is 09876/54321/12356 and time is 25/12/2017 11:00:00, you have to write 0987654321235625122017110000",
    },
    {
      id: 20,
      question:
        "My Aadhaar Card mentions only the year of my Birth instead of full Date of Birth. How can I view the date that is mentioned with UIDAI?",
      ans: "To view your Date of Birth including date, month and year, you can download your Aadhaar Card at https://eaadhaar.uidai.gov.in",
    },
    {
      id: 21,
      question: "How to change my mobile number in Aadhaar Database?",
      ans: "You may contact UIDAI Helpdesk 1800-300-1947 for updation of Aadhaar database or click on link",
    },
    {
      id: 22,
      question:
        "I am not receiving an OTP on my mobile number registered with Aadhaar database?",
      ans: "You may contact UIDAI Helpdesk 1800-300-1947.",
    },
    {
      id: 23,
      question:
        "I am unable to link my Aadhaar number in e-filing website post updation of PAN/Aadhaar details.",
      ans: "although you might have updated the details and got an acknowledgement, but there would be a time lag in refreshing the details. Hence, please try linking the Aadhaar after a few days.",
    },
    {
      id: 24,
      question:
        "What are the due dates to link Aadhaar with the Bank Account and Mobile Number (SIM) ?",
      ans: "Earlier, the deadlines to link Aadhaar with Bank account and Mobile number(SIM) was extended to 31/03/2018. as per the judgement of Supreme court (SC) passed on 26th September 2018 , there is no requirement of linking Aadhaar with the Bank Account and Mobile Number (SIM).",
    },
    {
      id: 25,
      question:
        "What will happen to those ITRs which are filed without quoting Aadhar?",
      ans: "In Budget 2017, government introduced the necessary linking of Aadhaar with PAN.But with the several disputes and difference of opinion, Court permitted individuals to file ITR without linking.Thus, those ITR which has been filed without Aadhar PAN linking will not have any adverse effect while processing of returns.",
    },
    {
      id: 26,
      question:
        "I have two PAN cards, which PAN card shall be linked with Aadhaar?",
      ans: "Holding two PAN cards is not legal, as per the Income Tax law. Therefore, if you are holding two PAN cards, you should link the correct PAN card with aadhar and surrender the other one. The surrender application can be filed online or offline.Also, a letter intimating such surrender shall be written to your assessing officer.",
    },
  ];

  return (
    <div className="main-containerr">
      <div className="panLinkAadhar  bg-[#f1f7fb]">
        <div className="topLink py-5 md:flex-col sm:flex-col xs:flex-col  h-auto bg-[#e8eff3] flex px-24 md:px-[0] sm:px-[0] xs:px-[0]">
          <div className="topPanAadharImage gap-[60px] flex flex-col  items-center justify-center flex-initial w-[65%] md:w-auto sm:w-auto xs:w-auto p-15 md:p-4 sm:p-4 xs:p-4 ">
            <p className="text-3xl sm:text-lg xs:text-lg font-semibold text-[#1a284c]">
              PAN Aadhaar Link- Know the easiest way to link{" "}
              <span className="font-bold text-[#0F52BA]">Aadhaar card</span> with
              PAN card
            </p>
            <img src="aadhaar-pan-link.svg" alt="" />
          </div>
          <div className="topPanAadharText  flex-initial w-auto ">
            <div className="border gap-2 px-7  bg-[#1E1E24] flex flex-col items-center justify-center py-5 h-full">
              <p className="font-semibold text-xl text-[#fff]">
                Link your Aadhaar Number and PAN card
              </p>

              <p className="text-center text-white text-md">
                It is now compulsory to link your PAN with Aadhaar Card. The
                various methods for linking PAN Aadhaar are -
              </p>

              <p className="text-lg w-auto min-w-[430px] md:min-w-[420px] sm:min-w-[370px] xs:min-w-[300px] bg-white px-5 py-3">
                1-e-Filing Income Tax Website{" "}
                <span className="font-semibold">(Without login)</span>
              </p>
              <p className="text-lg w-auto min-w-[430px] md:min-w-[420px] sm:min-w-[370px] xs:min-w-[300px] bg-white px-5 py-3">
                2- e-Filing Income Tax Website{" "}
                <span className="font-semibold">(With login)</span>
              </p>
              <p className="text-lg w-auto min-w-[430px] md:min-w-[420px] sm:min-w-[370px] xs:min-w-[300px] bg-white px-5 py-3">
                3- Linking through SMS
              </p>
              <p className="text-lg w-auto min-w-[430px] md:min-w-[420px] sm:min-w-[370px] xs:min-w-[300px] bg-white px-5 py-3">
                4- Link Aadhar-PAN manually
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="w-[80%] ml-[150px] md:mx-auto sm:mx-auto xs:mx-auto md:w-full sm:w-full xs:w-full">
          <p className="text-md shadow-lg  font-bold px-10 py-5 text-center text-[#333333] bg-white">
            The deadline for linking Aadhaar with PAN has been extended to 31
            March 2023. Non-compliance on this will result in the PAN will become
            inoperative from April 1, 2023. Also, individuals will have to pay a
            fine of Rs 1000 as a late fee for linking their PAN with their
            Aadhaar. Till June 30, 2022, this penalty was Rs 500.
          </p>
          <br />
          <p>
            PAN card is accepted as valid identity proof all over India. A PAN
            card is required for most financial transactions, and the IT
            department tracks your transaction via it. On the other hand Aadhaar
            holds all the information of an individual along with their biometric
            verification.
          </p>
          <br />
          <div className="bg-[#1E1E24] text-white px-10 py-5">
            <p className="text-2xl font-semibold">
              What are the benefits of Aadhaar and PAN linking?
            </p>
            <br />
            <p>
              Aadhaar-PAN linking is required for legal aspects and provides
              benefits like:-
            </p>
            <br />
            <p>For Individuals :</p>
            <br />
            <ul className="list-disc">
              <li>
                Aadhaar Card is an important document for all transactions.
                Linking Aadhaar and PAN provides an audit trail of all
                transactions to the Income tax department.
              </li>
              <li>
                Starting next year, ITR filing will not be allowed until your
                Aadhaar-PAN is linked.
              </li>
              <li>
                Filing ITR will be an easy process as it would eliminate the need
                for actions like submitting receipts to the IT department or
                e-signature. These actions will be automatically done using
                Aadhaar e-verification.
              </li>
              <li>
                Aadhaar- PAN linking can help in preventing the cancellation of
                the latter.
              </li>
              <li>
                Through the use of an Aadhaar Card, the requirement for other
                documents has been reduced to a large extent.
              </li>
              <li>
                Aadhaar cards also serve the purpose of identity proof and address
                proof. It has made this document of great importance for
                individuals.
              </li>
            </ul>
            <br />
            <p>For Government :</p>
            <br />
            <ul className="list-disc">
              <li>
                With the introduction of aadhaar, tracking transactions has become
                easier and more effective. Aadhaar-based authentication has become
                a norm now.
              </li>
              <li>
                It is also useful for tracking tax evaders. Aadhaar-PAN linking
                solved the problem of an individual having multiple PAN cards and
                reduced the possibility of fraudulent activities.
              </li>
            </ul>
          </div>
          <br />
          <div>
            <p className="text-2xl text-[#052f5f]">
              What is the process to link your PAN to your Aadhaar?
            </p>
            <br />
            <p>There are two main steps for Linking Aadhaar with PAN:</p>
            <br />
            <p className="text-2xl shadow-lg  font-semibold px-10 py-5 sm:text-lg xs:text-lg text-[#333333] bg-white">
              1. Payment of applicable fee on e-filing Portal either using “e-pay
              Tax Service” or on Protean (NSDL) Portal under major head (021) and
              minor head (500).
            </p>

            <br />
            <div className="bg-white shadow-lg px-10 py-5 md:px-2 sm:px-2 xs:px-2">
              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 1:</p>
                  <p>
                    Click on Link Aadhaar in Quick Links section on e-filing
                    website. Alternatively, login to e-filing portal and Click on
                    Link Aadhar in Profile Section.
                  </p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-7.jpg"
                  alt=""
                />
              </div>
              <hr />
              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 2:</p>
                  <p>Enter your PAN and Aadhaar Number</p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-2.jpg"
                  alt=""
                />
              </div>
              <hr />
              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 3:</p>
                  <p>Continue to Pay Through e-Pay Tax.</p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-3.jpg"
                  alt=""
                />
              </div>
              <hr />
              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 4:</p>
                  <p>
                    Enter your PAN, Confirm PAN and any Mobile number to receive
                    OTP
                  </p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-4.jpg"
                  alt=""
                />
              </div>
              <hr />
              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 5:</p>
                  <p>
                    After OTP veriification, you will be redirected to e-Pay Tax
                    page. Click on Income Tax File.
                  </p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-5.jpg"
                  alt=""
                />
              </div>
              <hr />
              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 6:</p>
                  <p>
                    Select AY (2023-24) and Type of payments as Other Receipts
                    (500) and Continue.
                  </p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-6.jpg"
                  alt=""
                />
              </div>
              <hr />

              <p>
                Now, challan will be generated. On the next screen you have to
                select the mode of payment after selecting the mode of payment you
                will be re-directed to the Bank website where you can make the
                payment.{" "}
                <b>
                  In case, if you have an account in the Bank which is not
                  Authorised for payment through “e-Pay Tax”, Protean (NSDL)
                  Portal is the other option to proceed with payment. The process
                  is as follows:-
                </b>
              </p>
              <br />
              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 1:</p>
                  <p>
                    Click on Link Aadhaar in Quick Links section on the e-filing
                    portal.
                  </p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-7.jpg"
                  alt=""
                />
              </div>
              <hr />

              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 2:</p>
                  <p>Enter your PAN and Aadhaar Number.</p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-8.jpg"
                  alt=""
                />
              </div>
              <hr />

              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 3:</p>
                  <p>Click on Continue to Pay Through e-Pay Tax</p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-3.jpg"
                  alt=""
                />
              </div>
              <hr />

              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 4:</p>
                  <p>
                    Click on hyperlink given on e-Pay tax page to redirect to
                    Protean (NSDL) Portal
                  </p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-9.jpg"
                  alt=""
                />
              </div>
              <hr />

              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 5:</p>
                  <p>
                    You will be redirected to Protean (NSDL) Portal. Click Proceed
                    under Challan No./ITNS 280
                  </p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-10.jpg"
                  alt=""
                />
              </div>
              <hr />

              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 6:</p>
                  <p>Select Tax applicable as 0021 and Type of Payment as 500.</p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-11.jpg"
                  alt=""
                />
              </div>
              <hr />

              <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
                <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                  <p className="font-bold text-md">Step 7:</p>
                  <p>
                    Provide Assessment Year as 2023-24 and other mandatory details
                    and click on Proceed.
                  </p>
                </div>
                <img
                  className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                  src="step-12.jpg"
                  alt=""
                />
              </div>
              <p>
                <b>
                  Post payment of fee you can link your Aadhaar with PAN on the
                  e-Filing Portal.
                </b>
              </p>
              <hr />
            </div>
          </div>
          <br />
          <div className="text-md shadow-lg px-10 py-5 text-[#333333] bg-white">
            <p className="text-2xl">2. Submit the Aadhaar-PAN link request</p>
            <br />
            <p>There are three ways for the linking of Aadhaar-PAN:-</p>
            <br />
            <ul className="list-disc ml-6">
              <li>Linking without log-in to the Income Tax website</li>
              <li>Linking after log-in the Income Tax website</li>
              <li>Linking of Aadhaar Number and PAN via SMS</li>
            </ul>
          </div>
          <br />
          <p className="text-3xl">
            Method 1: Linking Aadhar Card with PAN card without logging in to the
            income tax website
          </p>
          <br />

          <div className="bg-white shadow-lg px-10 py-5 md:px-2 sm:px-2 xs:px-2">
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 1:</p>
                <p>
                  Visit the income tax government website , under the quick links
                  you will get the option to link your Aadhaar with PAN.
                </p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="step-7.jpg"
                alt=""
              />
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 2:</p>
                <p>Enter PAN and Aadhaar number and click ‘Validate’.</p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="step-2.jpg"
                alt=""
              />
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 3:</p>
                <p>
                  the 6-digit OTP received on your mobile number and validate.
                </p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="verification.jpg"
                alt=""
              />
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 4:</p>
                <p>
                  Request for the link of Aadhaar has been submitted successfully;
                  now you can check the Aadhaar-PAN link status.
                </p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="check_status.jpg"
                alt=""
              />
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-full md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Scenario 1:-</p>
                <p>
                  If your PAN and Aadhaar is already liked, you will get the
                  pop-up on the screen. Here you can lodge a grievance or contact
                  the e-Filing Helpdesk to un-link your Aadhaar and PAN if your
                  PAN is linked with another Aadhaar.
                </p>
              </div>
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Scenario 2:-</p>
                <p>
                  After entering PAN and Aadhaar, if you haven't linked
                  Aadhaar-PAN, the pop-up message will be ” Payments details not
                  found” click on Continue to Pay Through e-Pay Tax for the
                  payment of fee as payment of fee is the pre-requisite to submit
                  the Aadhaar PAN link request. The process is shared above.
                </p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="epay_click.jpg"
                alt=""
              />
            </div>
          </div>
          <br />
          <p className="text-3xl">
            Method 2: Linking Aadhaar Card with PAN card After logging in to the
            income tax website
          </p>
          <br />
          <p>
            In order to link Aadhaar with PAN card , all you have to do is follow
            these 5 simple steps:-
          </p>
          <br />
          <div className="bg-white shadow-lg px-10 py-5 md:px-2 sm:px-2 xs:px-2">
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 1:</p>
                <p>Visit the income tax website, and click on the login tab.</p>
              </div>
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 2:</p>
                <p>Fill in your details to login into the portal.</p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="login-into-the-portal.jpg"
                alt=""
              />
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 3:</p>
                <p>
                  On the income tax dashboard, under the Link Aadhaar to PAN
                  option, click on the 'Link’ button.
                </p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="Link-Aadhaar-to-PAN-option.jpg"
                alt=""
              />
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 4:</p>
                <p>Enter the Aadhar and click on validate.</p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="enter_aadhar.jpg"
                alt=""
              />
            </div>
          </div>
          <br />
          <p className="text-3xl">
            Method 3: Link Your Aadhar Card with PAN card via SMS
          </p>
          <br />
          <div className="bg-white text-center shadow-lg px-10 py-5 md:px-2 sm:px-2 xs:px-2">
            <p>
              The income tax department has started SMS-based Aadhaar to PAN
              linking facilities to make it easier for the residents to link their
              IDs. It can be done by sending an SMS to either 567678 or 56161.
              However, you need to use your registered mobile number.
            </p>
            <br />
            <p>
              Type UIDPAN{" "}
              <b>
                &lt;Space&gt;&lt;12 Digit Aadhaar No.&gt;&lt;Space&gt;&lt;10 Digit
                PAN&gt; and send a SMS to 567678 or 56161
              </b>
            </p>
            <br />
            <p>
              <b>For Ex- UIDPAN 111122223333 AAAPS1111A</b>
            </p>
          </div>
          <br />
          <p className="text-3xl">How To Check PAN And Aadhaar Link Status</p>
          <br />
          <p>
            To check whether your Aadhaar and PAN cards are linked or not, follow
            these steps:
          </p>
          <br />
          <div className="bg-white shadow-lg px-10 py-5 md:px-2 sm:px-2 xs:px-2">
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 1:</p>
                <p>
                  On the income tax portal, head on to the “our services” tab.
                </p>
              </div>
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 2:</p>
                <p>Click on the “Link Aadhaar” card displayed on the page.</p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="Aadhaar-and-PAN-card.jpg"
                alt=""
              />
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 3:</p>
                <p>
                  You will see a window where you need to fill in your PAN and
                  Aadhaar card numbers.
                </p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="Link-Aadhaar-Status (1).jpg"
                alt=""
              />
            </div>
            <hr />
            <div className="flex mb-5 gap-3 md:flex-col sm:flex-col xs:flex-col">
              <div className="md:mb-4 w-[50%] md:w-auto sm:w-auto xs:w-auto">
                <p className="font-bold text-md">Step 4:</p>
                <p>Enter the Aadhar and click on validate.</p>
              </div>
              <img
                className="w-[50%] md:w-auto sm:w-auto xs:w-auto"
                src="Pop-up-will-appear-showing.jpg"
                alt=""
              />
            </div>
          </div>
          <br />
          <p className="text-3xl">
            What are the consequences of not linking your Aadhaar with PAN card?
          </p>
          <br />
          <div className="bg-white shadow-lg px-10 py-5 md:px-2 sm:px-2 xs:px-2">
            <ul className="list-disc">
              <li>
                Filing an Income Tax Return (ITR) may become challenging if the
                PAN and Aadhaar cards are not linked.
              </li>
              <li>
                Pending Refunds will not be issued, and Pending ITRs will not be
                processed with inoperative PAN.
              </li>
              <li>
                Income Tax will be deducted at a higher rate if PAN becomes
                invalid due to non-linking.
              </li>
              <li>
                Pending proceedings, as in the case of Defective Returns, cannot
                be completed with an inoperative PAN.
              </li>
              <li>
                Suppose the PAN Card and the Aadhaar Card are not linked. In that
                case, it may cause difficulties when accessing certain government
                services such as obtaining subsidies, applying for a passport,
                opening a bank account, etc.
              </li>
              <li>
                Obtaining a new PAN Card may be difficult if not linked to the
                Aadhaar Card. The government has also made it mandatory to include
                the Aadhaar Card number when applying for a new PAN.
              </li>
              <li>
                If the PAN Card and the Aadhaar Card are not linked, you may be
                subject to a penalty of up to Rs. 10,000/-
              </li>
            </ul>
          </div>
          <br />
          <div className="xs:p-2">
            <p className="text-3xl font-bold mb-2 text-[#333333]">
              Frequently Asked Questions
            </p>
            <p>
              Get answers to all your queries related to the linking of PAN card
              with Aadhaar card.
            </p>
            <br />
            {item.map((curItem) => {
              return (
                <PanAccrodatipn
                  question={curItem.question}
                  answer={curItem.ans}
                  key={curItem.id}
                />
              );
            })}
            {/* <PanAccrodatipn item={item} /> */}
          </div>
        </div>
      </div>
      <div className='fix-watsapp-icon'>
      <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default AadharLinkPan;
