import React, { useState, useContext } from "react";
import RefContext from "../../Context/RefContext";
import axios from "axios";
import Swal from "sweetalert2";
import ButtonLoader from "../ButtonLoader";
import { BsWhatsapp } from "react-icons/bs";
const TaxOnWinnings = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const ref = useContext(RefContext);
  // console.log(ref.ref)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const date = new Date().toDateString();
    const API = process.env.REACT_APP_API_URL;

    let data = {
      "date": date,
      "event_name": "Contact",
      "name": name,
      "email": email,
      "phone_number": phone,
      "ref": "Tax On Winning"
    }

    try {
      if (phone.length === 10) {
        const result = await axios.post(API, data,
          {
            headers: {
              'Content-Type': "text/plain;charset=utf-8"
            },
            'mode': "no-cors"
          }).then((resp) => {
            if (resp.status === 200) {

              setLoading(false);
              setName('');
              setEmail('');
              setPhone('');
              Swal.fire(
                'Thank You For Inquiry , we will contact You soon on Whatsapp',
                'Your form is succesfully submitted!',
                'success'
              )
            }


          }).catch(error => {

            setLoading(false);
            Swal.fire(
              "Something went wrong",
              "Try after few minutes",
              "error"
            );

          });
        // const googleSheet = await axios.post(SHEET_API, formData);

        // if (result.status === 200 && googleSheet.status === 200) {
        console.log("data", result)

      } else {
        Swal.fire(
          "Something went wrong",
          "Mobile Number Not Valid",
          "error"
        );
      }


    } catch (error) {
      setLoading(false);
      Swal.fire(error.message, "Something went wrong", "error");
    }
  };
  return (
    <div className="main-containerr">
      <div className="w-[100%] mx-auto">
        <div className="bg-[#F5F6F7] xl:h-full 2xl:h-full">
          <div className="px-6 py-8 xs:px-0 xs:py-0 w-4/5 mx-auto xs:w-full sm:w-full md:w-full xl:w-9/12 2xl:w-9/12 xl:h-[600px] 2xl:h-[600px]">
            <div className=" py-12 grid grid-cols-2 gap-9 xs:py-6 sm:py-6 md:py-8 xs:px-4 sm:px-4 md:px-6 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
              <div>
                <h1 className="text-[#0c2343] text-3xl font-normal xs:text-xl sm:text-xl md:text-xl">
                  Taxes on
                  <br />
                  <p className="my-2 text-[40px] font-extrabold xs:text-2xl sm:text-2xl md:text-2xl xs:my-1 sm:my-1 md:my-1">
                    Winnings from{" "}
                    <span className="text-[#1D70ED]">
                      {" "}
                      Online gaming websites and apps{" "}
                    </span>{" "}
                  </p>
                </h1>
                <div className="mt-8">
                  <div className="flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      Tax2clear offers easy and hassle-free tax filing services
                      for gaming winnings.
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[17px]">
                      Our team of experts ensures that your tax filing is
                      compliant with government requirements.
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      We provide personalized assistance to help you understand
                      your tax liability and maximize deductions to reduce tax
                      liability.
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      Our online platform allows you to file taxes from the
                      comfort of your home, saving you time and effort.
                    </h3>
                  </div>
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                className="w-4/5 mx-auto bg-[#ffffff80] box-shadow rounded-[30px] py-6 px-8 border-b border-[#0F52BA] xs:w-full sm:w-full md:w-full"
              >
                <h1 className="py-2 text-xl font-bold text-center">
                  Taxes on Winnings from Online gaming websites and apps
                </h1>
                <div className="py-4">
                  <input
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    required
                    className="w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />

                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    required
                    className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    type="number"
                    placeholder="Mobile Number"
                    value={phone}
                    maxLength="10"
                    required
                    className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />
                </div>
                <p>
                  By submitting the form, you agree to our{" "}
                  <a href="https://sites.google.com/view/Tax2clear/terms-condition" target="_blank" className="text-[#0F52BA] hover:underline">
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a href="https://sites.google.com/view/Tax2clear/privacy-policy" target="_blank" className="text-[#0F52BA] hover:underline">
                    Privacy Policy
                  </a>{" "}
                </p>
                <button
                  type="submit"
                  className="text-white bg-[#0F52BA] w-full rounded-[4px] mt-5 py-2 text-center"
                >
                  {loading === true ? <ButtonLoader /> : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <br />
        <div className="w-3/4 mx-auto">
          <br />
          <p className="text-2xl font-semibold">
            More About Taxes on Winnings from Online gaming websites and apps
          </p>
          <br />
          <p>
            Winnings from online gaming websites and apps in India are taxable as
            per the Income Tax Act, 1961.
          </p>
          <br />
          <p>
            The tax rate for such winnings is 30% plus applicable surcharge and
            cess, which is deducted at source by the gaming operator.
          </p>
          <br />
          <p>
            Individuals are required to file an income tax return if their total
            annual income, including online gaming winnings, exceeds the basic
            exemption limit of Rs. 2.5 lakh.
          </p>
          <br />
          <p>
            Deductions under Section 80C can be availed to reduce taxable income,
            and non-compliance with tax laws can result in penalties and legal
            consequences.
          </p>
          <br />
          <p>
            you are required to file an income tax return and declare your online
            gaming winnings.
          </p>
          <br />
          <p>
            Non-compliance with tax laws can lead to penalties and legal
            consequences, so it is advisable to consult a tax expert or a CA to
            ensure proper compliance with the tax laws in India.
          </p>
          <br />
        </div>
      </div>
      <div className='fix-watsapp-icon'>
        <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default TaxOnWinnings;
