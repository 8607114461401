import React from "react";
import { HashLink } from "react-router-hash-link";
import { BsCheck2Circle } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs"
import section80 from "../utils/section80.json";
const Section80 = () => {
  const content = [
    {
      id: 1,
      content: "What is Income Tax Deduction under Chapter VI A of Income Tax Act?",
      path: "#whatisdeductions",
    },
    {
      id: 2,
      content: "Section 80 Deduction List - Who can Claim Income Tax Deductions?",
      path: "#whocanclaindeductions",
    },
    {
      id: 3,
      content: "Investments that Qualify for Deductions under Section 80C",
      path: "#investment-quality-section80c",
    },
    {
      id: 4,
      content: "Expenses that Qualify for Tax Deductions under Section 80C",
      path: "#expenses-quality-section80c",
    },
    {
      id: 5,
      content: "Features of Income Tax Deduction u/s 80",
      path: "#final-feature-income-tax-deduction",
    },
    {
      id: 1,
      content: "Frequently Asked Questions",
      path: "#faqs",
    },
  ];

  return (
    <div className="main-containerr">
      <div className="section80 mt-5">
        <p className="text-4xl font-bold">
          Income Tax Deductions List: Deductions on Section 80C, 80CCC, 80CCD & 80D - FY 2023-24 (AY 2024-25)
        </p>
        <br />
        <p>
          The Income Tax Department, recognizing the significance of fostering savings and investments, has incorporated a comprehensive set of deductions under Chapter VI A of the Income Tax Act. While Section 80C stands out as a widely known provision, several other deductions exist, providing taxpayers with opportunities to strategically reduce their tax liabilities. These deductions serve as powerful incentives, allowing individuals to optimize their financial planning and contribute to the nation's economic growth.Let us understand these deductions in detail:
        </p>
        <br />
        <p>
          In this guide, we will explain to you all the income tax deductions of
          the Income Tax. Let’s get started!
        </p>
        <br />
        <div className="contentBox">
          <h3 className="text-2xl font-semibold">Contents</h3>
          <ul>
            {content.map((item) => {
              return (
                <HashLink to={item.path} smooth key={item.id}>
                  <li className="font-bold"># {item.content}</li>
                </HashLink>
              );
            })}
          </ul>
        </div>
        <br />

        <div id="whatisdeductions">
          <p className="text-2xl">
            What is Income Tax Deduction under Chapter VI A of Income Tax Act?
          </p>
          <br />
          <p>
            Income Tax Deduction under Chapter VIA of Income Tax Act refers to a reduction in the taxable income of an individual or a business entity, which results in a lower tax liability. The Indian Income Tax Act provides for various deductions under different sections, which can be claimed by an individual or a business entity while calculating their taxable income.
          </p>
          <br />
          <p>
            Let us take an example of tax saving for individuals with yearly salaries up to 20 lakhs.
          </p>
          <br />
          <p className="font-bold">
            Tax saving calculation for yearly income-20 lakhs
          </p>
          <br />
          <table className="border">
            <tr>
              <td className="border py-3 px-5">Gross Salary</td>
              <td className="border py-3 px-5">2,000,000</td>
            </tr>
            <tr>
              <td className="border py-3 px-5 font-bold">Less:</td>
              <td className="border py-3 px-5"></td>
            </tr>
            <tr>
              <td className="border py-3 px-5">HRA</td>
              <td className="border py-3 px-5">200,000</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">LTA</td>
              <td className="border py-3 px-5">40,000</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">Reimbursements</td>
              <td className="border py-3 px-5">24,500</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">
                Children education and hostel allowance{" "}
              </td>
              <td className="border py-3 px-5">9,600</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">Standard Deduction</td>
              <td className="border py-3 px-5">50,000</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">Professional Tax</td>
              <td className="border py-3 px-5">2400</td>
            </tr>
            <tr>
              <td className="border py-3 px-5 font-bold">
                Taxable Salary Income
              </td>
              <td className="border py-3 px-5 "></td>
            </tr>
            <tr>
              <td className="border py-3 px-5 font-bold">Less: Deductions</td>
              <td className="border py-3 px-5"></td>
            </tr>
            <tr>
              <td className="border py-3 px-5">80C (Refer Note below)</td>
              <td className="border py-3 px-5">150,000</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">80D</td>
              <td className="border py-3 px-5">50,000</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">80E</td>
              <td className="border py-3 px-5">22,000</td>
            </tr>
            <tr>
              <td className="border py-3 px-5 font-bold">Net Taxable Income</td>
              <td className="border py-3 px-5 font-bold">14,51,500</td>
            </tr>
            <tr>
              <td className="border py-3 px-5 font-bold">
                Tax on the above income
              </td>
              <td className="border py-3 px-5 font-bold">2,57,868</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">Rebate u/s 87A </td>
              <td className="border py-3 px-5">Not applicable</td>
            </tr>
            <tr>
              <td className="border py-3 px-5 font-bold">Total Tax </td>
              <td className="border py-3 px-5 font-bold">2,57,868</td>
            </tr>
            <tr>
              <td className="border py-3 px-5 font-bold">
                Apart from this, you can also claim these tax deductions if
                eligible:{" "}
              </td>
              <td className="border py-3 px-5"></td>
            </tr>
            <tr>
              <td className="border py-3 px-5">
                Interest on home loan EMIs under Section 24b{" "}
              </td>
              <td className="border py-3 px-5">-2,00,000</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">
                Principal amount of the home loan under section 80EEA{" "}
              </td>
              <td className="border py-3 px-5">-1,50,000</td>
            </tr>
            <tr>
              <td className="border py-3 px-5">
                National Pension Scheme (NPS) investments u/s 80CCD(1B){" "}
              </td>
              <td className="border py-3 px-5">-50,000</td>
            </tr>
          </table>
        </div>

        <br />
        <hr />
        <br />

        <div id="whocanclaindeductions">
          <p className="text-4xl">Section 80 Deduction List - Who can Claim Income Tax Deductions??</p>
          <br />
          <p>
            Only eligible taxpayers can claim these deductions in their income tax returns. Such eligible taxpayers have been specified under various sections of the Act. In some cases, it is individual, in some it is companies, HUF, etc. It is pertinent to note that the taxpayers who opt to pay tax under the new tax regime can claim only deductions under sections 80CCD(2) and 80JJAA.
          </p>
          <br />
          <p>
            Income tax deduction needs to be claimed at the time of filing your Income Tax Return, and no separate disclosure compliances are required for claiming such deductions. The number of deductions should be reduced from the gross income to reach the taxable amount.
          </p>
        </div>
        <br />
        <hr />
        <br />
        <div>
          {/* <p className="text-2xl font-semibold">
          Section 80C: Deduction in respect of life insurance premium,
          contribution to PF, children tuition fees, PPF etc.
        </p>
        <br /> */}
          {/* <p>
          A list of other investments that are eligible for deduction under
          section 80C. are enumerated below:
        </p>
        <br />
        <ul>
          <li>
            1) Premium paid for life insurance policy Premium paid on insurance
            policies of self, spouse or child (minor or major). If you pay
            premium for your parents, then you will not be allowed to take
            deduction. If In the case of HUF, the premium paid for any member.
            It can be either a life policy or an endowment policy.
          </li>
          <li>
            2) Any amount invested in the Sukanya Samriddhi Scheme in the name
            of your daughter or any girl child for whom you are a legal
            guardian.
          </li>
          <li>
            3) Contribution to: - Public Provident Fund - Approved
            superannuation fund - Unit-linked Insurance Plan, 1971 - Unit-linked
            Insurance Plan of LIC Mutual Fund - Approved annuity plan of LIC -
            Pension fund which is set up by mutual fund or by the administrator
            or the specified company - National Housing Bank Term Deposit
            Scheme, 2008 - additional account under NPS - Senior Citizens
            Savings Scheme Rules, 2004
          </li>
          <li>
            Subscription to: - National Savings Certificates (VIII issue) -
            units of any mutual fund or from the administrator or the specified
            company - notified deposit scheme of a public sector company that
            provides long-term finance for construction or purchase or
            construction of houses for residential purposes in India or any
            other deposit scheme concerned with housing accommodation or
            planning, improvement or development of cities, towns and villages
            or both. - specified equity shares or debentures or units of mutual
            fund - notified bonds issued by NABARD
          </li>
          <li>
            5) Investment in five-year fixed deposit (FD) of Scheduled Bank or
            Post Office
          </li>
          <li>
            6) Repayment of housing loan principal amount(including stamp duty,
            registration fee and other expenses)
          </li>
          <li>
            7) Payment of tuition fees to any college, school, university or
            other educational institutions within India for full-time education
            for maximum 2 children
          </li>
        </ul>
        <br /> */}
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr className="border ">
                <th className="border brder-black p-8">Sections</th>
                <th className="border brder-black p-8">Income Tax Deduction for FY 2023-24(AY 2024-25)</th>
                <th className="border brder-black p-8">Eligible person</th>
                <th className="border brder-black p-8">Maximum deduction available for FY 2023-24(AY 2024-25)</th>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#final-feature-income-tax-deduction">Section 80C</a></td>
                <td className="border brder-black p-8">Investing into very common and popular investment options like LIC, PPF, Sukanya Samriddhi Account, Mutual Funds, FD, child tuition fee, ULIP, etc</td>
                <td className="border brder-black p-8">Individual
                  Or
                  HUF</td>
                <td className="border brder-black p-8" rowspan="3">Upto Rs 1,50,000</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80ccc">Section 80CCC</a></td>
                <td className="border brder-black p-8">Investment in Pension Funds</td>
                <td className="border brder-black p-8">Individuals</td>

              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80ccd-1">Section 80CCD (1)</a></td>
                <td className="border brder-black p-8">Atal Pension Yojana and National Pension Scheme Contribution</td>
                <td className="border brder-black p-8">Individuals</td>

              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80ccd-1b">Section 80CCD(1B)</a></td>
                <td className="border brder-black p-8">Atal Pension Yojana and National Pension SchemeContribution (additional deduction)</td>
                <td className="border brder-black p-8">Individuals</td>
                <td className="border brder-black p-8">Upto Rs 50,000</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80ccd-2">Section 80CCD(2)</a></td>
                <td className="border brder-black p-8">National Pension SchemeContribution by Employer</td>
                <td className="border brder-black p-8">Individuals</td>
                <td className="border brder-black p-8">Amount Contributed
                  or
                  14% of Basic Salary + Dearness Allowance (in case the employer is Government)
                  10% of Basic Salary+ Dearness Allowance(in case of any other employer)
                  - Whichever is lower</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80d">Section 80D</a></td>
                <td className="border brder-black p-8">Medical Insurance Premium, preventive health checkup and Medical Expenditure</td>
                <td className="border brder-black p-8">Individual
                  Or
                  HUF</td>
                <td className="border brder-black p-8">Upto Rs 1,00,000</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80dd">Section 80DD</a></td>
                <td className="border brder-black p-8">Medical Treatment of a Dependent with Disability</td>
                <td className="border brder-black p-8">Individual
                  Or
                  HUF</td>
                <td className="border brder-black p-8">Normal Disability (atleast 40% or more but less than 80%): Rs 75000/-
                  Severe Disability (atleast 80% or more) : Rs 125000/-</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80ddb">Section 80DDB</a></td>
                <td className="border brder-black p-8">Interest paid on Loan taken for Higher Education</td>
                <td className="border brder-black p-8">Individuals</td>
                <td className="border brder-black p-8">No limit (Any amount of interest paid on education loan)upto 8 assessment years</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80ee">Section 80EE</a></td>
                <td className="border brder-black p-8">Interest paid on Housing Loan</td>
                <td className="border brder-black p-8">Individuals</td>
                <td className="border brder-black p-8">Upto Rs 50,000 subject to some conditions</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80eea">Section 80EEA</a></td>
                <td className="border brder-black p-8">Interest Paid on Housing Loan</td>
                <td className="border brder-black p-8">Individuals</td>
                <td className="border brder-black p-8">Upto Rs 1,50,000/- subject to some conditions</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80eeb">Section 80EEB</a></td>
                <td className="border brder-black p-8">Interest paid on Electric Vehicle Loan</td>
                <td className="border brder-black p-8">Individuals</td>
                <td className="border brder-black p-8">Upto Rs 1,50,000 subject to some conditions</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80g">Section 80G</a></td>
                <td className="border brder-black p-8">Donation to specified funds/institutions. Institutions</td>
                <td className="border brder-black p-8">All Assessee (Individual, HUF, Company, etc)</td>
                <td className="border brder-black p-8">100% or 50% of the Donated amount or Qualifying limit,
                  Allowed donation in cash upto Rs.2000/-</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80gg">Section 80GG</a></td>
                <td className="border brder-black p-8">Income Tax Deduction for House Rent Paid</td>
                <td className="border brder-black p-8">Individual</td>
                <td className="border brder-black p-8">Rs. 5000 per month
                  25% of Adjusted Total Income
                  Rent paid - 10% of Adjusted Total Income
                  - whichever is lower</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80gga">Section 80GGA</a></td>
                <td className="border brder-black p-8">	Donation to Scientific Research & Rural Development</td>
                <td className="border brder-black p-8">All assessees except those who have an income (or loss) from a business and/or a profession</td>
                <td className="border brder-black p-8">100% of the amount donated.
                  Allowed donation in cash upto Rs.10,000/-</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80ggb">Section 80GGB</a></td>
                <td className="border brder-black p-8">Contribution to Political Parties	</td>
                <td className="border brder-black p-8">Companies</td>
                <td className="border brder-black p-8">100% of the amount contributed
                  No deduction available for the contribution made in cash</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80ggc">Section 80GGC</a></td>
                <td className="border brder-black p-8">Individuals on contribution to Political Parties</td>
                <td className="border brder-black p-8">Individual
                  HUF
                  AOP
                  BOI
                  Firm</td>
                <td className="border brder-black p-8">100% of the amount contributed.
                  No deduction available for the contribution made in cash</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80rrb">Section 80RRB</a></td>
                <td className="border brder-black p-8">Royalty on Patents</td>
                <td className="border brder-black p-8">Individuals (Indian citizen or foreign citizen being resident in India)</td>
                <td className="border brder-black p-8">Rs.3,00,000/-
                  Or
                  Specified Income
                  - whichever is lower</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80qqb">Section 80QQB</a></td>
                <td className="border brder-black p-8">Royalty Income of Authors</td>
                <td className="border brder-black p-8">Individuals (Indian citizen or foreign citizen being resident in India)</td>
                <td className="border brder-black p-8">Rs.3,00,000/-
                  Or
                  Specified Income
                  - whichever is lower</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80tta">Section 80TTA</a></td>
                <td className="border brder-black p-8">Interest earned on Savings Accounts</td>
                <td className="border brder-black p-8">Individual
                  Or
                  HUF (except senior citizen)</td>
                <td className="border brder-black p-8">Upto Rs 10,000/-</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80ttb">Section 80TTB</a></td>
                <td className="border brder-black p-8">Interest Income earned on deposits(Savings/ FDs)</td>
                <td className="border brder-black p-8">Individual (60 yrs or above)</td>
                <td className="border brder-black p-8">Upto Rs 50,000/-</td>
              </tr>
              <tr className="border">
                <td className="border brder-black p-8"><a href="#sub-section-80u">Section 80U</a></td>
                <td className="border brder-black p-8">Disabled Individuals</td>
                <td className="border brder-black p-8">Individuals</td>
                <td className="border brder-black p-8">Normal Disability: Rs. 75,000/-<br></br>
                  Severe Disability: Rs. 1,25,000/-</td>
              </tr>
            </table>
          </div>
          <div id="investment-quality-section80c">

            <p className="text-4xl pt-4 font-semibold">
              Investments that Qualify for Deductions under Section 80C
            </p>
            <p className="pt-5 font-semibold">
              Tax saving options under Section 80C
            </p>
            <p className="pt-5">
              There are several options you can choose to save tax under Section 80C of the Income Tax Act. These include:
            </p>
            <ul className="list-decimal pt-3">
              <li>1. Equity Linked Saving Scheme (ELSS)</li>
              <li>2. National Pension Scheme (NPS)</li>
              <li>3. Unit Linked Insurance Plan (ULIP)</li>
              <li>4. Public Provident Fund (PPF)</li>
              <li>5. Sukanya Samriddhi Yojana (SSY)</li>
              <li>6. National Savings Certificate (NSC)</li>
              <li>7. Fixed Deposit (FD)</li>
              <li>8. Employee Provident Fund (EPF)</li>
            </ul>
            <p className="pt-5">Please note that these benefits are available if you have chosen the “Old Tax Regime.”</p>
            <br />
          </div>
          <div id="expenses-quality-section80c">

            <p className="text-4xl font-semibold">
              Expenses that Qualify for Tax Deductions under Section 80C
            </p>
            <ul className="pt-5">
              <li><BsCheck2Circle color={"#0F52BA"} /><p>Life Insurance Premiums</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>Employee Provident Fund (EPF) contributions</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>Public Provident Fund (PPF) investments</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>National Savings Certificate (NSC) investments</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>Equity-Linked Savings Scheme (ELSS) investments</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>Sukanya Samriddhi Yojana (SSY) investments</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>5-Year Fixed Deposit with Banks</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>Senior Citizens Savings Scheme (SCSS) investments</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>Tuition Fees for up to two children</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>Home Loan Principal Repayment</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>Stamp Duty and Registration Charges for a Home</p></li>
            </ul>
            <br />
          </div>

          <div id="final-feature-income-tax-deduction" className="p-10 border-t-2 border-[#0F52BA] shadow-lg">

            <p className="text-4xl font-semibold fon">Features of Income Tax Deduction u/s 80</p>
            <p className="font-medium pt-9 text-xl">Section 80C: This section provides a deduction of up to Rs. 1.5 lakh for investments in specified instruments such as EPF, PPF, NSC, ELSS, tax-saving fixed deposits, etc.</p>
            <p className="pt-9 font-normal text-[20px]">A list of other investments that are eligible for deduction under section 80C. is enumerated below</p>
            <ul>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>1) Premium paid for life insurance policy Premium paid on insurance policies of self, spouse, or child (minor or major). If you pay a premium for your parents, then you will not be allowed to take a deduction. If In the case of HUF, the premium paid for any member. It can be either a life policy or an endowment policy.</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>2) Any amount invested in the Sukanya Samriddhi Scheme in the name of your daughter or any girl child for whom you are a legal guardian.</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>3) Contribution to:<br />
                - Public Provident Fund<br />
                - Approved superannuation fund<br />
                - Unit-linked Insurance Plan, 1971<br />
                - Unit-linked Insurance Plan of LIC Mutual Fund<br />
                - Approved annuity plan of LIC<br />
                - Pension fund which is set up by mutual fund or by the administrator or the specified company<br />
                - National Housing Bank Term Deposit Scheme, 2008<br />
                - additional account under NPS<br />
                - Senior Citizens Savings Scheme Rules, 2004<br />
              </p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>4) Subscription to::<br />
                - National Savings Certificates (VIII issues)<br />
                - units of any mutual fund or from the administrator or the specified company<br />
                - notified deposit scheme of a public sector company that provides long-term finance for construction or purchase or construction of houses for residential purposes in India or any other deposit scheme concerned with housing accommodation or planning, improvement or development of cities, towns, and villages or both.<br />
                - specified equity shares or debentures or units of mutual fund<br />
                - notified bonds issued by NABARD<br />
              </p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>5) Investment in five-year fixed deposit (FD) of Scheduled Bank or Post Office</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>6) Repayment of housing loan principal amount(including stamp duty, registration fee, and other expenses)</p></li>
              <li><BsCheck2Circle color={"#0F52BA"} /><p>7) Payment of tuition fees to any college, school, university or other educational institutions within India for full-time education for maximum 2 children</p></li>
            </ul>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80ccc">

            <p className="text-[25px] font-semibold">Section 80CCC: This section provides a deduction for contributions made to annuity plans of LIC or any other insurer for receiving pension.</p>
            <p className="text-[18px] mt-5 ">Under section 80CCC income tax deduction for the contributions made in specified pension plans can be claimed. The tax deduction can be claimed by individuals (whether resident or non-resident). Maximum permissible deduction under sections 80C, 80CCC, and 80CCD(1) put together is Rs. 1,50,000</p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80ccd-1">

            <p className="text-[25px] font-semibold">Section 80CCD(1): An income tax deduction for contributions made by individuals to eligible NPS</p>
            <p className="mt-5">
              The contribution made to eligible NPS account is tax-deductible up to Rs 1.5 lakhs under section 80CCD(1). The deductions shall be restricted to the amount contributed or the below-given percentage, whichever is less. However, this tax benefit is within the overall ceiling limits of section 80CCE, i.e., Rs. 1,50,000. To know the computation of the exempt amount, eligibility, and much more.
            </p>
            <br />
            <img src="898.webp" alt="" />
          </div>

          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80ccd-1b">
            <p className="text-2xl font-semibold">
              Section 80CCD(1B): Additional Income tax deduction for contributions
              made by Individual to eligible NPS
            </p>
            <br />
            <p>
              Section 80CCD(1B) gives you the additional tax saving benefit of upto
              Rs 50,000 for contributions to the NPS account. It is over and above
              the limits of section 80C,i.e. It shall not be subjected to the
              ceiling limit of Rs. 1,50,000. This section 80CCD has gained so much
              attention as you can invest up to Rs. 2 lakh in an NPS account and
              claim a deduction of the full amount, i.e. Rs. 1.50 lakh under Sec
              80CCD(1) and Rs. 50,000 under Section 80CCD(1B). Click to know more.
            </p>

          </div>

          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80ccd-2">
            <p className="text-2xl font-semibold">
              Section 80CCD(2): Income tax deduction for contributions by an
              employer to eligible NPS
            </p>
            <br />
            <p>
              Your contribution to NPS is deductible under 80CCD(1), and 80CCD(1B),
              and the amount contributed by your employer towards your NPS account
              is also tax-deductible under section 80CCD(2). Read to know more
              details. The deduction amount shall be restricted to 14% of
              salary(Basic salary + DA) in case of central Govt. employees and 10%
              in case of any other employees.
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80d">
            <p className="text-2xl font-semibold">
              Section 80D: Income Tax benefit for medical insurance premium
            </p>
            <br />
            <p>
              Section 80D is amongst the most popular tax saving options. Under this
              tax benefit is admissible for
            </p>
            <br />
            <ol>
              <li>1. Medical Insurance Premiums</li>
              <li>2. Expenditure on Preventive Health Check-up</li>
              <li>3. Other Medical Expenditure</li>
            </ol>
            <br />
            <p>The admissible deductions under this section are as under:</p>
            <br />
            <span className="text-[14px] font-bold">
              In the case of an individual
            </span>
            <ol>
              <li>
                1. Case I – If your self / spouse or dependent children are below 60
                Years of age, then the maximum deduction is Rs. 25,000, and if your
                parents are also below 60 years of age, then the maximum deduction
                is Rs. 25,000. Therefore, the aggregate deduction shall be a maximum
                of Rs. 50,000.
              </li>
              <li>
                2. Case II – If your self / spouse or dependent children are below 60
                Years of age, then the maximum deduction is Rs. 25,000, and if
                parents are 60 years or above, then the maximum deduction is Rs.
                50,000. Therefore, the aggregate deduction shall be a maximum of Rs.
                75,000.
              </li>
              <li>
                3. Case III – If your self / spouse or dependent children are 60 years
                or above, then the maximum deduction is Rs. 50,000, and if your
                parents are also 60 years or above, then the maximum deduction is
                Rs. 50,000. Therefore, the aggregate deduction shall be a maximum of
                Rs. 1,00,000.
              </li>
              <li>
                4. Deduction upto Rs. 5,000 shall be allowed in respect of payment made
                towards preventive health check-up of self, spouse, dependant
                children or dependant parents made during the previous year.
                However, the said deduction of Rs. 5,000 shall be within the overall
                limit of Rs. 25,000 or Rs. 50,000, specified above.
              </li>
            </ol>
            <br />
            <span className="text-[14px] font-bold">In the case of HUF</span>
            <br />
            <p>
              The maximum deduction available to a HUF in respect of premium paid to
              insure the health of any member of the family would be Rs. 25,000, and
              in case any member is a senior citizen, then Rs. 50,000.
            </p>
            <br />
            <span className="text-[14px] font-bold">Notes:</span>
            <ul>
              <li>
                1. You can also claim a deduction of upto Rs. 50,000 under section 80D
                even if you do not have any health insurance policy provided any
                amount is incurred towards: - medical treatment expenditure of self,
                spouse and dependent children (who is of the age of sixty years or
                more and not having medical insurance cover) - medical treatment
                expenditure of any parent(s) (who is of the age of sixty years or
                more and not having medical insurance cover)
              </li>
              <li>
                2. Deduction where the health insurance premium is paid in lump sum:
                Deduction shall be apportioned towards all the years for which the
                premium is paid.
              </li>
            </ul>
          </div>

          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80dd">
            <p className="text-2xl font-semibold">
              Section 80DD: Income Tax Deduction for Medical Treatment of a
              Dependent with Disability
            </p>
            <br />
            <p>
              Section 80DD provides an income tax benefit to the extent of Rs 75,000
              (Where disability is 40% or more but less than 80%) & Rs 1,25,000
              (Where there is severe disability (disability is 80% or more)
              respectively. The benefit can be availed for incurring medical
              expenditures for a disabled dependent relative. For diseases covered,
              documents required and other information, please refer to the detailed
              guide.
            </p>
          </div>

          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80ddb">

            <p className="text-2xl font-semibold">
              Section 80DDB: Income Tax Deduction for Specified Diseases
            </p>
            <br />
            <p>
              The income tax deduction under section 80DDB serves as financial help
              for those suffering from a severe disease or are taking care of such
              dependent family members. The deduction is allowed in respect of the
              amount actually paid for the medical treatment of such disease or
              ailment of the specified persons. The maximum deduction is summarized
              hereunder:
            </p>
            <br />
            <table className="border">
              <tr>
                <td className="border py-3 px-5">Dependant</td>
                <td className="border py-3 px-5">Maximum limit (Rs.)</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">
                  A senior citizen (being a resident individual){" "}
                </td>
                <td className="border py-3 px-5">1,00,000</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">Other than a senior citizen </td>
                <td className="border py-3 px-5">40,000</td>
              </tr>
            </table>
            <br />
            <p>
              No such deduction shall be allowed unless a prescription is obtained
              for such medical treatment from a neurologist, oncologist, urologist,
              haematologist, immunologist, or other specialists, as may be
              prescribed. Read more to know the eligibility and other qualifying
              criteria.
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80e">

            <p className="text-2xl font-semibold">
              Section 80E: Income Tax Deduction for Interest paid on Higher
              Education Loan
            </p>
            <br />
            <p>
              The interest paid on higher education loan taken for self, spouse,
              child or student of whom you are a legal guardian is eligible for
              income tax deduction under section 80E. The tax benefit is available
              for the 8 Assessment Years. i.e., The current year and the next 7
              years, without any maximum limits.
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80ee">

            <p className="text-2xl font-semibold">
              Section 80EE: Income Tax Deduction for Home Loan
            </p>
            <br />
            <p>
              Section 80EE provides an additional deduction of upto Rs. 50,000 in
              respect of the interest on loan taken by an individual to acquire
              residential house property from any financial institution. Read
              insights here. 80EE deduction is in addition to the deduction
              available under section 24 while computing ‘income from house
              property’. The conditions for availing deduction of interest are:
              here.
            </p>
            <br />
            <img src="996.webp" alt="" />
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80eea">
            <p className="text-2xl font-semibold">
              Section 80EEA: Income Tax Deduction for first time home buyers
            </p>
            <br />
            <p>
              This section is Section 80EEA which allows an additional deduction to
              taxpayers for paying interest on a home loan availed by them. While
              Section 24 allowed for interest exemption on home loans up to INR 2
              lakhs, this section allows an additional exemption of Rs 1.5 lakhs to
              home buyers who avail a home loan and pay interest on the loan.
            </p>
            <p>Other conditions for availing deduction of interest:</p>
            <br />
            <img src="232.webp" alt="" />
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80eeb">
            <p className="text-2xl font-semibold">
              Section 80EEB: Income Tax Deduction for repayment of Electronic
              Vehicle Loan
            </p>
            <br />
            <p>
              This section was introduced to promote the purchase of electric
              vehicles among individuals by giving them tax relief on the interest
              paid on loan taken to purchase such vehicles from any financial
              institution from 01/04/2019 to 31/03/2023. The limit of deduction is
              up to Rs 1.5 lakhs.
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80g">

            <p className="text-2xl font-semibold">
              Section 80G: Deduction in respect of donations made to specified funds
              and charitable institutions etc
            </p>
            <br />
            <ul>
              <li>
                Deduction under this section is available to all types of taxpayers
                (individual/ firm/ LLP or any other person).
              </li>
              <li>
                The deduction amount is based on the category in which the fund
                falls, i.e. with or without any qualifying limit. Where the funds
                are subject to qualifying limit, the formula for calculation of
                deduction = Gross Qualifying Amount - Net Qualifying Amount
              </li>
              <li>
                The donation should be made in any mode of payment other than cash
                if it exceeds Rs. 2,000. Donations in kind are not eligible for
                deduction under this section
              </li>
            </ul>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80gg">
            <p className="text-2xl font-semibold">
              Section 80GG: Income Tax Deduction for House Rent Paid
            </p>
            <br />
            <p>
              Deduction under this section is available only to those individuals
              who do not receive benefits by way of HRA(House Rent Allowance) or
              RFA(Rent Free Accommodation). Deduction u/s 80GG can be claimed to the
              extent of Rs 5,000 per month for the house rent paid.
            </p>
            <p>The admissible deduction shall be least of the following:</p>
            <ul>
              <li>a. Rs. 5,000 per month</li>
              <li>b. 25% of the adjusted total income*; or</li>
              <li>c. Rent paid less 10% of total income*</li>
            </ul>
            <br />
            <p>
              *Adjusted Total Income = total income excluding short-term capital
              gains under section 111A, long term capital gains, income under
              section 115A and deductions under sections 80C to 80U
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80gga">
            <p className="text-2xl font-semibold">
              Section 80GGA: Income Tax Deduction for Donation towards Scientific
              Research & Rural Development
            </p>
            <br />
            <p>
              Donations for Scientific Research or Rural Development can avail
              deduction under section 80GGA. Assessee having an income from
              Business/Profession cannot avail of this benefit. Under this section,
              the whole amount is allowed as a deduction without any upper limit.
              However, cash donations of more than Rs. 2,000 are not allowed. Read
              More about Section 80GGA
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80ggb">
            <p className="text-2xl font-semibold">
              Section 80GGB: Income Tax Deduction for donation to Political Parties
            </p>
            <br />
            <p>
              Donations made by an Indian company to any political party or an
              electoral trust shall be eligible for deduction under this section.
              However, no deduction shall be permissible in respect of cash
              donations.
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80ggc">

            <p className="text-2xl font-semibold">
              Section 80GGC: Income Tax Deduction in respect of contributions given
              by any person to Political Parties
            </p>
            <br />
            <p>
              Any person other than an Indian Company can avail deduction under
              section 80GGC of the total amount paid to a political party or
              electoral trust, except the cash donations. However, local authorities
              and every artificial judicial (wholly or partly funded by the
              government) person cannot claim a deduction under this section.
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80rrb">
            <p className="text-2xl">
              Section 80RRB: Income Tax Deduction for Royalty on Patents
            </p>
            <br />
            <p>
              A resident of India and an individual patentee (true and first
              inventor of the invention, including co-patentee) can claim a
              deduction under section 80RRB in respect of patents registered on or
              after 01.04.2003. The deduction amount shall be the lower of:
            </p>
            <ul className="mt-3">
              <li>
                <BsCheck2Circle color="#0F52BA" />
                100% of Royalty Income from patent
              </li>
              <li>
                <BsCheck2Circle color="#0F52BA" />
                Rs. 3,00,000
              </li>
            </ul>
            <br />
            <p>
              For claiming the benefit under this section patent must be registered
              under the Patents Act 1970. Read More about Section 80RRB
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80qqb">

            <p className="text-2xl font-semibold">
              Section 80QQB: Income Tax Deductions for Royalty Income of Authors
            </p>
            <br />
            <p>
              An author (Resident of India or resident but not ordinarily resident
              in India), including the joint author of a book, can claim a deduction
              under Sec 80QQB. The deduction amount shall be as follows:
            </p>
            <p className="mt-3 ">
              a. In the case of lump sum payment - Total amount of royalty income
              subject to a maximum of Rs. 3,00,000.
            </p>
            <br />
            <p>
              b. In other cases - Total amount of such income subject to a maximum
              of 15% of the value of books sold during the previous year.
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80u">

            <p className="text-2xl font-semibold">
              Section 80U: Income Tax Deduction for Disabled Individuals
            </p>
            <br />
            <p>
              resident individual certified by the medical authority or a government
              doctor to be a person with a disability (having a disability of 40% or
              more) can claim a deduction of Rs. 75,000 under this section. In the
              case of a person with a severe disability (having a disability of 80%
              or more ), the quantum of deduction allowed is Rs. 1,25,000. It is a
              fixed deduction and is not based on actual expenses.
            </p>
          </div>

          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80tta">
            <p className="text-2xl font-semibold">
              Section 80TTA: Deduction in respect of interest on deposits in Savings
              Account
            </p>
            <br />
            <p>
              Section 80TTA allows deduction in respect of interest income on
              deposits in Savings Bank Accounts of Banks, Co-Operatives Banks or
              Post Office. The quantum of deduction allowed under this section is
              Rs. 10,000 or the actual interest earned, whichever is lower. Both
              individual and HUF can avail this deduction (Other than Resident
              Senior Citizen). This deduction is not available on interest income
              from fixed deposits
            </p>
          </div>
          <div className="mt-10 p-10 border-t-2 border-[#0F52BA] shadow-lg" id="sub-section-80ttb">

            <p className="text-2xl font-semibold">
              Section 80TTB: Deduction in respect of interest from deposits held by
              Senior Citizens
            </p>
            <br />
            <p>
              Section 80TTB allows a deduction upto Rs 50,000 in respect of interest
              income from deposits held by resident senior citizens (age 60 years or
              more) with a banking company, a post office, cooperative, society
              engaged in the banking business etc. Consequently, the limit of tds
              deduction u/s 194A for senior citizens has been raised to Rs. 50,000.
              However, no deduction under section 80TTA shall be allowed in these
              cases. Note that the senior citizen aged 75 years and above, earning
              only pension and interest income, is exempted from ITR filing as tax
              shall be deducted at source by the banks.
            </p>
          </div>
        </div>
        <br />
        <div id="faqs" className="p-10 xs:p-4 mb-10 bg-100 bg-[#C6DBFA]">
          <h1 className="font-bold text-4xl text-900 text-[#1E1E24]">
            Frequently Asked Questions
          </h1>
          {section80.map((item) => {
            return (
              <>
                <div key={item.id}>
                  <p className="text-900 text-[#1E1E24] font-bold text-xl pt-10 pb-5 flex items-center gap-2.5 xs:text-lg xs:leading-6">
                    <span className="text-base xs:mt-2 text-center flex items-center justify-center bg-900 bg-[#1E1E24] question p-2 xs:leading-[100%] h-6 w-6  xs:text-sm xs:rounded-[100%] rounded-full  font-normal text-white">
                      Q
                    </span>
                    {item.question}
                  </p>
                  <p className="text-base text-justify text-sm">Ans: {item.answer}</p>
                  {item.list ? (
                    <ul className="pt-5 listQuestion">
                      {item.list.map((li) => {
                        return <li>&#x2713; {li}</li>;
                      })}
                    </ul>
                  ) : null}
                  {/* <div className='border-solid border border-sky-900 my-4 w-1/4'></div> */}
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className='fix-watsapp-icon'>
      <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default Section80;
