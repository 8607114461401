import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ButtonLoader from "../ButtonLoader";
import { BsWhatsapp } from "react-icons/bs";

const FileYourReturn = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const API = process.env.REACT_APP_API_URL;
  const SHEET_API = process.env.REACT_APP_SHEET_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const date = new Date().toDateString();

    let data = {
      "date": date,
      "event_name": "Contact",
      "name": name,
      "email": email,
      "phone_number": phone,
      "ref": "File your Return"
    }

    try {
      if (phone.length === 10) {
        const result = await axios.post(API, data,
          {
            headers: {
              'Content-Type': "text/plain;charset=utf-8"
            },
            'mode': "no-cors"
          }).then((resp) => {
            if (resp.status === 200) {

              setLoading(false);
              setName('');
              setEmail('');
              setPhone('');
              Swal.fire(
                'Thank You For Inquiry , we will contact You soon on Whatsapp',
                'Your form is succesfully submitted!',
                'success'
              )
            }


          }).catch(error => {

            setLoading(false);
            Swal.fire(
              "Something went wrong",
              "Try after few minutes",
              "error"
            );

          });
        // const googleSheet = await axios.post(SHEET_API, formData);

        // if (result.status === 200 && googleSheet.status === 200) {
        console.log("data", result)

      } else {
        Swal.fire(
          "Something went wrong",
          "Mobile Number Not Valid",
          "error"
        );
      }


    } catch (error) {
      setLoading(false);

      Swal.fire(error.message, "Something went wrong", "error");
    }
  };

  return (
    <div className="main-containerr">
      <div className="bg-[#F5F6F7] xl:h-full 2xl:h-full h-auto">
        <div className="px-6 py-8  w-4/5 mx-auto xs:w-full xs:px-0 xs:py-0 sm:w-full md:w-full xl:w-9/12 2xl:w-9/12 xl:h-[auto] 2xl:h-[auto]">
          <div className=" py-12 grid grid-cols-2 gap-9 xs:py-6 sm:py-6 md:py-8 xs:px-4 sm:px-4 md:px-6 xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 md:grid-cols-1">
            <div>
              <h1 className="text-[#0c2343] text-3xl font-normal xs:text-xl sm:text-xl md:text-xl">
                Hire Expert CA's for <br />
                <p className="my-2 	text-[40px] font-extrabold xs:text-2xl sm:text-2xl md:text-2xl xs:my-1 sm:my-1 md:my-1">
                  Filing your
                  <span
                    className="text-[#1D70ED]"
                    style={{ lineHeight: "50px" }}
                  >
                    {" "}
                    ITR with Tax2clear{" "}
                  </span>{" "}
                </p>
                is easy and hassle-free!
              </h1>
              <br />
              <p>
                Take a break and let our team of experts handle your tax filing
                process. We are committed to helping you save time and money by
                maximizing your tax savings."
              </p>
              <div className="mt-8">
                <div className="flex items-center gap-3">
                  <img className="w-6" src="tick-icon.webp" />
                  <h3 className="font-semibold text-[18px]">
                    Maximum Tax Saving guarantee
                  </h3>
                </div>
                <div className="pt-3 flex items-center gap-3">
                  <img className="w-6" src="tick-icon.webp" />
                  <h3 className="font-semibold text-[17px]">
                    Human Connection
                  </h3>
                </div>
                <div className="pt-3 flex items-center gap-3">
                  <img className="w-6" src="tick-icon.webp" />
                  <h3 className="font-semibold text-[18px]">
                    On demand tax filling supports
                  </h3>
                </div>
                <br />
              </div>
            </div>

            <form
              onSubmit={handleSubmit}
              className="w-4/5  h-[500px] mx-auto bg-[#ffffff80] box-shadow rounded-[30px] py-6 px-8 xs:px-4 border-b border-[#0F52BA] xs:w-full sm:w-full md:w-full"
            >
              <h1 className="py-2 text-xl font-bold text-center">
                File your Income tax Return
              </h1>
              <div className="py-4">
                <input
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  required
                  className="w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                />

                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  required
                  className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                />
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  type="number"
                  placeholder="Mobile Number"
                  value={phone}
                  maxLength="10"
                  required
                  className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                />
              </div>
              <p>
                By submitting the form, you agree to our{" "}
                <a href="https://sites.google.com/view/Tax2clear/terms-condition" target="_blank" className="text-[#0F52BA] hover:underline">
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a href="https://sites.google.com/view/Tax2clear/privacy-policy" target="_blank" className="text-[#0F52BA] hover:underline">
                  Privacy Policy
                </a>{" "}
              </p>
              <button
                type="submit"
                className="text-white bg-[#0F52BA] w-full rounded-[4px] mt-5  py-2 text-center"
              >
                {loading === true ? <ButtonLoader /> : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <br />
      <div className="w-3/4 mx-auto">
        <p>
          Its is mandatory for individuals, NRIs, partnership firms, LLPs,
          companies and Trust to file income tax returns each year. <br />{" "}
          <br /> Individuals and NRIs are required to file income tax return, if
          their income exceeds Rs.2.5 lakhs per annum. Proprietorship firms and
          partnership firms are required income tax return - irrespective of
          amount of income or loss.
          <br /> <br />
          Tax2clear provides income tax e filing services with dedicated Tax
          Expert support. Upload your Form-16, sit back and relax. Our experts
          will file your income tax return and provide you the acknowledgement
          within 1 - 2 business days.
        </p>
        <br />
        <p className="text-2xl font-semibold">
          Penalty for Late Filing Income Tax Return
        </p>
        <br />
        <p>
          Taxpayers who do not file their income tax return on time are subject
          to penalty and charged an interest on the late payment of income tax.
          Also, the penalty for late filing income tax return on time has been
          increased recently. The penalty for late filing income tax return is
          now as follows:
        </p>
        <br />

        <ul className="list-disc list-inside">
          <li className="font-semibold text-[16px] p-2">
            Late Filing between 1st August and 31st December - Rs.5000
          </li>
          <li className="font-semibold text-[16px] p-2">
            Late Filing After 31st December - Rs.10,000
          </li>
          <li className="font-semibold text-[16px] p-2">
            Penalty if taxable income is less than Rs.5 lakhs - Rs.1000
          </li>
        </ul>
        <br />
        <p className="text-2xl font-semibold">Income Tax Return Due Date</p>
        <br />
        <p>
          The due date for income tax return filing is 31st July of every year
          for individual taxpayers. The due date for income tax return filing
          for companies and taxpayer requiring tax audit is 30th September.
          Section 44AD of the Income Tax Act deals with tax audit under Income
          Tax Act.
        </p>
        <br />
        <p className="text-2xl font-semibold">Business</p>
        <br />
        <p>
          In case of a business, tax audit would be required if the total sales
          turnover or gross receipts in the business exceeds Rs.1 crore in any
          previous year.
        </p>
        <br />
        <p className="text-2xl font-semibold">Professional</p>
        <br />
        <p>
          In case of a profession or professional, tax audit would be required
          if gross receipts in the profession exceeds Rs.50 lakhs in any of the
          previous year.
        </p>
        <br />
        <p className="text-2xl font-semibold">Presumptive Taxation Scheme</p>
        <br />
        <p>
          If a person is enrolled under the presumptive taxation scheme under
          section 44AD? and total sales or turnover is more than Rs. 2 crores,
          then tax audit would be required.
        </p>
        <br />
      </div>
      <div className='fix-watsapp-icon'>
        <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default FileYourReturn;
