import React from "react";
import { HashLink } from "react-router-hash-link";
import { BsCheck2Circle } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs"
import tdsFaqs from "../utils/tdsFaqs.json";
const TDS = () => {
  const content = [
    {
      id: 1,
      content: "What is TDS?",
      path: "#whattds",
    },
    {
      id: 2,
      content:
        "What is the need of tax deduction/implementing the system of TDS?",
      path: "#needoftds",
    },
    {
      id: 3,
      content:
        "Who is liable to deduct TDS and Who is the TDS Deductor and TDS Deductee?",
      path: "#whoistdudcust",
    },
    {
      id: 4,
      content: "What type of payments are covered under the TDS scheme?",
      path: "#typepaymentcoverd",
    },
    {
      id: 5,
      content: "When TDS is to be deducted?",
      path: "#whentds",
    },
    {
      id: 6,
      content: "What happens after TDS deduction?",
      path: "#aftertds",
    },
    {
      id: 7,
      content:
        "What is the due date of depositing TDS amount with the government?",
      path: "#duedate",
    },
    {
      id: 8,
      content: "How to deposit TDS?",
      path: "#howdeposite",
    },
    {
      id: 9,
      content: "What is the Penalty on default in making TDS payment?",
      path: "#penalty",
    },
    {
      id: 10,
      content: "When TDS is not deducted (either in whole or in part)?",
      path: "#whentdsnotdeducted",
    },
    {
      id: 11,
      content: "When TDS is deducted but not deposited with the government?",
      path: "#whendeductbutnotdeposite",
    },
    {
      id: 12,
      content: "What is a TDS return?",
      path: "#tdsreturn",
    },
    {
      id: 13,
      content: "What is the due date of filing TDS return?",
      path: "#duedatetdsreturn",
    },
    {
      id: 14,
      content: "What is the Penalty on making default in filing TDS return?",
      path: "#penaltytdsreturn",
    },
    {
      id: 15,
      content: "What is a TDS certificate?",
      path: "#tdscertificate",
    },
    {
      id: 16,
      content: "What are the different types of TDS certificates?",
      path: "#typeofcertificate",
    },
    {
      id: 17,
      content: "What is Form 26AS?",
      path: "#form26AS",
    },
    {
      id: 18,
      content: "What is TAN?",
      path: "#whattan",
    },
    {
      id: 19,
      content: "What is the difference between TAN and PAN?",
      path: "#tavVspan",
    },
    {
      id: 20,
      content:
        "What to do in case of mismatch of TDS details in Form 26AS and actual TDS deducted?",
      path: "#mismatchdetails",
    },
    {
      id: 21,
      content: "How to claim the credit of TDS?",
      path: "#claimcredit",
    },
    {
      id: 22,
      content: "How can one get exemption from TDS?",
      path: "#tdsexemption",
    },
    {
      id: 23,
      content: "Frequently Asked Questions",
      path: "#faqs",
    },
  ];

  const tdsProvision = [
    {
      id: 1,
      section: "Section 192",
      payment: "TDS on Salary",
      payer: "Any Person",
      payee: "Employee(R or NR)",
      Rate: "Applicable income tax slab rates",
      exemption:
        "Basic GTI exemption limit of Rs.250000 or Rs.300000 or Rs.500000 as the case may be.",
    },
    {
      id: 2,
      section: "Section 192A",
      payment: "TDS on Premature Withdrawal from Provident Fund	",
      payer: "Any Person",
      payee: "Employee",
      Rate: "10% (If no PAN then MMR i.e. 35.535%)	",
      exemption: "Amount is less than Rs.50000",
    },
    {
      id: 3,
      section: "Section 193",
      payment: "TDS on Interest on Securities",
      payer: "Any Person",
      payee: "Any Resident Person",
      Rate: "10%",
      exemption:
        "Upto Rs.5000 in a financial year (FY) for Individual/HUF in the case of Debentures.",
    },
    {
      id: 4,
      section: "Section 194",
      payment: "TDS on Dividends",
      payer: "Domestic Company",
      payee: "Resident Person",
      Rate: "10%",
      exemption: "Upto Rs.2500 in a FY (for Individual)",
    },
    {
      id: 5,
      section: "Section 194A",
      payment: "TDS on Interest (Other than Interest on Securities)",
      payer:
        "Any Person(Other than Individual/HUF not liable to tax audit in last PY)",
      payee: "Resident Person",
      Rate: "10%",
      exemption:
        "Up to Rs.10,000 (for payments made by banks, cooperative banks or on post office deposits) and up to Rs.5000 (for other cases). [In case of a senior citizen, the limit of Rs 10,000 shall increase to Rs. 50,000 w.e.f. 1.4.2018]",
    },
    {
      id: 6,
      section: "Section 194B",
      payment: "TDS on Winnings from Lottery or Crossword Puzzles",
      payer: "Any Person",
      payee: "Any Person",
      Rate: "30%",
      exemption: "Up to Rs.10000",
    },
    {
      id: 7,
      section: "Section 194BB",
      payment: "TDS on Winnings from Race Horses",
      payer: "Any Person",
      payee: "Any Person",
      Rate: "30%",
      exemption: "Up to Rs.10000",
    },
    {
      id: 8,
      section: "Section 194C",
      payment: "TDS on Payment to Contractors",
      payer:
        "Any Person(Other than Individual/HUF not liable to tax audit in last PY)",
      payee: "Any Resident Person",
      Rate: "1% (for Individual/HUF) 2% (other person)",
      exemption:
        "Up to Rs.30000 for individual payment. Up to Rs.100000 for total amount during a FY.",
    },
    {
      id: 9,
      section: "Section 194D",
      payment: "TDS on Insurance Commission	",
      payer: "Insurance Company	",
      payee: "Resident Agent",
      Rate: "10% (if deductor is company) 5% (if deductor is resident person other than company)",
      exemption: "Up to Rs.15000 during FY",
    },
    {
      id: 10,
      section: "Section 194DA",
      payment: "TDS on Payment in Respect of Life Insurance Policy",
      payer: "Any Person",
      payee: "Any Resident Person",
      Rate: "1%",
      exemption: "up to Rs.100000",
    },
    {
      id: 11,
      section: "Section 194E",
      payment:
        "TDS on Payments to Non-Resident Sportsmen or Sports Association",
      payer: "Any Person",
      payee: "NR-Sportsmen-Sports Association-Entertainer",
      Rate: "20%",
      exemption: "No exemption limit.",
    },
    {
      id: 12,
      section: "Section 194EE",
      payment: "TDS on Payment for Deposit Under NSS",
      payer: "Any Person",
      payee: "Any Person",
      Rate: "10%",
      exemption: "Upto Rs.2500",
    },
    {
      id: 13,
      section: "Section 194G",
      payment: "TDS on Commission on Sale of Lottery Tickets",
      payer: "Any Person",
      payee: "Any Person",
      Rate: "5%",
      exemption: "Upto Rs.15000",
    },
    {
      id: 14,
      section: "Section 194H",
      payment: "TDS on Commission or Brokerage",
      payer:
        "Any Person(Other than Individual/HUF not liable to tax audit in last PY)",
      payee: "Any Resident Person",
      Rate: "5%",
      exemption: "Up to Rs.15000",
    },
    {
      id: 15,
      section: "Section 194I",
      payment: "TDS on Rent",
      payer:
        "Any Person(Other than Individual/HUF not liable to tax audit in last PY)",
      payee: "Any Resident Person",
      Rate: "2% (on rent paid for use of machinery, plant or equipment) 10% (other cases)",
      exemption: "Up to Rs. 240000 during the FY",
    },
    {
      id: 16,
      section: "Section 194IA",
      payment:
        "TDS on Payment on Transfer of Immovable Property (Not Being an Agricultural Land)	",
      payer: "Any Person (Other than person referred to in section-194LA)",
      payee: "Any Resident Person",
      Rate: "1%",
      exemption: "Less than Rs.50,00,000",
    },
    {
      id: 17,
      section: "Section 194IB",
      payment: "TDS on Payment of Rent by Certain Individuals or HUF",
      payer: "Individual & HUF(Other than covered u/s 194 I)",
      payee: "Any Resident Person",
      Rate: "5%",
      exemption: "Upto Rs.50000 per month",
    },
    {
      id: 18,
      section: "Section 194 IC",
      payment: "TDS on Payment Made Under Specified Agreement",
      payer: "Any Person",
      payee: "Any Resident Person",
      Rate: "10%",
      exemption: "No exemption limit",
    },
    {
      id: 19,
      section: "Section 194J",
      payment: "TDS on Fees for Professional or Technical Services",
      payer:
        "Any Person(Other than Individual/HUF not liable to tax audit in last PY)",
      payee: "Any Resident Person",
      Rate: "10% 2% (in case of payment of fees for technical service or made to the business of operating a call centre or royalty for cinematographic films.",
      exemption:
        "Up to Rs.30000 during FY (the separate limit for each kind of payment)",
    },
    {
      id: 20,
      section: "Section 194LA",
      payment:
        "TDS on Payment of Compensation on Acquisition of Certain Immovable Property",
      payer: "Any Person",
      payee: "Any Resident Person",
      Rate: "10%",
      exemption: "Upto Rs. 2,50,000",
    },
    {
      id: 21,
      section: "Section 195",
      payment:
        "TDS on Other Payments Made to NR (Not Company) or Foreign Company",
      payer: "Any Person",
      payee: "NR or Foreign Company",
      Rate: "Rate as specified in Act or DTAA",
      exemption: "No exemption limit",
    },
    {
      id: 22,
      section: "Section 194O",
      payment: "TDS on Payments Made to e-commerce Participants",
      payer: "An e-Commerce operator",
      payee: "An e-Commerce participant",
      Rate: "5%",
      exemption: "Rs 5 lakh is set only for resident individuals and HUF.",
    },
  ];

  return (
    <div className="main-containerr">
      <div className="tds mt-5">
        <p className="text-4xl font-bold">
          The Most Comprehensive Guide On TDS In India
        </p>
        <br />
        <p>
          Containing All the Updated TDS Sections that You Should Know for current
          FY 2022-23(AY 2023-24)
        </p>
        <br />
        <p>
          When it comes to tax deduction at source, there are lot of confusions.
          The first question being, what exactly is TDS? And when we understand
          that, the next question which pops-up, how many times do I have to pay
          tax? Why TDS is deducted from my salary? When is the TDS deducted? How
          the amount of TDS deduction on salary is calculated? What is the need of
          TDS and the list is, literally endless!
        </p>
        <br />
        <p>
          So, to clear all your queries and provide you with all the possible
          solutions, we bring you a series on Tax Deducted at Source or TDS. In
          this series, we will explain all the income tax laws related to TDS
          which hits our life in a daily manner.
        </p>
        <br />
        <p>But before that, let us beat the heat out of some TDS basics!</p>
        <br />
        <div className="contentBox">
          <h3 className="text-2xl font-semibold">Contents</h3>
          <ul>
            {content.map((item) => {
              return (
                <HashLink to={item.path} smooth key={item.id}>
                  <li className="font-bold"># {item.content}</li>
                </HashLink>
              );
            })}
          </ul>
        </div>

        <br />

        <div id="whattds">
          <p className="text-2xl font-semibold">What is TDS?</p>
          <br />
          <p>
            TDS is Tax Deducted at Source. The meaning of TDS is clear from its
            name i.e. income is taxed at the point of it’s generation itself. In
            simple words, TDS is retaining a part of income (which you receive) as
            tax and depositing it with the Income Tax Department on your behalf.
            So, it’s like pay as and when you earn. TDS can also be commonly said
            as Withholding Tax.
          </p>
          <br />
          <p>
            Further, it is to be noted that TDS doesn’t exempt you from the
            requirement of filing of ITR. If your income exceeds the basic
            exemption limit then irrespective of the fact whether TDS has been
            deducted or not you have to file ITR. Let us understand it with the
            help of below example where TDS is calculated on salary income.
          </p>
          <br />
          <p>
            For e.g. Let’s say you earn a total salary of Rs.6,00,000 (i.e.
            Rs.50,000 per month) and have no other income in F.Y 2022-23. Now, as
            your GTI exceeds basic exemption limit (of Rs.2,50,000), tax liability
            will occur at the time of filing of your return. Now, to understand
            the concept of TDS, we will consider 2 cases: One when TDS is deducted
            and one without TDS u/s 192.
          </p>
          <br />
          <p>
            <i>
              Note : Since, the income source is “Salary “ so in this case TDS
              will be deducted under Section 192. Further, Tax Calculation is done
              without giving the benefit of standard deduction of Rs. 50000.
            </i>
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr>
                <th className="border py-3 px-5">Without TDS (Situation 1)</th>
                <th className="border py-3 px-5">With TDS (Situation 2)</th>
              </tr>
              <tr>
                <td>
                  <table className="">
                    <tr>
                      <td className="border py-3 px-5">Salary Income</td>
                      <td className="border py-3 px-5">6,00,000</td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">Total Income </td>
                      <td className="border py-3 px-5">6,00,000 </td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">Gross Tax Liability </td>
                      <td className="border py-3 px-5">32,500 </td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">
                        <i>Cess @ 4%</i>
                      </td>
                      <td className="border py-3 px-5">1300</td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5"></td>
                      <td className="border py-3 px-5"></td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">
                        Tax Payable at the time of filing the return
                      </td>
                      <td className="border py-3 px-5">33,800 </td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">Tax Refund</td>
                      <td className="border py-3 px-5">-</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table className="">
                    <tr>
                      <td className="border py-3 px-5">Salary Income</td>
                      <td className="border py-3 px-5">6,00,000</td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">Total Income </td>
                      <td className="border py-3 px-5">6,00,000 </td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">Gross Tax Liability </td>
                      <td className="border py-3 px-5">32,500 </td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">
                        <i>Cess @ 4%</i>
                      </td>
                      <td className="border py-3 px-5">1300</td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">Less: TDS deducted</td>
                      <td className="border py-3 px-5">33,800</td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">
                        Tax Payable at the time of filing the return
                      </td>
                      <td className="border py-3 px-5">-</td>
                    </tr>
                    <tr>
                      <td className="border py-3 px-5">Tax Refund</td>
                      <td className="border py-3 px-5">-</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          <br />
          <p>
            In Situation 2 (when TDS is deducted), your employer will deduct the
            TDS as Rs.33800/12 months, which comes to Rs.2817 (round-off) per
            month. This is how TDS is calculated on salary. So, every month Rs.
            2817 is deducted from your salary income and deposited to the
            government as the tax by your employer.
          </p>
          <br />
          <p>
            So from the above example, you can easily understand that how TDS
            helped to evenly spread your total income tax liability during the
            year. And even in some cases, makes you eligible for a refund.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="needoftds">
          <p className="text-2xl">
            What is the need of tax deduction/implementing the system of TDS?
          </p>
          <br />
          <p>
            The need for implementing the system of TDS is because of various
            benefits it possesses. They have been summarized as below:
          </p>
          <br />
          <p>
            From the government’s point of view, the need for implementing TDS
            arises in order to avoid tax evasion. As per the TDS scheme, the
            liability to deposit the tax is on payer of income and not the
            receiver. Thus, it becomes easy for government to collect taxes and
            keep a check on payers rather than controlling a large number of
            receiver’s of income.
          </p>
          <br />
          <p>
            Other one being that government gets the steady source of revenue
            round the year rather than waiting till the time of filing returns.
          </p>
          <br />
          <p>
            From a common man’s point of view like you and me, under the system of
            TDS the tax is deducted at various point when you receive income
            during the year itself. Therefore, you don’t need to pinch your pocket
            and pay the tax in one go.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="whoistdudcust">
          <p className="text-2xl">
            Who is liable to deduct TDS and Who is the TDS Deductor and TDS
            Deductee?
          </p>
          <br />
          <p>
            There are basically two parties involved in any transaction one is the
            person making payment and other being the person receiving the
            payment. The government has covered certain categories of persons
            making the payment under TDS Scheme. As per this scheme the person
            making the payment is liable to deduct the tax at a specified rate and
            deposit it with the government.
          </p>
          <br />
          <p className="text-[12px] font-bold">So we can say –</p>
          <ul>
            <span className="flex items-center gap-3 font-bold">
              <BsCheck2Circle color="#0F52BA" />
              <p>TDS Deductor:</p>
            </span>
            <li>
              Is the one who is liable to deduct a specified amount as TDS from
              the amount which is to be given to the receiver/ payee/ deductee.
              Deductor is also referred as Payer (because he/she deposits this
              part of tax to the government on payee’s behalf)
            </li>
            <br />
            <span className="flex items-center gap-3 font-bold">

              <BsCheck2Circle color="#0F52BA" />
              <p>  TDS Deductee:</p>
            </span>
            <li>
              Is the one who is receiving the balance money after deduction of tax
              at source and deposited on his/her behalf to the government by the
              Deductor. Deductee is also commonly referred as Payee.
            </li>
            <br />
          </ul>
          <br />
          <p>
            Now, as per the Income Tax Act, Person means Individual or HUF or Firm
            or AOP or BOI or Company or Corporation or Government or any local
            authority or Non-Resident etc. The deductor/deductee can be any of the
            person depending on the section and rules applicable on that
            transaction.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="typepaymentcoverd">
          <p className="text-2xl font-semibold">
            What type of payments are covered under the TDS scheme?
          </p>
          <br />
          <p>
            There are various types of payments defined under the Income Tax Act
            on which tax deduction provisions are attracted like salary payments
            (to residents/non-residents), other than salary payments like
            interest, dividends, rent, commission, lottery winnings, royalty etc.
          </p>
          <br />
          <p>
            For your better understanding, we have made a summary table of all the
            sections with their number, name, rate of tax deduction and the
            threshold limit (which simply means that upto this limit there is no
            requirement for deducting TDS).
          </p>
          <br />
          <p>
            In the below table, you can simply click on the relevant section to
            know more about it in-detail.
          </p>
          <br />
          <p className="text-[14px] font-bold">
            Brief Overview of all the TDS Provisions for F.Y 2022-23 (2023-24)
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr>
                <th className="border py-3 px-5">S.No.</th>
                <th className="border py-3 px-5">TDS Section </th>
                <th className="border py-3 px-5">Nature of Payment </th>
                <th className="border py-3 px-5">Payer (Deductor) </th>
                <th className="border py-3 px-5">Payee (Deductee) </th>
                <th className="border py-3 px-5">Rate of TDS </th>
                <th className="border py-3 px-5">Exemption Limit</th>
              </tr>
              {tdsProvision.map((item) => {
                return (
                  <tr>
                    <td className="border py-3 px-5">{item.id}</td>
                    <td className="border py-3 px-5">{item.section}</td>
                    <td className="border py-3 px-5">{item.payment}</td>
                    <td className="border py-3 px-5">{item.payer}</td>
                    <td className="border py-3 px-5">{item.payee}</td>
                    <td className="border py-3 px-5">{item.Rate}</td>
                    <td className="border py-3 px-5">{item.exemption}</td>
                  </tr>
                );
              })}
              <tr></tr>
            </table>
          </div>
          <br />
          <p>*R means Resident and NR means Non-Resident</p>
          <br />
          <p>
            **Note: In the above table, unless other rate like MMR is specified,
            if PAN is not available then TDS rate will become 20%.
          </p>
          <br />
          <p>
            Also, now you can easily compute actual TDS for many sections using
            our Online TDS Calculator Tool, that too for FREE.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="whentds">
          <p className="text-2xl">When TDS is to be deducted?</p>
          <br />
          <p>
            There are majorly 2 situations when the deductor (payer) is required
            to deduct tax, which are:
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>

                At the time of making payment, or
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                At the time of credit of income to the account of payee (receiver)

                or actual payment (in cash, cheque, draft or other mode),
              </p>
            </li>
          </ul>
          <br />
          <p>whichever happens earlier.</p>
          <br />
          <p>
            It is to be noted that in some sections, TDS liability arises only at
            the time of payment and in the rest it happens according to the
            payment or credit whichever is earlier . For example, u/s 192 i.e. TDS
            on salary, TDS is deducted at the time of payment . Whereas u/s 194J
            i.e TDS on professional services, TDS is deducted at the time of
            credit or payment whichever is earlier.
          </p>
          <br />
          <p>
            Therefore, to know about respective section, please refer the detailed
            discussion of each section by clicking on the relevant section in the
            summary table above.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="aftertds">
          <p className="text-2xl">What happens after TDS deduction?</p>
          <br />
          <p>
            As soon as your TDS is deducted, an entire chain of process starts
            which is described briefly below:
          </p>
          <br />
          <img src="858.svg" alt="" />
          <br />
          <ul>
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Deposit TDS with Government:
              </p>
            </span>
            <li>
              {" "}
              After deducting TDS, the deductor is required to deposit it with the
              government within the specified time period(7th day of the next
              month, 30th April incase of March).
            </li>
            <br />

            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                File TDS Return:
              </p>
            </span>
            <li>
              {" "}
              The deductor is also required to file TDS returns (quarterly) where
              he declares the financial details of all the deductee for whom TDS
              has been deducted during the quarter.
            </li>
            <br />
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Updation of 26AS:
              </p>
            </span>
            <li>
              {" "}
              When the government receives the amount of TDS, Form 26AS of the
              deductee gets updated with Tax deposited details.
            </li>
            <br />
            <span className="font-bold flex items-center gap-3">
              <BsCheck2Circle color="#0F52BA" /><p>
                Issue of TDS Certificates & ITR Filing:
              </p>
            </span>
            <li>
              {" "}
              After that, when the deductee (or payee) files ITR in the relevant
              financial year, he/she considers his Form 26AS and claim the credit
              of TDS available in it.
            </li>
            <br />
          </ul>
          <br />
          <p>
            Which ultimately reduces his total tax liability to be paid via income
            tax return (and in some cases result in tax refund/TDS refund as
            well).
          </p>
          <p>
            Now you must be having many question regarding the process discussed
            above. Just relax! The next set of questions will solve all your
            queries.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="duedate">
          <p className="text-2xl">
            Who is liable to deduct TDS and Who is the TDS Deductor and TDS
            Deductee?
          </p>
          <br />
          <p>
            There are basically two parties involved in any transaction one is the
            person making payment and other being the person receiving the
            payment. The government has covered certain categories of persons
            making the payment under TDS Scheme. As per this scheme the person
            making the payment is required to deduct the tax at a specified rate
            and deposit it with the government.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div>
          <p className="text-2xl">
            What is the due date of depositing TDS amount with the government?
          </p>
          <br />
          <p>
            After deducting the TDS amount, the deductor is required to deposit
            this amount with the government within a specified time and this is
            called making TDS payment.
          </p>
          <br />
          <p>
            The amount of TDS is deposited by using TDS Challan No. 281. You can
            visit the link to see where this payment is made.
          </p>
          <br />
          <p>
            The due dates for depositing TDS (or making TDS payment) are as
            follows:
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr>
                <th className="border py-3 px-5 text-left">Month of Deduction </th>
                <th className="border py-3 px-5 text-left">
                  Due Date for Making Payment Through Challan for both Government or
                  any other deductor
                </th>
              </tr>
              <tr>
                <td className="border py-3 px-5">April</td>
                <td className="border py-3 px-5">7th May</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">May</td>
                <td className="border py-3 px-5">7th June</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">June</td>
                <td className="border py-3 px-5">7th July</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">July</td>
                <td className="border py-3 px-5">7th August</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">August</td>
                <td className="border py-3 px-5">7th September</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">September</td>
                <td className="border py-3 px-5">7th October</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">October </td>
                <td className="border py-3 px-5">7th November</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">November</td>
                <td className="border py-3 px-5">7th December</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">December</td>
                <td className="border py-3 px-5">7th January</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">January</td>
                <td className="border py-3 px-5">7th February</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">February</td>
                <td className="border py-3 px-5">7th March</td>
              </tr>
              <tr>
                <td className="border py-3 px-5">March</td>
                <td className="border py-3 px-5">
                  30th April for deductors other than government and 7th April for
                  government deductors.
                </td>
              </tr>
            </table>
          </div>
        </div>

        <br />
        <hr />
        <br />

        <div id="howdeposite">
          <p className="text-2xl">How to deposit TDS?</p>
          <br />
          <p>
            The procedure for payment of TDS is almost same as payment of income
            tax with some minor changes. To know the process click here. The TDS
            amount is required to be paid online through Challan No. 281 at the
            Online e Tax website.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="penalty">
          <p className="text-2xl">
            What is the Penalty on default in making TDS payment?
          </p>
          <br />
          <p>
            Now, when the deductor does not comply with the above provisions of
            TDS payment, some penalties are levied on him/her which are discussed
            as below:
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="whentdsnotdeducted">
          <p className="text-2xl">
            When TDS is not deducted (either in whole or in part)?
          </p>
          <br />
          <p>
            Interest- late deposit interest @ 1% per month or part of month is
            levied on the TDS amount from the date when tax was required to be
            deducted till the date of actual deduction.[Section 201(1A)(i)]
          </p>
          <br />
          <p>For example Date of:</p>
          <br />
          <ul>
            <li>1. Payment/credit is 16.08.2017</li>
            <li>2. Deduction is 30.04.2018</li>
            <li>3. Deposit of tax is 05.05.2018</li>
          </ul>
          <br />
          <p>
            the interest would be payable for 9 months i.e. from 16.08.2017 to
            30.04.2018 @ 1% pm.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="whendeductbutnotdeposite">
          <p className="text-2xl">
            When TDS is deducted but not deposited with the government?
          </p>
          <br />
          <p>
            Interest- Interest will imposed @ 1.5% for each month or part of the
            month
          </p>
          <br />
          <p>For Example :</p>
          <br />
          <ul>
            <li>1. Date of payment/credit is 16.08.2017</li>
            <li>2. Date of deduction is 16.08.2017</li>
            <li>3. Date of deposit of tax is 31.07.2018</li>
          </ul>
          <br />
          <p>
            then interest would be payable for 12 months i.e. from 16.08.2017 to
            31.07.2018 @ 1.5% pm.
          </p>
          <br />
          <p>Prosecution under Section 276B</p>
          <br />
          <p>
            As per this section, any person who fails to deduct/collect TDS or
            does not deposit to the government after deducting. The IT Officer can
            punish the defaulter with an imprisonment of minimum 3 months or
            maximum 7 year along with fine.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="tdsreturn">
          <p className="text-2xl font-semibold">What is a TDS return?</p>
          <br />
          <p>
            TDS return is a summary statement of all tax deductions made during
            the quarter by the person deducting tax submitted to the Income Tax
            Department.
          </p>
          <br />
          <p>
            It contains the entries of tax deducted by the deductor and deposited
            with the government. This statement include various details like PAN
            of the deductor & the deductees, detailed particulars of all the TDS
            paid to the government and the TDS Challan information.
          </p>
          <br />
          <p>Some TDS Return Forms are as follows:</p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr>
                <th className="border px-3 py-5">Form No</th>
                <th className="border px-3 py-5">What it represents? </th>
                <th className="border px-3 py-5">Frequency of Submission</th>
              </tr>
              <tr>
                <td className="border px-3 py-5">Form 24Q </td>
                <td className="border px-3 py-5">
                  Statement for TDS from salaries{" "}
                </td>
                <td className="border px-3 py-5">Quarterly</td>
              </tr>

              <tr>
                <td className="border px-3 py-5">Form 26Q</td>
                <td className="border px-3 py-5">
                  Statement for TDS on all payments other than salaries.
                </td>
                <td className="border px-3 py-5">Quarterly</td>
              </tr>

              <tr>
                <td className="border px-3 py-5">Form 27Q</td>
                <td className="border px-3 py-5">
                  Statement for TDS on income received from interest, dividends, or
                  any other sum payable to non residents.
                </td>
                <td className="border px-3 py-5">Quarterly</td>
              </tr>

              <tr>
                <td className="border px-3 py-5">Form 26QB</td>
                <td className="border px-3 py-5">
                  Statement of TDS on Payment on Transfer of Immovable Property (Not
                  Being an Agricultural Land).
                </td>
                <td className="border px-3 py-5">
                  Within 30 days from the end of the month in which tax is deducted.
                </td>
              </tr>

              <tr>
                <td className="border px-3 py-5">Form 26QC </td>
                <td className="border px-3 py-5">
                  Statement of TDS on Payment of Rent by Certain Individuals or HUF.
                </td>
                <td className="border px-3 py-5">
                  Within 30 days from the end of the month in which tax is deducted.
                </td>
              </tr>
            </table>
          </div>
        </div>

        <br />
        <hr />
        <br />

        <div id="duedatetdsreturn">
          <p className="text-2xl">What is the due date of filing TDS return?</p>
          <br />
          <p>
            As we said above, all the people who are required to deduct TDS are
            also mandatorily required to file TDS return.The due date of filing
            quarterly return other than Form 26QB or Form 26QC are as follows:
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr>
                <th className="border px-3 py-5">Month of Deduction </th>
                <th className="border px-3 py-5">Quarter </th>
                <th className="border px-3 py-5">
                  Due Date for Filing of Return for All Deductors
                </th>
              </tr>

              <tr>
                <td className="border px-3 py-5">April</td>
                <td className="border px-3 py-5" rowspan="3">
                  1st Quarter
                </td>
                <td className="border px-3 py-5" rowspan="3">
                  31st July of F.Y
                </td>
              </tr>
              <tr>
                <td className="border px-3 py-5">May</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">June</td>
              </tr>

              <tr>
                <td className="border px-3 py-5">July</td>
                <td className="border px-3 py-5" rowspan="3">
                  2nd Quarter
                </td>
                <td className="border px-3 py-5" rowspan="3">
                  31st October of F.Y
                </td>
              </tr>
              <tr>
                <td className="border px-3 py-5">August</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">September</td>
              </tr>

              <tr>
                <td className="border px-3 py-5">October </td>
                <td className="border px-3 py-5" rowspan="3">
                  3rd Quarter
                </td>
                <td className="border px-3 py-5" rowspan="3">
                  31st January of F.Y
                </td>
              </tr>
              <tr>
                <td className="border px-3 py-5">November</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">December</td>
              </tr>

              <tr>
                <td className="border px-3 py-5">January </td>
                <td className="border px-3 py-5" rowspan="3">
                  4th Quarter
                </td>
                <td className="border px-3 py-5" rowspan="3">
                  31st May of F.Y immediately following the F.Y in which TDS has
                  been deducted
                </td>
              </tr>
              <tr>
                <td className="border px-3 py-5">February</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">March</td>
              </tr>
            </table>
          </div>
        </div>

        <br />
        <hr />
        <br />

        <div id="penaltytdsreturn">
          <p className="text-2xl">
            What is the Penalty on making default in filing TDS return?
          </p>
          <br />
          <p>
            In case of non-compliance with the above TDS return filing provisions,
            the following penalty late fees or penalty will be levied:
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div>
          <p className="text-2xl">
            Section 234E: Late fees on Delay in Filing TDS Return
          </p>
          <br />
          <p>
            The tax deductor will be liable to pay a late filing fees of Rs.200
            per day for the period till TDS return is not filed. However, the
            total amount of fees will not exceed the amount of TDS required to be
            deducted.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div>
          <p className="text-2xl">
            Section 271H: Penalty on Non-Filing of TDS Return
          </p>
          <br />
          <p>
            The A.O. may ask the TDS deductor to pay a minimum penalty of 10,000
            which may extend to 1,00,000 for not filing TDS return within the due
            date. It is important to note that penalty u/s 271H is in addition to
            penalty u/s 234E.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="tdscertificate">
          <p className="text-2xl">What is a TDS certificate?</p>
          <br />
          <p>
            A person who deducts tax (TDS) is required to issue an
            acknowledgement( ‘form’ )to the person whose TDS is being
            deducted(deductee) that tax has been deducted and deposited with the
            government by him.This Form/Acknowledgement is known as TDS
            certificate.It contains the particulars of payment,deductor , deductee
            details, the date of tax deduction and the date of its credit to the
            government .
          </p>
          <br />
          <p>
            Using this certificate, the taxpayer (or deductee) can claim the
            credit or the refund (if any) of taxes while filing his/her income tax
            return.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="typeofcertificate">
          <p className="text-2xl">
            What are the different types of TDS certificates?
          </p>
          <br />
          <p>
            As discussed above, different TDS return forms have different TDS
            certificates which are as follows:
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <thead>
                <tr>
                  <th className="border px-3 py-5">Respective TDS Certificate </th>
                  <th className="border px-3 py-5">TDS Return Form No </th>
                  <th className="border px-3 py-5">Due Date </th>
                  <th className="border px-3 py-5">Time of Issue</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-3 py-5">Form 16 (For TDS on Salary)</td>
                  <td className="border px-3 py-5">Form 24Q</td>
                  <td className="border px-3 py-5">
                    By 31st May of the F.Y immediately following the F.Y in which
                    tax is deducted.
                  </td>
                  <td className="border px-3 py-5">Annually</td>
                </tr>

                <tr>
                  <td className="border px-3 py-5">
                    Form 16A (For TDS on other income)
                  </td>
                  <td className="border px-3 py-5">Form 26Q </td>
                  <td className="border px-3 py-5">
                    Within 15 days of furnishing Form 26Q
                  </td>
                  <td className="border px-3 py-5">Quarterly</td>
                </tr>

                <tr>
                  <td className="border px-3 py-5">
                    Form 16B (For TDS on purchase of immovable property)
                  </td>
                  <td className="border px-3 py-5">Form 26QB </td>
                  <td className="border px-3 py-5">
                    Within 15 days of furnishing Form 26QB
                  </td>
                  <td className="border px-3 py-5">Every transaction</td>
                </tr>

                <tr>
                  <td className="border px-3 py-5">
                    Form 16C (For TDS on payment of rent)
                  </td>
                  <td className="border px-3 py-5">Form 26QC </td>
                  <td className="border px-3 py-5">
                    Within 15 days of furnishing Form 26QC
                  </td>
                  <td className="border px-3 py-5">Every transaction</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <br />
        <hr />
        <br />

        <div id="form26AS">
          <p className="text-2xl">What is Form 26AS?</p>
          <p>
            Form 26AS is simply a summarized annual statement which contains tax
            credit information of each taxpayer against his PAN.
          </p>
          <br />
          <p>
            If you have paid any tax (like self assessment tax, advance tax) or
            any tax has which has been deducted and deposited with government on
            your behalf (like various TDS) then all such details will be available
            on Form 26AS.
          </p>
          <br />
          <p>
            This form helps you to claim credit of all the taxes you have paid
            (plus paid on your behalf-TDS ) while filing income tax return.
            Therefore, it becomes very important that details mentioned here are
            correct and they match with relevant TDS certificates.
          </p>
          <br />
          <p>
            If you want to about Form 26AS further in detail like how to download?
            It’s importance? etc then you can refer to our detailed guide.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="whattan">
          <p className="text-2xl">What is TAN?</p>
          <br />
          <p>
            TAN means Tax Deduction/Collection Account Number. It is a 10 digit
            alphanumeric number required to be obtained by every person, who is
            responsible for either deducting or collecting the tax. It is issued
            by the Income Tax department to all such persons.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="tavVspan">
          <p className="text-2xl">What is the difference between TAN and PAN?</p>
          <br />
          <p>
            TAN is given to a person who is liable to deduct or collect tax at
            source. Whereas, PAN is issued to a person who is involved in the
            financial transaction exceeding a particular threshold limit.
            (Basically, the one from the whose income tax is being deducted).
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="mismatchdetails">
          <p className="text-2xl">
            What to do in case of mismatch of TDS details in Form 26AS and actual
            TDS deducted?
          </p>
          <br />
          <p>
            As we said above that it’s important for Form 26AS details to match
            with the actual TDS deducted, the reason being, in case of any
            mismatch you will not be able to claim benefit of taxes paid or you
            may claim excess credit which will lead to penalty being imposed you.
            So what to do in such case? Well, the biggest reason for such mistakes
            is the wrong updation of information in the TDS return forms by the
            deductor. As information in Form 26AS is updated by ITD using the
            filed TDS return, so wrong information at that place impacts your 26AS
            which in turn will impact your ITR. Hence, in such case, you need get
            in touch with the TDS deductor and get all the corrections/updations
            done and then file ITR (or revised in some cases).
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="claimcredit">
          <p className="text-2xl">How to claim credit of TDS?</p>
          <br />
          <p>
            It is very simple to claim the benefit of TDS while filing Income Tax
            Return. Simply download your Form 26AS, consider all the details which
            it shows and update them in your return and this will reduce your
            total tax liability (and it may also make you eligible for the income
            tax refund, if your TDS, advance tax etc exceeds total tax liability).
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="tdsexemption">
          <p className="text-2xl">How can one get the exemption from TDS?</p>
          <br />
          <p>
            Well, the liability for TDS can be avoided only when your income is
            below the basic exemption limit. There are 2 forms which can help a
            resident citizen to avoid TDS liability on interest income. These are:
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Form 15G (for Resident Citizen below 60 years & HUF)
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Form 15H (for Resident Senior Citizens who are 60 years or above)
              </p>
            </li>
          </ul>
          <br />
          <p>
            You need to use these forms when although a particular income is
            exceeding than the threshold limit specified in individual section but
            your overall limit is within basic exemption limit. To know about
            these forms in detail, refer to our guide.
          </p>
          <br />
          <p>
            Further, if the income of non-residents is also less than the basic
            exemption limit then for avoiding the TDS liability they need to file
            an application u/s 195(3) of the IT Act to the jurisdictional tax
            officer to obtain a certificate of non-deduction or lower deduction of
            taxes.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="faqs" className="p-10 xs:p-4 mb-10 bg-100 bg-[#C6DBFA]">
          <h1 className="font-bold text-4xl text-900 text-[#1E1E24]">
            Frequently Asked Questions
          </h1>
          {tdsFaqs.map((item) => {
            return (
              <>
                <div key={item.id}>
                  <p className="text-900 text-[#1E1E24] font-bold text-xl pt-10 pb-5 flex items-center xs:items-start gap-2.5 xs:text-lg xs:leading-6">
                    <span className="text-base xs:mt-2 text-center h-6 w-6 flex items-center justify-center bg-900 bg-[#1E1E24] question p-2 xs:leading-[100%]  xs:text-sm xs:rounded-[100%] rounded-full  font-normal text-white">
                      Q
                    </span>
                    {item.question}
                  </p>
                  <p className="text-base">Ans: {item.answer}</p>
                  {item.list ? (
                    <ul className="pt-5 listQuestion">
                      {item.list.map((li) => {
                        return <li>=&gt; {li}</li>;
                      })}
                    </ul>
                  ) : null}
                  {/* <div className='border-solid border border-sky-900 my-4 w-1/4'></div> */}
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className='fix-watsapp-icon'>
        <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default TDS;
