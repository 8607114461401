import { useState } from "react";
import RefContext from "./RefContext";

const RefState = (props) => {

    const [ref, setRef] = useState()

    return (
        <RefContext.Provider value={{ ref, setRef }} >
            {
                props.children
            }
        </RefContext.Provider>
    )
}

export default RefState;