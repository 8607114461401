import React, { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";

const PanAccrodatipn = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col mb-5  bg-[#f5f5f5] ">
      <div
        className="flex items-center rounded-md justify-between border border-[#ddd] px-10 py-5 aadhar-pan-link-faq-icon"
        onClick={() => setOpen(!open)}
      >
        <p className=" text-[#2c5277] font-bold">{question}</p>
        <BsPlusCircle size={22} />
      </div>
      {open ? (
        <div className=" rounded-b-md bg-white w-full  px-10 py-5">
          {answer}
        </div>
      ) : null}
    </div>
  );
};

export default PanAccrodatipn;
