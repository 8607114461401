import React, { useState } from "react";
import Tab from "./Tab";
import { BsWhatsapp } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
function getDiscountPercentage() {
    // Define the date ranges and their corresponding discounts
    const discountRanges = [
        { startDate: '2024-05-18', endDate: '2024-05-31', discount: 30 },
        { startDate: '2024-06-01', endDate: '2024-06-15', discount: 20 },
        // Add more date ranges as needed
    ];
    // Get the current date
    const currentDate = new Date();
    // Helper function to parse date strings and compare dates
    function isDateInRange(currentDate, startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return currentDate >= start && currentDate <= end;
    }
    // Iterate over the discount ranges to find a matching date range
    for (const range of discountRanges) {
        if (isDateInRange(currentDate, range.startDate, range.endDate)) {
            return range.discount;
        }
    }
    // If no matching date range is found, return 0% discount
    return 0;
}
// Usage example
const discount = getDiscountPercentage();
console.log(`The current discount is ${discount}%`);
const Tabs = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <div className="tabs-container">
            <div className='hero-pricing w-[80%] flex justify-around md:block xs:block'>
                <div className='w-[20%] xs:w-[80%]  sm:w-[80%] md:w-[60%] h-[auto] xs:h-auto xs:mx-auto sm:mx-auto md:mx-auto'>
                    <img src='pricing-page-person.png' alt='Person Image' className="w-[100%] h-[100%]"></img>
                </div>
                <div className='about-pricing-itr text-center'>
                    <h1 className='text-6xl xs:text-4xl mt-[150px] sm:mt-[0px] md:mt-[0px] xs:mt-[0px]'>Tax Services at the most <br /> Affordable Prices.</h1>
                    <p className='mt-[20px] text-2xl'>Pick a service and choose a plan that’s right for you.</p>
                    <div className="tabs grid grid-cols-3">
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                onClick={() =>
                                    handleTabClick(index)
                                }
                                isActive={index === activeTab}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="tab-content">
                {activeTab === 0 &&
                    <div className="main-pricing-part">
                        <div className='pricing-plan-main'>
                            <div className='itr-fillng-info bg-[#C6DBFA] flex justify-between'>
                                <div className='income-tax-head w-[60%] xs:w-[90%]  p-[20px] text-black'>
                                    <h1 className='text-3xl font-medium'>ITR Filling</h1>
                                    <p className='mt-1 text-[13px] text-[#4d4d4d]'>Sit back and relax. Leave the stress of tax filing to us. Let our experts help you save maximum time & taxes.</p>
                                    {/* <p className='text-[13px] text-[#4d4d4d]'>Let our experts help you save maximum time & taxes.</p> */}
                                </div>
                            </div>
                            <div className='pricing-plan-child'>
                                <h1 className='text-center text-5xl mt-3'>Plans based on your Selection</h1>
                                <div className='itr-filling-price-list  xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2'>
                                    <div className='salary-house-property-plan relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Salary & House property Plan</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;499</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Single & Multiple Employers
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Single & Multiple House Property
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Income from Other Sources
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Agriculture Income
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Salary+House+property+Plan&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='bussiness-professional-plan relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Business & Professional Plan</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;1299</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Single & Multiple Employers
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Single & Multiple House Property
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Business & Professional Income (Non Audit) - Without B/S P/L*
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Income from Other Sources
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Agriculture Income
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Business+Professional+Plan&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='capital-gain-plan relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Capital Gain Plan</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;1499</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Single & Multiple Employers
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Single & Multiple House Property
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Multiple Capital Gain Income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Business & Professional Income(Non Audit)- Without B/S P/L*
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Income from Other Sources
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Agriculture Income
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Capital+Gain+Plan&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='future-options-plan mt-5 relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Future & Options Plan</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;2,599</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    F&O Income/Loss (Non Audit)
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Speculative Income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Single & Multiple Employers
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Single & Multiple House Property
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Multiple Capital Gain Income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Income from Other Sources
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Business & Professional Income(Non Audit)- Without B/S P/L*
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Agriculture Income
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Future+Options+Plan&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='crypto-currency-plan mt-5 relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Crypto Currency Plan</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;3,799</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Crypto Income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    F&O Income/Loss(Non Audit)
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Single & Multiple Employers
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Single & Multiple House Property
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Multiple Capital Gain Income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Business & Professional Income(Non Audit)- Without B/S P/L*
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Income from Other Sources
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Agriculture Income
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Crypto+Currency+Plan&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='nri-resident-plan mt-5 relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>NRI/Resident Having Foreign Income</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;3,799</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Foreign salary(Including Foreign Tax relief)
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    DTAA Tax Relief
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Single & Multiple Employers
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Single & Multiple House Property
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Multiple Capital Gain Income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    RSU/ESOP
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Business & Professional Income(Non Audit)- Without B/S P/L*
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Crypto Income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    F&O Income/Loss(Non Audit)
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Speculative Income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Income from Other Sources
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Agriculture Income
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=NRI/Resident+Foreign+Income&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center mt-[50px] mb-[20px]'>
                                    <p className='text-lg'><b>*For Balance sheet & P/L / Attestation: ₹1000</b></p>
                                    <p className='text-lg'><b>*If you have received any arrears and want to claim relief then need to pay Rs. 499 over and above on mentioned plans</b></p>
                                    <p className='text-lg'><b>*18% GST will be applicable to the base plan amount</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {activeTab === 1 &&
                    <div className="main-pricing-part">
                        <div className='pricing-plan-main'>
                            <div className='itr-fillng-info bg-[#C6DBFA] flex justify-between'>
                                <div className='income-tax-head w-[60%] xs:w-[100%]  p-[20px] text-black'>
                                    <h1 className='text-3xl font-medium'>Tax Planner</h1>
                                    <p className='mt-1 text-[13px] text-[#4d4d4d]'>Personalized tax planning assistance to individuals and businesses by a team of qualified and credible tax experts.</p>
                                </div>
                            </div>
                            <div className='pricing-plan-child-tax-planning'>
                                <h1 className='text-center text-5xl mt-3'>Basic Tax Planner</h1>
                                <div className="basic-tax-planer-main p-10 w-[55%] sm:w-[90%] md:w-[90%] xs:w-[100%] bg-[#e9e9e95f]">
                                    <div className="flex  justify-around xs:block ">
                                        <div className="w-[30%] xs:w-[100%]">
                                            <h1><span className="text-6xl ">Free <br /><span className="line-through text-[#0F52BA]">&#x20B9; 499</span></span> / Year</h1>
                                            <p>*Exclusive of Taxes</p>
                                        </div>
                                        <div className="w-[50%] xs:w-[100%] xs:mt-5">
                                            <h1 className="text-2xl font-medium">Tax planning (DIY)</h1>
                                            <p className="mt-4">Maximize your financial potential with Tax2clear - your exclusive access to tax planning resources.</p>
                                        </div>
                                    </div>
                                    <div className="watsapp-now-btn">
                                        <button className='mt-3 py-2.5 px-10 mt-[20px] rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]   xs:text-sm sm:text-sm md:text-[15px]'>
                                            <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                            </a>
                                        </button>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <h1 className="text-5xl mt-4">Advance Tax Planner Plans</h1>
                                    <p className="mt-4">Our dedicated team of professionals provides personalized assistance to individuals and businesses, helping you:</p>
                                    <ul className="flex xs:block justify-around w-[80%] xs:w-[90%] sm:block md:block  mx-auto">
                                        <li className='flex items-center mt-3'>
                                            <FaCheck className='mr-2' color={"#0F52BA"} /><p className="md:text-left">
                                                In-depth tax report based on your investments
                                            </p>
                                        </li>
                                        <li className='flex items-center mt-3'>
                                            <FaCheck className='mr-2' color={"#0F52BA"} /><p className="md:text-left">
                                                Every potential tax saving investment options
                                            </p>
                                        </li>
                                        <li className='flex items-center mt-3'>
                                            <FaCheck className='mr-2' color={"#0F52BA"} /><p className="md:text-left">
                                                All types of tax planning in one place (Individual, Business, NRI, Capital Gain)
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div className='itr-filling-price-list-tax-planning xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2'>
                                    <div className='taxsaver-pro-plan relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>TaxSaver Pro</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;1,699</span></p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    For salaried taxpayers
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Old v New tax regime: Which is best for you?
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Increase saving by salary restructuring
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Tax saving opportunities suited to your goals
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Bifurcation of HRA calculation
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=TaxSaver+Pro&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='taxsaver-advance-plan relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>TaxSaver Advance</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;2,599</span> / Year</p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    For taxpayers with income other than salary
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Sold a property? Get solutions against capital gains
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Tax calculation and saving opportunities based on your tax slab
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Tax loss harvesting solutions
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Advance Tax calculation
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Personalized tax saving options for business income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Bifurcation of HRA calculation
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=TaxSaver+Advance&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='advance-tax-calculate-plan relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Advance Tax calculation</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;899</span> / Year</p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Advance tax calculation based on source of income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Quarterly review
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Assistance with Challan payment
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Advance+Tax+calculation&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='future-options-plan relative'>  
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>TaxSaver NRI</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;4,199</span> / Year</p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Guidance for taxation on foreign investments
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Tax implications on NRO/NRE account
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Applicability of  DTAA provisions
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Optimizing tax strategies for managing RSU/ESOP including advance tax calculations on sale
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Understanding residential status as per Indian law
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=TaxSaver+NRI&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center mt-[50px] mb-[20px]'>
                                    <p className='text-lg'><b>*18% GST will be applicable to the base plan amount</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* {activeTab === 2 &&

                    <div className="main-pricing-part">
                        <div className='pricing-plan-main'>
                            <div className='itr-fillng-info bg-[#C6DBFA] flex justify-between'>
                                <div className='income-tax-head w-[60%] xs:w-[100%]  p-[20px] text-black'>
                                    <h1 className='text-3xl font-medium'>GST </h1>
                                    <p className='mt-1 text-[13px] text-[#4d4d4d]'>Offload your GST filing to our CAs and peacefully focus on your business.</p>
                                </div>
                            </div>
                            <div className='pricing-plan-child-GST'>
                                <h1 className='text-center text-5xl mt-5'>Plans based on your Selection</h1>
                                <div className='itr-filling-price-list-GST xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2'>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>GST Registration</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;999</span></p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Application for GST Registration
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Application for Clarification
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Any modification in GST Registration Application
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>GST Compliances of Online Seller</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;10,199</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    GSTR-1 Return Filing
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    GSTR-3B Return Filing
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Credit Reconciliation (Reconciliation of Purchase Register and GSTR-2A)
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <ImCross color={"#FF0000"} className='mr-2' /><p>
                                                    Excludes Annual Return
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>GST Compliances for Freelancers</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;6,799</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    GSTR-1 Return Filing
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    GSTR-3B Return Filing
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Credit Reconciliation (Reconciliation of Purchase Register and GSTR-2A)
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <ImCross color={"#FF0000"} className='mr-2' /><p>
                                                    Excludes Annual Return
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>GST Compliances for Traders</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;8,499</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    GSTR-1 Return Filing
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    GSTR-3B Return Filing
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    Credit Reconciliation (Reconciliation of Purchase Register and GSTR-2A)
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <ImCross color={"#FF0000"} className='mr-2' /><p>
                                                    Excludes Annual Return
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>GST Compliance for Composite Dealer</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;4,999</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    CMP-08 Filing
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <ImCross color={"#FF0000"} className='mr-2' /><p>
                                                    Excludes Annual Return
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>LUT Filing</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;1,699</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Filing of LUT
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>Annual Return Filing of Composite Dealer</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;3,399</span> </p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    GSTR-9A Filing
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>Annual Return Filing of Regular Dealer</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;12,699</span> </p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Filing of GSTR-9 Return
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Filing of GSTR-9C (Reconciliation Statement)
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>GST Credit Reconciliation (Monthly)</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;599</span> / Month</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    GSTR-2A and Purchase Register Reconciliation for an entire month
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Automated follow up with Supplier to file GST Return
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Reconciliation Report
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gst-palns'>
                                        <p className='text-3xl'>GST Credit Reconciliation (Yearly)</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;5,899</span> / Year</p>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    GSTR-2A and Purchase Register Reconciliation for an entire year
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Automated follow up with Supplier to file GST Return
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Reconciliation Report
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=File+Your+Return&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center mt-[50px] mb-[20px]'>
                                    <p className='text-lg'><b>*18% GST will be applicable to the base plan amount</b></p>
                                </div>
                            </div>
                        </div>
                    </div>


                } */}
                {activeTab === 2 &&

                    <div className="main-pricing-part">
                        <div className='pricing-plan-main'>
                            <div className='itr-fillng-info bg-[#C6DBFA] flex justify-between'>
                                <div className='income-tax-head w-[60%] xs:w-[100%]  p-[20px] text-black'>
                                    <h1 className='text-3xl font-medium'>Income Tax Notices</h1>
                                    <p className='mt-1 text-[13px] text-[#4d4d4d]'>Experience stress-free notice management, tailored to your needs. Stay effortlessly informed and organized, with our user-friendly expertise.</p>
                                </div>
                            </div>
                            <div className='pricing-plan-child-GST'>
                                <h1 className='text-center text-5xl mt-5'>PRICING</h1>
                                <div className='itr-filling-price-list-notice-plan xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2'>
                                    <div className='notice-palns relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Appeal Cases</p>
                                        <p className="text-[#ff9700] mt-2">Starting From</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;25,399</span></p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    For 1st & 2nd Appeal
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Appeals that can be conducted through online tax portals, without requiring a physical visit
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    ITAT Appeals will be charged separately, depending upon location & availability of tax consultant
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    For cases where you cannot proceed with simple Rectification, Revise Return, Feedback
                                                </p>
                                            </li>
                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Appeal+Cases&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='notice-palns relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Rectification / Revised / Defective</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;2,499</span></p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Applicable to rectifying defects, revisions, adjustments, HVT Notice, adjustments, Form 67 defects,26AS-AIS/TIS Mismatch etc.
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Major overhauls or replacements beyond basic rectification
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    If the ITR plan fee is higher than the Notice fee, the higher of the two fees will be applicable
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Full payment upfront
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    We aim to complete the service within 4 business days from the date of receiving the notice with all related documents
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    This pricing plan covers a two-time rectification / revise / defective service for each eligible customer.
                                                </p>
                                            </li>

                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Rectification+Revised+Defective&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='notice-palns relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Routine Notice</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;899</span></p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    For providing a simple response and cross checking your filed ITR.
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck className='mr-2' color={"#0F52BA"} /><p>
                                                    Applicable for Risk Management Notices, Refund Re-issue, Simple Response to outstanding demands, E-Campaign Response etc.
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    For revise filing due to omission of income or taken incorrect deductions, plan will change accordingly
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p> You will have authenticated
                                                    For Agree/Disagree Responses.
                                                </p>
                                            </li>

                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Routine+Notice&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='notice-palns relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Scrutiny Cases</p>
                                        <p className="text-[#ff9700] mt-2">Starting From</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;21,199</span> </p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Comprehensive services for scrutiny cases, including document review, preparation of responses.
                                                </p>
                                            </li>
                                        </ul>
                                        <p className="mt-5"><b>NOTE:</b> Scrutiny is done by the tax department on a random basis. The filing of income tax returns (ITR) OR the type of sources of income OR type of ITR Form are not related to this process.</p>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Scrutiny+Cases&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='notice-palns relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Calling For Information/Seeking Clarification</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;6,399</span></p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <p className='mt-5 text-[#0B3B84]'>SUITED FOR:</p>
                                        <p className="mt-5">Assistance in seeking clarification or providing additional details in response to  :</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Financial Transactions
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Deductions
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    F&O Trades
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Unreported Income
                                                </p>
                                            </li>
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Misreported/Underreported Income
                                                </p>
                                            </li>

                                        </ul>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Calling+For+Information/Seeking+Clarification&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='notice-palns relative'>
                                        <p className='text-3xl border-b-2 border-dashed pb-[20px]'>Scrutiny Cases (ITR Filed By Tax2clear)</p>
                                        <p className="text-[#ff9700] mt-2">Starting From</p>
                                        <p><span className='text-6xl text-[#0B3B84]'>&#x20B9;12,699</span></p>
                                        <span className="bg-[#0F52BA] p-[6px] absolute top-[15px] right-[15px] rounded-sm text-center text-[#fff] text-[12px]">{discount}% Off</span>
                                        <p className='mt-3'>*Exclusive of Taxes</p>
                                        <ul className="mt-3">
                                            <li className='flex items-center mt-3'>
                                                <FaCheck color={"#0F52BA"} className='mr-2' /><p>
                                                    Special rate for scrutiny cases where Tax2clear has already filed the Income Tax Return (ITR) on behalf of the client for that particular A.Y. only.
                                                </p>
                                            </li>
                                        </ul>
                                        <p className="mt-5"><b>NOTE:</b> Scrutiny is done by the tax department on a random basis. The filing of income tax returns (ITR) OR the type of sources of income OR type of ITR Form are not related to this process.</p>
                                        <div className='watsapp-us-part'>
                                            <button className='mt-3 py-2.5 px-10 rounded-lg bg-transparent text-[#000] border-2 hover:border-[#0B3B84]  xs:text-sm sm:text-sm md:text-[15px]'>
                                                <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Scrutiny+Cases+(ITR Filed By Tax2clear)&type=phone_number&app_absent=0' className='flex items-center gap-2 text-center' >
                                                    <BsWhatsapp /><span className='text-center'>WhatsApp us</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>
        </div>
    );
};

export default Tabs