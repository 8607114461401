import React from "react";
import { HashLink } from "react-router-hash-link";
import { BsCheck2Circle } from "react-icons/bs";
import capital from "../utils/capital.json";
import { BsWhatsapp } from "react-icons/bs"
const CapitalGainsTax = () => {
  const content = [
    {
      id: 1,
      content: "What is a capital gain?",
      path: "#whatiscapitalinindia",
    },
    {
      id: 2,
      content: "What are Capital Assets?",
      path: "#whatcapitalassets",
    },
    {
      id: 3,
      content: "What are the Different Types of Capital Assets?",
      path: "#typeofcapitalassets",
    },
    {
      id: 4,
      content: "What are Short-term Capital Assets?",
      path: "#shortterm-capital-asset",
    },
    {
      id: 5,
      content: "What are Long-term Capital Assets?",
      path: "#longterm-capital-asset",
    },
    {
      id: 6,
      content: "How are Inherited Capital Assets Classified?",
      path: "#inherited-capital-asset",
    },
    {
      id: 7,
      content: "What are the Different Types of Capital Gain Tax?",
      path: "#typeofcapitalgains",
    },
    {
      id: 8,
      content: "What is Short-term Capital Gain Tax?",
      path: "#shortterm-capitalgaintax",
    },
    {
      id: 9,
      content: "What is Long-term Capital Gain Tax?",
      path: "#longterm-capitalgaintax",
    },
    {
      id: 10,
      content: "How to Calculate Capital Gains Tax?",
      path: "#howcapitalgaincalculated",
    },
    {
      id: 11,
      content: "What is Indexation?",
      path: "#indexationcost",
    },
    {
      id: 12,
      content: "What are the Expenses Allowed for Capital Gains?",
      path: "#expenses-capitalgains",
    },
    {
      id: 13,
      content: "Examples of Capital Gain",
      path: "#",
    },
    {
      id: 14,
      content: "Capital Gains Tax Rates",
      path: "#capital-gain-rate",
    },
    {
      id: 15,
      content: "Tax Exemption on Capital Gain",
      path: "#exemptionofcapitalgain",
    },
    {
      id: 16,
      content: "ITR Filing for Capital Gains",
      path: "#itrfilling-for-capitalgains",
    },
    {
      id: 8,
      content: "Frequently Asked Questions",
      path: "#faqcapitalgain",
    },
  ];

  const financeYear = [
    {
      id: 1,
      year: "2001 - 02",
      cii: 100,
    },
    {
      id: 2,
      year: "2002 - 03",
      cii: 105,
    },
    {
      id: 3,
      year: "2003 - 04",
      cii: 109,
    },
    {
      id: 4,
      year: "2004 - 05",
      cii: 113,
    },
    {
      id: 5,
      year: "2005 - 06",
      cii: 117,
    },
    {
      id: 6,
      year: "2006 - 07",
      cii: 122,
    },
    {
      id: 7,
      year: "2007 - 08",
      cii: 129,
    },
    {
      id: 8,
      year: "2008 - 09",
      cii: 137,
    },
    {
      id: 9,
      year: "2009 - 10",
      cii: 148,
    },
    {
      id: 10,
      year: "2010 - 11",
      cii: 167,
    },
    {
      id: 11,
      year: "2011 - 12",
      cii: 184,
    },
    {
      id: 12,
      year: "2012 - 13",
      cii: 200,
    },
    {
      id: 13,
      year: "2013 - 14",
      cii: 220,
    },
    {
      id: 14,
      year: "2014 - 15",
      cii: 240,
    },
    {
      id: 15,
      year: "2015 - 16",
      cii: 254,
    },
    {
      id: 16,
      year: "2016 - 17",
      cii: 264,
    },
    {
      id: 17,
      year: "2017 - 18",
      cii: 272,
    },
    {
      id: 18,
      year: "2018 - 19",
      cii: 280,
    },
    {
      id: 19,
      year: "2019 - 20",
      cii: 289,
    },
    {
      id: 20,
      year: "2020 - 2021",
      cii: 301,
    },
    {
      id: 21,
      year: "2021 - 2022",
      cii: 317,
    },
    {
      id: 21,
      year: "2022 - 2023",
      cii: 331,
    },
    {
      id: 21,
      year: "2023 - 2024",
      cii: 348,
    },
  ];

  const exampleCapital = [
    {
      id: 1,
      particulars: "The full value of consideration	",
      calculation: "-",
      amount: "75,00,000",
    },
    {
      id: 2,
      particulars: "Less: indexed cost of acquisition	",
      calculation:
        "Cost of acquisition * CII of the year in which the asset is sold / CII of the year in which the asset was acquired = 20 lakhs * (CII of 2017-18 / CII of 2001-02 since it is the base year)= 20 lakhs * (272/100)	",
      amount: "54,40,000",
    },
    {
      id: 3,
      particulars: "Less: indexed cost of improvement	",
      calculation:
        "Cost of improvement * CII of the year in which the asset is sold / CII of the year in which the asset was improved = 5 lakhs * (CII of 2017-18 / CII of 2004-05)= 5 lakhs * (272/113)	",
      amount: "12,03,540",
    },
    {
      id: 4,
      particulars: "Less: brokerage paid	",
      calculation: "-",
      amount: "1,00,000",
    },
    {
      id: 5,
      particulars: "Long term capital gain	",
      calculation: "-",
      amount: "7,56,460",
    },
  ];

  return (
    <div className="main-containerr">
      <div className="capitalGainTax mt-5">
        <p className="text-4xl  font-bold">
          What is Capital Gains Tax? - Types, Tax Rates,<br /> Calculation, Exemptions & Tax Rates
        </p>
        <br />
        <p>
          You've just made a savvy investment in the stock market, watched your portfolio grow, and decided to cash in on your success by selling some of your stocks. But before you celebrate your financial triumph, there's one thing you can't overlook: capital gains tax.
        </p>
        <p>
          Capital Gain Tax in India is the tax imposed by the government on the profit earned from the sale of certain assets, such as stocks, bonds, real estate, or other investments. This tax applies to both individuals and businesses.
        </p>
        <p>
          In this guide, we have covered important aspects related to capital gains tax, capital gains tax in India, capital assets, their calculation, the Cost Inflation Index (CII), and much more in a very lucid and comprehensive manner.
        </p>
        <br />
        <div className="contentBox">
          <h3 className="text-2xl font-semibold">Contents</h3>
          <ul>
            {content.map((item) => {
              return (
                <HashLink to={item.path} smooth key={item.id}>
                  <li className="font-bold"># {item.content}</li>
                </HashLink>
              );
            })}
          </ul>
        </div>
        <br />

        <div id="whatiscapitalinindia">
          <p className="text-4xl">What is Capital Gains?</p>
          <br />
          <p >
            Capital gains tax is a tax imposed on the profits realized from the sale of assets such as stocks, bonds, real estate, and other investments. It is the tax applied to the difference between an asset's purchase price (or "cost basis") and its selling price.
          </p>
          <p className="mt-3">
            When you sell an asset for more than you paid for it, you have a capital gain. Conversely, if you sell an asset for less than you paid for it, you have a capital loss. Capital gains tax is typically only applied to capital gains, not to the total amount received from the sale.
          </p>
          <p className="mt-3">
            It is mandatory for taxpayers to file their income tax returns for capital gains <b>by submitting right ITR form to the Income Tax Department.</b>
          </p>
          <p className="mt-3">To understand capital gains, you need to understand the concept of capital assets</p>
        </div>

        <br />
        <hr />
        <br />

        <div id="whatcapitalassets">
          <p className="text-4xl">What are Capital Assets?</p>
          <br />
          <p>
            Capital assets are the property that you own and which can be
            transferred like land, building, shares, patents, trademarks,
            jewellery, leasehold rights, machinery, vehicles etc.
          </p>
          <p>
            Here is a list of capital assets that are not coming under capital
            gain:–
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                The stock of consumables or raw materials which are held for use in
                business or profession.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Personal belongings which are meant for personal use like clothes,
                furniture, etc.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>A piece of agricultural land is
                located in a rural area.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Special bearer bonds, 6.5% gold bonds (1977), 7% gold bonds (1980)
                or national defence gold bonds (1980) which have been issued by the
                Central Government.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Gold deposit bond (1999) which had been issued under the gold
                deposit scheme or deposit certificate issued under Gold Monetisation
                Scheme, 2015 notified by the Central Government.
              </p>
            </li>
          </ul>
        </div>

        <br />
        <hr />
        <br />

        <div id="typeofcapitalassets">
          <p className="text-4xl">
            What are the different types of Capital Assets?
          </p>
          <br />
          <p>
            Capital assets are divided into two types based on the period after
            which they are sold off. The types of capital assets are as follows –
          </p>
          <ul className="mt-3">
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Short-term Capital Assets
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Long-term Capital Assets
              </p>
            </li>
          </ul>
          <br />
          <img src="capitalgain.png" alt="" />
          <br />
          <div id="shortterm-capital-asset">
            <p className="text-3xl">What are Short-term Capital Assets?</p>
            <br />
            <p>
              Short-term capital assets are those held for less than or equal to 36 months. This means that if you sell off the asset within 36 months of buying it, it would be called a short-term capital asset. However, in some cases, the holding period is reduced to 24 months and 12 months. These cases include the following –
            </p>
            <br />
            <ul className="mt-3">
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  If the asset is an immovable property like land, building, or house, then the holding period would be considered 24 months. This means that if you sell off an immovable property within 24 months of buying it, it would be called a short-term capital asset.
                </p>
              </li>
              <li>
                <BsCheck2Circle color={"#0F52BA"} /><p>
                  Similarly, equity shares of a company listed on the Recognized stock exchange, securities listed on the Recognized stock exchange, UTI units, equity-oriented mutual fund units, and zero-coupon bonds have a holding period of 12 months. If these assets are sold off before 12 months of purchase, they would be called short-term capital assets.
                </p>
              </li>
            </ul>
          </div>
          <br />
          <div id="longterm-capital-asset">
            <p className="text-2xl">What are Long-term Capital Assets?</p>
            <br />
            <p>
              Long-term capital assets are those held for more than 36 months and then sold off. Immovable property sold after 24 months would be categorized as a long-term capital asset. In the case of equity shares, securities, mutual fund units, etc., however, the holding period of 12 months is applicable. If sold off after 12 months, they would be called long-term capital assets.
            </p>
            <p>
              Generally, the holding period of capital assets to be considered as long-term is 36 months. However, there are certain exceptions to this rule. Here’s a summary of different types of capital assets and the period of holding, after which they are considered long-term capital assets -
            </p>
            <div className="w-[100%] mt-3 overflow-x-auto">
              <table className="border tr-even:bg-[#f9f9f9]">
                <tr className="bg-[#f9f9f9]">
                  <th className="border p-4">Capital Assets</th>
                  <th className="border p-4">Holding Period</th>
                </tr>
                <tr>
                  <td className="border p-4">Equity Shares or Preference Shares in a company (listed)</td>
                  <td className="border p-4">12 months</td>
                </tr>
                <tr className="bg-[#f9f9f9]">
                  <td className="border p-4">Equity Shares or Preference Shares in a company (unlisted)</td>
                  <td className="border p-4">24 months</td>
                </tr>
                <tr>
                  <td className="border p-4">Immovable Property (land or building or both)</td>
                  <td className="border p-4">24 months</td>
                </tr>
                <tr className="bg-[#f9f9f9]">
                  <td className="border p-4">Securities like bonds, debentures, derivatives, and government securities (listed)</td>
                  <td className="border p-4">12 months</td>
                </tr>
                <tr>
                  <td className="border p-4">Units of UTI (Unit Trust of India) (listed or unlisted)</td>
                  <td className="border p-4">12 months</td>
                </tr>
                <tr className="bg-[#f9f9f9]">
                  <td className="border p-4">Units of equity-oriented mutual funds (listed or unlisted)</td>
                  <td className="border p-4">12 months</td>
                </tr>
                <tr>
                  <td className="border p-4">Units of debt-oriented mutual funds (listed or unlisted)</td>
                  <td className="border p-4">36 months</td>
                </tr>
                <tr className="bg-[#f9f9f9]">
                  <td className="border p-4">Zero coupon bonds (listed or unlisted)</td>
                  <td className="border p-4">12 months</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <br />
        <hr />
        <br />
        <div className="pb-3" id="inherited-capital-asset">
          <p className="text-4xl">How are Inherited Capital Assets Classified?</p>
          <p className="mt-3">When acquiring an asset through gift, will, succession, or inheritance, the duration for which the previous owner held the asset is considered.</p>
          <ul className="mt-3">
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                For bonus shares or rights shares, the holding period starts from the date of allotment of bonus shares.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                This consideration applies to determining whether the asset qualifies as short-term or long-term capital asset.
              </p>
            </li>
          </ul>
        </div>
        <br />
        <hr />
        <br />
        <div id="typeofcapitalgains">
          <p className="text-4xl">
            What are the Different Types of Capital Gain Tax?
          </p>
          <br />
          <p>
            Now that you have understood what capital assets are and their types, it’s time to understand the types of capital gains. Capital gains are divided into short-term capital gains and long-term capital gains –
          </p>
          <br />
          <img src="445.png" alt="" />
          <br />

        </div>

        <br />
        <hr />
        <br />

        <div id="shortterm-capitalgaintax">
          <p className="text-4xl">What is Short-term Capital Gain Tax?</p>
          <p className="mt-3">Short-term capital gains (STCG) are the profits you earn when you sell off your capital assets within one year of holding them. Note that the holding period varies as per the capital asset.</p>
          <ul className="mt-3">
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                When the security transaction tax is applicable: Short-term capital gain tax is 15%
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                When a security transaction tax is not applicable, the short-term capital gain tax will be calculated based on the taxpayers' income and will be automatically added to the taxpayer's ITR and charged at normal slab rates.
              </p>
            </li>
          </ul>
        </div>
        <br />
        <hr />
        <br />
        <div id="longterm-capitalgaintax">
          <p className="text-4xl">What is Long-term Capital Gain Tax?</p>
          <p className="mt-3">Long-term capital gain tax (LTCG) are the profits you earn when you sell off your capital assets after one year. Note that the period of holding for different assets to be claimed as long-term assets varies according to the asset.</p>
          <ul className="mt-3">
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Long-term capital gain tax is applicable at 20% except on the sale of equity shares and the units of equity-oriented funds.
              </p>
            </li>
            <li>
              <BsCheck2Circle color={"#0F52BA"} /><p>
                Long-term capital gains are 10% over and above Rs 1 lakh on the sales of equity shares and units of equity-oriented funds.
              </p>
            </li>
          </ul>
          <p className="mt-3">Are you ready to simplify your capital gain tax filing process and maximize your returns? At Tax2clear, we specialize in providing expert assistance tailored to your unique financial situation. Our comprehensive services ensure accuracy, compliance, and peace of mind for our clients.</p>
        </div>
        <br />
        <hr />
        <br />
        <div id="howcapitalgaincalculated">
          <p className="text-4xl">How to Calculate Capital Gains Tax?</p>
          <br />
          <p className="text-2xl">Short term capital gains Calculations</p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr className="border bg-[#f9f9f9]">
                <td className="px-16 py-5 border">Full value of consideration </td>
                <td className="px-16 py-5 border">xxxxx</td>
              </tr>
              <tr>
                <td className="px-16 py-5 border">
                  (-) expenses incurred on transferring the asset{" "}
                </td>
                <td className="px-16 py-5 border">(xxxxx)</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="px-16 py-5 border">(-) cost of acquisition </td>
                <td className="px-16 py-5 border">(xxxxx)</td>
              </tr>
              <tr>
                <td className="px-16 py-5 border">(-) cost of improvement</td>
                <td className="px-16 py-5 border">(xxxxx)</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="font-bold px-16 py-5 border">
                  Short term capital gains{" "}
                </td>
                <td className="font-bold px-16 py-5 border">xxxxx</td>
              </tr>
            </table>
          </div>
          <br />
          <p>
            Let’s understand it with an example. A house property was bought on
            1st January 2017 for INR 50 lakhs. On 1st January 2018, INR 5 lakhs
            was spent on making improvements to the house. On 1st November 2018,
            the house property was sold for INR 65 lakhs.
          </p>
          <br />
          <p>
            Since the house was sold after 22 months of buying it, it would be
            categorized as a short term capital asset. The gain from selling the
            house would be called short term capital gain and it would be
            calculated as follows-
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr className="border bg-[#f9f9f9]">
                <td className="px-16 py-5 border">
                  The full value of consideration{" "}
                </td>
                <td className="px-16 py-5 border">INR 65 lakhs</td>
              </tr>
              <tr>
                <td className="px-16 py-5 border">(-) cost of acquisition </td>
                <td className="px-16 py-5 border">INR 50 lakhs</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="px-16 py-5 border">(-) cost of improvement </td>
                <td className="px-16 py-5 border">INR 5 lakhs</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="font-bold px-16 py-5 border">
                  Short term capital gains{" "}
                </td>
                <td className="font-bold px-16 py-5 border">INR 10 lakhs</td>
              </tr>
            </table>
          </div>
          <br />
          <p className="text-2xl mb-3">Long Term Capital Gain Calculations</p>
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr className="border bg-[#f9f9f9]">
                <td className="px-16 py-5 border">
                  The full value of consideration{" "}
                </td>
                <td className="px-16 py-5 border">xxxxx</td>
              </tr>

              <tr>
                <td className="px-16 py-5 border">
                  (-) expenses incurred in transferring the asset{" "}
                </td>
                <td className="px-16 py-5 border">(xxxx)</td>
              </tr>

              <tr className="bg-[#f9f9f9]">
                <td className="px-16 py-5 border">
                  (-) indexed cost of acquisition{" "}
                </td>
                <td className="px-16 py-5 border">(xxxx)</td>
              </tr>

              <tr className="bg-[#f9f9f9]">
                <td className="px-16 py-5 border">
                  (-) indexed cost of improvement{" "}
                </td>
                <td className="px-16 py-5 border">(xxxx)</td>
              </tr>

              <tr className="bg-[#f9f9f9]">
                <td className="px-16 py-5 border">
                  (-) expenses allowed to be deducted from full value of
                  consideration{" "}
                </td>
                <td className="px-16 py-5 border">(xxxx)</td>
              </tr>

              <tr className="bg-[#f9f9f9]">
                <td className="px-16 py-5 border">
                  (-) exemptions available under Sections 54, 54EC, 54B and 54F
                  etc{" "}
                </td>
                <td className="px-16 py-5 border">(xxxx)</td>
              </tr>

              <tr className="bg-[#f9f9f9]">
                <td className="font-bold px-16 py-5 border">
                  Long term capital gains
                </td>
                <td className="font-bold px-16 py-5 border">xxxxx</td>
              </tr>
            </table>
          </div>
          <br />
          <p className="mt-3">Let’s understand this with the help of an example -</p>
          <p className="mt-3">A house property was purchased on 1st January 2000 for INR 20 lakhs. On 1st January 2005, repairs were done on the house, which amounted to INR 5 lakhs. On 1st January 2023, the house was sold for INR 75 lakhs. A brokerage was paid to the broker, which was INR 1 lakh. What would be the capital gain amount?</p>
          <p className="text-2xl mt-3">Solution:</p>
          <p className="mt-3">Since the asset has been held for more than 36 months, it is a long-term capital asset, and the gain is a long-term capital gain. The gain would be calculated as follows –</p>
          <div className="w-[100%] overflow-x-auto mt-3">
            <table className="border">
              <tr className="bg-[#f9f9f9]">
                <th className="border p-4">Particulars</th>
                <th className="border p-4">Calculation</th>
                <th className="border p-4">Amount</th>
              </tr>
              <tr>
                <td className="border p-4">The full value of consideration</td>
                <td className="border p-4">-</td>
                <td className="border p-4">INR 75,00,000</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="border p-4">Less: indexed cost of acquisition</td>
                <td className="border p-4">Cost of acquisition * CII of the year in which the asset is sold / CII of the year in which the asset was acquired = 20 lakhs * (CII of 2022-23 / CII of 2001-02 since it is the base year)= 20 lakhs * (331/100)</td>
                <td className="border p-4">INR 66,20,000 </td>
              </tr>
              <tr>
                <td className="border p-4">Less: indexed cost of improvement</td>
                <td className="border p-4">Cost of improvement * CII of the year in which the asset is sold / CII of the year in which the asset was improved = 5 lakhs * (CII of 2022-23 / CII of 2004-05)= 5 lakhs * (272/113)</td>
                <td className="border p-4">INR 14,64,602</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="border p-4">Less: brokerage paid</td>
                <td className="border p-4">-</td>
                <td className="border p-4">INR 1,00,000</td>
              </tr>
              <tr>
                <td className="border p-4">Long term capital gain or Long term capital Loss</td>
                <td className="border p-4">-</td>
                <td className="border p-4">INR -6,84,602</td>
              </tr>
            </table>
          </div>

        </div>

        <br />
        <hr />
        <br />

        <div id="calculatediffrentcapital">

        </div>

        <div id="indexationcost">
          <p className="text-4xl">What is Indexation?</p>
          <br />
          <p>
            Indexation of costs is done to factor in the inflation over the years when you hold the capital asset. Since inflation decreases the value of money, indexation of the acquisition and improvement costs increases the amount of these costs, thereby lowering the capital gain earned. To calculate indexation, Cost Inflation Index (CII) accounts for the inflation incurred over the holding period. To calculate the indexed costs, the following formula is used –
          </p>
          <img src="552.png" alt="" />
          <br />
          <p>
            The base year for CII has changed from 1981 to 2001. That is why, when calculating the indexed cost of acquisition, CII of 2001-02 is considered if the asset was purchased before the financial year 2001-02.
          </p>
          <br />
          <p>
            With the shift in the base year, the CII numbers have also changed. The CII for different years, as determined by the Central Government, are as follows –
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              {financeYear.map((item) => {
                return (
                  <tr
                    key={item.id}
                    className={item.id % 2 === 0 ? "border bg-[#f9f9f9]" : "border"}
                  >
                    <td className="border px-24 py-3">{item.year}</td>
                    <td className="border px-24 py-3">{item.cii}</td>
                  </tr>
                );
              })}
            </table>
          </div>
          <p className="mt-3"><b>Note:</b> Getting help from tax experts can make capital gains tax calculation extremely easy and quick. All you have to do is hire an online CA and give them a summary of your capital gain transactions during the year. These experts will not only calculate your capital gains tax but also file your ITR accurately on time.</p>

          <br />

          <p className="text-2xl">What is Surcharge?</p>
          <br />
          <p>Income tax surcharge is the additional tax charged on the income tax payable. It is levied on the taxpayers having a higher income inflow during the year. Given below are the surcharge rates on STCG and LTCG for individuals, HUF, AOP, BOI, and AJP -</p>
          <div className="w-[100%] overflow-x-auto mt-3">
            <table className="border">
              <tr >
                <th rowSpan={2} className="border p-3">Nature of Income</th>
                <th colSpan={5} className="border p-3">Range of Total Income</th>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="border p-3">Up to Rs. 50 lakhs</td>
                <td className="border p-3">More than Rs. 50 lakhs but up to Rs. 1 crore</td>
                <td className="border p-3">More than Rs. 1 crore but up to Rs. 2 crores</td>
                <td className="border p-3">More than Rs. 2 crores but up to Rs. 5 crores</td>
                <td className="border p-3">More than Rs. 5 crores</td>
              </tr>
              <tr>
                <td className="border p-3">Short-term capital gain covered under Section 111A/115AD</td>
                <td className="border p-3">Nil</td>
                <td className="border p-3">10%</td>
                <td className="border p-3">15%</td>
                <td className="border p-3">15%</td>
                <td className="border p-3">15%</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="border p-3">Long-term capital gain covered under Section 112A/115AD</td>
                <td className="border p-3">Nil</td>
                <td className="border p-3">10%</td>
                <td className="border p-3">15%</td>
                <td className="border p-3">15%</td>
                <td className="border p-3">15%</td>
              </tr>
            </table>
          </div>
        </div>
        <br />
        <hr />
        <br />

        <div id="expenses-capitalgains">
          <p className="text-4xl">What are the Expenses Allowed for Capital Gains?</p>
          <br />
          <p>These are the expenses that were necessary to be incurred when selling the asset. Without these expenses, the asset would not have been purchased. These expenses, since mandatory, are allowed to be deducted from the full value of the consideration, which lowers the selling price / increases the cost of acquisition, and also decreases the capital gain. The expenses allowed to be deducted include the following –</p>
          <br />
          <b>Expenses Allowed for Property, Shares and Jewelry</b>
          <div className="w-[100%] overflow-x-auto mt-4">
            <table className="border">
              <tr className="bg-[#f9f9f9]">
                <th className="border p-3">House Property</th>
                <th className="border p-3">Shares</th>
                <th className="border p-3">Jewelry</th>
              </tr>
              <tr>
                <td className="border p-3"> Stamp paper cost<br />Brokerage or commission paid to a broker for arranging a buyer<br />Traveling expenses incurred for the sale of the asset<br />Expenses incurred in obtaining succession certificates, paying the executor of the Will, and other legal procedures if the property is acquired through a Will or inheritance</td>
                <td className="border p-3">Commission paid to the broker for selling the shares.</td>
                <td className="border p-3">Commission paid to the broker for arranging a buyer for the jewelry</td>
              </tr>

            </table>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div id="capital-gain-rate">
          <p className="text-4xl">
            Capital Gains Tax Rates
          </p>
          <br />
          <p>
            Given below is the summary of the holding period and the capital gains tax rates for different capital assets  –
          </p>
          <br />
          <div className="w-[100%] overflow-x-auto">
            <table className="border">
              <tr className="bg-[#f9f9f9]">
                <th className="border py-3 px-10">Capital Asset </th>
                <th className="border py-3 px-10">Holding Period for Long Term Capital Asset	 </th>
                <th className="border py-3 px-10">Long Term Capital Gain Tax (LTCG)</th>
                <th className="border py-3 px-10">Short Term Capital Gain Tax (STCG)</th>
                <th className="border py-3 px-10">Remarks</th>
              </tr>
              <tr>
                <td className="border p-3">Stocks</td>
                <td className="border p-3"> &#62; 12 months</td>
                <td className="border p-3">10% of gain</td>
                <td className="border p-3">15% of gain</td>
                <td className="border p-3">LTCG applicable if total exceeds Rs. 1 Lakh in a financial year.</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="border p-3">Unit Linked Insurance Plan (ULIPs)</td>
                <td className="border p-3">&#62; 12 months</td>
                <td className="border p-3">10% of gain</td>
                <td className="border p-3">15% of gain</td>
                <td className="border p-3">LTCG applicable if total exceeds Rs. 1 Lakh in a financial year.</td>
              </tr>
              <tr>
                <td className="border p-3">Equity Oriented Mutual Funds</td>
                <td className="border p-3">&#62; 12 months</td>
                <td className="border p-3">10% of gain</td>
                <td className="border p-3">15% of gain</td>
                <td className="border p-3">LTCG applicable if total exceeds Rs. 1 Lakh in a financial year.</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="border p-3">Other Mutual Funds</td>
                <td className="border p-3">&#62; 36 months</td>
                <td className="border p-3">20% with inflation indexation</td>
                <td className="border p-3">Taxed based on income tax slab</td>
                <td className="border p-3"></td>
              </tr>
              <tr>
                <td className="border p-3">Government and Corporate Bonds</td>
                <td className="border p-3">&#62; 36 months</td>
                <td className="border p-3">20% with inflation indexation</td>
                <td className="border p-3">Taxed based on income tax slab</td>
                <td className="border p-3"></td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="border p-3">Gold</td>
                <td className="border p-3">&#62; 36 months</td>
                <td className="border p-3">20% with inflation indexation</td>
                <td className="border p-3">Taxed based on income tax slab</td>
                <td className="border p-3"></td>
              </tr>
              <tr>
                <td className="border p-3">Gold ETF</td>
                <td className="border p-3">&#62; 12 months</td>
                <td className="border p-3">10% of gain</td>
                <td className="border p-3">Taxed based on income tax slab</td>
                <td className="border p-3">LTCG applicable if total exceeds Rs. 1 Lakh in a financial year.</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="border p-3">Immovable Property</td>
                <td className="border p-3">&#62; 24   months</td>
                <td className="border p-3">20% with inflation indexation</td>
                <td className="border p-3">Taxed based on income tax slab</td>
                <td className="border p-3"></td>
              </tr>
              <tr>
                <td className="border p-3">Movable Property</td>
                <td className="border p-3">&#62; 36 months</td>
                <td className="border p-3">20% with inflation indexation</td>
                <td className="border p-3">Taxed based on income tax slab</td>
                <td className="border p-3">No tax for LTCG reinvested in approved assets.</td>
              </tr>
              <tr className="bg-[#f9f9f9]">
                <td className="border p-3">Privately held Stocks</td>
                <td className="border p-3">&#62; 24 months</td>
                <td className="border p-3">20% with inflation indexation</td>
                <td className="border p-3">Taxed based on income tax slab</td>
                <td className="border p-3"></td>
              </tr>
            </table>
          </div>
          <p className="mt-3"><b>Note:</b> Taxes mentioned do not include any surcharge levied on income tax.</p>
        </div>
        <br />
        <hr />
        <br />

        <div id="exemptionofcapitalgain">
          <p className="text-4xl">Tax Exemption on Capital Gain</p>
          <br />
          <p>Because capital gains tax tends to erode a significant portion of earnings, it becomes critical for individuals to utilize tax-saving strategies to help them reduce their tax liability. To assist individuals in minimizing their capital gains tax liability, the government provides a list of exemptions under capital gains. These tax exemptions are known as capital gains exemptions.</p>
        </div>

        <br />


        <div id="exemptionundersection54">
          <p className="text-3xl">
            Exemption Under Section 54: Sale of House Property on Purchase of
            Another House Property
          </p>
          <br />
          <p>
            The exemption on two house properties shall be available once in a
            lifetime to a taxpayer, provided the capital gains do not exceed Rs. 2
            crores. The taxpayer is only required to invest the amount of capital
            gains, not the complete sale proceeds. The exemption will be limited
            to the total capital gain on sale if the purchase price of the new
            property is higher than the amount of capital gains.
          </p>
          <br />
          <p>
            The following conditions must be met in order to enjoy the benefit:
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                The new property can be purchased either one year before or two
                years after the previous property has been sold.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Gains can also be invested in property construction, but
                construction must be completed within three years of the sale date.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                In the 2014-15 Budget, it was made clear that only one house
                property could be purchased or built with capital gains to qualify
                for this exemption.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Please keep in mind that this exemption can be revoked if the new
                property is sold within three years of its purchase or completion of
                construction.
              </p>
            </li>
          </ul>
          <br />
          <p className="text-3xl">
            Exemption Under 54B: Transfer of Land Used for Agricultural Purposes
          </p>
          <br />
          <p>
            An exemption is available under Section 54B when you make short-term
            or long-term capital gains from the transfer of land used for
            agricultural purposes – by an individual, the individual's parents, or
            a Hindu Undivided Family (HUF) – for two years prior to the sale. The
            exempt amount is the lesser of the investment in a new asset or the
            capital gain. You must reinvest in new agricultural land within two
            years of the transfer date.
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                The new agricultural land purchased to claim capital gains exemption
                should not be sold within three years of its purchase date.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                If you are unable to purchase agricultural land before the due date
                for filing your income tax return, the amount of capital gains must
                be deposited in any branch (except rural branches) of a public
                sector bank or IDBI Bank before the due date.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Exemptions can be claimed for the amount deposited. If the amount
                deposited under the Capital Gains Account Scheme was not used to
                purchase agricultural land, it shall be treated as capital gains of
                the year in which the period of two years from the date of sale of
                land elapsed.
              </p>
            </li>
          </ul>
          <br />
          <p className="text-3xl">
            Exemption Under Sections 54 E, 54EA, and 54EB – Profits from
            investments in certain securities
          </p>
          <br />
          <p>
            This capital gains exemption applies to capital gains derived from the
            transfer of long-term capital assets. Individuals can take advantage
            of such long-term capital gain exemptions if they reinvest in
            particular securities such as targeted debentures, UTI units,
            government securities, government bonds, and so on.
          </p>
          <br />
          <p>The following conditions must be met–</p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Individuals must reinvest in such new securities within six months
                of the transfer of capital assets.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                If the individual plans to sell the new securities before 3 years or
                36 months, the previously offered exemption would be deducted from
                the total cost to determine the capital gains.
              </p>
            </li>
          </ul>
          <br />
          <p>
            It is important to note that any loan availed against these securities
            before 3 years would be treated as a capital gain.
          </p>
          <br />
          <p className="text-3xl">
            Exemption Under Section 54EC – Profits from the sale of a long-term
            capital asset are exempt from tax if reinvested in specific long-term
            assets.
          </p>
          <br />
          <p>
            Long-term capital gains on the sale of long-term assets would be
            qualified for long-term capital gain exemption. Individuals will be
            eligible for such exemptions if they reinvest their proceeds in assets
            of either the Rural Electrification Corporation or the NHAI.
          </p>
          <br />
          <p>
            Such capital exemptions are available if and only if the following
            conditions are met:
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Individuals reinvest the proceeds into specified assets within six
                months of the asset's sale.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Capital gains should not exceed the amount invested. If only a
                portion of the gains were reinvested, the capital gain exemption
                would apply only to the amount that was reinvested.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Specific assets must be held for a minimum of 36 months.
              </p>
            </li>
          </ul>
          <br />
          <p className="text-3xl">
            Exemption Under Section 54EE – Profits from a transfer of investments.
          </p>
          <br />
          <p>
            Capital gains derived from the transfer of long-term capital assets
            would be eligible for a capital gain exemption if –
          </p>
          <br />
          <ul>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                Individuals should reinvest their proceeds within six months of
                receiving them.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                If individuals sell their new securities before 36 months, the
                previously offered exemption is subtracted from the cost to
                calculate capital gains.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                If a loan is taken out against new securities before 36 months, the
                capital gains are taxed.
              </p>
            </li>
            <li>
              <BsCheck2Circle color="#0F52BA" /><p>
                In both the current and following fiscal years, such investments
                should not exceed Rs. 50 lakh.
              </p>
            </li>
          </ul>
          <br />
          <p className="text-3xl">
            Exemption Under Section 54F: Capital gains on the sale of any asset
            other than a home.
          </p>
          <br />
          <p>
            Exemption under Section 54F is available when capital gains are
            realised from the sale of a long-term asset other than a home. To
            qualify for this exemption, you must invest the entire sale
            consideration, not just the capital gain, in the purchase of a new
            residential house property. Purchase the new property either one year
            before or two years after the previous one. You can also use the
            profits to fund the construction of a home. The construction, however,
            must be completed within three years of the date of sale.
          </p>
          <br />
          <p>
            In Budget 2014-15, it was stated that only one house property could be
            purchased or built from the sale consideration in order to claim this
            exemption. This exemption can be revoked if the new property is sold
            within three years of purchase. If you meet the mentioned conditions
            and invest the complete sale proceeds in the new house, the entire
            capital gain will be tax-free.
          </p>
          <br />
          <p>
            However, if you invest a portion of the sale proceeds, the capital
            gains exemption will be calculated as follows: capital gains x cost of
            new house /net consideration = capital gains x cost of new house /net
            consideration.
          </p>
          <br />
          <p>
            Under Capital Gains, any profit that is made from a capital asset transfer during the year is taxable. Let us help you navigate the complexities of capital gains taxation and optimize your returns.
          </p>
          <br />

          <p className="text-3xl">
            Amendment to Section 54 - Capital Gain Exemption
          </p>
          <br />
          <p>
            Investing in property has long been a favored strategy for individuals seeking secure and potentially profitable investments. The approach of buying property, holding it for a few years, and selling it at a higher price has been embraced by many as a reliable investment mantra.
          </p>
          <br />
          <p>
            In many cases, the owners of residential properties need to sell their houses due to reasons like moving to a new city, switching jobs, retirement, etc. Under Section 54 of the Income Tax Act, if the seller of a residential property acquires or constructs another residential property from that amount, he or she gets benefits from capital gains tax. In this case, the objective is not to earn income from selling the old house but to acquire another suitable house. In other words, when an assessee sells a residential property and purchases or constructs another residential house property, he or she gets an exemption from capital gains under Section 54 of the Income Tax Act.
          </p>
          <br />
          <p>
            <b>Revised Section 54 –</b> For individuals or Hindu Undivided Families (HUFs) selling a residential house property (Long Term Capital Asset), the exemption on capital gains will be limited to Rs. 10 crore. Even if the new house purchased exceeds this limit, the maximum exemption allowed will be capped at Rs. 10 crore. For instance, if the capital gain is Rs. 18 crore and the individual buys a new house worth Rs. 18 crore, the exemption will be restricted to Rs. 10 crore.
          </p>
          <p>
            <b>Revised Section 54F –</b> Similarly, for individuals or HUFs selling a capital asset other than residential property (Long Term Capital Asset), the maximum exemption on capital gains will also be limited to Rs. 10 crore. Any investment exceeding this limit will not be considered for exemption. A provision is added to exclude the portion of net consideration exceeding Rs. 10 crore from the calculation of exemption under this section. For example, if the consideration from selling a plot is Rs. 15 crore, with a capital gain of Rs. 8 crore, and the individual invests Rs. 12 crore in a new residential house, the exempted gain will be calculated as Rs. 8 * 10/15 = Rs. 5.33 crore, and the taxable amount will be Rs. 8 - 5.33 crore = Rs. 2.67 crore.
          </p>
          <br />
          <p>
            These amendments also apply to the provisions related to Capital Gains Accounts Scheme (CGAS), ensuring that the maximum exemption allowed is restricted to Rs. 10 crore. These changes aim to streamline and regulate the exemption provisions under the capital gains head.
          </p>
        </div>

        <br />
        <hr />
        <br />

        <div id="itrfilling-for-capitalgains">
          <p className="text-4xl">ITR Filing for Capital Gains</p>
          <br />
          <p>If you have any capital gains in the previous year, you must mandatorily file ITR. Capital gains/losses during the year have to be reported in ITR-2 and ITR-3. You can also claim the available exemptions while filing your ITR.</p>
          <br />
          <p>However, filing ITR for capital gains can be complicated. Don’t worry! Our tax experts can help you file your ITR while ensuring that you don’t miss out on any potential deductions. If you are looking to save income tax on capital gains</p>
          <br />
        </div>

        <div id="faqcapitalgain" className="p-10 xs:p-4 mb-10 bg-100 bg-[#C6DBFA]">
          <h1 className="text-4xl text-900 text-[#1E1E24]">Frequently Asked Questions</h1>
          {capital.map((item) => {
            return (
              <>
                <div key={item.id}>
                  <p className="text-900 text-[#1E1E24] font-bold text-xl pt-10 pb-5 flex items-center gap-2.5 xs:text-lg xs:leading-6">
                    <span className="text-base xs:mt-2 text-center flex items-center justify-center bg-900 bg-[#1E1E24] question p-2 xs:leading-[100%] h-6 w-6 xs:text-sm xs:rounded-[100%] rounded-full  font-normal text-white">
                      Q
                    </span>
                    {item.question}
                  </p>
                  <p className="text-base text-justify text-sm">Ans: {item.answer}</p>

                  {/* <div className='border-solid border border-sky-900 my-4 w-1/4'></div> */}
                </div>
              </>
            );
          })}
        </div>

        <br />
        <hr />
        <br />
      </div>
      <div className='fix-watsapp-icon'>
      <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default CapitalGainsTax;
