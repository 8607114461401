import React from 'react'
import Tabs from "./Tabs";
import { BsWhatsapp } from "react-icons/bs"

const pricedemo = () => {
    const tabData = [
        { label: "ITR Filling" },
        { label: "Tax Planning" },
        { label: "Notice" },
    ];
    return (
        <div className='main-containerr'>
            <Tabs tabs={tabData} />
            <div className='fix-watsapp-icon'>
            <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
            </div>
        </div>
    )
}

export default pricedemo
