import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ButtonLoader from "../ButtonLoader";
import { BsWhatsapp } from "react-icons/bs";

const CapitalGainTax = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(name, email, phone);
    const date = new Date().toDateString();
    const API = process.env.REACT_APP_API_URL;

    let data = {
      "date": date,
      "event_name": "Contact",
      "name": name,
      "email": email,
      "phone_number": phone,
      "ref": "Capital gain tax"
    }

    try {
      if (phone.length === 10) {
        const result = await axios.post(API, data,
          {
            headers: {
              'Content-Type': "text/plain;charset=utf-8"
            },
            'mode': "no-cors"
          }).then((resp) => {
            if (resp.status === 200) {

              setLoading(false);
              setName('');
              setEmail('');
              setPhone('');
              Swal.fire(
                'Thank You For Inquiry , we will contact You soon on Whatsapp',
                'Your form is succesfully submitted!',
                'success'
              )
            }


          }).catch(error => {

            setLoading(false);
            Swal.fire(
              "Something went wrong",
              "Try after few minutes",
              "error"
            );

          });
        // const googleSheet = await axios.post(SHEET_API, formData);

        // if (result.status === 200 && googleSheet.status === 200) {
        console.log("data", result)

      } else {
        Swal.fire(
          "Something went wrong",
          "Mobile Number Not Valid",
          "error"
        );
      }


    } catch (error) {
      setLoading(false);
      Swal.fire(error.message, "Something went wrong", "error");
    }
  };

  return (
    <div className="main-containerr">
      <div className="w-[100%] mx-auto">
        <div className="bg-[#F5F6F7] xl:h-full 2xl:h-full">
          <div className="px-6 py-8 xs:px-0 xs:py-0 w-4/5 mx-auto xs:w-full sm:w-full md:w-full xl:w-9/12 2xl:w-9/12 xl:h-[600px] 2xl:h-[600px]">
            <div className=" py-12 grid grid-cols-2 gap-9 xs:py-6 sm:py-6 md:py-8 xs:px-4 sm:px-4 md:px-6 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
              <div>
                <h1 className="text-[#0c2343] text-3xl font-normal xs:text-xl sm:text-xl md:text-xl">
                  Hire Expert CA's for <br />
                  <p className="my-2 text-[40px] font-extrabold xs:text-2xl sm:text-2xl md:text-2xl xs:my-1 sm:my-1 md:my-1">
                    Income <span className="text-[#1D70ED]"> Tax </span> Return
                    Filing
                  </p>
                  and Get Maximum Refunds
                </h1>
                <div className="mt-8">
                  <div className="flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      Authorised by Income Tax Department
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[17px]">
                      India’s Most Trusted Tax Filing Partner (4.8 Service Rating)
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      Lowest Tax Filing Fees in India
                    </h3>
                  </div>
                  <div className="pt-3 flex items-center gap-3">
                    <img className="w-6" src="tick-icon.webp" />
                    <h3 className="font-semibold text-[18px]">
                      Reliable and Secure Return Filing Platform
                    </h3>
                  </div>
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                className="w-4/5 mx-auto bg-[#ffffff80] box-shadow rounded-[30px] py-6 px-8 border-b border-[#0F52BA] xs:w-full sm:w-full md:w-full"
              >
                <h1 className="py-2 text-xl font-bold text-center">
                  Calculate Capital Gain on Share or Property
                </h1>
                <div className="py-4">
                  <input
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    required
                    className="w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />

                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    required
                    className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    type="tel"
                    placeholder="Mobile Number"
                    value={phone}
                    maxLength="10"
                    required
                    className="mt-6 w-full py-1.5 px-2.5 border border-[#ccc] rounded text-[#555] focus:outline-0 focus:border-[#66afe9]"
                  />
                </div>
                <p>
                  By submitting the form, you agree to our{" "}
                  <a href="https://sites.google.com/view/Tax2clear/terms-condition" target="_blank" className="text-[#0F52BA] hover:underline">
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a href="https://sites.google.com/view/Tax2clear/privacy-policy" target="_blank" className="text-[#0F52BA] hover:underline">
                    Privacy Policy
                  </a>{" "}
                </p>
                <button
                  type="submit"
                  className="text-white bg-[#0F52BA] w-full rounded-[4px] mt-5 py-2 text-center"
                >
                  {loading === true ? <ButtonLoader /> : "Submit "}
                </button>
              </form>
            </div>
          </div>
        </div>
        <br />
        <div className="w-3/4 mx-auto  ">
          <p className="text-2xl font-semibold">
            Calculate Capital Gain on Share or Property
          </p>
          <br />
          <p>
            Calculating capital gains on shares or property can be a complex
            process, involving many steps. If you need advice on how to save the
            most money on your capital gains, our team can help. Contact us for
            expert assistance in navigating the capital gains tax rules,
            identifying tax-saving opportunities, and optimizing your tax
            liability."
          </p>
          <br />
          <p className="text-2xl font-semibold">Capital Gains on Shares</p>
          <br />
          <p>
            Capital appreciation is the growth in the price of an asset which
            gives it a higher value than its purchase price. The price of assets
            fluctuates according to their performance in the market.
          </p>
          <br />
          <p>
            If investors end up selling this asset at a higher price than that at
            which he or she had purchased it, the profit is known as capital gain
            on equity shares.
          </p>
          <br />
          <p>
            Wealth gain on shares is divided into two categories depending on the
            time for which the shares are held by investors. It may be short term
            (if term of holding the assets less than 12 months) or long term (if
            the term of holding these assets is more than 12 months, 24 months or
            36 months, depending on the type of asset) capital gain on shares.
          </p>
          <br />
          <p className="text-2xl font-semibold">
            Long Term Capital Gain on Shares
          </p>
          <br />
          <p>
            Long term capital gain on share is calculated by deducting the sale
            price and cost of acquisition of an asset that has been held for more
            than 12 months by an investor. This is given by the net profit that
            investors earn while selling the asset.
          </p>
          <br />
          <p>
            However, this span of 12 months is considered only in case of listed
            equity shares. These are the shares that are traded through exchanges
            like NSE, BSE, etc. For unlisted equity shares, long term capital
            gains are generated for assets held for 24 months to 36 months or
            more.
          </p>
          <br />
          <p className="text-2xl font-semibold">
            Short Term Capital Gain on Shares
          </p>
          <br />
          <p>
            Gains generated from shares held for a period shorter than 36 months
            (for unlisted equity shares) or 12 months (for listed equity shares)
            are considered short term capital gain on shares.
          </p>
          <br />
          <p>
            When it comes to profitability between long term capital assets and
            short term ones, investors often choose to invest in the long term
            equity shares because of the tax benefits offered on them.
          </p>
          <br />
          <p className="text-2xl font-semibold">
            What is Long Term Capital Gain?
          </p>
          <br />
          <p>
            Investments that provide returns over a longer period of time are
            called as long-term capital gains or LTCG. All the investments that
            offer returns in periods that range between 1 and 3 years can be
            called as long-term capital gains.
          </p>
          <br />
          <p>
            This means that if a person has held an investment for 3 years before
            transferring it, then the returns from the investment at the time of
            transfer it will be considered a long-term capital gains. Some of the
            investments that can generate long-term capital gains are:
          </p>
          <br />
          <ul className="list-disc list-inside">
            <li className="font-semibold text-[16px] p-2">Sale of Property</li>
            <li className="font-semibold text-[16px] p-2">
              Sale of Agricultural Land
            </li>
            <li className="font-semibold text-[16px] p-2">
              Mutual Fund Investments
            </li>
            <li className="font-semibold text-[16px] p-2">Stocks</li>
          </ul>
          <br />
          <p className="text-2xl font-semibold">
            What is Short Term Capital Gain?
          </p>
          <br />
          <p>
            Short term capital gain refers to any capital gain/profit which an
            individual gets on sale of short term capital assets. Assets like
            shares that are listed on a recognised stock exchange and has been
            held for less than 12 months, are treated as short-term capitals. The
            proceeds earned through them are treated as short-term capital gains.
          </p>
          <br />
          <p>
            Such shares include Government securities, debentures, equity-oriented
            Mutual Funds, UTI units and Zero-Coupon Bonds
          </p>
          <br />
          <p>Cost Inflation Index (CII) from 2015-16 to 2022-23</p>
          <br />
          <table className="border">
            <thead>
              <tr>
                <th className="border px-3 py-5">Financial Year </th>
                <th className="border px-3 py-5">Cost Inflation Index </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-3 py-5">2015-2016</td>
                <td className="border px-3 py-5">254</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">2016-2017</td>
                <td className="border px-3 py-5">264</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">2017-2018</td>
                <td className="border px-3 py-5">272</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">2018-2019</td>
                <td className="border px-3 py-5">280</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">2019-2020</td>
                <td className="border px-3 py-5">289</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">2020-2021</td>
                <td className="border px-3 py-5">301</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">2021-2022</td>
                <td className="border px-3 py-5">317</td>
              </tr>
              <tr>
                <td className="border px-3 py-5">2022-2023</td>
                <td className="border px-3 py-5">331</td>
              </tr>
            </tbody>
          </table>

          <br />
          <p className="text-2xl font-semibold">Long Term Capital Gains Tax</p>
          <br />
          <p>
            Previously, long term capital gains included under Section 10(38) of
            the Income Tax Act were exempt from taxation. However, after the
            reforms introduced in the 2018-19 Union Budget, the previously exempt
            capital gains are now subject to taxation without indexing if the
            quantum of gain exceeds Rs. 1 Lakh.
          </p>
          <br />
          <p className="text-2xl">Taxation of Short Term Capital Gains</p>
          <br />
          <p>
            There is a different rule for taxation of LTCG and STCG on shares. For
            instance, the STCG that falls under Section 111A of the Income Tax Act
            is liable to be charged at a rate of 15%. The STCG under this Section
            includes equity shares and equity-oriented Mutual Funds that were sold
            on or after 1st October 2004 on any recognised stock exchange, and
            fall under Securities Transaction Tax.
          </p>
          <br />
          <p>
            Apart from the above two tax regimes on capital gains, there is a
            third type of tax implication on capital gains that is levied on
            overseas investments.
          </p>
          <br />
          <p className="text-2xl font-semibold">
            Tax Implications of Capital Gains on Foreign Shares
          </p>
          <br />
          <p>
            Capital gains from foreign investment can be taxed twice, once in
            India and once in the country where the shares are held. Under this
            double taxation, the long term capital gains from foreign shares will
            be taxed at 20% while the short term capital gains are taxed at 30%.
            However, individuals can avoid the double taxation liability under
            Section 91 of the Income Tax Act, 1961.
          </p>
          <br />
          <p>
            Therefore, it is crucial for investors to conduct extensive research
            on their investments to ensure that they understand the tax
            liabilities on both their short term and long term assets.
          </p>
          <br />
          <p className="text-2xl font-semibold">
            Ways to Reduce Capital Gains Tax
          </p>
          <br />
          <p>
            Generally, the capital gains tax you have to pay when selling a
            property runs in lakhs. However, you can substantially reduce it by
            using one of the following methods:
          </p>
          <br />
          <ol className="list-decimal">
            <li className="font-semibold text-[16px] p-2">
              Exemptions under Section 54F, when you buy or construct a
              Residential Property
            </li>
            <li className="font-semibold text-[16px] p-2">
              Purchase Capital Gains Bonds under Section 54EC
            </li>
            <li className="font-semibold text-[16px] p-2">
              Investing in Capital Gains Accounts Scheme
            </li>
            <li className="font-semibold text-[16px] p-2">
              Invest for the long term
            </li>
            <li className="font-semibold text-[16px] p-2">
              Take advantage of tax-deferred retirement plans
            </li>
            <li className="font-semibold text-[16px] p-2">
              Use capital losses to offset gains
            </li>
            <li className="font-semibold text-[16px] p-2">
              Pick your cost basis
            </li>
          </ol>
          <br />
        </div>
      </div>
      <div className='fix-watsapp-icon'>
        <a target="_blank" href='https://api.whatsapp.com/send/?phone=919328343844&text=Tax2clear&type=phone_number&app_absent=0'><p className=' w-[70px] xs:w-[50px] rounded-full bg-[#25D366] p-4 xs:p-2 fixed bottom-5 xs:right-1 xs:bottom-3 z-20 right-10'><BsWhatsapp className='text-4xl text-[#fff]' /></p></a>
      </div>
    </div>
  );
};

export default CapitalGainTax;
